import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import { appliedReferralCodesProvider } from "./appliedReferralCodesProvider";
import { autovioEmployeesProvider } from "./autovioEmployeesProvider";
import { branchesProvider } from "./branchesProvider";
import { catalogProvider } from "./catalogProvider";
import { drivingLessonsProvider } from "./drivingLessonsProvider";
import { drivingLicenseAuthorityFormsProvider } from "./drivingLicenseAuthorityFormsProvider";
import { drivingLicenseAuthorityInstructionsProvider } from "./drivingLicenseAuthorityInstructionsProvider";
import { drivingLicenseAuthoritiesProvider } from "./drivingLicenseAuthoritiesProvider";
import { drivingSchoolsProvider } from "./drivingSchoolsProvider";
import { examLocationsProvider } from "./examLocationsProvider";
import { invoicesProvider } from "./invoicesProvider";
import { theoryLessonsProvider } from "./theoryLessonsProvider";
import { instructorsProvider } from "./instructorsProvider";
import { studentsProvider } from "./studentsProvider";
import { usersProvider } from "./usersProvider";
import { instructorFavoriteLocationsProvider } from "./instructorFavoriteLocationsProvider";
import { adasProvider } from "./adasProvider";
import {
  carsProvider,
  motorcyclesProvider,
  simulatorsProvider,
  trailersProvider,
  vehiclesProvider,
} from "./resourceProviders";
import { balanceProvider } from "./balanceProvider";
import { paymentsProvider } from "./paymentsProvider";
import { saldoProvider } from "./saldoProvider";
import { bookedQuotesProvides } from "./bookedQuotesProvider";
import { calendarEventsProvider } from "./calendarEventsProvider";
import { coursesProvider } from "./coursesProvider";
import { theoryExamSimulationResultsProvider } from "./theoryExamSimulationResultsProvider";
import { dunningProvider } from "./dunningProvider";
import { usersToBeDeletedProvider } from "./usersToBeDeletedProvider";
import { inAppNotificationsProvider } from "./inAppNotificationsProvider";
import { instructorPrivateAppointmentsProvider } from "./instructorPrivateAppointmentsProvider";
import { instructorWorkTimesProvider } from "./instructorWorkTimesProvider";
import { backendUserProvider } from "./backendUserProvider";
import { instructorRecommendationSettingsProvider } from "./instructorRecommendationSettingsProvider";
import { monthlyFinanceReportsProvider, monthlyPayoutReportsProvider } from "./financeReportsProvider";
import { drivingSchoolNotesProvider, studentNotesProvider } from "./notesProvider";
import { drivingSchoolDocumentsProvider, studentDocumentsProvider, threadDocumentsProvider } from "./documentsProvider";
import { studentStickyNotesProvider } from "./studentStickyNotesProvider";
import { workflowsProvider } from "./workflowsProvider";
import { promoCodesProvider } from "./promoCodesProvider";
import { dunningProcessesProvider } from "./dunningProcessesProvider";
import { studentDrivingLessonsProvider } from "./studentDrivingLessonsProvider";
import { calendarEventHistoryProvider } from "./calendarEventHistoryProvider";
import { performanceOverviewProvider } from "./performanceOverviewProvider";
import { autovioPayoutProvider } from "./autovioPayoutProvider.js";
import { invoiceCustomersProvider } from "./invoiceCustomersProvider";
import { performanceOverviewReportsProvider } from "./performanceOverviewReportsProvider";
import { autovioPayoutItemProvider } from "./autovioPayoutItemProvider.js";
import { creditNotesProvider } from "./creditNotesProvider.js";
import { taxConsultantExportsProvider } from "./taxConsultantsExportsProvider.js";
import { giftVouchersProvider } from "./giftVouchersProvider.js";
import { startingPointsProvider } from "./startingPointsProvider.js";
import { studentActivitiesProvider } from "./studentActivitiesProvider";
import { DateTime } from "luxon";
import { threadsProvider } from "./threadsProvider.js";
import { playgroundProvider } from "./playgroundProvider";
import { employeesProvider } from "./employeesProvider";

class CompositeDataProvider {
  private dataProviders: { dataProvider: DataProvider; resources: string[] }[];

  constructor() {
    this.dataProviders = [
      { dataProvider: appliedReferralCodesProvider as DataProvider, resources: ["appliedReferralCodes"] },
      { dataProvider: backendUserProvider as DataProvider, resources: ["backendUser", "fundingInstructions"] },
      { dataProvider: branchesProvider as DataProvider, resources: ["branches"] },
      {
        dataProvider: drivingLicenseAuthorityFormsProvider as DataProvider,
        resources: ["drivingLicenseAuthorityForms"],
      },
      {
        dataProvider: drivingLicenseAuthorityInstructionsProvider as DataProvider,
        resources: ["drivingLicenseAuthorityInstructions"],
      },
      { dataProvider: drivingLicenseAuthoritiesProvider as DataProvider, resources: ["drivingLicenseAuthorities"] },
      { dataProvider: employeesProvider as DataProvider, resources: ["employees"] },
      { dataProvider: drivingSchoolsProvider as DataProvider, resources: ["drivingSchools"] },
      { dataProvider: examLocationsProvider as DataProvider, resources: ["examLocations"] },
      { dataProvider: usersProvider as DataProvider, resources: ["users"] },
      { dataProvider: instructorsProvider as DataProvider, resources: ["instructors"] },
      { dataProvider: studentsProvider as DataProvider, resources: ["students", "examinationAssignments"] },
      { dataProvider: invoiceCustomersProvider as DataProvider, resources: ["invoiceCustomers"] },
      { dataProvider: usersToBeDeletedProvider as DataProvider, resources: ["usersToBeDeleted"] },
      { dataProvider: catalogProvider, resources: ["areas", "products", "bundles", "trainings", "offerFeedback"] },
      { dataProvider: adasProvider, resources: ["adas"] },
      { dataProvider: vehiclesProvider as DataProvider, resources: ["vehicles"] },
      { dataProvider: carsProvider as DataProvider, resources: ["cars"] },
      { dataProvider: motorcyclesProvider as DataProvider, resources: ["motorcycles"] },
      { dataProvider: trailersProvider as DataProvider, resources: ["trailers"] },
      { dataProvider: simulatorsProvider as DataProvider, resources: ["simulators"] },
      { dataProvider: autovioEmployeesProvider as DataProvider, resources: ["autovioEmployees"] },
      { dataProvider: paymentsProvider as DataProvider, resources: ["payments"] },
      { dataProvider: saldoProvider as DataProvider, resources: ["saldo"] },
      { dataProvider: balanceProvider as DataProvider, resources: ["balances"] },
      { dataProvider: monthlyFinanceReportsProvider, resources: ["monthlyFinanceReports"] },
      { dataProvider: monthlyPayoutReportsProvider, resources: ["monthlyPayoutReports"] },
      {
        dataProvider: invoicesProvider,
        resources: ["b2bInvoices", "b2cInvoices", "studentBalance"],
      },
      {
        dataProvider: creditNotesProvider,
        resources: ["b2bCreditNotes", "b2cCreditNotes"],
      },
      { dataProvider: drivingLessonsProvider as DataProvider, resources: ["drivingLessons"] },
      { dataProvider: studentDrivingLessonsProvider as DataProvider, resources: ["studentDrivingLessons"] },
      { dataProvider: theoryLessonsProvider as DataProvider, resources: ["theoryLessons"] },
      { dataProvider: calendarEventsProvider as DataProvider, resources: ["calendarEvents"] },
      { dataProvider: coursesProvider as DataProvider, resources: ["courses"] },
      {
        dataProvider: instructorPrivateAppointmentsProvider as DataProvider,
        resources: ["instructorPrivateAppointments"],
      },
      {
        dataProvider: instructorRecommendationSettingsProvider as DataProvider,
        resources: ["instructorRecommendationSettings"],
      },
      {
        dataProvider: instructorWorkTimesProvider as DataProvider,
        resources: ["instructorWorkTimes"],
      },
      { dataProvider: bookedQuotesProvides as DataProvider, resources: ["bookedQuotes"] },
      { dataProvider: theoryExamSimulationResultsProvider as DataProvider, resources: ["theoryExamSimulationResults"] },
      {
        dataProvider: dunningProvider as DataProvider,
        resources: [
          "studentsWithNegativeBalance",
          "candidatesForBlocking",
          "candidatesForFirstDunningNotice",
          "candidatesForFirstDunningNoticeParkingLot",
          "candidatesForSecondDunningNotice",
          "candidatesForSecondDunningNoticeParkingLot",
          "candidatesForHandoverToPairFinance",
          "candidatesForHandoverToPairFinanceParkingLot",
          "studentsHandedOverToPairFinance",
          "candidatesForReactivation",
        ],
      },
      { dataProvider: instructorFavoriteLocationsProvider as DataProvider, resources: ["instructorFavoriteLocations"] },
      { dataProvider: inAppNotificationsProvider as DataProvider, resources: ["inAppNotifications"] },
      { dataProvider: studentNotesProvider as DataProvider, resources: ["studentNotes"] },
      { dataProvider: drivingSchoolNotesProvider as DataProvider, resources: ["drivingSchoolNotes"] },
      { dataProvider: threadsProvider as DataProvider, resources: ["threads", "posts"] },
      { dataProvider: studentDocumentsProvider as DataProvider, resources: ["studentDocuments"] },
      { dataProvider: drivingSchoolDocumentsProvider as DataProvider, resources: ["drivingSchoolDocuments"] },
      { dataProvider: threadDocumentsProvider as DataProvider, resources: ["threadDocuments"] },
      { dataProvider: studentStickyNotesProvider as DataProvider, resources: ["studentStickyNotes"] },
      { dataProvider: workflowsProvider as DataProvider, resources: ["workflows"] },
      { dataProvider: promoCodesProvider as DataProvider, resources: ["promoCodes"] },
      { dataProvider: dunningProcessesProvider as DataProvider, resources: ["dunningProcesses"] },
      { dataProvider: calendarEventHistoryProvider as DataProvider, resources: ["calendarEventHistory"] },
      {
        dataProvider: performanceOverviewProvider as DataProvider,
        resources: ["performanceOverview", "advancePayments", "openInvoices"],
      },
      { dataProvider: performanceOverviewReportsProvider as DataProvider, resources: ["performanceOverviewReports"] },
      { dataProvider: autovioPayoutProvider as DataProvider, resources: ["autovioPayouts"] },
      { dataProvider: autovioPayoutItemProvider as DataProvider, resources: ["autovioPayoutItems"] },
      { dataProvider: taxConsultantExportsProvider as DataProvider, resources: ["taxConsultantExports"] },
      { dataProvider: giftVouchersProvider as DataProvider, resources: ["giftVouchers"] },
      { dataProvider: startingPointsProvider as DataProvider, resources: ["startingPoints"] },
      { dataProvider: studentActivitiesProvider as DataProvider, resources: ["studentActivities"] },
      { dataProvider: playgroundProvider as DataProvider, resources: ["playground"] },
    ];
  }

  async _delegate(methodName: string, resource: string, params: any, call: string) {
    console.info(`${call} ...`);
    try {
      const { dataProvider } = this.dataProviders.find((it) => it.resources.includes(resource)) ?? {};
      if (!dataProvider) {
        throw new Error(`Unknown resource: ${resource}`);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
      const method = dataProvider[methodName] as Function;
      const t0 = Date.now();
      const intervalId = setInterval(() => {
        const dt = Math.round((Date.now() - t0) / 1000);
        console.info(`${call} is still running after ${dt} s`);
      }, 1000);
      try {
        const result = await method.bind(dataProvider, resource, params)();
        const durationInSeconds = (Date.now() - t0) / 1000;
        const durationFormated = `${(Math.round(durationInSeconds * 10) / 10).toFixed(1)} s`;
        const records = methodName === "getOne" ? result && [result.data] : methodName.startsWith("get") && result.data;
        if (Array.isArray(records)) {
          if (records.length === 1) {
            console.info(`${call} => retrieved 1 record in ${durationFormated}`);
          } else {
            console.info(`${call} => retrieved ${result.data.length} records in ${durationFormated}`);
          }
        } else {
          console.info(`${call} took ${durationFormated}`);
        }
        return result;
      } finally {
        clearInterval(intervalId);
      }
    } catch (error) {
      console.error(`${call} failed:`, error);
      throw error;
    }
  }

  async getList(resource: string, params: GetListParams) {
    const dataReceivedCallback = _dataExpected("getList", resource, params);
    // Keep the log message short if there are objects in params.meta ...
    const { meta, ...params_ } = params;
    if (meta) {
      (params_ as any).meta = Object.fromEntries(
        Object.entries(meta).map(([k, v]) => [k, typeof v === "object" ? "..." : v]),
      );
    }
    const call = `dataProvider.getList(${JSON.stringify(resource)}, ${JSON.stringify(params_)})`;
    const result = await this._delegate("getList", resource, params, call);
    dataReceivedCallback(result);
    return result;
  }

  async getOne(resource: string, params: GetOneParams) {
    const dataReceivedCallback = _dataExpected("getOne", resource, params);
    const call = `dataProvider.getOne(${JSON.stringify(resource)}, ${JSON.stringify(params)})`;
    const result = await this._delegate("getOne", resource, params, call);
    dataReceivedCallback(result);
    return result;
  }

  async getMany(resource: string, params: GetManyParams) {
    const dataReceivedCallback = _dataExpected("getMany", resource, params);
    const { ids, ...restParams } = params;
    const params_ =
      ids.length <= 3
        ? JSON.stringify(params)
        : (() => {
            const s = JSON.stringify(restParams);
            if (s === "{}") {
              return `{"ids":[... ${ids.length} ids ...]}`;
            } else {
              return `{"ids":[... ${ids.length} ids ...],${s.substring(1)}}`;
            }
          })();
    const call = `dataProvider.getMany(${JSON.stringify(resource)}, ${params_})`;
    const result = await this._delegate("getMany", resource, params, call);
    dataReceivedCallback(result);
    return result;
  }

  async getManyReference(resource: string, params: GetManyReferenceParams) {
    const dataReceivedCallback = _dataExpected("getManyReference", resource, params);
    const call = `dataProvider.getManyReference(${JSON.stringify(resource)}, ${JSON.stringify(params)})`;
    const result = await this._delegate("getManyReference", resource, params, call);
    dataReceivedCallback(result);
    return result;
  }

  create(resource: string, params: CreateParams) {
    const call = `dataProvider.create(${JSON.stringify(resource)}, ${JSON.stringify(params)})`;
    return this._delegate("create", resource, params, call);
  }

  update(resource: string, params: UpdateParams) {
    const params_ = { ...params };
    if (params_.previousData) {
      params_.previousData = "...";
    }
    const call = `dataProvider.update(${JSON.stringify(resource)}, ${JSON.stringify(params_).replace(
      '"previousData":"..."',
      '"previousData":...',
    )})`;
    return this._delegate("update", resource, params, call);
  }

  updateMany(resource: string, params: UpdateManyParams) {
    const call = `dataProvider.updateMany(${JSON.stringify(resource)}, ${JSON.stringify(params)})`;
    return this._delegate("updateMany", resource, params, call);
  }

  delete(resource: string, params: DeleteParams) {
    const call = `dataProvider.delete(${JSON.stringify(resource)}, ${JSON.stringify(params)})`;
    return this._delegate("delete", resource, params, call);
  }

  deleteMany(resource: string, params: DeleteManyParams) {
    const call = `dataProvider.deleteMany(${JSON.stringify(resource)}, ${JSON.stringify(params)})`;
    return this._delegate("deleteMany", resource, params, call);
  }
}

export const compositeDataProvider = new CompositeDataProvider() as DataProvider;

let _dataExpected: (methodName: string, resource: string, params: any) => (result: any) => void = () => () => {};

export interface DataSnapshot {
  url: string;
  createdAt: DateTime;
  data: Array<{
    methodName: string;
    resource: string;
    params: any;
    result: any;
  }>;
}

export function recordDataSnapshot(refresh: () => void): Promise<DataSnapshot> {
  const dataSnapshot: DataSnapshot = { url: window.location.href, createdAt: DateTime.now(), data: [] };
  return new Promise((resolve) => {
    let dataExpectedCounter = 0;
    const onIdle = () => {
      _dataExpected = () => () => {};
      resolve(dataSnapshot);
    };
    let timeout = setTimeout(onIdle, 757);
    _dataExpected = (methodName, resource, params) => {
      clearTimeout(timeout);
      dataExpectedCounter += 1;
      return (result: any) => {
        dataExpectedCounter -= 1;
        dataSnapshot.data.push({ methodName, resource, params, result });
        if (dataExpectedCounter === 0) {
          timeout = setTimeout(onIdle, 757);
        }
      };
    };
    // Calling refresh() will trigger all data for the current page to be loaded ...
    refresh();
  });
}
