import type { Car } from "../providers/resourceProviders";
import { CarIcon } from "../icons/CarIcon";
import { Create, EditBase, Resource, Tab, useNotify, useRedirect } from "react-admin";
import { CarForm } from "../details/vehicles/CarForm";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { Column } from "../misc/Column";
import { overviewCardStyle } from "../misc/backofficeTheme";
import { Tabs } from "../misc/Tabs";
import { ResourceCalendar } from "../details/vehicles/ResourceCalendar";
import { grants } from "../backoffice.access_control";
import { VehicleDetailsHeader } from "../details/vehicles/VehicleDetailsHeader";
import { useSwitchDrivingSchoolIfNecessary } from "../hooks/useSwitchDrivingSchoolIfNecessary";

function _CarsList() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  useEffect(() => {
    if (drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/fleet#cars`);
    }
  });
  return drivingSchoolId ? null : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

function _CarCreate() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  const notify = useNotify();
  useEffect(() => {
    if (!grants.includes("Vehicle:create") && drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/fleet#cars`);
    }
  }, []);

  if (!grants.includes("Vehicle:create")) {
    return null;
  }

  return drivingSchoolId ? (
    <Create
      title={<BackofficeRecordTitle />}
      mutationOptions={{
        onSuccess: ({ name }: { name: string }) => {
          window.onNavigation = () => {
            delete window.onNavigation;
            notify(`Auto ${name} erfolgreich gespeichert`, { type: "success" });
          };
          redirect(`/drivingSchools/${drivingSchoolId}/fleet#cars`);
        },
      }}
    >
      <CarForm mode="create" sx={{ padding: overviewCardStyle.padding }} />
    </Create>
  ) : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

function _CarEdit() {
  useSwitchDrivingSchoolIfNecessary((car: Car) => car.entitledDrivingSchools[0]);
  const notify = useNotify();
  return (
    <EditBase
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess: () => notify("Änderungen erfolgreich gespeichert", { type: "success" }) }}
    >
      <Tabs
        sx={{ ...overviewCardStyle, padding: "10px 30px" }}
        header={<VehicleDetailsHeader />}
        syncWithLocation={false}
        syncWithHash
        separateCards
      >
        <Tab label="Kalender" value="calendar">
          <ResourceCalendar />
        </Tab>
        {grants.includes("Vehicle:edit") && (
          <Tab label="Fahrzeug-Daten" value="data">
            <CarForm mode="edit" />
          </Tab>
        )}
      </Tabs>
    </EditBase>
  );
}

export const carsResource = (
  <Resource
    key="cars"
    name="cars"
    icon={CarIcon}
    list={_CarsList}
    create={_CarCreate}
    edit={_CarEdit}
    options={{ label: "Autos", createTitle: "Neues Auto" }}
    recordRepresentation={(record: Car) => {
      return record.deletedAt
        ? `${record.name} (gelöscht am: ${record.deletedAt.toLocaleString(undefined, { locale: "de" })})`
        : record.name;
    }}
  />
);
