import { GetListParams, GetListResult, GetOneParams, GetOneResult } from "react-admin";
import { baseUrl as serverBaseUrl, EmployeeDto, serverAPI } from "../api/server.api";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";

export interface Employee {
  id: string;
  drivingSchoolId?: string;
  name: string;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  avatarUrl: string;
  disabled: boolean;
  roles: Array<string>;
  _sortOrder: string;
}

export type DrivingSchoolEmployee = Defined<Employee, "drivingSchoolId">;

class EmployeesProvider extends AbstractDataProvider<Employee> {
  async getOne(_resource: string, { id }: GetOneParams): Promise<GetOneResult<Employee>> {
    const employee = await serverAPI.getEmployee(id);
    return { data: _dtoToRecord(employee) };
  }

  async getList(_resource: string, params: GetListParams): Promise<GetListResult<Employee>> {
    const { filter, sort, pagination } = params;
    const { drivingSchoolId, ...clientSideFilter } = filter;
    if (!drivingSchoolId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId": ...}`);
    }
    const dtos = await serverAPI.getDrivingSchoolEmployees(drivingSchoolId);
    const records = dtos.map((dto) => {
      const record = _dtoToRecord(dto);
      record.drivingSchoolId = drivingSchoolId;
      return record;
    });
    return applyPagination(applySort(applyFilter(records, clientSideFilter), sort), pagination);
  }
}

export const employeesProvider = new EmployeesProvider();

function _dtoToRecord(dto: EmployeeDto): Employee {
  const { firstName, lastName, roles } = dto;
  // driving school managers first, then instructors, then customer service (APP-5289) ...
  const sortOrderPrefix = roles.includes("drivingSchoolManager")
    ? 1
    : roles.includes("drivingSchoolCustomerService")
      ? 3
      : 2;
  return {
    id: dto.firebaseAuthUserUid,
    drivingSchoolId: dto.drivingSchoolId,
    name: `${firstName} ${lastName}`.trim(),
    firstName,
    lastName,
    email: dto.email,
    phoneNumber: dto.phoneNumber,
    avatarUrl: `${serverBaseUrl}/user/${dto.firebaseAuthUserUid}/avatar`,
    disabled: dto.disabled,
    roles,
    _sortOrder: `${sortOrderPrefix} ${firstName} ${lastName}`,
  };
}
