import { AbstractDataProvider } from "./AbstractDataProvider";
import { DrivingLesson } from "../model/autovioCalendarEvents";
import { GetListParams, GetListResult, GetOneResult } from "react-admin";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { drivingLessonConverter } from "./converter/drivingLessonConverter";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import safeJsonStringify from "safe-json-stringify";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";

class StudentDrivingLessonsProvider extends AbstractDataProvider<DrivingLesson> {
  async getOne(_resource: string, { id }: { id: string }): Promise<GetOneResult<DrivingLesson>> {
    const snapshot = await getDoc(doc(firestore, `calendar_events/${id}`));
    const drivingLesson = drivingLessonConverter.fromFirestore(snapshot);
    return { data: drivingLesson };
  }

  async getList(_resource: string, { filter, sort, pagination }: GetListParams): Promise<GetListResult<DrivingLesson>> {
    const { studentUid, ...otherFilters } = filter;
    if (!studentUid) {
      throw new Error(`Unexpected filter: ${safeJsonStringify(filter)} -- expected: {studentUid:"...", ...}`);
    }
    let query_ = query(collection(firestore, `/calendar_events`));
    if (restrictAccessToDrivingSchoolIds) {
      if (restrictAccessToDrivingSchoolIds.length === 1) {
        query_ = query(query_, where("drivingSchoolUid", "==", restrictAccessToDrivingSchoolIds[0]));
      } else {
        query_ = query(query_, where("drivingSchoolUid", "in", restrictAccessToDrivingSchoolIds));
      }
    }
    query_ = query(
      query_,
      where("type", "==", "DrivingLesson"),
      where(`student.uid`, "==", studentUid),
      where("drivingLessonType", "!=", "theoretischePruefung"),
    );
    let { docs } = await getDocs(query_);
    docs = docs.filter((doc) => !doc.data().replacedBy);
    const drivingLessons = docs.map(drivingLessonConverter.fromFirestore);
    console.info(`Retrieved ${drivingLessons.length} driving lesson(s) for student ${studentUid}`);
    return applyPagination(applySort(applyFilter(drivingLessons, otherFilters), sort), pagination);
  }
}

export const studentDrivingLessonsProvider = new StudentDrivingLessonsProvider();
