import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import { useNotify, useRecordContext } from "react-admin";
import { useState } from "react";
import { reportError } from "../backoffice.utils";
import { serverAPI } from "../api/server.api";
import { LocalizedError } from "../utils/LocalizedError";
import { draftDunningNotice } from "../api/backend.api";
import { Student } from "../providers/studentsProvider";
import { useMutation } from "react-query";
import { useDialog } from "../hooks/useDialog";
import { SendDunningNoticeDialog, SendDunningNoticeFormValues } from "../dialogs/SendDunningNoticeDialog";
import type { EmailDraft } from "../generated/backendClient";
import { EventPropagationStopper } from "../misc/EventPropagationStopper";

export const SendFirstDunningNoticeButton = () => {
  const student = useRecordContext<Student>();
  const notify = useNotify();
  const { dialogProps, openDialog, closeDialog } = useDialog();
  const [draftingEmail, setDraftingEmail] = useState(false);
  const [dunningProcessId, setDunningProcessId] = useState<string>();
  const [draft, setDraft] = useState<EmailDraft | undefined>();

  const draftEmailAndOpenDialog = async (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDraftingEmail(true);
    try {
      const { dunningProcess, draft } = await draftDunningNotice(student, "first_dunning_notice");
      setDunningProcessId(dunningProcess.id);
      setDraft(draft);
      openDialog();
    } catch (error) {
      if (error instanceof LocalizedError) {
        notify(error.message, { type: "error" });
      } else {
        reportError("Failed to draft first dunning notice", error);
        notify("Oje, es ist ein Fehler beim Vorbereiten der 1. Mahnung aufgetreten. Bitte wende Dich an die Technik.", {
          type: "error",
        });
      }
    } finally {
      setDraftingEmail(false);
    }
  };

  const { mutateAsync: sendFirstDunningNotice } = useMutation(
    async ({ emailSubject, emailText }: SendDunningNoticeFormValues) => {
      if (!dunningProcessId) {
        throw new Error("dunningProcessId not set");
      }
      await serverAPI.sendDunningNotice({
        student,
        dunningProcessId,
        noticeType: "first_dunning_notice",
        emailSubject,
        emailText,
      });
    },
    {
      onSuccess: () => {
        notify("1. Mahnung erfolgreich versandt.", { type: "success" });
        closeDialog();
      },
      onError: (error) => {
        reportError("Failed to send first dunning notice", error);
        notify("Fehler beim Versenden der 1. Mahnung", { type: "error" });
      },
    },
  );

  return (
    <EventPropagationStopper>
      <LoadingButton
        variant="outlined"
        loading={draftingEmail}
        startIcon={<SendIcon />}
        disabled={!student}
        onClick={draftEmailAndOpenDialog}
      >
        {"1.\u00A0Mahnung"}
      </LoadingButton>
      <SendDunningNoticeDialog
        title="1. Mahnung"
        {...dialogProps}
        initialFormValues={{
          emailSubject: draft?.subject ?? "",
          emailText: draft?.text ?? "",
        }}
        onSubmit={sendFirstDunningNotice}
      />
    </EventPropagationStopper>
  );
};
