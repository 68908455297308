import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import EventIcon from "@mui/icons-material/Event";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import NotesIcon from "@mui/icons-material/Notes";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StartIcon from "@mui/icons-material/Start";
import BookedIcon from "@mui/icons-material/ThumbUpOffAlt";
import InvitationIcon from "@mui/icons-material/HourglassEmpty";
import { Box, CircularProgress, Collapse, Stack, SxProps, Tooltip, TooltipProps, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { EditBase, Form, Link, SelectInput, useGetMany, useGetOne, useNotify, useRecordContext } from "react-admin";
import { reportError } from "../../backoffice.utils";
import { AddStudentToTheoryLessonButton } from "../../buttons/AddStudentToTheoryLessonButton";
import {
  ASFCourseSession,
  ASFCourseSessionAttendance,
  AutovioCalendarEvent,
  DrivingLesson,
  hasInstructor,
  isASFCourseSession,
  isDrivingLesson,
  isOtherEvent,
  isPracticalExam,
  isTheoryExam,
  isTheoryLesson,
  OtherEvent,
  PracticalExam,
  TheoryExam,
  TheoryLesson,
  TheoryLessonStudentAttendance,
} from "../../model/autovioCalendarEvents";
import {
  calendarEventTitle,
  formatDateTimeRange,
  formatTime,
  renderTheoryLessonSlots,
  renderLessonStatus,
} from "../../utils/calendar";
import { AddressDisplay } from "../AddressDisplay";
import { autovioColors } from "../backofficeTheme";
import { BookedTrainingClassDisplay } from "../BookedTrainingClassDisplay";
import { EventIdBar } from "../IdBar";
import { InstructorAvatar } from "../InstructorAvatar";
import { Money } from "../Money";
import { StudentAvatar } from "../StudentAvatar";
import { StudentDisplayName } from "../StudentDisplayName";
import { VehicleDisplay } from "../VehicleDisplay";
import { TransitionGroup } from "react-transition-group";
import { Student } from "../../providers/studentsProvider";
import { grants } from "../../backoffice.access_control";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { calendarEventsProvider } from "../../providers/calendarEventsProvider";
import { useQueryClient } from "react-query";
import { Column } from "../Column";
import { Row } from "../Row";
import { RemoveStudentFromTheoryLessonButton } from "../../buttons/RemoveStudentFromTheoryLessonButton";
import { InstructorDisplayName } from "../InstructorDisplayName";
import { SetStudentAttendanceButtons } from "../../buttons/SetStudentAttendanceButtons";

// TODO: Refactor into a separate component for the header box and for the changelog entries
type EventInfoCardProps = {
  chip?: React.ReactNode;
  changedKeys?: Array<string>;
  showIdBar?: boolean;
  showStartedAtEndedAt?: boolean;
  mode?: "beforeChange" | "afterChange";
};

export const EventInfoCard = (props: { event: AutovioCalendarEvent } & EventInfoCardProps) => {
  const { event, ...restProps } = props;
  if (isTheoryLesson(event)) {
    return <TheoryLessonEventInfoCard event={event} {...restProps} />;
  } else if (isDrivingLesson(event)) {
    return <DrivingLessonEventInfoCard event={event} {...restProps} />;
  } else if (isTheoryExam(event)) {
    return <TheoryExamEventInfoCard event={event} {...restProps} />;
  } else if (isASFCourseSession(event)) {
    return <ASFCourseSessionEventInfoCard event={event} {...restProps} />;
  } else if (isOtherEvent(event)) {
    return <OtherEventInfoCard event={event} {...restProps} />;
  }
  useEffect(() => {
    if (event) {
      reportError(`Unsupported event of type: ${event.type}`);
    }
  }, [event]);
  return <Typography color="error">Fehler beim Anzeigen</Typography>;
};

function _helpers(props: { mode?: "mainCard" | "beforeChange" | "afterChange"; changedKeys?: Array<string> }): {
  mode: "mainCard" | "beforeChange" | "afterChange";
  highlightProps: (key: string) => { isHighlighted?: boolean; highlightColor?: string };
} {
  const mode: "mainCard" | "beforeChange" | "afterChange" = props.mode ?? "mainCard";
  if (mode === "mainCard") {
    return {
      mode,
      highlightProps: () => ({}),
    };
  }
  const { changedKeys } = props;
  if (!changedKeys) {
    throw new Error(`changedKeys must be provided for mode ${mode}`);
  }
  return {
    mode,
    highlightProps: (key: string) =>
      changedKeys.includes(key)
        ? {
            isHighlighted: true,
            highlightColor: mode === "beforeChange" ? "#c79200" : "#12873b",
          }
        : { mode },
  };
}

/** See https://zpl.io/m88Oyn3 */
const TheoryLessonEventInfoCard = (props: { event: TheoryLesson } & EventInfoCardProps) => {
  const { event } = props;
  const { mode, highlightProps } = _helpers(props);
  return (
    <Column spacing="0.5em">
      <EventInfoRow {...highlightProps("instructorId")} tooltip="Fahrlehrer">
        <Link to={`/instructors/${event.instructorId}`} sx={{ display: "flex" }}>
          <InstructorAvatar instructorId={event.instructorId} size="20px" />
          <InstructorDisplayName sx={{ ml: ".5em" }} instructorId={event.instructorId} />
        </Link>
      </EventInfoRow>
      <EventInfoRow {...highlightProps("theoryUnit")} tooltip="Lektionsnummer">
        <FormatListNumberedIcon
          fontSize="small"
          sx={{ color: highlightProps("theoryUnit").highlightColor ?? "gray" }}
        />
        <Typography variant="body2">Lektion {event.theoryUnit}</Typography>
      </EventInfoRow>
      <EventInfoRow {...highlightProps("start")} tooltip="Beginn & Ende">
        <EventIcon fontSize="small" sx={{ color: highlightProps("start").highlightColor ?? "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(event.start, event.end)}</Typography>
      </EventInfoRow>
      {!!(event.bufferTimes?.preparationTime || event.bufferTimes?.wrapUpTime) && (
        <EventInfoRow {...highlightProps("bufferTimes")} tooltip="Vor- und Nachbereitungszeit">
          <MoreTimeIcon fontSize="small" sx={{ color: highlightProps("bufferTimes").highlightColor ?? "gray" }} />
          <Typography variant="body2" noWrap>
            {`Vorbereitungszeit ${event.bufferTimes?.preparationTime ?? 0} min.`}
            {" • "}
            {`Nachbereitungszeit ${event.bufferTimes?.wrapUpTime ?? 0} min.`}
          </Typography>
        </EventInfoRow>
      )}
      <EventInfoRow {...highlightProps("location")} tooltip="Ort des Theorieunterrichts">
        <PlaceOutlinedIcon fontSize="small" sx={{ color: highlightProps("location").highlightColor ?? "gray" }} />
        <Typography variant="body2">
          {event.location.type === "PostalAddress" && <AddressDisplay address={event.location} oneLine />}
          {event.location.type === "OnlineMeeting" && (
            <Link to={event.location.url} target="_blank">
              Online
            </Link>
          )}
        </Typography>
      </EventInfoRow>
      {mode === "mainCard" && (
        <>
          <EventInfoRow tooltip="Anzahl Buchungen / Maximale Teilnehmerzahl">
            <PeopleOutlineIcon fontSize="small" sx={{ color: "gray" }} />
            <Typography variant="body2">{renderTheoryLessonSlots(event)} Fahrschüler</Typography>
          </EventInfoRow>
          <div style={{ height: "10px" }} />
          <AttendeesList event={event} />
          <div style={{ height: "20px" }} />
        </>
      )}
    </Column>
  );
};

const DrivingLessonEventInfoCard = (props: { event: DrivingLesson } & EventInfoCardProps) => {
  const { event, changedKeys } = props;
  const { mode, highlightProps } = _helpers(props);
  return (
    <EventInfoCardBody
      {...{ event, mode, changedKeys }}
      showStatus={mode === "mainCard"}
      chip={props.chip}
      showIdBar={props.showIdBar}
    >
      {mode === "mainCard" && (
        <EventInfoRow tooltip="Fahrschüler">
          <StudentAvatar studentId={event.student.uid} size="20px" />
          <Link to={`/students/${event.student.uid}`} style={{ display: "flex", textDecoration: "none" }}>
            <StudentDisplayName studentId={event.student.uid} />
          </Link>
        </EventInfoRow>
      )}
      {(props.changedKeys ?? []).includes("isBooked") && (
        <EventInfoRow {...highlightProps("isBooked")} tooltip="Status">
          {event.isBooked ? <BookedIcon fontSize="small" /> : <InvitationIcon fontSize="small" />}
          <Typography variant="body2">{event.isBooked ? "Gebucht" : "Einladung"}</Typography>
        </EventInfoRow>
      )}
      <EventInfoRow {...highlightProps("start")} tooltip="Beginn & Ende">
        <EventIcon fontSize="small" sx={{ color: highlightProps("start").highlightColor ?? "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(event.start, event.end)}</Typography>
      </EventInfoRow>
      <Stack direction="row" spacing="30px">
        <EventInfoRow {...highlightProps("duration")} tooltip="Dauer">
          <ScheduleIcon fontSize="small" sx={{ color: highlightProps("duration").highlightColor ?? "gray" }} />
          <Typography variant="body2" noWrap>
            {`${Math.round(event.end.diff(event.start, "minutes").minutes)} min.`}
          </Typography>
        </EventInfoRow>
        {mode === "mainCard" && props.showStartedAtEndedAt && (
          <>
            <EventInfoRow tooltip="Gestartet um" tooltipPlacement="bottom">
              <StartIcon fontSize="small" sx={{ color: "gray" }} />
              <Typography variant="body2" noWrap>
                {event.startedAt ? formatTime(event.startedAt) : "Nicht gestartet"}
              </Typography>
            </EventInfoRow>
            <EventInfoRow tooltip="Beendet um" tooltipPlacement="bottom">
              <KeyboardTabIcon fontSize="small" sx={{ color: "gray" }} />
              <Typography variant="body2" noWrap>
                {event.endedAt ? formatTime(event.endedAt) : "Nicht beendet"}
              </Typography>
            </EventInfoRow>
          </>
        )}
      </Stack>
      {hasInstructor(event) && (
        <EventInfoRow {...highlightProps("bufferTimes")} tooltip="Transferzeiten">
          <MoreTimeIcon fontSize="small" sx={{ color: highlightProps("bufferTimes").highlightColor ?? "gray" }} />
          <Typography variant="body2" noWrap>
            Anfahrt {event.bufferTimes?.preparationTime ?? 0} min. • Abfahrt {event.bufferTimes?.wrapUpTime ?? 0} min.
          </Typography>
        </EventInfoRow>
      )}
      {event.student.bookedTrainingId && (
        <EventInfoRow {...highlightProps("bookedTrainingId")} tooltip="Zugewiesene Ausbildung">
          <CardMembershipOutlinedIcon
            fontSize="small"
            sx={{ color: highlightProps("duration").highlightColor ?? "gray" }}
          />
          <BookedTrainingClassDisplay studentId={event.student.uid} bookedTrainingId={event.student.bookedTrainingId} />
        </EventInfoRow>
      )}
      <EventInfoRow {...highlightProps("startLocation")} tooltip="Ort der Fahrstunde">
        <PlaceOutlinedIcon fontSize="small" sx={{ color: highlightProps("startLocation").highlightColor ?? "gray" }} />
        <Typography variant="body2">
          <AddressDisplay address={event.startLocation} oneLine />
        </Typography>
      </EventInfoRow>
      {event.vehicleId && (
        <VehicleDisplay
          vehicleId={event.vehicleId}
          iconSize="small"
          iconColor={highlightProps("vehicleId").highlightColor ?? "gray"}
          labelColor={highlightProps("vehicleId").highlightColor}
        />
      )}
      {event.vehicleId2 && (
        <VehicleDisplay
          vehicleId={event.vehicleId2}
          iconSize="small"
          iconColor={highlightProps("vehicleId2").highlightColor ?? "gray"}
          labelColor={highlightProps("vehicleId2").highlightColor}
        />
      )}
      <EventInfoRow {...highlightProps("price")} tooltip="Preis">
        <EuroRoundedIcon fontSize="small" sx={{ color: highlightProps("price").highlightColor ?? "gray" }} />
        <Typography variant="body2" noWrap>
          <Money cents={event.price.studentPrice.gross} /> (Einnahmen:{" "}
          <Money cents={event.price.instructorPrice.gross} />)
        </Typography>
      </EventInfoRow>
      {mode === "mainCard" && (
        <>
          {isPracticalExam(event) && (
            <EventInfoRow>
              <ExamResultForm exam={event} />
            </EventInfoRow>
          )}
          <EventExtensions event={event} />
        </>
      )}
    </EventInfoCardBody>
  );
};

const TheoryExamEventInfoCard = (props: { event: TheoryExam } & EventInfoCardProps) => {
  const { event, changedKeys } = props;
  const { mode, highlightProps } = _helpers(props);
  return (
    <EventInfoCardBody
      {...{ event, mode, changedKeys }}
      showStatus={mode === "mainCard"}
      chip={props.chip}
      showIdBar={props.showIdBar}
    >
      {mode === "mainCard" && (
        <EventInfoRow tooltip="Fahrschüler">
          <Link
            to={`/students/${event.student.uid}`}
            style={{ display: "flex", color: autovioColors.green, textDecoration: "none" }}
          >
            <StudentAvatar studentId={event.student.uid} size="20px" />
            <StudentDisplayName sx={{ ml: ".5em" }} studentId={event.student.uid} />
          </Link>
        </EventInfoRow>
      )}
      <EventInfoRow {...highlightProps("start")} tooltip="Beginn & Ende">
        <EventIcon fontSize="small" sx={{ color: highlightProps("start").highlightColor ?? "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(event.start, event.end)}</Typography>
      </EventInfoRow>
      <Stack direction="row" spacing="30px">
        <EventInfoRow {...highlightProps("duration")} tooltip="Dauer">
          <ScheduleIcon fontSize="small" sx={{ color: highlightProps("duration").highlightColor ?? "gray" }} />
          <Typography variant="body2" noWrap>
            {`${Math.round(event.end.diff(event.start, "minutes").minutes)} min.`}
          </Typography>
        </EventInfoRow>
      </Stack>
      {event.student.bookedTrainingId && (
        <EventInfoRow {...highlightProps("bookedTrainingId")} tooltip="Zugewiesene Ausbildung">
          <CardMembershipOutlinedIcon
            fontSize="small"
            sx={{ color: highlightProps("bookedTrainingId").highlightColor ?? "gray" }}
          />
          <BookedTrainingClassDisplay studentId={event.student.uid} bookedTrainingId={event.student.bookedTrainingId} />
        </EventInfoRow>
      )}
      <EventInfoRow {...highlightProps("startLocation")} tooltip="Ort der Theorieprüfung">
        <PlaceOutlinedIcon fontSize="small" sx={{ color: highlightProps("startLocation").highlightColor ?? "gray" }} />
        <Typography variant="body2">
          <AddressDisplay address={event.startLocation} oneLine />
        </Typography>
      </EventInfoRow>
      {mode === "mainCard" && (
        <EventInfoRow>
          <ExamResultForm exam={event} />
        </EventInfoRow>
      )}
    </EventInfoCardBody>
  );
};

const ASFCourseSessionEventInfoCard = (props: { event: ASFCourseSession } & EventInfoCardProps) => {
  const { event, changedKeys } = props;
  const { mode, highlightProps } = _helpers(props);
  return (
    <EventInfoCardBody {...{ event, mode, changedKeys }} chip={props.chip} showIdBar={props.showIdBar}>
      <EventInfoRow {...highlightProps("start")} tooltip="Beginn & Ende">
        <EventIcon fontSize="small" sx={{ color: highlightProps("start").highlightColor ?? "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(event.start, event.end)}</Typography>
      </EventInfoRow>
      <EventInfoRow {...highlightProps("bufferTimes")} tooltip="Vor- und Nachbereitungszeit">
        <MoreTimeIcon fontSize="small" sx={{ color: highlightProps("bufferTimes").highlightColor ?? "gray" }} />
        <Typography variant="body2" noWrap>
          {`Vorbereitungszeit ${event.bufferTimes?.preparationTime ?? 0} min.`}
          {" • "}
          {`Nachbereitungszeit ${event.bufferTimes?.wrapUpTime ?? 0} min.`}
        </Typography>
      </EventInfoRow>
      <EventInfoRow
        {...highlightProps("location")}
        tooltip={`Ort der ${event.sessionType === "fahrprobe" ? "Fahrprobe" : "Gruppensitzung"}`}
      >
        <PlaceOutlinedIcon fontSize="small" sx={{ color: highlightProps("location").highlightColor ?? "gray" }} />
        <Typography variant="body2">
          <AddressDisplay address={event.location} oneLine />
        </Typography>
      </EventInfoRow>
      {mode === "mainCard" && (
        <>
          <EventInfoRow tooltip="Anzahl Teilnehmer">
            <PeopleOutlineIcon fontSize="small" sx={{ color: "gray" }} />
            <Typography variant="body2">{Object.keys(event.participants).length} Teilnehmer</Typography>
          </EventInfoRow>
          <AttendeesList event={event} />
        </>
      )}
    </EventInfoCardBody>
  );
};

const OtherEventInfoCard = (props: { event: OtherEvent } & EventInfoCardProps) => {
  const { event, changedKeys } = props;
  const { mode, highlightProps } = _helpers(props);
  return (
    <EventInfoCardBody {...{ event, mode, changedKeys }} chip={props.chip} showIdBar={props.showIdBar}>
      <EventInfoRow {...highlightProps("start")} tooltip="Beginn & Ende">
        <EventIcon fontSize="small" sx={{ color: highlightProps("start").highlightColor ?? "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(event.start, event.end)}</Typography>
      </EventInfoRow>
      <EventInfoRow {...highlightProps("bufferTimes")} tooltip="Transferzeiten">
        <MoreTimeIcon fontSize="small" sx={{ color: highlightProps("bufferTimes").highlightColor ?? "gray" }} />
        <Typography variant="body2" noWrap>
          Pufferzeiten: davor {event.bufferTimes?.preparationTime ?? 0} min., danach{" "}
          {event.bufferTimes?.wrapUpTime ?? 0} min.
        </Typography>
      </EventInfoRow>
      <EventInfoRow {...highlightProps("notes")} tooltip="Notiz">
        <NotesIcon fontSize="small" sx={{ color: highlightProps("notes").highlightColor ?? "gray" }} />
        <Typography variant="body2">{event.notes}</Typography>
      </EventInfoRow>
    </EventInfoCardBody>
  );
};

const AttendeesList = (props: { event: TheoryLesson | ASFCourseSession; sx?: SxProps }) => {
  // We fetch the given event here via useGetOne so that we rerender whenever the event changes ...
  const { data } = useGetOne<TheoryLesson | ASFCourseSession>("calendarEvents", { id: props.event.id });
  const event = data ?? props.event;
  const attendanceByStudentId: Record<string, TheoryLessonStudentAttendance | ASFCourseSessionAttendance> =
    event.students || event.participants;
  const {
    data: students,
    isLoading: studentsIsLoading,
    error: studentsError,
  } = useGetMany<Student>("students", {
    ids: Object.keys(attendanceByStudentId),
    meta: { drivingSchoolId: event.drivingSchoolId },
  });
  const attendanceSortedByName: Array<[string, TheoryLessonStudentAttendance | ASFCourseSessionAttendance]> =
    useMemo(() => {
      const entries = Object.entries(attendanceByStudentId);
      if (!students || studentsIsLoading || studentsError) {
        return entries;
      }
      return entries.sort(([uid1], [uid2]) => {
        const student1 = students.find((student) => student.id === uid1);
        const student2 = students.find((student) => student.id === uid2);
        if (!student1 || !student2) return 0;
        return student1.name.localeCompare(student2.name);
      });
    }, [attendanceByStudentId, students]);
  const canAddStudents =
    isTheoryLesson(event) && (grants.includes("addStudentToTheoryLesson") || grants.includes("manageTheoryLessons"));
  const canRemoveStudents =
    isTheoryLesson(event) &&
    (grants.includes("removeStudentFromTheoryLesson") || grants.includes("manageTheoryLessons")) &&
    DateTime.now() < event.start;

  const canDocumentStudentsAttendance = isTheoryLesson(event) && DateTime.now() >= event.start;

  const registeredStudents = attendanceSortedByName.filter(([_, attendance]) => attendance.rsvp === "accepted");
  const deregisteredStudents = attendanceSortedByName.filter(([_, attendance]) => attendance.rsvp === "rejected");
  return (
    <Column spacing="10px" sx={props.sx}>
      <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body2" fontWeight="bold">
          Angemeldete Fahrschüler
        </Typography>
        {canDocumentStudentsAttendance && (
          <Typography variant="body2" color="#9b9b9b">
            Anwesenheit
          </Typography>
        )}
      </Row>
      <TransitionGroup component="div" style={{ display: "flex", flexDirection: "column" }}>
        {registeredStudents.map(([uid, attendance]) => {
          return (
            <Collapse key={uid} unmountOnExit>
              <Row sx={{ height: "40px", borderBottom: `1px solid ${autovioColors.borderGrey}` }}>
                <Link to={`/students/${uid}`} sx={{ display: "flex", alignItems: "center" }}>
                  <StudentAvatar studentId={uid} size="20px" />
                  <StudentDisplayName sx={{ ml: ".5em" }} studentId={uid} />
                </Link>
                <div style={{ flex: 1 }} />
                {canRemoveStudents && <RemoveStudentFromTheoryLessonButton theoryLesson={event} studentId={uid} />}
                {canDocumentStudentsAttendance && attendance && (
                  <SetStudentAttendanceButtons
                    studentId={uid}
                    studentAttendance={attendance as TheoryLessonStudentAttendance}
                  />
                )}
              </Row>
            </Collapse>
          );
        })}
      </TransitionGroup>
      {canAddStudents && <AddStudentToTheoryLessonButton sx={{ mt: "10px" }} theoryLesson={event} />}
      {deregisteredStudents.length > 0 && (
        <>
          <Typography variant="body2" fontWeight="bold">
            Abgemeldete Fahrschüler
          </Typography>
          <Column>
            {deregisteredStudents.map(([uid]) => (
              <Row key={uid} sx={{ height: "40px", borderBottom: `1px solid ${autovioColors.borderGrey}` }}>
                <Link to={`/students/${uid}`} sx={{ display: "flex", alignItems: "center" }}>
                  <StudentAvatar studentId={uid} size="20px" />
                  <StudentDisplayName sx={{ ml: ".5em" }} studentId={uid} />
                </Link>
              </Row>
            ))}
          </Column>
        </>
      )}
    </Column>
  );
};

type EventInfoCardBodyProps = {
  event: AutovioCalendarEvent;
  mode: "mainCard" | "beforeChange" | "afterChange";
  changedKeys?: Array<string>;
  chip?: React.ReactNode;
  children?: React.ReactNode;
  showIdBar?: boolean;
  showStatus?: boolean;
};

const EventInfoCardBody = (props: EventInfoCardBodyProps) => {
  const { event } = props;
  const { highlightProps } = _helpers(props);
  return (
    <Column spacing="1em">
      <Row spacing="1em">
        {hasInstructor(event) && (
          <InstructorAvatar
            instructorId={event.instructorId}
            size="40px"
            withTooltip
            borderColor={
              (props.changedKeys ?? []).includes("instructorId")
                ? props.mode === "beforeChange"
                  ? "#fff0c8"
                  : "#d3f9e0"
                : undefined
            }
          />
        )}
        <Column spacing="0.5em" sx={{ flex: 1 }}>
          <Row justifyContent="space-between" spacing={"1em"} alignContent="center">
            <Typography fontWeight="bold" color={highlightProps("drivingLessonType").highlightColor}>
              {calendarEventTitle(event)}
            </Typography>
            {props.showStatus && <Typography color="gray">({renderLessonStatus(event)})</Typography>}
            <Box flex={1} />
            {props.chip}
          </Row>
          {props.children}
        </Column>
      </Row>
      {props.showIdBar && <EventIdBar id={event.id} justifyContent="center" />}
    </Column>
  );
};

type EventInfoLineProps = {
  isHighlighted?: boolean;
  highlightColor?: string;
  tooltip?: string;
  tooltipPlacement?: TooltipProps["placement"];
  children: React.ReactNode;
};

const EventInfoRow = (props: EventInfoLineProps) => {
  return (
    <Tooltip title={props.tooltip} arrow placement={props.tooltipPlacement ?? "left"}>
      <Row spacing="0.5em" color={props.isHighlighted ? props.highlightColor : undefined}>
        {props.children}
      </Row>
    </Tooltip>
  );
};

const EventExtensions = ({ event }: { event: DrivingLesson }) => {
  const extensions = event.extensions;
  if (!extensions || extensions.length === 0) {
    return null;
  }

  return (
    <Stack spacing="10px">
      <Box height="5px" />
      <Typography variant="body2" fontWeight="bold">
        Verlängerungen
      </Typography>
      <Stack spacing="5px">
        {extensions.map((extension) => {
          const eventDuration = event.end.diff(event.start, "minutes").minutes;
          const extensionPrice = Math.round(
            (event.price.studentPrice.gross * extension.durationInMinutes) / eventDuration,
          );
          const originalEnd = formatTime(extension.newEnd.minus({ minutes: extension.durationInMinutes }));
          const newEnd = formatTime(extension.newEnd);
          return (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" key={extension.extendedAt.toISO()}>
                +{extension.durationInMinutes} min. von {originalEnd} bis {newEnd} (+
                {<Money cents={extensionPrice} />})
              </Typography>
              <Typography variant="body2" color="gray">
                um {formatTime(extension.extendedAt)}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

const ExamResultForm = ({ exam }: { exam: TheoryExam | PracticalExam }) => {
  return (
    <EditBase resource="calendarEvents" id={exam.id}>
      <Form>
        <ExamResultDropdown />
      </Form>
    </EditBase>
  );
};

const ExamResultDropdown = () => {
  const exam = useRecordContext<TheoryExam | PracticalExam>();
  const form = useFormContext();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const source = exam && isTheoryExam(exam) ? "theoryExamResult" : "practicalExamResult";

  return (
    <Row gap={1}>
      <SelectInput
        source={source}
        label="Prüfungsergebnis"
        onChange={(event) => {
          void form.handleSubmit(async (formValues) => {
            try {
              const examResult = formValues[source];
              const t0 = Date.now();
              await calendarEventsProvider.update("calendarEvents", {
                id: exam.id,
                data: { [source]: examResult },
                previousData: exam,
              });
              // [UX]: Make sure the spinner is displayed at least 500 ms ...
              const delay = Math.min(0, t0 + 500 - Date.now());
              if (delay > 0) {
                await new Promise((resolve) => setTimeout(resolve, delay));
              }
              await queryClient.invalidateQueries(["calendarEvents"]);
              notify("Prüfungsergebnis gespeichert.", { type: "success" });
            } catch (error) {
              console.error("Failed to save examResult", error);
              notify("Fehler beim Speichern des Prüfungserbnisses.", { type: "error" });
            }
          })(event);
        }}
        sx={{ width: 240 }}
        choices={[
          { id: "passed", name: "bestanden" },
          { id: "failed", name: "nicht bestanden" },
        ]}
        disabled={!exam || form.formState.isSubmitting}
      />
      {form.formState.isSubmitting && <CircularProgress size={24} sx={{ mt: "14px" }} />}
    </Row>
  );
};
