import { MoneyCurrency } from "../misc/Money";
import { Invoice as InvoiceDto } from "../generated/backendClient";
import { SimpleConfirmDialogButton } from "../misc/SimpleConfirmDialogButton";
import UndoIcon from "@mui/icons-material/Undo";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { RawMoneyInput } from "../inputs/RawMoneyInput";
import { useRefresh } from "react-admin";
import { useState } from "react";

type refundMode = "fullRefund" | "partialRefund";

export function PartialRefundPaymentDialogButton({ invoice }: { invoice: InvoiceDto }) {
  const [refundMode, setPartialRefund] = useState<refundMode>("fullRefund");
  const [amount, setAmount] = useState<string>("0,00");
  const refresh = useRefresh();

  const canBePartiallyRefunded = invoice.state === "PAID" && invoice.type === "PREPAID_CREDITS";
  const invoiceState = invoice.state;
  const invoiceId = invoice.id;

  let refundAmount: InvoiceDto["total_gross"];
  if (refundMode === "partialRefund") {
    refundAmount = { amount: amount.replace(",", "."), currency: "EUR" };
  } else {
    refundAmount = invoice.total_gross || { amount: "0.00", currency: "EUR" };
  }
  const rueckerstattenOrAusbuchen = invoiceState === "PAID" ? "Rückerstatten" : "Ausbuchen";
  return (
    <SimpleConfirmDialogButton
      disabled={!["PAID", "PAYMENT_REVOKED", "UNPAID"].includes(invoiceState)}
      actionDisabled={refundAmount.amount === "0.00"}
      label={rueckerstattenOrAusbuchen}
      dialogTitle={`${rueckerstattenOrAusbuchen} bestätigen`}
      action={async () => {
        const backendClient = await getAuthenticatedBackendClient();
        if (refundMode === "fullRefund") {
          await backendClient.invoice.invoiceRefundCreate({ id: invoiceId });
          refresh();
        } else {
          await backendClient.invoice.invoiceRefundPartiallyCreate({
            id: invoiceId,
            requestBody: { refund_amount: refundAmount },
          });
          refresh();
        }
      }}
      dialogCtaTitle={`Jetzt ${rueckerstattenOrAusbuchen}`}
      content={
        <>
          {canBePartiallyRefunded && (
            <>
              <div>
                <FormControl>
                  <FormLabel id="refund-mode-radio-buttons">Rückerstattung</FormLabel>
                  <RadioGroup
                    aria-labelledby="refund-mode-radio-buttons"
                    defaultValue="fullRefund"
                    name="radio-buttons-group"
                    value={refundMode}
                    onChange={(e) => setPartialRefund(e.target.value as refundMode)}
                  >
                    <FormControlLabel value="fullRefund" control={<Radio />} label="Vollständig Zurückerstatten" />
                    <FormControlLabel value="partialRefund" control={<Radio />} label="Teilweise Zurückerstatten" />
                    {refundMode === "partialRefund" && (
                      <>
                        <RawMoneyInput
                          label="Betrag"
                          value={amount}
                          onChange={setAmount}
                          style={{ width: "250px" }}
                          maxValue={parseFloat(invoice.total_gross.amount)}
                        />
                      </>
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
            </>
          )}
          <div>
            Sind Sie sicher, dass Sie die Rechnung über
            <b>
              {" "}
              <MoneyCurrency {...refundAmount} />
            </b>{" "}
            {rueckerstattenOrAusbuchen.toLowerCase()} möchten?
          </div>
        </>
      }
      icon={<UndoIcon />}
    />
  );
}
