import { Instructor } from "../../providers/instructorsProvider";
import { Student } from "../../providers/studentsProvider";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useRef, type ReactNode } from "react";
import { Tab, useNotify } from "react-admin";
import { DateTime } from "luxon";
import { useQueryClient } from "react-query";
import { handleBookingError } from "../../utils/handleBookingError";
import { Tabs } from "../../misc/Tabs";
import { type DateClickArg } from "@fullcalendar/interaction";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { ExamForm } from "./ExamForm";
import { DrivingLessonForm } from "./DrivingLessonForm";
import { AddTheoryCourseDialog } from "../AddTheoryCourseDialog/AddTheoryCourseDialog";
import { useDialog } from "../../hooks/useDialog";
import { OtherEventForm } from "./OtherEventForm";
import { grants } from "../../backoffice.access_control";
import { Vehicle } from "../../providers/resourceProviders";
import { TheoryLessonForm } from "./TheoryLessonForm";
import { LocalizedError } from "../../utils/LocalizedError";

interface AddAppointmentDialogProps {
  open: boolean;
  onClose: () => void;
  drivingSchoolId: string;
  instructor?: Instructor;
  vehicle?: Vehicle;
  tabs: Array<"drivingLesson" | "theoryLesson" | "simulatorDrivingLesson" | "exam" | "theoryCourse" | "otherEvent">;
}

interface AddAppointmentDialogType {
  (props: AddAppointmentDialogProps): ReactNode;

  initialDateTime?: DateTime;
  fullCalendarDateClickHandler: (openDialog: () => void) => (arg: DateClickArg) => void;
}

function _AddAppointmentDialog(props: AddAppointmentDialogProps) {
  const { open, onClose, drivingSchoolId, instructor, vehicle, tabs } = props;
  const notify = useNotify();
  const queryClient = useQueryClient();
  const initialDateTime = useRef(AddAppointmentDialog.initialDateTime).current;
  AddAppointmentDialog.initialDateTime = undefined; // <-- Side effect: reset AddAppointmentDialog.initialDateTime
  const { dialogProps, openDialog } = useDialog();

  const onFormSuccess = async () => {
    onClose();
    await queryClient.invalidateQueries(["recommendations", "student"]);
    await queryClient.invalidateQueries(["calendarEvents"]);
    await queryClient.invalidateQueries(["calendarEventHistory"]);
  };

  const onFormFailure = async (error: unknown, student?: Student) => {
    if (!(await handleBookingError(error, student, notify))) {
      console.error("Failed to invite student", error);
      if (error instanceof LocalizedError) {
        notify(error.message, { type: "error" });
      } else {
        notify("Fehler beim Versenden der Einladung", { type: "error" });
      }
    }
  };

  const effectiveTabs = tabs.filter((tab) => {
    if (tab === "exam") return grants.includes("Exam:create");
    else if (tab === "theoryCourse")
      return grants.includes("theoryCoursesCreate") || grants.includes("manageTheoryLessons");
    else if (tab === "theoryLesson") return grants.includes("manageTheoryLessons");
    else return true;
  });

  const isOnlyTheoryCourseAvailable = effectiveTabs.length === 1 && effectiveTabs[0] === "theoryCourse";

  useEffect(() => {
    if (open && isOnlyTheoryCourseAvailable) {
      openDialog();
    }
  }, [open]);

  if (isOnlyTheoryCourseAvailable) {
    return <AddTheoryCourseDialog {...dialogProps} onClose={onClose} initialDateTime={initialDateTime} />;
  }

  let dialogTitle = "Neuer Termin";
  if (effectiveTabs.length === 1) {
    if (effectiveTabs[0] === "drivingLesson") {
      dialogTitle = "Neue Fahrstunde";
    } else if (effectiveTabs[0] === "simulatorDrivingLesson") {
      dialogTitle = "Neue Simulator-Fahrstunde";
    } else if (effectiveTabs[0] === "exam") {
      dialogTitle = "Neue Prüfung";
    }
  }

  const drivingLessonForm = (
    <DrivingLessonForm
      drivingSchoolId={drivingSchoolId}
      initialDateTime={initialDateTime}
      instructor={instructor}
      vehicle={vehicle}
      onSuccess={onFormSuccess}
      onFailure={onFormFailure}
    />
  );

  const theoryLessonForm = (
    <TheoryLessonForm drivingSchoolId={drivingSchoolId} initialDateTime={initialDateTime} onSuccess={onFormSuccess} />
  );

  const examForm = (
    <ExamForm
      drivingSchoolId={drivingSchoolId}
      initialDateTime={initialDateTime}
      instructor={instructor}
      onSuccess={onFormSuccess}
      onFailure={onFormFailure}
    />
  );

  const otherEventForm = (
    <OtherEventForm instructor={instructor!} initialDateTime={initialDateTime} onSuccess={onFormSuccess} />
  );

  let dialogContent: React.ReactNode = null;
  if (effectiveTabs.length === 1) {
    if (effectiveTabs[0] === "drivingLesson" || effectiveTabs[0] === "simulatorDrivingLesson") {
      dialogContent = drivingLessonForm;
    } else if (effectiveTabs[0] === "exam") {
      dialogContent = examForm;
    } else if (effectiveTabs[0] === "otherEvent" && instructor) {
      dialogContent = otherEventForm;
    }
  } else if (effectiveTabs.length > 1) {
    dialogContent = (
      <Tabs sx={{ m: 0 }} syncWithLocation={false}>
        {effectiveTabs.includes("drivingLesson") && <Tab label="Fahrstunde">{drivingLessonForm}</Tab>}
        {effectiveTabs.includes("exam") && <Tab label="Prüfung">{examForm}</Tab>}
        {effectiveTabs.includes("theoryLesson") && <Tab label="Theoriestunde">{theoryLessonForm}</Tab>}
        {effectiveTabs.includes("theoryCourse") && (
          <Tab label="Theoriekurs" onClick={openDialog}>
            <AddTheoryCourseDialog {...dialogProps} onClose={onClose} initialDateTime={initialDateTime} />
          </Tab>
        )}
        {effectiveTabs.includes("otherEvent") && instructor && <Tab label="Sonstiges">{otherEventForm}</Tab>}
      </Tabs>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {dialogTitle}
        {vehicle?.type === "simulator" && (
          <>
            <br />
            {vehicle.name}
          </>
        )}
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent sx={{ minWidth: "400px" }}>{dialogContent}</DialogContent>
    </Dialog>
  );
}

export const AddAppointmentDialog: AddAppointmentDialogType = _AddAppointmentDialog as any;
AddAppointmentDialog.fullCalendarDateClickHandler =
  (openDialog: () => void) =>
  ({ dateStr, dayEl, jsEvent }) => {
    try {
      let dateTime = DateTime.fromISO(dateStr, { zone: "Europe/Berlin" });
      // We need to adapt dateTime here, because sometimes FullCalendar does not pass quite the correct date ...
      // Furthermore we add 15 minutes if the click was in the lower half of the 30 min time slot ...
      const { top, height } = dayEl.getBoundingClientRect();
      const h = ((24 - 6) / height) * (jsEvent.clientY - top);
      const m = (h - Math.floor(h)) * 60;
      if (dateTime.get("minute") === 0) {
        if (m >= 25) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 10) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else if (dateTime.get("minute") === 30) {
        if (m >= 55) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 40) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else {
        throw new Error(`Unexpected dateTime.get("minute"): ${dateTime.get("minute")}`);
      }
      AddAppointmentDialog.initialDateTime = dateTime;
      openDialog();
    } catch (error) {
      console.error("Failed to handle dateClick", error);
    }
  };
