import { AbstractDataProvider } from "./AbstractDataProvider";
import { ASFCourseSession, isASFCourseSession, isTheoryLesson, TheoryLesson } from "../model/autovioCalendarEvents";
import {
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  CreateParams,
  CreateResult,
} from "react-admin";
import type { z } from "zod";
import { DateTime } from "luxon";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { QueryFieldFilterConstraint } from "@firebase/firestore";
import { autovioCalendarEventConverter } from "./converter/autovioCalendarEventConverter";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";

interface Attendee {
  numBookedAppointments: number;
  hasBookedUpcomingAppointment?: true;
}

export interface Course {
  id: string;
  drivingSchoolId: Identifier;
  localizedType: "Theoriekurs" | "Aufbauseminar (ASF)" | "???";
  start: DateTime;
  end: DateTime;
  numAppointments: number;
  numAttendees: number;
  maxAttendees?: number;
  appointments: Array<TheoryLesson | ASFCourseSession>;
  attendees: { [userUid: string]: Attendee };
}

type CreateTheoryCourseDto = z.infer<typeof schemas.CreateTheoryCourseDto>;

class CoursesProvider extends AbstractDataProvider<Course> {
  async getOne(_resource: string, { id, meta }: GetOneParams<Course>): Promise<GetOneResult<Course>> {
    const { drivingSchoolId } = meta ?? {};
    if (!drivingSchoolId) {
      throw new Error("meta.drivingSchoolId is required");
    }
    const courses = await this._fetchCourses(drivingSchoolId, where("courseUid", "==", id));
    if (courses.length === 0) {
      throw new Error(`Course ${id} not found`);
    }
    return { data: courses[0] };
  }

  async getManyReference(
    _resource: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Course>> {
    if (target !== "drivingSchoolId") {
      throw new Error(`Invalid target: ${JSON.stringify(target)} -- expected: "drivingSchoolId"`);
    }
    const courses = await this._fetchCourses(id, where("courseUid", ">", ""));
    return applyPagination(applySort(applyFilter(courses, filter), sort), pagination);
  }

  private async _fetchCourses(
    drivingSchoolId: Identifier,
    courseUidConstraint: QueryFieldFilterConstraint,
  ): Promise<Array<Course>> {
    const calendarEventsRef = collection(firestore, "calendar_events");
    const docs = (
      await getDocs(query(calendarEventsRef, where("drivingSchoolUid", "==", drivingSchoolId), courseUidConstraint))
    ).docs;
    const eventsByCourseUid = new Map<string, Array<TheoryLesson | ASFCourseSession>>();
    for (const doc of docs) {
      const event = autovioCalendarEventConverter.fromFirestore(doc);
      if (isTheoryLesson(event) || isASFCourseSession(event)) {
        const array = eventsByCourseUid.get(event.courseUid!);
        if (!array) {
          eventsByCourseUid.set(event.courseUid!, [event]);
        } else {
          // Sort events in array by start ...
          let i = 0;
          while (i < array.length && array[i].start < event.start) {
            ++i;
          }
          array.splice(i, 0, event as any);
        }
      }
    }
    const courses = Array.from(eventsByCourseUid.entries())
      .map(([courseUid, appointments]) => {
        const start = appointments[0].start;
        const end = appointments[appointments.length - 1].end;
        const attendees: { [userUid: string]: Attendee } = {};
        const addAttendee = (userUid: string, forUpcomingEvent: boolean) => {
          let attendee = attendees[userUid];
          if (attendee) {
            attendee.numBookedAppointments += 1;
          } else {
            attendees[userUid] = attendee = { numBookedAppointments: 1 };
          }
          if (forUpcomingEvent) {
            attendee.hasBookedUpcomingAppointment = true;
          }
        };
        let maxAttendees: number | undefined;
        let numAttendees = 0;
        for (const appointment of appointments) {
          const isUpcomingEvent = appointment.start > DateTime.now();
          if (isTheoryLesson(appointment)) {
            maxAttendees = Math.max(maxAttendees ?? 0, appointment.maxStudents);
            numAttendees = Math.max(numAttendees, appointment.numBookedStudents);
            for (const [userUid, student] of Object.entries(appointment.students)) {
              if (student.rsvp === "accepted") {
                addAttendee(userUid, isUpcomingEvent);
              }
            }
          } else {
            numAttendees = Math.max(numAttendees, appointment.numBookedParticipants);
            for (const userUid of Object.keys(appointment.participants)) {
              addAttendee(userUid, isUpcomingEvent);
            }
          }
        }
        return {
          id: courseUid,
          drivingSchoolId,
          localizedType: isTheoryLesson(appointments[0])
            ? "Theoriekurs"
            : isASFCourseSession(appointments[0])
              ? "Aufbauseminar (ASF)"
              : "???",
          start,
          end,
          numAppointments: appointments.length,
          // Don't show more attendees than the maximum, because the important information is the number of free seats ...
          numAttendees: typeof maxAttendees === "number" ? Math.min(numAttendees, maxAttendees) : numAttendees,
          maxAttendees,
          appointments,
          attendees,
        } satisfies Course;
      })
      .filter((it) => it.appointments.filter((it) => !it.deleted).length > 1);
    return courses;
  }

  async create(_: string, params: CreateParams<CreateTheoryCourseDto>): Promise<CreateResult<Course>> {
    const { data } = params;
    const { drivingSchoolId, theoryLessons } = data;
    if (!drivingSchoolId) {
      throw new Error("drivingSchoolId is required");
    }
    if (!theoryLessons) {
      throw new Error("theoryLessons is required");
    }
    const serverClient = await getAuthenticatedServerClient();
    const courseId = theoryLessons[0].courseUid;
    await serverClient.createTheoryCourse({ drivingSchoolId, theoryLessons });
    const response = await this.getOne("courses", { id: courseId, meta: { drivingSchoolId } });
    return { data: response.data };
  }
}

export const coursesProvider = new CoursesProvider();
