import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { VehicleIcon } from "../icons/VehicleIcon";
import { ResourceType } from "../api/resource.api";

interface AddVehicleButtonsProps {
  type: ResourceType;
  style?: CSSProperties;
  "data-testid": string;
}

export function AddVehicleButton({ type, "data-testid": dataTestId }: AddVehicleButtonsProps) {
  return (
    <Button
      component={Link}
      sx={{ height: "32px" }}
      to={`/${type}s/create`}
      startIcon={<AddIcon />}
      variant="outlined"
      data-testid={dataTestId}
    >
      <VehicleIcon type={type} />
    </Button>
  );
}
