import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Instructor, instructorsProvider } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import { useState } from "react";
import { PaginationPayload, useGetList, useGetOne, useNotify, useRefresh } from "react-admin";
import { Bundle, catalogProvider } from "../providers/catalogProvider";
import { reportError } from "../backoffice.utils";
import { autovioColors } from "../misc/backofficeTheme";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { DialogProps } from "../misc/DialogProps";
import { LoadingIndicator } from "../misc/LoadingIndicator";

export function AddBundleDialog({ student, ...dialogProps }: DialogProps & { student: Student }) {
  const closeDialog = dialogProps.onClose;
  const notify = useNotify();
  const refresh = useRefresh();
  const [bundle, setBundle] = useState(undefined as Bundle | undefined);
  const [instructors, setInstructors] = useState(undefined as Array<Instructor> | undefined);
  const [addingBundle, setAddingBundle] = useState(false);
  const reset = () => {
    setBundle(undefined);
    setInstructors(undefined);
    setAddingBundle(false);
  };
  const addBundle = async (bundle: Bundle, instructorId: string) => {
    setAddingBundle(true);
    try {
      await catalogProvider.addBundle({ studentId: student.id, bundleId: bundle.id, instructorId });
    } finally {
      reset();
      closeDialog();
    }
    notify(`Paket ${bundle.name} hinzugefügt.`);
    refresh();
  };
  const onBundleSelected = async (selectedBundle: Bundle) => {
    setBundle(selectedBundle);
    try {
      const validInstructors = await determineValidInstructors(student, selectedBundle);
      const validInstructorIds = validInstructors.map((it) => it.id);
      // Prefer an instructor the student already knows ...
      for (const instructorId of student.instructorIds) {
        if (validInstructorIds.includes(instructorId)) {
          await addBundle(selectedBundle, instructorId);
          return;
        }
      }
      // No instructor the student already knows can teach the selected bundle ...
      if (validInstructors.length === 1) {
        await addBundle(selectedBundle, validInstructors[0].id);
      } else {
        // Let the backoffice user choose an instructor ...
        setInstructors(validInstructors);
      }
    } catch (error) {
      reportError("Failed to add bundle", error);
      notify("Oje, das hat leider nicht geklappt.", { type: "error" });
    }
  };
  const onInstructorSelected = async (selectedInstructor: Instructor) => {
    try {
      await addBundle(bundle!, selectedInstructor.id);
    } catch (error) {
      reportError("Failed to add bundle", error);
      notify("Oje, das hat leider nicht geklappt.", { type: "error" });
    }
  };
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        {!bundle && "Neues Paket auswählen"}
        {bundle && `Fahrlehrer für Paket ${bundle.name} auswählen`}
      </DialogTitle>
      <DialogCloseButton onClick={closeDialog} />
      <DialogContent>
        {!bundle && (
          <DialogContentText sx={{ mt: "1em", fontStyle: "italic" }}>
            Hinweis: Wenn du ein Paket hinzufügst, erhält der Fahrschüler eine Benachrichtigung in der App mit der Bitte
            den zusätzlichen Grundbetrag zu zahlen. Es ist keine manuelle Rechnung nötig.
          </DialogContentText>
        )}
        {!bundle && <_ChooseBundleList student={student} onSelect={onBundleSelected} />}
        {instructors && !addingBundle && (
          <_ChooseInstructorList instructors={instructors} onSelect={onInstructorSelected} />
        )}
        {addingBundle && <LinearProgress />}
      </DialogContent>
    </Dialog>
  );
}

function _ChooseBundleList({ student, onSelect }: { student: Student; onSelect: (bundle: Bundle) => void }) {
  const { drivingSchoolId } = student;
  const { data: drivingSchool } = useGetOne("drivingSchools", { id: drivingSchoolId });
  const { data: bundles } = useGetList<Bundle>("bundles", {
    filter: { drivingSchoolId },
    sort: { field: "name", order: "ASC" },
    pagination: {} as any as PaginationPayload,
  });
  if (!(drivingSchool && bundles)) {
    return (
      <Box sx={{ height: "150px" }}>
        <LoadingIndicator />
      </Box>
    );
  }
  const drivingLicenseClasses = new Set(student.bookedTrainings.map((it) => it.drivingLicenseClass));
  const validBundles = bundles.filter(
    (bundle) =>
      bundle.trainings.length > 0 && !bundle.trainings.some((it) => drivingLicenseClasses.has(it.drivingLicenseClass)),
  );
  if (validBundles.length === 0) {
    return <p className="error">Keine weiteren Pakete der Fahrschule {drivingSchool?.name ?? ""} gefunden.</p>;
  }
  return (
    <List>
      {validBundles.map((bundle) => (
        <ListItemButton key={bundle.id} onClick={() => onSelect(bundle)}>
          <ListItemText>{bundle.name}</ListItemText>
        </ListItemButton>
      ))}
    </List>
  );
}

function _ChooseInstructorList({
  instructors,
  onSelect,
}: {
  instructors: Array<Instructor>;
  onSelect: (instructor: Instructor) => void;
}) {
  return (
    <List>
      {instructors.map((instructor) => (
        <ListItemButton key={instructor.id} onClick={() => onSelect(instructor)}>
          <ListItemAvatar>
            <Avatar src={instructor.avatarOverrideUrl ?? instructor.avatarUrl} />
          </ListItemAvatar>
          <ListItemText primary={<span style={{ color: autovioColors.green }}>{instructor.name}</span>} />
        </ListItemButton>
      ))}
    </List>
  );
}

async function determineValidInstructors(student: Student, bundle: Bundle): Promise<Array<Instructor>> {
  const instructorsOfDrivingSchool = await instructorsProvider.getByDrivingSchoolId(bundle.drivingSchoolId);
  const bundleDrivingLicenseClasses = bundle.trainings.map((training) => training.drivingLicenseClass);
  const validInstructors = instructorsOfDrivingSchool.filter((instructor) => {
    return bundleDrivingLicenseClasses.every((it) => instructor.instructing.drivingLicenseClasses.includes(it));
  });
  return validInstructors;
}
