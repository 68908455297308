import { RecordContextProvider, useCreatePath, useGetList, useGetRecordId, useRecordContext } from "react-admin";
import { PageTitle } from "../../misc/PageTitle";
import * as React from "react";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { Avatar, Button, Typography } from "@mui/material";
import { Column } from "../../misc/Column";
import { CardsGrid } from "../../misc/CardsGrid";
import { Instructor } from "../../providers/instructorsProvider";
import { Row } from "../../misc/Row";
import { autovioColors } from "../../misc/backofficeTheme";
import { ActiveStudentsField } from "../../fields/ActiveStudentsField";
import { StudentsIcon } from "../../icons/StudentsIcon";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { currentUser, grants } from "../../backoffice.access_control";

/** See https://zpl.io/dRxNOMl */
export function DrivingSchoolInstructors() {
  const drivingSchoolId = useGetRecordId();
  const createPath = useCreatePath();
  const navigate = useNavigate();
  const { data: instructors } = useGetList<Instructor>(
    "instructors",
    {
      filter: {
        drivingSchoolId,
        ...(grants.includes("Employee:list") ? {} : { id: currentUser?.uid }),
      },
      pagination: { page: 1, perPage: 9999 },
      sort: { field: "_sortOrder", order: "ASC" },
    },
    { enabled: !!drivingSchoolId },
  );

  return (
    <div style={{ position: "relative" }}>
      <PageTitle>Fahrlehrer</PageTitle>
      {!instructors && <LoadingIndicator />}
      {instructors && grants.includes("Instructor:create") && (
        <Button
          sx={{ position: "absolute", top: 0, right: "16px" }}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => navigate(createPath({ type: "create", resource: "instructors" }))}
        >
          Hinzufügen
        </Button>
      )}
      {instructors && (
        <CardsGrid>
          {instructors.map((instructor) => (
            <_InstructorCard key={instructor.id} instructor={instructor} />
          ))}
        </CardsGrid>
      )}
    </div>
  );
}

function _InstructorCard({ instructor }: { instructor: Instructor }) {
  const navigate = useNavigate();

  return (
    <RecordContextProvider value={instructor}>
      <CardsGrid.Card onClick={() => navigate(`/instructors/${instructor.id}`)}>
        <Row sx={{ justifyContent: "flex-end" }}>
          <_ActiveStudents />
        </Row>
        <Column sx={{ mt: "8px", height: "192px", alignItems: "center" }} gap="8px">
          <Avatar src={instructor.avatarUrl} sx={{ width: 60, height: 60 }} />
          <_Name />
          <Typography variant="body2" color={autovioColors.grey} sx={{ textAlign: "center" }}>
            {instructor.isDrivingSchoolManager ? "Fahrschulleiter" : "Fahrlehrer"}
          </Typography>
          <Typography variant="body2" color={autovioColors.grey} sx={{ textAlign: "center" }}>
            {instructor?.instructing.drivingLicenseClasses.join(", ")}
          </Typography>
        </Column>
      </CardsGrid.Card>
    </RecordContextProvider>
  );
}

function _Name() {
  const instructor = useRecordContext<Instructor>();
  return (
    <Typography
      sx={{
        fontSize: "19px",
        fontWeight: 600,
        lineHeight: "23px",
        textAlign: "center",
      }}
    >
      {instructor.firstName || "\u00A0"}
      <br />
      {instructor.lastName || "\u00A0"}
    </Typography>
  );
}

function _ActiveStudents() {
  return (
    <Row sx={{ height: "20px", justifyContent: "space-between" }} gap="5px">
      <ActiveStudentsField />
      <StudentsIcon sx={{ fontSize: "20px", fill: autovioColors.grey }} />
    </Row>
  );
}
