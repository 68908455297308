import { type ReactNode } from "react";
import { SaveButton, TextInput, useNotify } from "react-admin";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { Instructor } from "../../providers/instructorsProvider";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { SelectInput } from "../../inputs/SelectInput";
import { DateInput } from "../../inputs/DateInput";
import { TimeInput } from "../../inputs/TimeInput";
import { type CreateCalendarEventDto, serverAPI } from "../../api/server.api";
import { Form2Theme } from "../../misc/Form2";

interface OtherEventFormProps {
  instructor: Instructor;
  initialDateTime?: DateTime;
  onSuccess: () => Promise<void>;
}

export type OtherEventCategory = keyof typeof OtherEventCategories;

export interface OtherEventFormValues {
  start?: DateTime;
  end?: DateTime;
  category: OtherEventCategory;
  notes: string;
  duration?: "fullDay" | "halfDayInTheMorning" | "halfDayInTheAfternoon";
}

export interface OtherEventParams extends OtherEventFormValues {
  start: DateTime;
}

const customDurationEvents = ["sickness", "vacation", "training"];

export const OtherEventForm = ({ instructor, initialDateTime, onSuccess }: OtherEventFormProps) => {
  const notify = useNotify();

  const defaultValues = {
    start: initialDateTime ?? undefined,
    end: undefined,
    category: "journeyFromAndToCustomer",
    notes: "",
  } satisfies OtherEventFormValues;

  const formProps = useForm<OtherEventFormValues>({
    defaultValues,
  });

  function validate(formValues: OtherEventFormValues): OtherEventParams {
    const { start, category, notes, duration } = formValues;
    let { end } = formValues;
    if (!start) {
      throw new Error(`Invalid start time: ${start}`);
    }
    if (end) {
      end = end.set({ year: start.year, month: start.month, day: start.day });
    }
    if (!customDurationEvents.includes(category)) {
      if (!end) {
        throw new Error(`Invalid end time: ${end}`);
      } else if (end <= start) {
        throw new Error(`End time should be after start time: ${end}`);
      }
    }
    if (customDurationEvents.includes(category) && !duration) {
      throw new Error(`Invalid Duration: ${duration}`);
    }

    return {
      start,
      category,
      notes,
      end,
      duration,
    } satisfies OtherEventParams;
  }

  const onSubmit = async (formValues: OtherEventFormValues) => {
    try {
      const { start, end, duration, ...params } = validate(formValues);
      const dto: CreateCalendarEventDto = {
        type: "Other" as const,
        uid: uuidv4(),
        instructorId: instructor.id,
        start: start.toISO(),
        ...(customDurationEvents.includes(category) ? { otherEventDuration: duration } : { end: end!.toISO() }),
        ...params,
      };
      await serverAPI.createCalendarEvent(dto);
      notify("Termin erfolgreich gespeichert.", {
        type: "success",
      });
      await onSuccess();
    } catch (error) {
      console.error("Failed to create other event", error);
      notify("Fehler beim Speichern des Termins", { type: "error" });
    }
  };

  const { category } = formProps.watch();

  return (
    <Form2Theme>
      <FormProvider {...formProps}>
        <form onSubmit={formProps.handleSubmit(onSubmit)} style={{ height: "100%" }}>
          <Column height="100%" minHeight={500} gap="1em">
            <WhenFormIsInitialized>
              <SelectInput
                label="Kategorie"
                source="category"
                options={OtherEventCategoryOptions}
                validate={(value) => (value ? true : "Bitte wähle die Kategorie")}
                size="small"
              />
              {customDurationEvents.includes(category) ? (
                <>
                  <DateInput source="start" allowPastDates={false} size="small" />
                  <SelectInput
                    label="Dauer"
                    source="duration"
                    options={customEventDurationOptions}
                    validate={(value) => (value ? true : "Bitte wähle die Dauer")}
                    size="small"
                  />
                </>
              ) : (
                <>
                  <DateTimeInput source="start" allowPastDates={false} size="small" />
                  <TimeInput source="end" label="Ende" sx={{ width: "100%" }} size="small" />
                </>
              )}
              <TextInput label="Notizen" source="notes" rows={4} fullWidth multiline size="small" />
              <Row sx={{ justifyContent: "flex-end", mt: "auto" }}>
                <SaveButton icon={<></>} label="Termin speichern" />
              </Row>
            </WhenFormIsInitialized>
          </Column>
        </form>
      </FormProvider>
    </Form2Theme>
  );
};

function WhenFormIsInitialized({ children }: { children: ReactNode }) {
  const { formState } = useFormContext();
  return formState.isLoading ? null : children;
}

const customEventDurationOptions: [string, string][] = [
  ["fullDay", "Ganzer Tag"],
  ["halfDayInTheMorning", "halber Tag (am Morgen)"],
  ["halfDayInTheAfternoon", "halber Tag (am Nachmittag)"],
];

const OtherEventCategories = {
  businessTrip: "Dienstfahrt",
  cancelledDrivingLesson: "Abgesagte Fahrstunde",
  carCare: "Auto pflegen",
  changeCars: "Fahrzeug wechseln",
  chargeCars: "Fahrzeug laden",
  examPlanning: "Prüfungsplanung",
  journeyFromAndToCustomer: "Kundenfahrt",
  meeting: "Besprechung",
  office: "Büro",
  other: "Sonstiges",
  pause: "Pause",
  preparationTheory: "Theorie vorbereiten",
  private: "Privat",
  publicHoliday: "Feiertag",
  sickness: "Krankheit",
  training: "Schulung",
  vacation: "Urlaub",
};

const OtherEventCategoryOptions = Object.entries(OtherEventCategories);
