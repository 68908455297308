import { createPortal } from "react-dom";
import deLocale from "@fullcalendar/core/locales/de";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useGetManyReference, useGetOne } from "react-admin";

import { AddAppointmentDialog } from "../../dialogs/AddAppointmentDialog/AddAppointmentDialog";
import { autovioCalendarEventToEventInput, EventContent } from "../../misc/calendar/CalendarEventCardContent";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { eventClassNames } from "../../utils/calendar";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { useDateFromHashOrNow } from "../../hooks/useDateFromHashOrNow";
import { useDialog } from "../../hooks/useDialog";
import { grants } from "../../backoffice.access_control";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { useRefetchCalendarEventsCounter } from "../../hooks/useRefetchCalendarEventsCounter";

export function DrivingSchoolCalendar() {
  useEffect(() => {
    document.title = "Kalender";
  }, []);
  const [{ drivingSchoolId }] = useAutovioContext();
  const date = useDateFromHashOrNow();
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [branchDropdownAnchor, setBranchDropdownAnchor] = useState<undefined | HTMLDivElement>();
  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: date.startOf("week").toISODate(),
    // date.endOf("week") returns a DateTime for the week's sunday at 23:59:59.999,
    // but we need the date of next weeks monday, therefore we add 13 hours ...
    to: date.endOf("week").plus({ hour: 13 }).toISODate(),
  });
  const refetchCounter = useRefetchCalendarEventsCounter();
  const { data, isLoading } = useGetManyReference<AutovioCalendarEvent>(
    "calendarEvents",
    {
      target: "drivingSchoolId",
      id: drivingSchoolId,
      filter: {
        dateRange,
        type: ["TheoryLesson", ...["TheoryExam"], "PracticalExam"],
        ...(selectedBranchId ? { branchId: selectedBranchId } : {}),
      },
      pagination: { page: 1, perPage: 9999 },
      meta: { refetchCounter },
    },
    {
      enabled: !!drivingSchoolId,
    },
  );

  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    {
      id: drivingSchoolId!,
    },
    {
      enabled: !!drivingSchoolId,
    },
  );
  const branches = drivingSchool?.branches ?? [];

  useEffect(() => {
    let stop = false;
    void (async () => {
      let anchorButton = undefined;
      do {
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (stop) {
          return;
        }
        anchorButton = document.querySelector(".fc-branchDropdownAnchor-button");
      } while (!anchorButton);
      const anchor = document.createElement("div");
      anchorButton.insertAdjacentElement("beforebegin", anchor);
      setBranchDropdownAnchor(anchor);
    })();
    return () => {
      stop = true;
    };
  }, []);

  const minHour = data?.reduce((acc, it) => Math.min(acc, it.start.hour), 6) ?? 6;
  const { dialogProps, openDialog } = useDialog();

  const canCreateExamOrCourse =
    grants.includes("Exam:create") || grants.includes("theoryCoursesCreate") || grants.includes("manageTheoryLessons");

  if (!drivingSchoolId) {
    return null;
  }

  return (
    <Box>
      <FullCalendar
        timeZone="Europe/Berlin"
        locales={[deLocale]}
        height="auto"
        slotMinTime={`0${minHour}:00:00`}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        eventMinHeight={1}
        headerToolbar={{
          left: "title branchDropdownAnchor",
          center: "",
          right: `prev today next ${canCreateExamOrCourse ? "addAppointmentButton " : ""}timeGridDay,timeGridWeek,dayGridMonth`,
        }}
        customButtons={{
          addAppointmentButton: { text: "＋\u00A0Termin", click: openDialog, hint: "Neuen Termin hinzufügen" },
          selectBranchDropdownAnchor: {},
        }}
        initialDate={date.toISO()}
        initialView="timeGridWeek"
        scrollTime="08:00:00"
        allDaySlot={false}
        editable={false}
        weekNumbers={true}
        dayMaxEvents={true}
        nowIndicator={true}
        events={data?.map(autovioCalendarEventToEventInput) ?? []}
        eventClassNames={eventClassNames}
        eventContent={(eventInfo) => <EventContent eventInfo={eventInfo} mode="DrivingSchoolCalendar" />}
        datesSet={(dates) => {
          const from = DateTime.fromJSDate(dates.start, { zone: "Europe/Berlin" }).toISODate();
          const to = DateTime.fromJSDate(dates.end, { zone: "Europe/Berlin" }).toISODate();
          if (dateRange.from !== from || dateRange.to !== to) {
            setDateRange({ from, to });
          }
        }}
        dateClick={canCreateExamOrCourse ? AddAppointmentDialog.fullCalendarDateClickHandler(openDialog) : undefined}
      />
      <AddAppointmentDialog
        {...dialogProps}
        drivingSchoolId={drivingSchoolId}
        tabs={["exam", "theoryCourse", "theoryLesson"]}
      />
      {isLoading && <LoadingIndicator />}
      {branchDropdownAnchor &&
        branches.length > 1 &&
        (createPortal(
          <FormControl size="small">
            <InputLabel>Filiale</InputLabel>
            <Select value={selectedBranchId} onChange={(e) => setSelectedBranchId(e.target.value)} label="Filiale">
              <MenuItem value="">
                <em>Alle Termine anzeigen</em>
              </MenuItem>
              {branches.map((branch) => (
                <MenuItem value={branch.uid}>{branch.name}</MenuItem>
              ))}
            </Select>
          </FormControl>,
          branchDropdownAnchor,
        ) as any)}
    </Box>
  );
}
