import { Resource, useRedirect } from "react-admin";
import { StudentsIcon } from "../icons/StudentsIcon";
import { StudentDetails } from "../details/StudentDetails";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { Column } from "../misc/Column";

function _StudentsList() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  useEffect(() => {
    if (drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/students`);
    }
  });
  return drivingSchoolId ? null : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

export const studentsResource = (
  <Resource
    key="students"
    name="students"
    icon={StudentsIcon}
    list={_StudentsList}
    edit={StudentDetails}
    options={{ label: "Fahrschüler" }}
    recordRepresentation={(student) => student.name.replaceAll(" ", "\u00A0")}
  />
);
