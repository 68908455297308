import {
  CheckboxGroupInput,
  Datagrid,
  EditBase,
  FunctionField,
  List,
  Pagination,
  RaRecord,
  Resource,
  SaveButton,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useGetList,
  useRecordContext,
} from "react-admin";
import AutovioEmployeeIcon from "@mui/icons-material/Engineering";
import { AvatarField } from "../fields/AvatarField";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { Box } from "@mui/material";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { Row } from "../misc/Row";
import { PageTitle } from "../misc/PageTitle";
import { AutovioEmployee } from "../providers/autovioEmployeesProvider";
import { useFormContext } from "react-hook-form";

const filters = [<TextInput label="Suche" source="q" alwaysOn resettable />];

const EmployeePagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

function AutovioEmployeeList() {
  return (
    <List
      title={<BackofficeListTitle />}
      filters={filters}
      sort={{ field: "name", order: "ASC" }}
      pagination={<EmployeePagination />}
      exporter={false}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <AvatarField label="" source="avatarUrl" />
        <TextField label="Name" source="displayName" />
        <FunctionField label="Rollen" render={(record?: RaRecord) => record?.roles.join(", ")} />
        <ShowIconButton />
      </Datagrid>
    </List>
  );
}

const ROLES_CHOICES = [
  { id: "admin", name: "AUTOVIO Admin (admin)" },
  { id: "partnerSuccess", name: "AUTOVIO Customer Success Fahrlehrer (partnerSuccess)" },
  { id: "studentSales", name: "AUTOVIO Customer Success Fahrschüler (studentSales)" },
  { id: "dispatcher", name: "AUTOVIO Disponent (dispatcher)" },
  { id: "drivingSchoolManager", name: "Fahrschulleiter (drivingSchoolManager)" },
  { id: "drivingSchoolAnalyst", name: "Fahrschulanalyst (drivingSchoolAnalyst)" },
  { id: "drivingSchoolCustomerService", name: "Fahrschule Kundenservice (drivingSchoolCustomerService)" },
  { id: "instructor", name: "Fahrlehrer (instructor)" },
  { id: "student", name: "Fahrschüler (student)" },
  { id: "appTester", name: "AUTOVIO Tester (appTester)" },
];

function AutovioEmployeeDetails() {
  return (
    <>
      <Show
        className="autovio-employee-details"
        title={<BackofficeRecordTitle />}
        sx={{ mt: "20px", border: "1px solid #e5e5e5", borderRadius: 0.5, p: "20px" }}
        actions={false}
      >
        <AvatarAndName />
      </Show>
      <EditBase redirect={false}>
        <AutovioEmployeeForm />
      </EditBase>
    </>
  );
}

function AvatarAndName() {
  const autovioEmployee = useRecordContext<AutovioEmployee>();

  return (
    <Row spacing="20px" alignItems="center">
      <AvatarField source="avatarUrl" sx={{ width: "60px", height: "60px" }} />
      <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
        {autovioEmployee && <PageTitle>{autovioEmployee.displayName}</PageTitle>}
      </Box>
    </Row>
  );
}

function AutovioEmployeeForm() {
  const autovioEmployee = useRecordContext<AutovioEmployee>();

  if (!autovioEmployee) {
    return null;
  }

  return (
    <SimpleForm
      sx={{ mt: "30px", p: 0 }}
      warnWhenUnsavedChanges
      toolbar={
        <Toolbar>
          <SaveButton label="Speichern" />
        </Toolbar>
      }
    >
      <Row gap="30px">
        <RolesInput />
        <EntitledForDrivingSchoolsBox />
      </Row>
    </SimpleForm>
  );
}

export function RolesInput({ label = "Rollen" }: { label?: string }) {
  return <CheckboxGroupInput label={label} source="roles" choices={ROLES_CHOICES} row={false} />;
}

export function EntitledForDrivingSchoolsBox() {
  const { watch } = useFormContext<{ roles: Array<string> }>();
  const roles = watch("roles") ?? [];
  const { data: drivingSchools } = useGetList("drivingSchools", {
    pagination: { page: 1, perPage: 9999 },
    sort: { field: "name", order: "ASC" },
  });

  if (!((roles.includes("dispatcher") || roles.includes("drivingSchoolCustomerService")) && drivingSchools?.length)) {
    return null;
  }

  return (
    <Box sx={{ border: "1px solid #e5e5e5", borderRadius: 0.5, px: "20px" }}>
      <CheckboxGroupInput
        label="hat Zugriff auf"
        source="entitledForDrivingSchools"
        choices={drivingSchools}
        row={false}
      />
    </Box>
  );
}

export const autovioEmployeesResource = (
  <Resource
    key="autovioEmployees"
    name="autovioEmployees"
    icon={AutovioEmployeeIcon}
    list={AutovioEmployeeList}
    edit={AutovioEmployeeDetails}
    options={{ label: "AUTOVIO Mitarbeiter" }}
    recordRepresentation={(autovioEmployee) => autovioEmployee.name}
  />
);
