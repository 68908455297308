import {
  collection,
  getDocsFromServer,
  onSnapshot,
  query,
  type QueryDocumentSnapshot,
  Unsubscribe,
  where,
} from "firebase/firestore";
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import type { z } from "zod";
import { createNote, editNote } from "../api/backoffice.api";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { firestore } from "../firebase";
import { DrivingSchoolNoteSchema, StudentNoteSchema, type DrivingSchoolNote, type StudentNote } from "../model/Note";
import { t } from "../model/types";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { gcs } from "../utils/storage";
import { grants } from "../backoffice.access_control";

type CreateNoteDto = z.infer<typeof schemas.CreateNoteDto>;
type UpdateNoteDto = z.infer<typeof schemas.UpdateNoteDto>;
export type NotesSource = "studentNotes" | "drivingSchoolNotes";

export type Note = StudentNote | DrivingSchoolNote;

abstract class AbstractNotesProvider<T extends Note> extends AbstractDataProvider<T> {
  private _snapshotPromiseRecordId: Identifier | undefined;
  private _snapshotPromise: Promise<Array<T>> | undefined;
  private _unsubscribe: Unsubscribe | undefined;
  private readonly _onUpdateListeners: Array<() => void> = [];

  constructor(private readonly resource: "studentNotes" | "drivingSchoolNotes") {
    super();
  }

  async getOne(_: string, { id }: GetOneParams<T>): Promise<GetOneResult<T>> {
    const notes = await this._snapshotPromise!;
    const note = notes.find((it) => it.id === id);
    if (!note) {
      throw new Error(`Could not find note ${JSON.stringify(id)}`);
    }
    return { data: note };
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<T>> {
    if (this.resource === "studentNotes" && target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    if (this.resource === "drivingSchoolNotes" && target !== "drivingSchoolUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "drivingSchoolUid"`);
    }
    const notes = await this.fetchIfNeeded(id);
    return applyPagination(applySort(applyFilter(notes, filter), sort), pagination);
  }

  async update(_: string, { data }: UpdateParams<T>): Promise<UpdateResult<T>> {
    if (this.resource === "drivingSchoolNotes") {
      const { id, body, drivingSchoolId } = t
        .object({ id: t.uid(), body: t.string(), drivingSchoolId: t.string() })
        .parse(data);
      let note: T | undefined;
      const noteUpdated = new Promise<void>((resolve, reject) => {
        const unsubscribe = this.onUpdate(async () => {
          try {
            const notes = await this.fetch(drivingSchoolId);
            note = notes.find((it) => it.id === id && it)!;
            if (note && note.body === body) {
              unsubscribe();
              resolve();
            }
          } catch (error) {
            unsubscribe();
            reject(error);
          }
        });
      });
      await this.editServerNote({ id, body });
      await noteUpdated;
      if (!note) {
        throw new Error(`Could not find note ${JSON.stringify(id)}`);
      }
      return { data: note };
    }
    if (this.resource === "studentNotes") {
      const { id, studentUid, body } = t
        .object({
          id: t.uid(),
          studentUid: t.uid(),
          body: t.string(),
        })
        .parse(data);
      await editNote({ studentUid, noteUid: id, body });
      const notes = await this.fetch(studentUid);
      const note = notes.find((it) => it.id === id)!;
      return { data: note };
    }
    throw new Error(`Unexpected resource: ${this.resource}`);
  }

  async create(
    _: string,
    {
      data,
    }: CreateParams<{
      recordId: string;
      body: string;
      files?: Array<File>;
    }>,
  ): Promise<CreateResult<T>> {
    const { recordId, body, files } = data;
    if (typeof recordId !== "string") throw new Error("Property recordId must be provided.");
    if (!recordId) throw new Error("Property recordId must not be empty.");
    if (typeof body !== "string") throw new Error("Property body must be provided.");
    const attachments = files && files.length > 0 ? await gcs.uploadFiles(files) : [];
    const knownIds = new Set((await this.fetchIfNeeded(recordId)).map((note) => note.id));
    if (this.resource === "drivingSchoolNotes") {
      let note: T | undefined;
      const noteCreated = new Promise<void>((resolve, reject) => {
        const unsubscribe = this.onUpdate(async () => {
          try {
            const notes = await this.fetch(recordId);
            note = notes.find((it) => it.body === body && it)!;
            if (note && note.body === body) {
              unsubscribe();
              resolve();
            }
          } catch (error) {
            unsubscribe();
            reject(error);
          }
        });
      });
      await this.createServerNote({ drivingSchoolId: recordId, body, type: "DRIVING_SCHOOL", attachments });
      await noteCreated;
    } else if (this.resource === "studentNotes") {
      // TODO: Migrate this to call the server as well.
      await createNote({ studentUid: recordId, body, attachments });
    }
    const notes = await this.fetch(recordId);
    const newNote = notes.find((it) => !knownIds.has(it.id))!;
    return { data: newNote };
  }

  async delete(
    _: string,
    { id, meta: { note } }: DeleteParams<{ id: string; meta: { note: T } }>,
  ): Promise<DeleteResult<T>> {
    if (!id) throw new Error("Property id must not be empty.");
    await this.deleteNote({ id, type: this.resource === "drivingSchoolNotes" ? "DRIVING_SCHOOL" : "STUDENT" });
    return { data: note };
  }

  fetchIfNeeded(recordId: Identifier): Promise<Array<T>> {
    if (this._snapshotPromiseRecordId === recordId) {
      return this._snapshotPromise!;
    }
    return this.fetch(recordId);
  }

  private get parser() {
    return this.resource === "studentNotes" ? StudentNoteSchema : DrivingSchoolNoteSchema;
  }

  private parseSnapshot(doc: QueryDocumentSnapshot, recordId: Identifier): T {
    let parserData;
    if (this.resource === "studentNotes") {
      parserData = { studentUid: recordId };
    } else {
      parserData = {};
    }
    return this.parser.parse({ ...parserData, ...doc.data() }) as unknown as T;
  }

  fetch(recordId: Identifier): Promise<Array<T>> {
    const path = this.resource === "studentNotes" ? `/users/${recordId}/notes` : `/driving_schools/${recordId}/notes`;
    const firestoreCollection = collection(firestore, path);
    const constraints = !grants.includes("Note:viewDeleted") ? [where("deleted", "==", false)] : [];
    const q = query(firestoreCollection, ...constraints);
    this._unsubscribe?.();
    this._unsubscribe = undefined;
    this._snapshotPromiseRecordId = recordId;
    this._snapshotPromise = getDocsFromServer(q).then(async (snapshot) => {
      let notes = snapshot.docs.map((doc) => {
        return this.parseSnapshot(doc, recordId);
      });
      console.info(`Retrieved ${notes.length} note(s) for ${this.owner(recordId)}`);
      this._unsubscribe = onSnapshot(q, async (snapshot) => {
        const updatedNotes = await Promise.all(
          snapshot.docs.map((doc) => {
            return this.parseSnapshot(doc, recordId);
          }),
        );
        // Ignore incomplete snapshots by checking their length ...
        if (updatedNotes.length < notes.length) {
          return;
        }
        notes = updatedNotes;
        this._snapshotPromise = Promise.resolve(notes);
        console.info(`Retrieved new snapshot with ${notes.length} note(s) for ${this.owner(recordId)}`);
        for (const listener of this._onUpdateListeners) {
          try {
            listener();
          } catch (error) {
            console.error("listener failed", error);
          }
        }
      });
      return notes;
    });
    return this._snapshotPromise;
  }

  onUpdate(listener: () => any): () => void {
    if (this._onUpdateListeners.indexOf(listener) >= 0) {
      throw new Error("listener already registered");
    }
    this._onUpdateListeners.push(listener);
    return () => {
      const i = this._onUpdateListeners.indexOf(listener);
      if (i < 0) {
        throw new Error("listener already unregistered");
      }
      this._onUpdateListeners.splice(i, 1);
    };
  }

  private async createServerNote(data: CreateNoteDto) {
    const client = await getAuthenticatedServerClient();
    const note = await client.createNote(data);
    return note;
  }

  private async editServerNote(data: UpdateNoteDto) {
    const client = await getAuthenticatedServerClient();
    return await client.updateNote(data);
  }

  private async deleteNote({ id, type }: { id: string; type: "DRIVING_SCHOOL" | "STUDENT" }) {
    const client = await getAuthenticatedServerClient();
    await client.deleteNote(undefined, { params: { id }, queries: { type } });
    await this.fetch(this._snapshotPromiseRecordId!);
  }

  private owner(id: Identifier): string {
    switch (this.resource) {
      case "drivingSchoolNotes":
        return `driving school ${id}`;
      case "studentNotes":
        return `student ${id}`;
    }
  }
}

class StudentNotesProvider extends AbstractNotesProvider<StudentNote> {}
class DrivingSchoolNotesProvider extends AbstractNotesProvider<DrivingSchoolNote> {}

export const studentNotesProvider = new StudentNotesProvider("studentNotes");
export const drivingSchoolNotesProvider = new DrivingSchoolNotesProvider("drivingSchoolNotes");
