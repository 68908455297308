import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import OnlineMeetingIcon from "@mui/icons-material/PersonalVideo";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import InstructorIcon from "@mui/icons-material/Person";
import StudentIcon from "@mui/icons-material/PersonOutline";
import { EventContentArg, EventInput } from "@fullcalendar/core";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";

import {
  ASFCourseSession,
  AutovioCalendarEvent,
  DrivingLesson,
  hasInstructor,
  isASFCourseSession,
  isDrivingLesson,
  isOtherEvent,
  isRemoteLesson,
  isTheoryExam,
  isTheoryLesson,
  OtherEvent,
  RemoteLesson,
  TheoryExam,
  TheoryLesson,
} from "../../model/autovioCalendarEvents";
import { calendarEventBackgroundColor, calendarEventTextColor, calendarEventTitle } from "../../utils/calendar";
import { InstructorDisplayName } from "../InstructorDisplayName";
import { StudentDisplayName } from "../StudentDisplayName";
import { autovioColors } from "../backofficeTheme";
import { EventDialog, useEventDialog } from "./EventDialog";
import { Recommendation, RecommendationWithPrice } from "../../api/backoffice.api";
import { useRecoilValue } from "recoil";
import { selectedStartLocationState, useIsDebug } from "./InstructorCalendar";
import { LinearProgress, RecordContextProvider, TextField, useGetOne, useRecordContext } from "react-admin";
import { Instructor } from "../../providers/instructorsProvider";
import { ConfirmBookingDialog } from "./ConfirmBookingDialog";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { Vehicle } from "../../providers/resourceProviders";
import { VehicleIcon } from "../../icons/VehicleIcon";

interface EventContentProps {
  readonly eventInfo: EventContentArg;
  readonly mode: "DrivingSchoolCalendar" | "InstructorCalendar" | "ResourceCalendar";
}

export const EventContent = (props: EventContentProps) => {
  const event = props.eventInfo.event.extendedProps?.event as AutovioCalendarEvent | undefined;
  const recommendation = props.eventInfo.event.extendedProps?.recommendation as Recommendation | undefined;
  if (event) {
    return <AutovioCalendarEventContent eventInfo={props.eventInfo} event={event} mode={props.mode} />;
  }
  if (recommendation) {
    return <RecommendationContent eventInfo={props.eventInfo} recommendation={recommendation} />;
  }
  return <GenericEventContent eventInfo={props.eventInfo} />;
};

interface AutovioCalendarEventContentProps {
  readonly eventInfo: EventContentArg;
  readonly event: AutovioCalendarEvent;
  readonly mode: "DrivingSchoolCalendar" | "InstructorCalendar" | "ResourceCalendar";
}

const AutovioCalendarEventContent = (props: AutovioCalendarEventContentProps) => {
  const { event } = props;
  const [isDialogOpen, setIsDialogOpen_] = useState<boolean>(() => {
    const openDialog = new URL(location.href).searchParams.get("openDialog");
    return openDialog === `${event.type}Dialog!${event.id}`;
  });
  const { canBeOpenedInDialog } = useEventDialog();
  const setIsDialogOpen = (value: boolean) => {
    // Side effect: update browser URL ...
    const url = new URL(location.href);
    if (value) {
      if (url.searchParams.get("openDialog") !== `${event.type}Dialog!${event.id}`) {
        url.searchParams.set("openDialog", `${event.type}Dialog!${event.id}`);
        browserHistory.replace(url.toString());
      }
    } else {
      url.searchParams.delete("openDialog");
      url.searchParams.delete("showHistory");
      browserHistory.replace(url.toString());
    }
    setIsDialogOpen_(value);
  };

  return (
    <EventContentWithTooltip
      eventInfo={props.eventInfo}
      title={<CalendarEventCardContent event={event} mode="Tooltip" />}
      onClick={canBeOpenedInDialog(event) ? () => setIsDialogOpen(true) : undefined}
      hideTooltip={isDialogOpen}
    >
      <CalendarEventCardContent event={event} mode={props.mode} />
      <EventDialog event={props.event} isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
    </EventContentWithTooltip>
  );
};

interface GenericEventContentProps {
  readonly eventInfo: EventContentArg;
}

const GenericEventContent = (props: GenericEventContentProps) => {
  const content = <EventTitle eventOrTitle={props.eventInfo.event.title} />;

  return (
    <EventContentWithTooltip eventInfo={props.eventInfo} title={content}>
      {content}
    </EventContentWithTooltip>
  );
};

interface RecommendationContentProps {
  readonly eventInfo: EventContentArg;
  readonly recommendation: Recommendation | RecommendationWithPrice;
}

const RecommendationContent = (props: RecommendationContentProps) => {
  const instructor = useRecordContext<Instructor>();
  const [isConfirmBookingDialogOpen, setIsConfirmBookingDialogOpen] = useState(false);
  const { data: drivingSchool } = useGetOne("drivingSchools", { id: instructor.drivingSchoolId });
  const startLocation = useRecoilValue(selectedStartLocationState);

  const content = (
    <>
      <RecommendationCardContent recommendation={props.recommendation} />
      {"price" in props.recommendation && drivingSchool && startLocation && (
        <>
          <HoverContainer onClick={() => setIsConfirmBookingDialogOpen(true)} />
          <ConfirmBookingDialog
            isOpen={isConfirmBookingDialogOpen}
            drivingSchool={drivingSchool}
            startLocation={startLocation}
            recommendation={props.recommendation}
            onClose={() => setIsConfirmBookingDialogOpen(false)}
          />
        </>
      )}
    </>
  );

  return (
    <EventContentWithTooltip
      eventInfo={props.eventInfo}
      title={content}
      onClick={() => setIsConfirmBookingDialogOpen(true)}
      hideTooltip
    >
      {content}
    </EventContentWithTooltip>
  );
};

const IconWithText = ({ icon, text }: { icon: React.ReactNode; text: string | React.ReactNode }) => {
  return (
    <Stack direction="row" gap="3px">
      {icon}
      {typeof text === "string" ? (
        <Typography fontSize="11px" noWrap>
          {text}
        </Typography>
      ) : (
        text
      )}
    </Stack>
  );
};

interface CalendarEventContentProps<T extends AutovioCalendarEvent> {
  event: T;
  mode: "DrivingSchoolCalendar" | "InstructorCalendar" | "ResourceCalendar" | "Tooltip";
}

const CalendarEventCardContent = (props: CalendarEventContentProps<AutovioCalendarEvent>) => {
  const { event, mode } = props;
  if (isDrivingLesson(event)) {
    return <DrivingLessonContent event={event} mode={mode} />;
  } else if (isTheoryLesson(event)) {
    return <TheoryLessonContent event={event} mode={mode} />;
  } else if (isOtherEvent(event)) {
    return <OtherEventContent event={event} mode={mode} />;
  } else if (isTheoryExam(event)) {
    return <TheoryExamContent event={event} mode={mode} />;
  } else if (isRemoteLesson(event)) {
    return <RemoteLessonContent event={event} mode={mode} />;
  } else if (isASFCourseSession(event)) {
    return <ASFCourseSessionContent event={event} mode={mode} />;
  }
  // Fallback for unknown events ...
  return <Typography>Fehler beim Anzeigen</Typography>;
};

const HourGlassIconTopRight = () => {
  return (
    <div style={{ position: "absolute", top: 0, right: 0 }}>
      <HourglassEmptyIcon fontSize="small" sx={{ opacity: 0.5 }} />
    </div>
  );
};

const EventTitle = ({ eventOrTitle }: { readonly eventOrTitle: AutovioCalendarEvent | string }) => {
  return (
    <Typography variant="body2" fontWeight="bold" noWrap>
      {typeof eventOrTitle === "string" ? eventOrTitle : calendarEventTitle(eventOrTitle)}
    </Typography>
  );
};

const DrivingLessonContent = (props: CalendarEventContentProps<DrivingLesson>) => {
  const { event, mode } = props;
  const isPending = event.student?.rsvp === "pending";

  return (
    <Stack sx={{ position: "relative" }}>
      {isPending && mode !== "Tooltip" && <HourGlassIconTopRight />}
      <EventTitle eventOrTitle={event} />
      {mode === "Tooltip" && <_Instructor instructorId={event.instructorId} />}
      {mode === "Tooltip" || mode === "InstructorCalendar" ? (
        <_Student studentId={event.student.uid} />
      ) : (
        <IconWithText
          icon={<StudentIcon fontSize="inherit" />}
          text={
            <Stack direction="row" gap="3px">
              <StudentDisplayName studentId={event.student.uid} fontSize="11px" noWrap />
              {hasInstructor(event) && (
                <Stack direction="row">
                  <Typography fontSize="11px" noWrap>
                    {"("}
                  </Typography>
                  <InstructorDisplayName instructorId={event.instructorId!} source="firstName" fontSize="11px" noWrap />
                  <Typography fontSize="11px" noWrap>
                    {")"}
                  </Typography>
                </Stack>
              )}
            </Stack>
          }
        />
      )}
      {mode === "Tooltip" && (event.resources ?? []).map((id) => <_Vehicle key={id} vehicleId={id} />)}
      {mode === "Tooltip" && <_Location event={event} />}
    </Stack>
  );
};

const TheoryLessonContent = (props: CalendarEventContentProps<TheoryLesson>) => {
  const { event } = props;
  const eventIsInPast = event.end ? event.end.plus({ hours: 2 }) < DateTime.now() : false;
  const numRegisteredStudents = event.students
    ? Object.values(event.students).filter((it) => it.rsvp === "accepted").length
    : undefined;
  const numAttendedStudents = Object.values(event.students).filter((it) => it.attended).length;
  const numMaxStudents = event.maxStudents;
  return (
    <Stack sx={{ position: "relative" }}>
      <EventTitle eventOrTitle={`Lektion ${event.theoryUnit}`} />
      <_Instructor instructorId={event.instructorId} />
      <_Location event={event} />
      <Stack direction="row" justifyContent="space-between" gap="10px">
        {eventIsInPast ? (
          <IconWithText icon={<PeopleIcon fontSize="inherit" />} text={numAttendedStudents?.toString() ?? "0"} />
        ) : (
          <Stack direction="row">
            {(numRegisteredStudents || numMaxStudents) && (
              <IconWithText icon={<PeopleIcon fontSize="inherit" />} text={numRegisteredStudents?.toString() ?? "0"} />
            )}
            {numMaxStudents && <Typography fontSize="11px">{`/${numMaxStudents}`}</Typography>}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const ASFCourseSessionContent = (props: CalendarEventContentProps<ASFCourseSession>) => {
  const { event, mode } = props;
  return (
    <Stack sx={{ position: "relative" }}>
      <EventTitle eventOrTitle={calendarEventTitle(event)} />
      {mode !== "InstructorCalendar" && <_Instructor instructorId={event.instructorId} />}
      <_Location event={props.event} />
      <IconWithText icon={<PeopleIcon fontSize="inherit" />} text={event.numBookedParticipants} />
    </Stack>
  );
};

const OtherEventContent = (props: CalendarEventContentProps<OtherEvent>) => {
  const { event, mode } = props;
  return (
    <Stack>
      <EventTitle eventOrTitle={event} />
      {mode !== "InstructorCalendar" && <_Instructor instructorId={event.instructorId} />}
      {event.notes && (
        <Typography fontSize="11px" noWrap>
          {event.notes}
        </Typography>
      )}
    </Stack>
  );
};

const TheoryExamContent = (props: CalendarEventContentProps<TheoryExam>) => {
  const { event, mode } = props;

  return (
    <Stack>
      <EventTitle eventOrTitle={event} />
      <_Student studentId={event.student.uid} />
      {mode === "Tooltip" && <_Location event={event} />}
    </Stack>
  );
};

const RemoteLessonContent = (props: CalendarEventContentProps<RemoteLesson>) => {
  const { event, mode } = props;
  return (
    <Stack>
      <EventTitle eventOrTitle={props.event} />
      {mode !== "InstructorCalendar" && <_Instructor instructorId={event.instructorId} />}
      <_Student studentId={event.student.uid} />
    </Stack>
  );
};

interface RecommendationCardContentProps {
  readonly recommendation: Recommendation;
}

const RecommendationCardContent = (props: RecommendationCardContentProps) => {
  const isDebug = useIsDebug();
  return (
    <Stack>
      <EventTitle eventOrTitle={"Terminvorschlag"} />
      {props.recommendation.resourceUids.map((id) => (
        <_Vehicle key={id} vehicleId={id} />
      ))}
      {isDebug && props.recommendation.score !== undefined && (
        <Typography>{`Score: ${Math.round(props.recommendation.score)}`}</Typography>
      )}
      {isDebug && props.recommendation.individualScores?.coverage !== undefined && (
        <Typography>{`Coverage: ${Math.round(props.recommendation.individualScores?.coverage)}`}</Typography>
      )}
      {isDebug && props.recommendation.individualScores?.instructorSwitch !== undefined && (
        <Typography>{`Instructor Switch: ${Math.round(
          props.recommendation.individualScores?.instructorSwitch,
        )}`}</Typography>
      )}
      {isDebug && props.recommendation.individualScores?.resourceSwitch !== undefined && (
        <Typography>{`Resource Switch: ${Math.round(
          props.recommendation.individualScores?.resourceSwitch,
        )}`}</Typography>
      )}
      {isDebug && props.recommendation.removeReason && (
        <Typography>{`Reason: ${props.recommendation.removeReason}`}</Typography>
      )}
    </Stack>
  );
};

export const autovioCalendarEventToEventInput = (event: AutovioCalendarEvent): EventInput => ({
  title: calendarEventTitle(event),
  id: event.id,
  backgroundColor: calendarEventBackgroundColor(event),
  textColor: calendarEventTextColor(event),
  editable: false,
  start: event.start.toISO(),
  end: event.end.toISO(),
  extendedProps: {
    event: event,
  },
});

const HoverContainer = (props: { readonly onClick: () => void }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100%",
        cursor: "pointer",
        ":hover": { background: autovioColors.grey, opacity: 0.2 },
      }}
      onClick={props.onClick}
    />
  );
};

interface EventContentTooltipProps {
  readonly children: React.ReactNode;
  readonly eventInfo: EventContentArg;
  readonly title: React.ReactNode;
  readonly onClick?: (eventInfo: EventContentArg) => void;
  readonly hideTooltip?: boolean;
}

const EventContentWithTooltip = (props: EventContentTooltipProps) => {
  const wrappedContent = (
    <Box overflow="hidden">
      <Box sx={{ px: "5px" }}>
        {props.children}
        {props.onClick && <HoverContainer onClick={() => props.onClick?.(props.eventInfo)} />}
      </Box>
    </Box>
  );

  return (
    <>
      {props.hideTooltip || !props.eventInfo.event.title ? (
        <>{wrappedContent}</>
      ) : (
        <Tooltip
          PopperProps={{ placement: "top" }}
          disableFocusListener
          disableInteractive
          title={
            <>
              <Typography variant="caption">{props.eventInfo.timeText}</Typography>
              {props.title}
            </>
          }
        >
          <Box sx={{ width: "100%", height: "100%" }}>{wrappedContent}</Box>
        </Tooltip>
      )}
    </>
  );
};

function _Instructor({ instructorId }: { instructorId: string | undefined }) {
  if (!instructorId) {
    return null;
  }
  return (
    <IconWithText
      icon={<InstructorIcon fontSize="inherit" />}
      text={<InstructorDisplayName instructorId={instructorId} fontSize="11px" noWrap />}
    />
  );
}

function _Student({ studentId }: { studentId: string }) {
  return (
    <IconWithText
      icon={<StudentIcon fontSize="inherit" />}
      text={<StudentDisplayName studentId={studentId} fontSize="11px" noWrap />}
    />
  );
}

function _Vehicle({ vehicleId }: { vehicleId: string }) {
  const { data: vehicle } = useGetOne<Vehicle>("vehicles", { id: vehicleId });
  if (!vehicle) {
    return <LinearProgress sx={{ width: "75%" }} />;
  }
  return (
    <RecordContextProvider value={vehicle}>
      <IconWithText
        icon={<VehicleIcon type={vehicle.type} fontSize="inherit" />}
        text={<TextField source="name" fontSize={"11px"} noWrap />}
      />
    </RecordContextProvider>
  );
}

const _Location = ({ event }: { readonly event: DrivingLesson | TheoryLesson | TheoryExam | ASFCourseSession }) => {
  const { drivingSchoolId } = event;
  const { data: drivingSchool } = useGetOne<DrivingSchool>("drivingSchools", { id: drivingSchoolId });
  const location = isDrivingLesson(event) || isTheoryExam(event) ? event.startLocation : event.location;

  if (location.type === "OnlineMeeting") {
    return <IconWithText icon={<OnlineMeetingIcon fontSize="inherit" />} text="Online" />;
  }

  if (!drivingSchool) {
    return <LinearProgress sx={{ width: "75%" }} />;
  }

  for (const branch of drivingSchool.branches) {
    const branchAddress = branch.postalAddress;
    if (location.street === branchAddress.street && location.postalCode === branchAddress.postalCode) {
      return <IconWithText icon={<PlaceIcon fontSize="inherit" />} text={branch.name} />;
    }
  }

  return <IconWithText icon={<PlaceIcon fontSize="inherit" />} text={`${location.city}, ${location.street}`} />;
};
