import { DateTime } from "luxon";
import { FieldValues, useFormContext, Controller, Path } from "react-hook-form";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { FormControl } from "@mui/material";

interface DateTimeInputProps<T extends FieldValues> {
  source: Path<T>;
  allowPastDates?: boolean;
  sx?: SxProps<Theme>;
  label?: string;
  size?: "small" | "medium";
}

export const DateTimeInput = <T extends FieldValues>({
  source,
  allowPastDates = false,
  sx,
  label = "Datum & Uhrzeit",
  size,
}: DateTimeInputProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={source}
      control={control}
      render={({ field, formState }) => {
        const error = formState.errors[source]?.message;
        const errorMessage = typeof error === "string" ? error : undefined;
        return (
          <FormControl>
            <DateTimePicker
              sx={sx}
              label={label}
              inputRef={field.ref}
              ampm={false}
              value={field.value ?? null}
              disabled={formState.isSubmitting}
              minutesStep={5}
              slotProps={{
                textField: {
                  size: size,
                  onBlur: field.onBlur,
                  ...(errorMessage ? { error: true, helperText: errorMessage } : {}),
                },
              }}
              onChange={(value) => field.onChange({ target: { value } })}
            />
          </FormControl>
        );
      }}
      rules={{
        validate: (value) => {
          if (!value) {
            return "Bitte wähle das Datum und die Uhrzeit aus.";
          }
          const dateTimeValue = value as DateTime;
          if (dateTimeValue.hour === 0 && dateTimeValue.minute === 0) {
            return "Bitte wähle noch die Uhrzeit aus.";
          } else if (dateTimeValue < DateTime.now() && !allowPastDates) {
            return "Bitte wähle einen Zeitpunkt in der Zukunft aus.";
          } else {
            return true;
          }
        },
      }}
    />
  );
};
