import { RobotIcon } from "../../icons/RobotIcon";
import { Button, Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMenu } from "../../hooks/useMenu";

export const InstructorCalendarHeaderMenuButton = ({
  setShowRecommednationFilters,
}: {
  setShowRecommednationFilters: (state: boolean) => void;
}) => {
  const { anchorEl, open, openMenu, closeMenu } = useMenu();

  return (
    <div>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem onClick={closeMenu}>
          <Button
            variant="text"
            startIcon={<RobotIcon />}
            onClick={() => {
              setShowRecommednationFilters(true);
            }}
          >
            Zeige Terminvorschläge
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};
