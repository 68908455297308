import { DayHeaderContentArg, EventInput } from "@fullcalendar/core";
import deLocale from "@fullcalendar/core/locales/de";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useGetManyReference, useGetOne, useRecordContext } from "react-admin";
import { useLocation } from "react-router";
import { atom, useRecoilState } from "recoil";
import {
  MissingAddressDataError,
  NoRecommendationResultReason,
  Recommendation,
  RecommendationsData,
  useStudentRecommendationsQuery,
} from "../../api/backoffice.api";
import { PostalAddress } from "../../model/PostalAddress";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { Instructor } from "../../providers/instructorsProvider";
import { Student } from "../../providers/studentsProvider";
import { BookedTraining } from "../../model/BookedTraining";
import { PrivateAppointment } from "../../providers/instructorPrivateAppointmentsProvider";
import { RecommendationSettings } from "../../providers/instructorRecommendationSettingsProvider";
import { TimeRange, WorkTimeDaysEnum, WorkTimes } from "../../providers/instructorWorkTimesProvider";
import { autovioColors } from "../backofficeTheme";
import { AddAppointmentDialog } from "../../dialogs/AddAppointmentDialog/AddAppointmentDialog";
import { autovioCalendarEventToEventInput, EventContent } from "./CalendarEventCardContent";
import { LoadingIndicator } from "../LoadingIndicator";
import { eventClassNames } from "../../utils/calendar";
import { useDialog } from "../../hooks/useDialog";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { useDateFromHashOrNow } from "../../hooks/useDateFromHashOrNow";
import { InstructorCalendarStats } from "./InstructorCalendarStats";
import { Column } from "../Column";
import { grants } from "../../backoffice.access_control";
import { useRefetchCalendarEventsCounter } from "../../hooks/useRefetchCalendarEventsCounter";
import { createPortal } from "react-dom";
import { InstructorCalendarHeaderMenuButton } from "./InstructorCalendarHeaderMenuButton";
import { Row } from "../Row";
import { InstructorCalendarRecommendationFilter } from "./InstructorCalendarRecommendationFilter";

export function useIsDebug() {
  const { search } = useLocation();
  return new URLSearchParams(search).get("debug") !== null;
}

interface CalendarData {
  data: {
    instructorEvents?: AutovioCalendarEvent[];
    privateAppointments?: PrivateAppointment[];
    recommendationsData?: RecommendationsData;
    workTimes?: WorkTimes;
    recommendationSettings?: RecommendationSettings;
  };
  isLoading: boolean;
  error: unknown;
}

/**
 *  If [withStudentConstraints] is true, uses the same constraints (max lessons per week, max days in advance)
 *   that apply when a student gets recommendations.
 */
function useCalendarData(props: {
  dateRange: { from: string; to: string };
  selectedStudent?: Student;
  selectedBookedTraining?: BookedTraining;
  selectedStartLocation?: PostalAddress;
  withStudentConstraints?: boolean;
}): CalendarData {
  const isDebug = useIsDebug();
  const instructor = useRecordContext<Instructor>();
  const { dateRange, selectedStudent, selectedBookedTraining } = props;
  const { id: instructorId, drivingSchoolId } = instructor;
  const refetchCounter = useRefetchCalendarEventsCounter();
  const {
    data: instructorEvents,
    isLoading: isLoading1,
    error: error1,
  } = useGetManyReference<AutovioCalendarEvent>("calendarEvents", {
    target: "drivingSchoolId",
    id: drivingSchoolId,
    filter: { dateRange, instructorId },
    pagination: { page: 1, perPage: 9999 },
    meta: { refetchCounter, withLessonsCanceledAtShortNotice: true },
  });
  const {
    data: privateAppointments,
    isLoading: isLoading2,
    error: error2,
  } = useGetManyReference<PrivateAppointment>("instructorPrivateAppointments", {
    id: instructorId,
    filter: { from: dateRange.from, to: dateRange.to },
    target: "dummy",
  });
  const {
    data: workTimes,
    isLoading: isLoading3,
    error: error3,
  } = useGetOne<WorkTimes>("instructorWorkTimes", {
    id: instructorId.toString(),
  });
  const bookedTrainings = selectedStudent?.bookedTrainings;
  const {
    data: recommendationsData,
    isLoading: isLoading4,
    error: error4,
  } = useStudentRecommendationsQuery(
    selectedStudent && bookedTrainings && bookedTrainings.length > 0
      ? {
          from: dateRange.from,
          to: dateRange.to,
          bookedTrainingId: (selectedBookedTraining ?? selectedStudent.activeOrMostRecentBookedTraining).id,
          student: selectedStudent,
          instructorId: instructorId.toString(),
          startLocation: props.selectedStartLocation,
          options: {
            returnRemoved: isDebug,
            ...(props.withStudentConstraints
              ? {}
              : {
                  ignoreMaxDaysInAdvance: true,
                  ignoreMaxBookableDrivingLessonsPerWeek: true,
                }),
          },
        }
      : undefined,
  );
  const {
    data: recommendationSettings,
    isLoading: isLoading5,
    error: error5,
  } = useGetOne<RecommendationSettings>("instructorRecommendationSettings", {
    id: instructorId.toString(),
  });
  return {
    data: {
      instructorEvents: instructorEvents?.filter((it) => it.type !== "TheoryExam"),
      recommendationsData,
      privateAppointments,
      workTimes,
      recommendationSettings,
    },
    isLoading: isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5,
    error: error1 || error2 || error3 || error4 || error5,
  };
}

export const selectedStartLocationState = atom<PostalAddress | undefined>({
  key: "InstructorEventsCalendar.selectedStartLocation",
  default: undefined,
});

export function InstructorCalendar() {
  const isDebug = useIsDebug();
  const date = useDateFromHashOrNow();
  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: date.startOf("week").toISODate(),
    // date.endOf("week") returns a DateTime for the week's sunday at 23:59:59.999,
    // but we need the date of next weeks monday, therefore we add 13 hours ...
    to: date.endOf("week").plus({ hour: 13 }).toISODate(),
  });
  const [selectedStudent, setSelectedStudent_] = useState<Student | undefined>();
  const [selectedBookedTraining, setSelectedBookedTraining] = useState<BookedTraining | undefined>();
  const [showRecommednationFilters, setShowRecommednationFilters] = useState<boolean>(false);
  const [threeDotsMenuAnchor, setThreeDotsMenuAnchor] = useState<undefined | HTMLDivElement>();
  const setSelectedStudent = (student: Student | undefined) => {
    setSelectedStudent_(student);
    setSelectedBookedTraining(undefined);
  };
  const [selectedStartLocation, setSelectedStartLocation] = useRecoilState(selectedStartLocationState);
  const [withStudentConstraints, setWithStudentConstraints] = useState(false);
  const { data, isLoading, error } = useCalendarData({
    dateRange,
    selectedStudent,
    selectedBookedTraining,
    selectedStartLocation,
    withStudentConstraints,
  });
  const instructor = useRecordContext<Instructor>();
  const missingAddressInfo = error instanceof MissingAddressDataError;
  const minHour = data?.instructorEvents?.reduce((acc, it) => Math.min(acc, it.start.hour), 6) ?? 6;
  const { dialogProps, openDialog } = useDialog();
  const [{ drivingSchoolId }] = useAutovioContext();

  useEffect(() => {
    let stop = false;
    void (async () => {
      let anchorButton = undefined;
      do {
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (stop) {
          return;
        }
        anchorButton = document.querySelector(".fc-threeDotsMenuAnchor-button");
      } while (!anchorButton);
      const anchor = document.createElement("div");
      anchorButton.insertAdjacentElement("beforebegin", anchor);
      setThreeDotsMenuAnchor(anchor);
    })();
    return () => {
      stop = true;
    };
  }, []);

  const canCreateDrivingLessonOrExam = grants.includes("DrivingLesson:create") || grants.includes("Exam:create");

  if (!drivingSchoolId) {
    return null;
  }

  return (
    <Column>
      <Row
        sx={{
          p: "24px 30px",
          gap: "30px",
          width: "100%",
          backgroundColor: autovioColors.white,
          borderRadius: "8px",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.06)",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {showRecommednationFilters ? (
          <InstructorCalendarRecommendationFilter
            instructor={instructor}
            selectedStudent={selectedStudent}
            selectedBookedTraining={selectedBookedTraining}
            setSelectedStudent={setSelectedStudent}
            setSelectedBookedTraining={setSelectedBookedTraining}
            setSelectedStartLocation={setSelectedStartLocation}
            setShowRecommednationFilters={setShowRecommednationFilters}
          />
        ) : (
          <InstructorCalendarStats dateRange={dateRange} events={data.instructorEvents} />
        )}
      </Row>
      {isDebug && selectedStudent ? (
        <FormControlLabel
          label="Debug: Terminvorschläge aus Sicht des Schülers anzeigen"
          checked={withStudentConstraints}
          control={<Checkbox onChange={(event) => setWithStudentConstraints(event.target.checked)} />}
        />
      ) : (
        <Box sx={{ height: "20px" }} />
      )}
      {missingAddressInfo && (
        <Typography sx={{ mb: "20px", color: autovioColors.red }}>Dem Schüler fehlen Addressinformationen</Typography>
      )}
      <FullCalendar
        timeZone="Europe/Berlin"
        locales={[deLocale]}
        height="auto"
        slotMinTime={`0${minHour}:00:00`}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        eventMinHeight={1}
        headerToolbar={{
          left: "title threeDotsMenuAnchor",
          center: "",
          right: `prev today next ${
            !selectedStudent
              ? `${canCreateDrivingLessonOrExam ? "addAppointmentButton " : ""}timeGridDay,timeGridWeek,dayGridMonth`
              : "timeGridDay,timeGridWeek,dayGridMonth"
          }`,
        }}
        customButtons={{ addAppointmentButton: { text: "＋\u00A0Termin", click: openDialog }, threeDotsMenuAnchor: {} }}
        initialDate={date.toISO()}
        initialView="timeGridWeek"
        scrollTime="08:00:00"
        allDaySlot={false}
        editable={false}
        weekNumbers={true}
        dayMaxEvents={true}
        nowIndicator={true}
        events={[
          ...(data.recommendationsData?.recommendations?.map(_recommendationToEventInput) ?? []),
          ...(isDebug
            ? (data.recommendationsData?.removedRecommendations?.map(_removedRecommendationToEventInput) ?? [])
            : []),
          ...(data.instructorEvents?.map(autovioCalendarEventToEventInput) ?? []),
          ...(data.instructorEvents?.flatMap(_bufferTimesToEventInput) ?? []),
          ...(data.privateAppointments?.map(_privateAppointmentToEventInput) ?? []),
          ...(data.workTimes && data.recommendationSettings
            ? _workTimesToEventInputs({
                workTimes: data.workTimes,
                recommendationSettings: data.recommendationSettings,
                dateRange,
              })
            : []),
        ]}
        dayHeaderContent={(arg) => {
          return _renderDayHeaderContent(arg, {
            noRecommendationResultReasonPerDay: data.recommendationsData?.noResultReasonPerDay,
          });
        }}
        eventClassNames={eventClassNames}
        eventContent={(eventInfo) => <EventContent eventInfo={eventInfo} mode="InstructorCalendar" />}
        datesSet={(dates) => {
          const from = DateTime.fromJSDate(dates.start, { zone: "Europe/Berlin" }).toISODate();
          const to = DateTime.fromJSDate(dates.end, { zone: "Europe/Berlin" }).toISODate();
          if (dateRange.from !== from || dateRange.to !== to) {
            setDateRange({ from, to });
          }
        }}
        dateClick={
          canCreateDrivingLessonOrExam ? AddAppointmentDialog.fullCalendarDateClickHandler(openDialog) : undefined
        }
      />
      {threeDotsMenuAnchor &&
        (createPortal(
          <InstructorCalendarHeaderMenuButton setShowRecommednationFilters={setShowRecommednationFilters} />,
          threeDotsMenuAnchor,
        ) as any)}
      <AddAppointmentDialog
        {...dialogProps}
        drivingSchoolId={drivingSchoolId}
        instructor={instructor}
        tabs={["drivingLesson", "exam", "otherEvent"]}
      />
      {isLoading && <LoadingIndicator />}
    </Column>
  );
}

const _privateAppointmentToEventInput = (privateAppointment: PrivateAppointment): EventInput => ({
  title: "Privater Termin",
  id: privateAppointment.id,
  backgroundColor: autovioColors.greyUltraLight,
  color: autovioColors.greyUltraLight,
  textColor: autovioColors.black,
  editable: false,
  start: privateAppointment.start.toISO(),
  end: privateAppointment.end.toISO(),
});

const _workTimesToEventInputs = ({
  workTimes,
  recommendationSettings,
  dateRange,
}: {
  workTimes: WorkTimes;
  recommendationSettings: RecommendationSettings;
  dateRange: { from: string; to: string };
}): EventInput[] => {
  const startDate = DateTime.fromISO(dateRange.from).startOf("day");
  const endDate = DateTime.min(
    DateTime.fromISO(dateRange.to).endOf("day"),
    DateTime.now().plus({ days: recommendationSettings.maxDaysBookableInAdvance }).endOf("day"),
  );

  if (startDate > endDate) {
    return [];
  }

  const workTimesPerDate: { [k: string]: any } = {};
  for (let date = startDate; date.endOf("day") < endDate; date = date.plus({ days: 1 })) {
    const weekDay = WorkTimeDaysEnum.options[date.weekday - 1];
    workTimesPerDate[date.toISO()] = workTimes[weekDay];
  }

  const eventInputs: EventInput[] = Object.entries(workTimesPerDate).flatMap(([date, timeRanges]) =>
    timeRanges.map((it: TimeRange) => {
      return {
        display: "background",
        start: DateTime.fromISO(date).plus({ hours: it.start.hour, minutes: it.start.minute }).toISO(),
        end: DateTime.fromISO(date).plus({ hours: it.end.hour, minutes: it.end.minute }).toISO(),
      };
    }),
  );
  return eventInputs;
};

const _recommendationToEventInput = (recommendation: Recommendation): EventInput => ({
  title: "Terminvorschlag",
  id: recommendation.id,
  backgroundColor: "#FAE5E5CC",
  color: autovioColors.black,
  textColor: autovioColors.black,
  editable: false,
  start: recommendation.start.toISO(),
  end: recommendation.end.toISO(),
  extendedProps: {
    bookable: true,
    recommendation: recommendation,
  },
});

const _removedRecommendationToEventInput = (recommendation: Recommendation): EventInput => ({
  title: "ENTFERNT",
  id: recommendation.id,
  backgroundColor: autovioColors.red,
  color: autovioColors.black,
  textColor: autovioColors.black,
  editable: false,
  start: recommendation.start.toISO(),
  end: recommendation.end.toISO(),
  extendedProps: {
    recommendation: recommendation,
  },
});

const _bufferTimesToEventInput = (event: AutovioCalendarEvent): EventInput[] => {
  const inputs: EventInput[] = [];
  const preparationTime = event.bufferTimes?.preparationTime;
  const wrapUpTime = event.bufferTimes?.wrapUpTime;
  if (preparationTime) {
    inputs.push({
      title: _bufferTimeTitle(event, "preparationTime"),
      id: `${event.id}-prep`,
      backgroundColor: autovioColors.greyUltraLight,
      color: autovioColors.black,
      textColor: autovioColors.black,
      editable: false,
      start: event.start.minus({ minutes: preparationTime }).toISO(),
      end: event.start.toISO(),
    });
  }
  if (wrapUpTime) {
    inputs.push({
      title: _bufferTimeTitle(event, "wrapUpTime"),
      id: `${event.id}-wrapUp`,
      backgroundColor: autovioColors.greyUltraLight,
      color: autovioColors.black,
      textColor: autovioColors.black,
      editable: false,
      start: event.end.toISO(),
      end: event.end.plus({ minutes: wrapUpTime }).toISO(),
    });
  }
  return inputs;
};

interface DayHeaderData {
  noRecommendationResultReasonPerDay?: { [day: string]: NoRecommendationResultReason | undefined };
}

const _renderDayHeaderContent = (arg: DayHeaderContentArg, data: DayHeaderData): JSX.Element => {
  const dayInCalendar = DateTime.fromJSDate(arg.date);
  const isInPast = dayInCalendar < DateTime.now();
  const noResultReason = data.noRecommendationResultReasonPerDay
    ? Object.entries(data.noRecommendationResultReasonPerDay).find(([day]) =>
        DateTime.fromISO(day).hasSame(dayInCalendar, "day"),
      )?.[1]
    : undefined;
  const isWorkingToday = noResultReason !== "notWorkingToday";

  return (
    <Tooltip
      title={!isInPast && isWorkingToday && noResultReason ? <NoResultReason reason={noResultReason} /> : undefined}
    >
      <Box display="flex" alignItems="center">
        <div>{arg.text}</div>
        <Box sx={{ width: "5px" }} />
        {!isInPast && isWorkingToday && noResultReason && (
          <InfoOutlinedIcon sx={{ width: "15px", height: "15px" }} color="warning" />
        )}
      </Box>
    </Tooltip>
  );
};

const NoResultReason = (props: { reason: NoRecommendationResultReason }) => {
  let reasonTranslation: string;
  switch (props.reason) {
    case "isHoliday":
      reasonTranslation = "Feiertag";
      break;
    case "maxBookableDrivingLessonsPerWeek":
      reasonTranslation = "Fahrschüler hat maximale Anzahl Fahrstunden pro Woche erreicht";
      break;
    case "maxDaysInAdvance":
      reasonTranslation = "Tag liegt zu weit in der Zukunft";
      break;
    case "maxDrivingLessonsPerDay":
      reasonTranslation = "Fahrlehrer hat maximale Anzahl Fahrstunden pro Tag erreicht";
      break;
    case "maxDrivingLessonsPerWeek":
      reasonTranslation = "Fahrlehrer hat maximale Anzahl Fahrstunden pro Woche erreicht";
      break;
    case "maxWorkTimePerDay":
      reasonTranslation = "Fahrlehrer hat maximale Arbeitszeit pro Tag erreicht";
      break;
    case "minTimeAhead":
      reasonTranslation = "Nicht genug Zeit bis zum Termin";
      break;
    case "unknown":
      reasonTranslation = `Gründe könnten sein:
    * Es passen keine Fahrstunden mehr in die Arbeitszeiten des Fahrlehrers
    * Durch Anfahrt, Pausenzeiten, Fahrzeugwechsel bleibt nicht genug Zeit für eine Fahrstunde
    * Es ist kein passendes Fahrzeug verfügbar
    * Der Fahrlehrer fährt an diesem Tag nicht im ausgewählten Gebiet
  `;
      break;
    default: {
      reasonTranslation = "Unbekannter Grund (bitte an Entwickler wenden)";
    }
  }
  return <p style={{ whiteSpace: "pre-wrap" }}>{`Keine Terminvorschläge: ${reasonTranslation}`}</p>;
};

const _bufferTimeTitle = (event: AutovioCalendarEvent, bufferTimeType: "preparationTime" | "wrapUpTime"): string => {
  switch (event.type) {
    case "DrivingLesson":
      return "Transfer";
    case "Other":
      if (event.category === "publicHoliday" || event.category === "sickness" || event.category === "vacation") {
        return "Blocker";
      }
      break;
    case "TheoryLesson":
      switch (bufferTimeType) {
        case "preparationTime":
          return "Vorbereitung";
        case "wrapUpTime":
      }
  }
  return "Pufferzeit";
};
