import { Course } from "../providers/coursesProvider";
import { useRef, useState } from "react";
import { Row } from "../misc/Row";
import { StudentSelect } from "../inputs/StudentSelect";
import { isASFCourseSession, isTheoryLesson } from "../model/autovioCalendarEvents";
import { Box, Button, CircularProgress, IconButton, SxProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { ConfirmCancellationPolicyDialog } from "../misc/calendar/ConfirmCancellationPolicyDialog";
import { useDialog } from "../hooks/useDialog";
import { Student } from "../providers/studentsProvider";
import { useGetOne, useNotify } from "react-admin";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { CancellationPolicy } from "../model/CancellationPolicy";
import { serverAPI } from "../api/server.api";
import { autovioColors } from "../misc/backofficeTheme";

export function AddStudentToCourseButton({ sx, course }: { sx?: SxProps; course: Course }) {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { data: drivingSchool } = useGetOne("drivingSchools", { id: drivingSchoolId });
  const [showStudentSelect, setShowStudentSelect] = useState(false);
  const studentRef = useRef<Student | null>(null);
  const {
    isDialogOpen: isConfirmDialogOpen,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialog();
  const [addingStudent, setAddingStudent] = useState(false);
  const notify = useNotify();
  const isTheoryCourse = isTheoryLesson(course.appointments[0]);
  const isASFCourse = isASFCourseSession(course.appointments[0]);

  if (!drivingSchool) {
    return null;
  }

  let filterStudents: (student: Student) => boolean;
  if (isTheoryCourse) {
    filterStudents = (student) =>
      !course.attendees[student.id] &&
      student.bookedTrainings.some((it) => it.hasCompulsoryTheoryLessons && !(it.hasPassedTheoryExam || it.isFinished));
  } else if (isASFCourse) {
    filterStudents = (student) =>
      !course.attendees[student.id] && student.bookedTrainings.some((it) => it.bundleName.includes("ASF"));
  } else {
    return null;
  }

  const handleStudentSelected = (student: Student | null) => {
    studentRef.current = student;
    if (!student) {
      return;
    }
    if (isTheoryCourse && drivingSchool.customizations.includes("cancellationPolicyPerLesson")) {
      openConfirmDialog();
    } else {
      void addStudentToCourse();
    }
  };

  const addStudentToCourse = async (agreedCancellationPolicy?: CancellationPolicy) => {
    const student = studentRef.current;
    if (!student) {
      throw new Error("Invalid state: No student selected");
    }
    try {
      setAddingStudent(true);
      await serverAPI.addStudentToCourse({ student, course, agreedCancellationPolicy });
      notify(`${student.name} erfolgreich hinzugefügt.`, { type: "success" });
    } catch (error) {
      console.error(`Failed to add student ${student.id} to course ${course.id}`, error);
      notify(`Fehler beim Hinzufügen von ${student.name}.`, { type: "error" });
    } finally {
      setAddingStudent(false);
    }
  };

  return (
    <>
      {showStudentSelect ? (
        <Row sx={sx} alignItems="center" spacing="10px">
          <StudentSelect
            autoFocus
            label="Teilnehmer hinzufügen"
            disabled={addingStudent}
            sx={{ minWidth: "250px", flex: 1 }}
            size="small"
            onChange={(student) => handleStudentSelected(student)}
            drivingSchoolId={drivingSchoolId}
            filterStudents={filterStudents}
          />
          {addingStudent ? (
            <CircularProgress size={24} sx={{ color: "grey" }} />
          ) : (
            <IconButton size="small" onClick={() => setShowStudentSelect(false)}>
              <CloseIcon sx={{ fill: autovioColors.black }} />
            </IconButton>
          )}
        </Row>
      ) : (
        <Box sx={sx}>
          <Button
            variant="outlined"
            sx={{ height: "40px" /* ... same height as <StudentSelect> */ }}
            startIcon={<AddIcon />}
            onClick={() => setShowStudentSelect(true)}
          >
            Teilnehmer
          </Button>
        </Box>
      )}
      <ConfirmCancellationPolicyDialog
        isOpen={isConfirmDialogOpen}
        studentUid={studentRef.current?.id}
        onCancel={() => {
          closeConfirmDialog();
          setShowStudentSelect(false);
          studentRef.current = null;
        }}
        onConfirm={async () => {
          closeConfirmDialog();
          await addStudentToCourse(drivingSchool.theoryLessonCancellationPolicy);
          setShowStudentSelect(false);
          studentRef.current = null;
        }}
      />
    </>
  );
}
