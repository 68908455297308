import { Box, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FieldValues, useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { coursesProvider } from "../../providers/coursesProvider";
import { Row } from "../../misc/Row";
import { Form, SaveButton, Button, useNotify } from "react-admin";
import { InstructorInput } from "../../inputs/InstructorInput";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { renderAddress } from "../../misc/AddressDisplay";
import { SelectInput } from "../../inputs/SelectInput";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { TheoryCourseLesson } from "./TheoryCourseWizard";

interface CreateTheoryCourseFormProps {
  initialLessons: TheoryCourseLesson[];
  drivingSchool: DrivingSchool;
  goBack: () => void;
  onSuccess: () => void;
}
export const CreateTheoryCourseForm = ({
  initialLessons,
  drivingSchool,
  goBack,
  onSuccess,
}: CreateTheoryCourseFormProps) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const createCourse = async (data: FieldValues) => {
    try {
      const courseId = uuidv4();
      const payload = data.lessons.map((lesson: TheoryCourseLesson) => ({
        courseUid: courseId,
        uid: lesson.id,
        type: "TheoryLesson",
        theoryUnit: lesson.theoryUnit,
        maxStudents: drivingSchool.branches.find((it) => it.uid === lesson.location)?.maxAmountOfStudents,
        location: drivingSchool.branches.find((it) => it.uid === lesson.location)?.postalAddress,
        attendees: {
          [lesson.instructorId]: {
            attendeeType: "instructor",
            rsvp: "accepted",
          },
        },
        start: lesson.start.toISO(),
        end: lesson.start.plus({ minutes: 90 }).toISO(),
      }));
      await coursesProvider.create("courses", { data: { theoryLessons: payload, drivingSchoolId: drivingSchool.id } });
      notify("Theoriekurs wurde erfolgreich erstellt", { type: "success" });
      void queryClient.invalidateQueries(["calendarEvents"]);
      void queryClient.invalidateQueries(["calendarEventHistory"]);
      void queryClient.invalidateQueries(["courses"]);
      onSuccess();
    } catch (error) {
      console.error("Failed to create theory course", error);
      notify("Der Theoriekurs konnte nicht erstellt werden", { type: "error" });
    }
  };
  return (
    <Form onSubmit={createCourse} defaultValues={() => ({ lessons: initialLessons })}>
      <Row justifyContent="space-between" width="100%" mb={3}>
        <Typography variant="subtitle1">Unterrichtseinheiten erstellen:</Typography>
        <Row gap={2}>
          <Button onClick={goBack} label="Zurück" variant="outlined" />
          <SaveButton alwaysEnable label="Kurs erstellen" />
        </Row>
      </Row>
      <Box>
        {initialLessons.map((lesson, index) => (
          <LessonAccordion key={lesson.id} drivingSchool={drivingSchool} index={index} />
        ))}
      </Box>
    </Form>
  );
};

interface LessonAccordionProps {
  drivingSchool: DrivingSchool;
  index: number;
}
const LessonAccordion = ({ drivingSchool, index }: LessonAccordionProps) => {
  const { watch } = useFormContext();
  const lesson = watch(`lessons[${index}]`) as TheoryCourseLesson;

  return (
    <Accordion key={lesson.id} square defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-content-${index}`}
        id={`panel-header-${index}`}
      >
        <Typography>
          {`Lektion ${lesson.label}: `}
          <Typography component="span" fontWeight="bold">
            {`${lesson.start.setLocale("de-DE").toFormat("ccc, dd.MM.yyyy HH:mm")} Uhr`}
          </Typography>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Row gap={3} alignItems="center">
          <DateTimeInput
            allowPastDates={false}
            source={`lessons[${index}].start`}
            sx={{ mt: "5px", "& .MuiInputBase-input": { py: "10.5px" } }}
          />
          <InstructorInput
            drivingSchoolId={drivingSchool.id}
            source={`lessons[${index}].instructorId`}
            sx={{ "& .MuiInputBase-input": { py: "12.5px" } }}
          />
          <SelectInput
            source={`lessons[${index}].location`}
            label="Location"
            options={drivingSchool.branches.map((it) => [
              it.uid,
              `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
            ])}
            sx={{ "& .MuiInputBase-input": { py: "12.5px" }, maxWidth: "300px" }}
            validate={(value) => (value ? true : "Bitte wählen Sie den Kursort aus.")}
          />
        </Row>
      </AccordionDetails>
    </Accordion>
  );
};
