import { StudentTab } from "./StudentTab";
import { Datagrid, FunctionField, List, ReferenceField, useGetRecordId, useRecordContext } from "react-admin";
import { DateField } from "../../fields/DateField";
import { LinkToFirestoreField } from "../../fields/LinkToFirestoreField";
import { AppointmentEmptyState } from "../../misc/AppointmentEmptyState";
import { EventStatusReasonTooltip as EventStatusReasonTooltip } from "../../misc/EventStatusReasonTooltip";
import { AutovioCalendarEvent, DrivingLesson } from "../../model/autovioCalendarEvents";
import { grants } from "../../backoffice.access_control";
import { useEffect, useState } from "react";
import { EventDialog } from "../../misc/calendar/EventDialog";
import { studentDrivingLessonsProvider } from "../../providers/studentDrivingLessonsProvider";
import { renderLessonStatus } from "../../utils/calendar";

const DRIVING_LESSON_TYPE_LABELS = {
  normal: "Normal",
  unterweisungAmFahrzeug: "Unterweisung am Fahrzeug",
  praktischeUnterweisung: "Praktische Unterweisung",
  ueberlandfahrt: "Überlandfahrt",
  autobahnfahrt: "Autobahnfahrt",
  nachtfahrt: "Nachtfahrt",
  simulator: "Simulator-Fahrstunde",
  praktischePruefung: "Praktische Prüfung",
  schaltkompetenz: "Schaltkompetenz",
  fahrprobe: "ASF Fahrprobe",
};

const _renderLessonType = (record: DrivingLesson): string => {
  const label = DRIVING_LESSON_TYPE_LABELS[record.drivingLessonType] ?? "Unbekannt";
  const durationInMinutes = Math.round(record.end.diff(record.start).as("minutes"));
  return `${label} (${durationInMinutes} Minuten)`;
};

export function StudentDrivingLessonsList() {
  const studentUid = useGetRecordId();
  const [selectedDrivingLesson, setSelectedDrivingLesson_] = useState<DrivingLesson | undefined>(undefined);
  const setSelectedDrivingLesson = (drivingLesson?: DrivingLesson) => {
    // Side effect: update browser URL ...
    const url = new URL(location.href);
    if (drivingLesson) {
      if (url.searchParams.get("openDialog") !== `DrivingLessonDialog!${drivingLesson.id}`) {
        url.searchParams.set("openDialog", `DrivingLessonDialog!${drivingLesson.id}`);
        browserHistory.replace(url.toString());
      }
    } else {
      url.searchParams.delete("openDialog");
      url.searchParams.delete("showHistory");
      browserHistory.replace(url.toString());
    }
    setSelectedDrivingLesson_(drivingLesson);
  };
  useEffect(() => {
    const openDialog = new URL(location.href).searchParams.get("openDialog");
    if (openDialog && openDialog.startsWith("DrivingLessonDialog!")) {
      void studentDrivingLessonsProvider
        .getOne("studentDrivingLessons", { id: openDialog.substring("DrivingLessonDialog!".length) })
        .then(({ data }) => setSelectedDrivingLesson(data));
    }
  }, []);
  return (
    <StudentTab>
      <List
        resource="studentDrivingLessons"
        title=" " // <-- prevent that the default list title is rendered
        filter={{ studentUid }}
        sort={{ field: "start", order: "DESC" }}
        empty={<AppointmentEmptyState label="Noch keine Fahrstunden gebucht" />}
        exporter={false}
        sx={{ marginTop: "-15px", height: "100%" }}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={(_, __, drivingLesson) => {
            setSelectedDrivingLesson(drivingLesson as DrivingLesson);
            return false;
          }}
        >
          <DateField label="Datum" source="start" showTime />
          <FunctionField label="Art" sortBy="type" render={_renderLessonType} />
          <ReferenceField label="Fahrlehrer" reference="instructors" source="instructorId" />
          <LessonStatusField />
          {grants.includes("viewFirestore") && (
            <LinkToFirestoreField label="" source="id" collection="/calendar_events" />
          )}
        </Datagrid>
        {selectedDrivingLesson && (
          <EventDialog event={selectedDrivingLesson} isOpen onClose={() => setSelectedDrivingLesson(undefined)} />
        )}
      </List>
    </StudentTab>
  );
}

const LessonStatusField = () => {
  const event = useRecordContext<AutovioCalendarEvent>();
  return (
    <EventStatusReasonTooltip event={event}>
      <FunctionField source="status" render={renderLessonStatus} />
    </EventStatusReasonTooltip>
  );
};
