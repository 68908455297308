import { AbstractDataProvider } from "./AbstractDataProvider";
import type { CreateParams, CreateResult, GetOneParams, GetOneResult, UpdateParams, UpdateResult } from "react-admin";
import z from "zod";
import {
  CreateDrivingLicenseAuthorityInstructionDto,
  schemas,
  serverAPI,
  UpdateDrivingLicenseAuthorityInstructionDto,
} from "../api/server.api";
import { drivingLicenseAuthoritiesProvider } from "./drivingLicenseAuthoritiesProvider";
import _isEqual from "lodash/isEqual";

type DrivingLicenseAuthorityDto = z.infer<typeof schemas.DrivingLicenseAuthorityDto>;

export type DrivingLicenseAuthorityInstruction = Simplify<
  Omit<ArrayItem<DrivingLicenseAuthorityDto["instructions"]>, "id"> & {
    /** "<authorityId>:<instructionId>" */
    id: string;
  }
>;

class DrivingLicenseAuthorityInstructionsProvider extends AbstractDataProvider<DrivingLicenseAuthorityInstruction> {
  async getOne(
    _resource: string,
    { id }: GetOneParams<DrivingLicenseAuthorityInstruction>,
  ): Promise<GetOneResult<DrivingLicenseAuthorityInstruction>> {
    const i = id.indexOf(":");
    if (i <= 0) {
      throw new Error(
        `Invalid id: ${JSON.stringify(id)} -- expected a string of the format "<authorityId>:<instructionId>"`,
      );
    }
    const authorityId = id.substring(0, i);
    const { data: authority } = await drivingLicenseAuthoritiesProvider.getOne("", { id: authorityId });
    const instruction = authority.instructions.find((it) => it.id === id);
    if (!instruction) {
      throw new Error(`DrivingLicenseAuthorityInstruction ${id} not found`);
    }
    return { data: { ...instruction, id } };
  }

  async create(_resource: string, params: CreateParams<DrivingLicenseAuthorityInstruction>): Promise<CreateResult> {
    const { authorityId, ...data } = params.data;
    if (!authorityId) {
      throw new Error("No authorityId in params.data");
    }
    if (typeof authorityId !== "string") {
      throw new Error("Invalid authorityId in params.data");
    }
    await serverAPI.createDrivingLicenseAuthorityInstruction({ authorityId, dto: _toCreateDto(data) });
    return {
      data: { id: "unknown" },
    };
  }

  async update(
    _resource: string,
    { id, data, previousData }: UpdateParams<DrivingLicenseAuthorityInstruction>,
  ): Promise<UpdateResult<DrivingLicenseAuthorityInstruction>> {
    const [authorityId, instructionId] = id.split(":");
    const dto = _toUpdateDto(data, previousData);
    await serverAPI.updateDrivingLicenseAuthorityInstruction({ authorityId, instructionId, dto });
    return {
      data: { id, ...data },
    };
  }
}

export const drivingLicenseAuthorityInstructionsProvider = new DrivingLicenseAuthorityInstructionsProvider();

function _toCreateDto(data: Partial<DrivingLicenseAuthorityInstruction>): CreateDrivingLicenseAuthorityInstructionDto {
  if (!data.drivingLicenseClasses) {
    data.drivingLicenseClasses = [];
  }
  return schemas.CreateDrivingLicenseAuthorityInstructionDto.parse(data);
}

function _toUpdateDto(
  data: Partial<DrivingLicenseAuthorityInstruction>,
  previousData: DrivingLicenseAuthorityInstruction,
): UpdateDrivingLicenseAuthorityInstructionDto {
  const delta = Object.fromEntries(Object.entries(data).filter(([k, v]) => !_isEqual(v, previousData[k])));
  return schemas.UpdateDrivingLicenseAuthorityInstructionDto.parse(delta);
}
