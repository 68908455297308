import { z } from "zod";
import { schemas } from "../generated/serverClient";

export const GenderEnum = schemas.GenderEnum;
export type Gender = z.infer<typeof GenderEnum>;

export const GENDER_CHOICES: { id: Gender; name: string }[] = [
  { id: "Male", name: "Männlich" },
  { id: "Female", name: "Weiblich" },
  { id: "Diverse", name: "Divers" },
];
