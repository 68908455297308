import { Vehicle } from "../../providers/resourceProviders";
import { NameHeader } from "../../misc/NameHeader";
import { Row } from "../../misc/Row";
import { Box, Avatar, Typography } from "@mui/material";
import { CarIcon } from "../../icons/CarIcon";
import { MotorcycleIcon } from "../../icons/MotorcycleIcon";
import { TrailerIcon } from "../../icons/TrailerIcon";
import { SimulatorIcon } from "../../icons/SimulatorIcon";
import { autovioColors } from "../../misc/backofficeTheme";
import { useRecordContext } from "react-admin";

export const VehicleDetailsHeader = () => {
  const vehicle = useRecordContext<Vehicle>();
  return (
    <Row sx={{ mt: "10px", mb: "20px" }} spacing="50px" alignItems="start">
      <Row spacing="20px" alignItems="center">
        <Avatar sx={{ p: 0, bgcolor: autovioColors.greyUltraLight, color: autovioColors.black }}>
          {_vehiclesIcons[vehicle.type as keyof typeof _vehiclesIcons]}
        </Avatar>
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <NameHeader>{vehicle.name}</NameHeader>
          <Typography sx={{ color: autovioColors.grey, fontSize: "14px", lineHeight: "1.43" }}>
            {vehicle[vehicle.type as keyof Vehicle]?.plateNumber ?? ""}
          </Typography>
        </Box>
      </Row>
    </Row>
  );
};

const _vehiclesIcons = {
  car: <CarIcon />,
  motorcycle: <MotorcycleIcon />,
  trailer: <TrailerIcon />,
  simulator: <SimulatorIcon />,
};
