import { Box, Grid, Paper } from "@mui/material";
import { DateTime } from "luxon";
import {
  Create,
  DateInput,
  PasswordInput,
  required,
  Resource,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useGetOne,
  useNotify,
  useRedirect,
} from "react-admin";
import { InstructorDetails } from "../details/InstructorDetails";
import { useAutovioContext } from "../hooks/useAutovioContext.js";
import { InstructorIcon } from "../icons/InstructorIcon";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle.js";
import { Column } from "../misc/Column.js";
import { Form2 } from "../misc/Form2.js";
import { DRIVING_LICENSE_CLASSES_CHOICES } from "../model/DrivingLicenseClass.js";
import { GENDER_CHOICES } from "../model/Gender.js";
import type { DrivingSchool } from "../providers/drivingSchoolsProvider.js";
import type { CreateInstructorDto } from "../api/server.api.js";
import { grants } from "../backoffice.access_control.js";
import { useEffect } from "react";

function _InstructorsList() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  useEffect(() => {
    if (drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/instructors`);
    }
  });
  return drivingSchoolId ? null : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

function CreateInstructorForm() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: drivingSchoolId! },
    {
      enabled: !!drivingSchoolId,
    },
  );

  if (!drivingSchoolId || !drivingSchool) {
    return (
      <Box className="centered">
        <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
      </Box>
    );
  }

  if (!grants.includes("Instructor:create")) {
    return null;
  }

  return (
    <Create
      title={<BackofficeRecordTitle />}
      resource="instructors"
      mutationOptions={{
        onSuccess: () => {
          notify("Fahrlehrer erfolgreich erstellt.", { type: "success" });
          redirect(`/drivingSchools/${drivingSchoolId}/instructors`);
        },
        onError: (error: Error) => {
          notify("Fehler beim Erstellen des Fahrlehrers.", { type: "error" });
          console.error("Failed to create instructor.", error);
        },
      }}
      transform={(data: CreateInstructorDto) => {
        return {
          ...data,
          drivingSchoolId,
        };
      }}
    >
      <InstructorForm mode="create" />
    </Create>
  );
}

function InstructorForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <Paper sx={{ p: 2 }}>
      <Form2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput label="E-Mail" source="email" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <PasswordInput label="Passwort" source="password" validate={required()} fullWidth />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextInput
              label="Vorname"
              source="firstName"
              autoFocus={mode === "create"}
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput label="Nachname" source="lastName" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput label="Telefonnummer" source="phoneNumber" validate={required()} fullWidth />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectInput
              label="Geschlecht"
              source="gender"
              validate={required()}
              choices={GENDER_CHOICES}
              fullWidth
              sx={{ margin: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SelectArrayInput
              label="Führerscheinklassen"
              source="drivingLicenseClasses"
              validate={required()}
              choices={DRIVING_LICENSE_CLASSES_CHOICES}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DateInput
              label="Startdatum"
              source="startDate"
              validate={required()}
              fullWidth
              defaultValue={DateTime.now()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label="Enddatum"
              source="endDate"
              validate={required()}
              fullWidth
              defaultValue={DateTime.now().plus({ years: 45 })}
            />
          </Grid>
        </Grid>
        <SaveButton label="Fahrlehrer hinzufügen" />
      </Form2>
    </Paper>
  );
}

export const instructorsResource = (
  <Resource
    key="instructors"
    name="instructors"
    icon={InstructorIcon}
    list={_InstructorsList}
    edit={InstructorDetails}
    create={CreateInstructorForm}
    options={{ label: "Fahrlehrer", createTitle: "Fahrlehrer hinzufügen" }}
    recordRepresentation={(instructor) => instructor.name.replaceAll(" ", "\u00A0")}
  />
);
