import { Datagrid, List, ReferenceArrayField, ReferenceField, TextField } from "react-admin";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { CommaSeparatedFieldList } from "../../misc/CommaSeparatedFieldList";
import { EmptyState } from "../../misc/EmptyState";
import { BooleanField } from "../../fields/BooleanField";
import { Row } from "../../misc/Row";
import { ShowIconButton } from "../../buttons/ShowIconButton";
import { CloneIconButton } from "../../buttons/CloneIconButton";
import { grants } from "../../backoffice.access_control";

export function TrailersList() {
  const [{ drivingSchoolId }] = useAutovioContext();
  return (
    <List
      title=" " // <-- prevent that the default list title is rendered
      resource="trailers"
      filter={{ entitledDrivingSchoolId: drivingSchoolId, withDeleted: false }}
      sort={/* initial sort order: */ { field: "name", order: "ASC" }}
      exporter={false}
      empty={false}
      hasCreate={false}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={grants.includes("Vehicle:edit") ? "edit" : false}
        empty={<EmptyState label="Keine Anhänger vorhanden." />}
        sx={{ "& td:last-child": { width: "1px" } /* ... place icon buttons on the right */ }}
      >
        <TextField label="Name" source="name" />
        <TextField label="Notizen" source="description" />
        <TextField label="Kennzeichen" source="trailer.plateNumber" />
        <BooleanField label="Geteilt" source="isShared" />
        <ReferenceArrayField label="Fahrlehrer" reference="instructors" source="entitledUsers">
          <CommaSeparatedFieldList>
            <ReferenceField source="id" reference="instructors" />
          </CommaSeparatedFieldList>
        </ReferenceArrayField>
        <Row gap={1}>
          <ShowIconButton />
          {grants.includes("Vehicle:create") && <CloneIconButton />}
        </Row>
      </Datagrid>
    </List>
  );
}
