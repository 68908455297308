import type { DialogProps } from "../misc/DialogProps";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { AccountBalance, DrivingSchoolAccounts } from "../details/student/AccountBalance";
import { grants } from "../backoffice.access_control";
import {
  Datagrid,
  LinearProgress,
  NumberField,
  ReferenceManyField,
  TextField,
  useListContext,
  useRecordContext,
} from "react-admin";
import { Student } from "../providers/studentsProvider";
import { GiftVoucherButton } from "../buttons/GiftVoucherButton";
import { GiftVoucher } from "../providers/giftVouchersProvider";
import { DateField } from "../fields/DateField";

export function VouchersDialog({ accounts, ...dialogProps }: { accounts?: DrivingSchoolAccounts } & DialogProps) {
  const student = useRecordContext<Student>();
  return (
    <Dialog {...dialogProps}>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent sx={{ width: 500, height: 680 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
          <div />
          {grants.includes("giftVoucher:create") && <GiftVoucherButton studentId={student.id} />}
        </Box>
        <AccountBalance key="giftVouchers" title="Verschenkte Gutscheine">
          <ReferenceManyField
            reference="giftVouchers"
            target="beneficiary"
            sort={{ field: "createdAt", order: "DESC" }}
            page={1}
            perPage={9999}
          >
            <GiftVouchersList showAmount={false} />
          </ReferenceManyField>
        </AccountBalance>
      </DialogContent>
    </Dialog>
  );
}

function GiftVouchersList({ showAmount = true }) {
  const { data } = useListContext<GiftVoucher>();

  if (!data) {
    return <LinearProgress />;
  }

  const totalAmount = data.reduce((sum, voucher) => sum + parseFloat(voucher.amount), 0);

  return (
    <>
      {showAmount && (
        <NumberField
          source="amount"
          record={{ amount: totalAmount }}
          options={{ style: "currency", currency: "EUR" }}
          style={{ fontWeight: 900, fontSize: "1.5rem" }}
        />
      )}
      <br />
      <Datagrid
        bulkActionButtons={false}
        isRowSelectable={() => false}
        empty={<Typography>Keine Transaktionen gefunden.</Typography>}
      >
        <DateField source="createdAt" label="Datum" />
        <NumberField source="amount" label="Betrag" options={{ style: "currency", currency: "EUR" }} />
        <TextField source="description" label="Beschreibung" />
      </Datagrid>
    </>
  );
}
