import {
  SaveButton,
  useCreateContext,
  useDelete,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useResourceContext,
} from "react-admin";
import { Vehicle, vehiclesProvider } from "../providers/resourceProviders";
import { Row } from "./Row";
import SaveIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { SimpleConfirmDialogButton } from "./SimpleConfirmDialogButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { grants } from "../backoffice.access_control";

export function VehicleFormToolbar(props: any) {
  const record = useRecordContext<Vehicle>();
  const { defaultTitle: creatingRecord } = useCreateContext();
  const deleted = !!record?.deletedAt;

  return (
    <Row style={{ display: "flex", justifyContent: "space-between" }} {...props}>
      {!deleted && grants.includes("Vehicle:edit") ? <SaveButton icon={<SaveIcon />} /> : <></>}
      {creatingRecord || !grants.includes("Vehicle:delete") ? null : deleted ? (
        <_RecoverButton record={record} />
      ) : (
        <_DeleteButton />
      )}
    </Row>
  );
}

function _DeleteButton() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const resource = useResourceContext();
  const record = useRecordContext<Vehicle>();
  const [deleteOne] = useDelete();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <SimpleConfirmDialogButton
      icon={<DeleteIcon />}
      label="Löschen"
      color="error"
      dialogTitle={`${record.name} wirklich löschen?`}
      action={() => deleteOne(resource, { id: record.id })}
      dialogCtaTitle="Jetzt löschen"
      content={null}
      onSuccess={() => {
        refresh();
        notify(`${record.name} gelöscht.`, { type: "success" });
        redirect(`/drivingSchools/${drivingSchoolId}/fleet#${record.type}s`);
      }}
    />
  );
}

function _RecoverButton({ record }: { record: Vehicle }) {
  const refresh = useRefresh();
  const notify = useNotify();
  return (
    <LoadingButton
      startIcon={<RestoreFromTrashIcon />}
      variant="contained"
      onClick={async () => {
        await vehiclesProvider.restore(record.id);
        refresh();
        notify(`${record.name} wieder hergestellt.`, { type: "success" });
      }}
    >
      Wiederherstellen
    </LoadingButton>
  );
}
