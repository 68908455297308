import Download from "@mui/icons-material/Download";
import OpenInBrowser from "@mui/icons-material/OpenInBrowser.js";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { RecordContextProvider } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api.js";
import { DateField } from "../fields/DateField";
import type { ModesEnum, PerformanceOverviewReport } from "../generated/backendClient/index.js";
import { Column } from "../misc/Column";
import { DialogProps } from "../misc/DialogProps";
import { Row } from "../misc/Row";
import type { PerformanceOverviewResources } from "../PerformanceOverview.js";
import { DateTime } from "luxon";
import { DialogCloseButton } from "../misc/DialogCloseButton";

interface PerformanceOverviewReportExportDialog extends DialogProps {
  serviceTime: string | undefined;
  drivingSchoolId: string;
  resource: PerformanceOverviewResources;
}

function performanceOverviewResourceToMode(resource: PerformanceOverviewResources): ModesEnum {
  switch (resource) {
    case "performanceOverview":
      return "services";
    case "advancePayments":
      return "prepaid";
    case "openInvoices":
      return "unpaid";
  }
}

function performanceOverviewResourceToTitle(resource: PerformanceOverviewResources): string {
  switch (resource) {
    case "performanceOverview":
      return "Leistungsübersicht";
    case "advancePayments":
      return "Vorauszahlungen";
    case "openInvoices":
      return "Offene Posten";
  }
}

export function PerformanceOverviewReportExportDialog({
  open,
  onClose,
  serviceTime,
  drivingSchoolId,
  resource,
}: PerformanceOverviewReportExportDialog) {
  const [report, setReport] = useState<PerformanceOverviewReport | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadLink, setDownloadLink] = useState<string | undefined | null>();
  const [documentLink, setDocumentLink] = useState<string | undefined | null>();
  useEffect(() => {
    if (open) {
      const exportReport = async () => {
        const backendClient = await getAuthenticatedBackendClient();
        const report = await backendClient.performanceOverviewReport.performanceOverviewReportExportCreate({
          requestBody: {
            service_time: serviceTime,
            driving_school_id: drivingSchoolId,
            modes: [performanceOverviewResourceToMode(resource)],
          },
        });
        setReport(report);
        setIsLoading(false);
        switch (resource) {
          case "performanceOverview":
            setDownloadLink(report.services_pdf_download);
            setDocumentLink(report.report_file_services);
            break;
          case "advancePayments":
            setDownloadLink(report.prepaid_pdf_download);
            setDocumentLink(report.report_file_prepaid);
            break;
          case "openInvoices":
            setDownloadLink(report.unpaid_pdf_download);
            setDocumentLink(report.report_file_unpaid);
            break;
        }
      };
      exportReport().catch(console.error);
    }
  }, [open]);
  const serviceTimeText = serviceTime ? DateTime.fromISO(serviceTime).setLocale("de").toFormat("LLLL yyyy") : "";
  const titleSuffix = performanceOverviewResourceToTitle(resource);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        Export {titleSuffix} {serviceTimeText && ":"} {serviceTimeText}
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Typography textAlign="justify">
          Ein Export kann nur einmal alle 30 Minuten erstellt werden. Je nach Umfang der Daten kann der Export einige
          Minuten dauern.
        </Typography>
        {resource === "openInvoices" && (
          <Typography textAlign="justify" color="text.secondary">
            Der "Offene Posten" Export enthält alle aktuell offenen Posten der Fahrschule.
          </Typography>
        )}
        {resource !== "openInvoices" && (
          <Typography textAlign="justify" color="text.secondary">
            Der "{titleSuffix}" Export wird für den in der Tabelle ausgewählten Monat erstellt.
          </Typography>
        )}
        {isLoading && (
          <Row justifyContent="space-around" alignItems="center" spacing={3}>
            <Column>
              <CircularProgress />
            </Column>
          </Row>
        )}
        {report && (
          <Row justifyContent="space-around" alignItems="center" sx={{ marginTop: 1 }}>
            {documentLink && (
              <Button startIcon={<OpenInBrowser />} variant="outlined" href={documentLink} target="_blank">
                Öffnen
              </Button>
            )}
            {downloadLink && (
              <Button startIcon={<Download />} variant="outlined" href={downloadLink}>
                Download
              </Button>
            )}
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        {report && downloadLink && (
          <RecordContextProvider value={report}>
            <Typography color="text.secondary" variant="subtitle2">
              Stand: <DateField source="created_at" showTime />
            </Typography>
          </RecordContextProvider>
        )}
      </DialogActions>
    </Dialog>
  );
}
