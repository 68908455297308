import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { LessonExtensionSchema } from "../../model/LessonExtension";
import {
  ASFCourseSessionAttendance,
  AutovioCalendarEvent,
  BaseAutovioCalendarEvent,
  DrivingLesson,
  PaidLessonStudentAttendance,
  TheoryExam,
  TheoryLesson,
  TheoryLessonStudentAttendance,
} from "../../model/autovioCalendarEvents";
import { fromFirestore } from "../../backoffice.utils";

export const autovioCalendarEventConverter = {
  toFirestore: (_: TheoryLesson): DocumentData => {
    throw new Error("Not implemented");
  },

  fromFirestore: (doc: DocumentSnapshot): AutovioCalendarEvent => {
    const data = doc.data();
    if (!data) {
      throw Error(`Document ${doc.ref.path} does not exist`);
    }
    const calendarEvent = autovioCalendarEventConverter.fromFirestoreData(data);
    if (calendarEvent.id !== doc.id) {
      throw Error(`Unexpected uid in Firestore document ${doc.ref.path}: ${calendarEvent.id}`);
    }
    return calendarEvent;
  },

  fromFirestoreData: (data: DocumentData): AutovioCalendarEvent => {
    const base: BaseAutovioCalendarEvent = {
      id: data.uid,
      start: fromFirestore(data.start),
      end: fromFirestore(data.end),
      drivingSchoolId: data.drivingSchoolUid,
      branchId: data.branchId,
      bufferTimes: data.bufferTimes ?? {
        wrapUpTime: 0,
        preparationTime: 0,
      },
      resources: data.resources,
      deletionReason: data.deletionReason,
      editCount: data.editCount ?? 0,
      editReason: data.editReason,
      editWithReasonCount: data.editWithReasonCount ?? 0,
      createdAt: fromFirestore(data.createdAt),
      createdBy: data.createdBy,
      updatedAt: fromFirestore(data.updatedAt),
      updatedBy: data.updatedBy,
      originalUid: data.originalUid,
      replacedBy: data.replacedBy,
      deleted: data.deleted,
      deletedAt: fromFirestore(data.deletedAt),
      deletedBy: data.deletedBy,
    };
    if (data.type === "TheoryLesson") {
      const students = fromFirestore(data.students) as any as Record<string, TheoryLessonStudentAttendance>;
      const numBookedStudents = Object.values(students).filter((it) => it.rsvp === "accepted").length;
      return {
        ...base,
        courseUid: data.courseUid,
        theoryUnit: parseInt(data.theoryUnit),
        instructorId: data.instructor.uid,
        students,
        numBookedStudents,
        maxStudents: data.maxStudents,
        type: "TheoryLesson",
        location: data.location,
      };
    }
    if (data.type === "DrivingLesson") {
      if (data.drivingLessonType === "theoretischePruefung") {
        return {
          ...base,
          type: "TheoryExam",
          student: fromFirestore(data.student) as any,
          drivingSchoolId: data.drivingSchoolUid,
          status: _theoryExamStatus(data),
          startLocation: data.startLocation,
          theoryExamResult: data.theoryExamResult,
        };
      }
      let drivingLessonType: DrivingLesson["drivingLessonType"];
      const { additionalContent } = data;
      if (Array.isArray(additionalContent) && additionalContent.includes("instructionOnVehicle")) {
        drivingLessonType = "unterweisungAmFahrzeug";
      } else if (Array.isArray(additionalContent) && additionalContent.includes("practicalIntroduction")) {
        drivingLessonType = "praktischeUnterweisung";
      } else {
        drivingLessonType = data.drivingLessonType;
      }
      return {
        ...base,
        type: "DrivingLesson",
        drivingLessonType,
        extensions: data.extensions ? data.extensions.map((it: any) => LessonExtensionSchema.parse(it)) : [],
        instructorId: data.instructor?.uid,
        student: fromFirestore(data.student) as any,
        endedAt: fromFirestore(data.endedAt),
        isBooked: _isDrivingLessonBooked(data),
        status: _drivingLessonStatus(data),
        startedAt: fromFirestore(data.startedAt),
        startLocation: data.startLocation,
        price: data.price,
        practicalExamResult: data.practicalExamResult,
        vehicleId: data.resources?.[0],
        vehicleId2: data.resources?.[1],
      };
    }
    if (data.type === "RemoteLesson") {
      return {
        ...base,
        type: "RemoteLesson",
        extensions: data.extensions ? data.extensions.map((it: any) => LessonExtensionSchema.parse(it)) : [],
        instructorId: data.instructor.uid,
        student: data.student,
      };
    }
    if (data.type === "ASFCourseSession") {
      const participants =
        (fromFirestore(data.participants) as any as Record<string, ASFCourseSessionAttendance>) ?? {};
      return {
        ...base,
        type: "ASFCourseSession",
        sessionType: data.sessionType,
        courseUid: data.courseUid,
        instructorId: data.instructor.uid,
        numBookedParticipants: Object.keys(participants).length,
        participants,
        location: data.location,
      };
    }
    if (data.type === "Other") {
      return {
        ...base,
        type: "Other",
        instructorId: data.instructor.uid,
        category: data.category,
        notes: data.notes,
        otherEventDuration: data.otherEventDuration,
      };
    }
    // Fallback for unknown events ...
    return { ...base, type: "Unknown" };
  },
};

function _theoryExamStatus(data: DocumentData): TheoryExam["status"] {
  if (data.deleted && !data.replacedBy) {
    return "canceledByInstructor";
  }
  const { student } = data;
  const rsvp: PaidLessonStudentAttendance["rsvp"] = student.rsvp;
  if (rsvp === "pending") {
    return "invited";
  } else if (rsvp === "accepted") {
    return (data.theoryExamResult as "passed" | "failed" | undefined) ?? "booked";
  } else if (rsvp === "rejected") {
    // Heuristic to determine if an invitation was declined or a booked lesson was canceled ...
    return student.bookedAt || student.cancelReason || data.paymentIntentId ? "canceledByStudent" : "declinedByStudent";
  } else if (rsvp === "declined") {
    return "declinedByStudent";
  } else if (rsvp === "canceled") {
    return "canceledByStudent";
  } else if (rsvp === "no-show") {
    return "no-show";
  }
}

function _isDrivingLessonBooked(data: DocumentData): boolean {
  if (data.deleted) {
    return false;
  }
  const { student } = data;
  const rsvp: PaidLessonStudentAttendance["rsvp"] = student.rsvp;
  return rsvp === "accepted" || rsvp === "no-show";
}

function _drivingLessonStatus(data: DocumentData): DrivingLesson["status"] {
  if (data.deleted && !data.replacedBy) {
    return "canceledByInstructor";
  }
  const { student } = data;
  const rsvp: PaidLessonStudentAttendance["rsvp"] = student.rsvp;
  if (rsvp === "pending") {
    return "invited";
  } else if (rsvp === "accepted") {
    return (data.practicalExamResult as "passed" | "failed" | undefined) ?? (data.endedAt ? "finished" : "booked");
  } else if (rsvp === "rejected") {
    // Heuristic to determine if an invitation was declined or a booked lesson was canceled ...
    return student.bookedAt || student.cancelReason || data.paymentIntentId ? "canceledByStudent" : "declinedByStudent";
  } else if (rsvp === "declined") {
    return "declinedByStudent";
  } else if (rsvp === "canceled") {
    return "canceledByStudent";
  } else if (rsvp === "no-show") {
    return "no-show";
  }
}
