import { Student, studentsProvider } from "./studentsProvider";
import { GetListParams, GetListResult } from "react-admin";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { Invoice, invoicesProvider } from "./invoicesProvider";
import { DateTime } from "luxon";
import { applyPagination, applySort } from "../backoffice.utils";

type Cents = number;

type InvoiceCustomer = Student & {
  sumUnpaidInvoices: Cents;
  sumPaidInvoices: Cents;
  remainingBudget?: Cents;
};

class InvoiceCustomersProvider extends AbstractDataProvider<InvoiceCustomer> {
  async getList(
    _resource: string,
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<InvoiceCustomer>> {
    if (["sumUnpaidInvoices", "sumPaidInvoices", "remainingBudget"].includes(sort.field)) {
      // We need to fetch all invoice customers and all invoices of those students,
      // calculate the sort field and then sort the result ...
      const { data: students, total } = await studentsProvider.getList("students", {
        filter: { ...filter, paymentStrategy: "purchaseOnAccount" },
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 999 },
      });
      if (typeof total !== "number") {
        throw new Error('Result of studentsProvider.getList("students", ...) did not contain total');
      }
      if (total > 999) {
        throw new Error(`Cannot sort by ${sort.field} -- too many invoice customers`);
      }
      const data = await Promise.all(students.map(_studentToInvoiceCustomer));
      return applyPagination(applySort(data, sort), pagination);
    }
    // We can delegate the sorting and pagination to the studentsProvider
    // and only need to fetch the invoices of the students on the current page ...
    const { data: students, total } = await studentsProvider.getList("students", {
      filter: { ...filter, paymentStrategy: "purchaseOnAccount" },
      sort,
      pagination,
    });
    return { data: await Promise.all(students.map(_studentToInvoiceCustomer)), total };
  }
}

export const invoiceCustomersProvider = new InvoiceCustomersProvider();

const _cache = new Map<string, { value: InvoiceCustomer; validUntil: DateTime }>();

async function _studentToInvoiceCustomer(student: Student): Promise<InvoiceCustomer> {
  const cacheEntry = _cache.get(student.id);
  if (cacheEntry && cacheEntry.validUntil > DateTime.now()) {
    return cacheEntry.value;
  }
  const { data: invoices, total } = await invoicesProvider.getList<Invoice>("b2cInvoices", {
    filter: { studentId: student.id },
    sort: { field: "id", order: "ASC" },
    pagination: { page: 1, perPage: 9999 },
  });
  if (typeof total !== "number") {
    throw new Error('Result of invoicesProvider.getList("b2cInvoices", ...) did not contain total');
  }
  if (total > 9999) {
    throw new Error(`Student ${student.id} as too many invoices`);
  }
  const paidInvoices = invoices.filter((it) => it.status === "PAID");
  const unpaidInvoices = invoices.filter((it) => !["PAID", "REFUNDED", "PARTIALLY_REFUNDED"].includes(it.status));
  const sumPaidInvoices = paidInvoices.reduce((sum, invoice) => sum + (invoice.paid_amount ?? 0), 0);
  const sumUnpaidInvoices = unpaidInvoices.reduce((sum, invoice) => sum + (invoice.payment_amount ?? 0), 0);
  const remainingBudget = (student.budget as number) - sumPaidInvoices - sumUnpaidInvoices;
  const invoiceCustomer = {
    ...student,
    sumPaidInvoices,
    sumUnpaidInvoices,
    remainingBudget,
  };
  _cache.set(student.id, { value: invoiceCustomer, validUntil: DateTime.now().plus({ minutes: 5 }) });
  return invoiceCustomer;
}
