import { Button } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { useDialog } from "../hooks/useDialog";
import { useFormContext } from "react-hook-form";

export const BookDrivingLessonWithoutInviteButton = ({ onSubmit }: { onSubmit: () => void }) => {
  const { dialogProps, openDialog } = useDialog();
  const { formState } = useFormContext();

  return (
    <>
      <Button
        disabled={!formState.isValid}
        variant="outlined"
        color="primary"
        label="Direkt buchen"
        onClick={openDialog}
      />
      <ConfirmationDialog
        {...dialogProps}
        onSubmit={() => {
          onSubmit();
          dialogProps.onClose();
        }}
      />
    </>
  );
};

interface ConfirmationDialog {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
const ConfirmationDialog = ({ open, onClose, onSubmit }: ConfirmationDialog) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Liegt eine schriftliche Bestätigung vor?</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Typography variant="body2">
          Du bist dabei, eine Fahrstunde direkt für den Fahrschüler zu buchen. Bitte stelle sicher, dass wir hierfür
          eine schriftliche Bestätigung (WhatsApp oder E-Mail) vorliegen haben, die bestätigt, dass der Kunde diesen
          Termin buchen möchte und den Stornierungsbedingungen zustimmt.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={{ py: 0.75, px: 2 }} variant="outlined" color="secondary" label="Abbrechen" onClick={onClose} />
        <Button
          sx={{ py: 0.75, px: 2 }}
          variant="contained"
          label="Schriftliche Bestätigung vorhanden"
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};
