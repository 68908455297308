import { useEffect, useState, FormEvent, KeyboardEvent } from "react";
import { LinearProgress, useGetRecordId, useNotify, useRefresh } from "react-admin";
import { useQuery } from "react-query";
import { Typography, IconButton, TextField, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import { studentsProvider } from "../providers/studentsProvider";
import { Row } from "../misc/Row";
import { grants } from "../backoffice.access_control";
import { serverAPI } from "../api/server.api";

export function AuthProvidersField() {
  const studentId = useGetRecordId().toString();
  const [editMode, setEditMode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState<string>("");
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: providerData } = useQuery(
    ["students", "fetchProviderData", { id: studentId }],
    () => studentsProvider.fetchProviderData(studentId),
    {
      onSuccess: (data) => {
        setEmail(data?.find((it) => it.providerId === "password")?.email ?? "");
      },
    },
  );

  useEffect(() => {
    if (editMode) {
      setEmail(providerData?.find((it) => it.providerId === "password")?.email ?? "");
    }
  }, [editMode]);

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState);
  };

  if (!providerData) {
    return <LinearProgress timeout={0} />;
  }

  const handleSubmission = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await serverAPI.updateAuthEmail({ studentId, email });
      setEditMode(false);
      refresh();
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      notify(errorMessage ?? "Aktualisierung der E-Mail-Adresse fehlgeschlagen.", { type: "error" });
      console.error("Error updating email", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (editMode) {
    return (
      <form onSubmit={handleSubmission}>
        <TextField
          sx={{ mt: 1 }}
          label="E-Mail"
          InputProps={submitting ? { endAdornment: <CircularProgress size={24} /> } : {}}
          value={email}
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => {
            toggleEditMode();
          }}
          onKeyDown={({ key }: KeyboardEvent<HTMLDivElement>) => {
            if (key === "Escape") {
              toggleEditMode();
            }
          }}
        />
      </form>
    );
  }

  return (
    <>
      {providerData.map((it) => (
        <Row key={it.providerId} gap={2}>
          <Typography key={it.providerId} variant="body2">
            {it.email} ({_providerLabels[it.providerId] ?? it.providerId})
          </Typography>
          {it.providerId === "password" && grants.includes("editStudentPersonalData") && (
            <IconButton sx={{ mt: "-10px" }} onClick={toggleEditMode}>
              <EditIcon />
            </IconButton>
          )}
        </Row>
      ))}
    </>
  );
}

const _providerLabels: Record<string, string> = {
  "google.com": "Google",
  "apple.com": "Apple",
  "facebook.com": "Facebook",
  password: "E-Mail",
};
