import {
  type FilterPayload,
  List,
  SelectInput,
  SelectInputProps,
  useGetList,
  useGetRecordId,
  useListContext,
  useRecordContext,
} from "react-admin";
import { styled } from "@mui/material/styles";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { PageTitle } from "../../misc/PageTitle";
import { DrivingLicenseClassEnum } from "../../model/DrivingLicenseClass";
import { autovioColors } from "../../misc/backofficeTheme";
import { currentUser, grants } from "../../backoffice.access_control";
import { ReactElement } from "react";
import { TheorieunterrichtIcon } from "../../icons/TheorieunterrichtIcon";
import { SimulatorIcon } from "../../icons/SimulatorIcon";
import { CarIcon } from "../../icons/CarIcon";
import { AutobahnIcon } from "../../icons/AutobahnIcon";
import { ManualGearIcon } from "../../icons/ManualGearIcon";
import { useRecoilValue } from "recoil";
import { isStudentsDataGridLegendVisibleAtom, StudentsDataGrid } from "../../lists/StudentsDataGrid";

export function DrivingSchoolStudentsList({
  title,
  storeKey,
  filter,
  empty,
}: {
  title?: string;
  storeKey: string;
  filter?: FilterPayload;
  empty?: ReactElement;
}) {
  const drivingSchoolId = useGetRecordId();
  const drivingSchool = useRecordContext<DrivingSchool>();
  const { data: simulators } = useGetList(
    "simulators",
    {
      filter: { entitledDrivingSchoolId: drivingSchoolId },
      pagination: { page: 1, perPage: 2 },
      sort: { field: "id", order: "DESC" },
    },
    {
      enabled: !!drivingSchoolId,
    },
  );
  const showSimulatorDrivingLessons = !!simulators?.length;

  if (!(drivingSchool && simulators)) {
    return (
      <>
        <PageTitle>{title ?? "Fahrschüler"}</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <div style={{ position: "relative" /* ... for positioning the <StudentsDataGridLegend /> */ }}>
      <_StudentsDataGridLegend showSimulatorDrivingLessons={showSimulatorDrivingLessons} />
      <List
        resource="students"
        storeKey={`${storeKey}@${drivingSchool.id}`}
        title={<PageTitle>{title ?? "Fahrschüler"}</PageTitle>}
        filter={{
          ...(filter ?? {}),
          drivingSchoolId: drivingSchool.id,
          ...(!grants.includes("Student:list") ? { instructorIds: currentUser?.uid } : {}),
        }}
        exporter={false}
        filters={[
          <StyledSelectInput
            label="Filiale"
            source="branchId"
            choices={drivingSchool.branches.map(({ uid, name }) => ({ id: uid, name }))}
            alwaysOn
          />,
          <StyledSelectInput
            label="Status"
            source="status"
            choices={[
              { id: "active", name: "Aktiv" },
              { id: "cancelled", name: "Abgebrochen" },
              { id: "inactive", name: "Inaktiv" },
              { id: "onHold", name: "Pausiert" },
              { id: "outstandingPayments", name: "Offene Zahlungen" },
              { id: "completed", name: "Fertig" },
            ]}
            alwaysOn
          />,
          <StyledSelectInput
            label="Führerscheinklasse"
            source="drivingLicenseClasses"
            choices={DrivingLicenseClassEnum.options.map((val) => ({ id: val, name: val }))}
            alwaysOn
          />,
          <StyledSelectInput
            label="Prüfauftrag"
            source="drivingLicenseApplicationStatus"
            choices={[
              { id: "pending", name: "Ausstehend" },
              { id: "approved", name: "Genehmigt" },
              { id: "notApproved", name: "Abgelehnt" },
            ]}
            alwaysOn
          />,
          <StyledSelectInput
            label="Fahrstunden/Woche"
            source="guaranteedDrivingLessonsPerWeek"
            choices={[
              { id: 2, name: "2 UE/W" },
              { id: 4, name: "4 UE/W" },
              { id: 8, name: "8 UE/W" },
            ]}
            alwaysOn
          />,
        ]}
        empty={false}
      >
        <StudentsDataGrid
          drivingSchool={drivingSchool}
          showSimulatorDrivingLessons={showSimulatorDrivingLessons}
          empty={empty}
        />
      </List>
    </div>
  );
}

const StyledSelectInput = styled((props: SelectInputProps) => (
  <SelectInput
    SelectProps={{
      MenuProps: {
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              fontSize: "14px",
            },
          },
        },
      },
    }}
    {...props}
  />
))({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: autovioColors.greyLight,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: autovioColors.grey,
  },
  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
    border: "1px solid #9b9b9b",
  },
  "& .MuiInputLabel-root.Mui-focused span": {
    color: autovioColors.grey,
  },
});

function _StudentsDataGridLegend({ showSimulatorDrivingLessons }: { showSimulatorDrivingLessons: boolean }) {
  const { isLoading } = useListContext();
  const isLegendVisible = useRecoilValue(isStudentsDataGridLegendVisibleAtom);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: isLegendVisible === "shown" ? (showSimulatorDrivingLessons ? "-90px" : "-60px") : "80px",
          boxShadow: "rgba(0, 0, 0, 0.06) 0 2px 5px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          padding: "5px 10px 20px",
          backgroundColor: "white",
          color: "#9b9b9b",
          fontSize: "14px",
          transition: "top .5s ease-out",
          visibility: isLegendVisible === "invisible" ? "hidden" : "visible",
          zIndex: "-2",
        }}
      >
        <TheorieunterrichtIcon sx={{ mt: "8px", mb: "-8px" }} /> Theoriestunden
        <br />
        {showSimulatorDrivingLessons && (
          <>
            <SimulatorIcon sx={{ mt: "8px", mb: "-8px" }} /> Simulator-Übungsstunden
            <br />
          </>
        )}
        <CarIcon sx={{ mt: "8px", mb: "-8px" }} /> normale Übungsstunden
        <br />
        <AutobahnIcon sx={{ mt: "8px", mb: "-8px" }} /> Sonderstunden (Überland, Autobahn, Dämmerung)
        <br />
        <ManualGearIcon sx={{ mt: "8px", mb: "-8px" }} /> Schaltwagenstunden
      </div>
      {/* Hide the legend behind another div if it is not visible ... */}
      <div
        style={{
          position: "absolute",
          right: 0,
          top: "80px",
          height: "160px",
          width: "420px",
          backgroundColor: "#f8f8f8",
          zIndex: "-1",
        }}
      />
    </>
  );
}
