import { FieldProps, useRecordContext } from "react-admin";
import get from "lodash/get";
import DoneIcon from "@mui/icons-material/Done";

interface BooleanFieldProps extends FieldProps {
  source: string;
}

export function BooleanField(props: BooleanFieldProps) {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source) ?? [];

  if (value) {
    return <DoneIcon fontSize="small" className="RaBooleanField-trueIcon" />;
  }
}
