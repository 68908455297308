import { useMemo } from "react";
import { FieldValues, Path } from "react-hook-form";
import { DrivingLicenseClass } from "../model/DrivingLicenseClass";
import { SelectInput } from "./SelectInput";
import { Student } from "../providers/studentsProvider";
import { HiddenInput } from "./HiddenInput";

interface DrivingLicenseClassInputProps {
  source: Path<FieldValues>;
  student: Student;
  label: string;
  includesSimulatorDrivingLessons?: true;
  size?: "small";
}

export function DrivingLicenseClassInput({
  source,
  student,
  label,
  includesSimulatorDrivingLessons,
  size,
}: DrivingLicenseClassInputProps) {
  const options = useMemo(() => {
    const unfinishedBookedTrainings = student.bookedTrainings.filter((it) => !it.isFinished);
    const relevantBookedTrainings = includesSimulatorDrivingLessons
      ? unfinishedBookedTrainings.filter((it) => it.includesSimulatorDrivingLessons)
      : unfinishedBookedTrainings;
    if (relevantBookedTrainings.length === 0) {
      console.warn(`Did not find relevant booked training for student ${student.id} (${student.name})`);
    }
    return relevantBookedTrainings.map((it) => [it.drivingLicenseClass, it.drivingLicenseClass]);
  }, [student.id, includesSimulatorDrivingLessons]) as Array<[DrivingLicenseClass, string]>;
  if (options.length < 1) {
    return null;
  } else if (options.length === 1) {
    return <HiddenInput source={source} value={options[0][0]} />;
  } else {
    return (
      <SelectInput
        source={source}
        label={label}
        options={options}
        validate={(value) => (value ? true : "Bitte wähle die Führerscheinklasse aus.")}
        size={size}
      />
    );
  }
}
