import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from "@mui/material";
import { useGetOne } from "react-admin";
import { Student } from "../../providers/studentsProvider";
import { SlideUp } from "../SlideUp";
import { DialogCloseButton } from "../DialogCloseButton";

interface ConfirmCancellationPolicyDialogPros {
  readonly isOpen: boolean;
  readonly studentUid?: string;
  readonly onCancel: () => void | Promise<void>;
  readonly onConfirm: () => void | Promise<void>;
}

export function ConfirmCancellationPolicyDialog({
  isOpen,
  studentUid,
  onCancel,
  onConfirm,
}: ConfirmCancellationPolicyDialogPros) {
  const { data: student } = useGetOne<Student>("students", { id: studentUid ?? "" }, { enabled: !!studentUid });
  return (
    <Dialog open={isOpen} onClose={onCancel} TransitionComponent={SlideUp}>
      <DialogTitle>Stornierungsbedingungen mitgeteilt?</DialogTitle>
      <DialogCloseButton onClick={onCancel} />
      <DialogContent>
        Hast Du {student?.firstName ?? "..."} über die geltenden{" "}
        <Link href="https://autovio.de/app/termine-absagen/" target="_blank" underline="none">
          Stornierungsbedingungen
        </Link>{" "}
        informiert?
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onConfirm}>
          Ja, habe ich
        </Button>
      </DialogActions>
    </Dialog>
  );
}
