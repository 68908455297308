import { useRecordContext } from "react-admin";
import * as React from "react";
import get from "lodash/get";
import { IconButton } from "@mui/material";
import { StripeIcon } from "../icons/StripeIcon";
import { firebaseApp } from "../firebase";
import ErrorIcon from "@mui/icons-material/Error";

interface OpenInStripeIconButtonProps {
  source: string;
}

function _OpenInStripeIconButton(props: OpenInStripeIconButtonProps) {
  const { source } = props;
  const { projectId } = firebaseApp.options;
  const baseUrl = `https://dashboard.stripe.com${projectId !== "autovio-prod" ? "/test" : ""}`;
  const record = useRecordContext(props);
  let url: string;
  const ref = get(record, source);
  if (!ref) {
    return <ErrorIcon role="presentation" color="error" fontSize="small" />;
  } else if (ref.startsWith("cus_")) {
    url = `${baseUrl}/customers/${ref}/`;
  } else if (ref.startsWith("pi_")) {
    url = `${baseUrl}/payments/${ref}/`;
  } else {
    url = `${baseUrl}/search?query=${encodeURIComponent(ref)}`;
  }

  return (
    <IconButton
      size="small"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        open(url, "_blank");
      }}
      title="In Stripe öffnen"
    >
      <StripeIcon sx={{ width: "18px", height: "18px" }} />
    </IconButton>
  );
}

export const OpenInStripeIconButton = React.memo(_OpenInStripeIconButton);

OpenInStripeIconButton.displayName = "OpenInStripeIconButton";
