import { RaRecord, useGetOne, useGetRecordId, useResourceContext } from "react-admin";
import { useAutovioContext } from "./useAutovioContext";
import { useEffect } from "react";

export function useSwitchDrivingSchoolIfNecessary<T extends RaRecord>(
  getDrivingSchoolId: (record: T) => string | undefined,
) {
  const resource = useResourceContext();
  const id = useGetRecordId();
  const { data: record } = useGetOne(resource, { id }, { enabled: !!(resource && id) });
  const [autovioContext, setAutovioContext] = useAutovioContext();
  useEffect(() => {
    if (!record) {
      return;
    }
    const drivingSchoolId = getDrivingSchoolId(record);
    if (drivingSchoolId !== autovioContext.drivingSchoolId) {
      setAutovioContext({ drivingSchoolId });
    }
  }, [record]);
}
