import EditIcon from "@mui/icons-material/EditOutlined";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { EditBase, Form, SaveButton, TextInput, useNotify, useRecordContext, useRefresh, useUpdate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { reportError } from "../../backoffice.utils";
import { useDialog } from "../../hooks/useDialog";
import { InvoiceAddress, InvoiceAddressSchema } from "../../model/InvoiceAddress";
import { Student } from "../../providers/studentsProvider";
import { DialogCloseButton } from "../../misc/DialogCloseButton";

export function EditInvoiceAddressButton() {
  const { dialogProps, openDialog } = useDialog();
  return (
    <>
      <IconButton onClick={openDialog}>
        <EditIcon />
      </IconButton>
      <EditInvoiceAddressDialog {...dialogProps} />
    </>
  );
}

interface EditInvoiceAddressDialogProps {
  open: boolean;
  onClose: () => void;
}

function EditInvoiceAddressDialog({ open, onClose }: EditInvoiceAddressDialogProps) {
  const student = useRecordContext<Student>();
  const [update] = useUpdate<Student>();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (invoiceAddress: InvoiceAddress) => {
    try {
      if (!student) {
        throw new Error("Invalid state: !student");
      }
      await update("students", {
        id: student.id,
        data: { invoiceAddress: InvoiceAddressSchema.parse(invoiceAddress) },
        previousData: student,
      });
      refresh();
      notify(`Rechnungsadresse von ${student.name} erfolgreich geändert.`, { type: "success" });
    } catch (error) {
      reportError(`Failed to change address of student ${student?.id}`, error);
      notify(`Fehler beim Ändern der Rechnungsadresse von ${student?.name ?? "dem Schüler"}.`, { type: "error" });
    } finally {
      onClose();
    }
  };

  const validate = (data: { [field: string]: any }) => {
    const { invoiceAddress } = data;
    const errors: { [field: string]: string } = {};
    const cannotBeEmptyMessage = "Darf nicht leer sein.";
    if (!invoiceAddress.firstName) {
      errors["invoiceAddress.firstName"] = cannotBeEmptyMessage;
    }
    if (!invoiceAddress.lastName) {
      errors["invoiceAddress.lastName"] = cannotBeEmptyMessage;
    }
    if (!invoiceAddress.street) {
      errors["invoiceAddress.street"] = cannotBeEmptyMessage;
    }
    if (!invoiceAddress.postalCode) {
      errors["invoiceAddress.postalCode"] = cannotBeEmptyMessage;
    }
    if (!invoiceAddress.city) {
      errors["invoiceAddress.city"] = cannotBeEmptyMessage;
    }
    return errors;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Rechnungsadresse von ${student?.name ?? "..."}`}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <EditBase>
        <Form onSubmit={(data) => onSubmit(data.invoiceAddress)} validate={validate} warnWhenUnsavedChanges>
          <DialogContent>
            <EditInvoiceAddressForm />
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </EditBase>
    </Dialog>
  );
}

function EditInvoiceAddressForm() {
  const { setValue } = useFormContext();
  const student = useRecordContext<Student>();

  const setFromPostalAddress = () => {
    const postalAddress = student.postalAddress;
    if (postalAddress) {
      setValue("invoiceAddress.firstName", student.firstName, { shouldDirty: true, shouldTouch: true });
      setValue("invoiceAddress.lastName", student.lastName, { shouldDirty: true, shouldTouch: true });
      setValue("invoiceAddress.street", postalAddress.street, { shouldDirty: true, shouldTouch: true });
      setValue("invoiceAddress.postalCode", postalAddress.postalCode, { shouldDirty: true, shouldTouch: true });
      setValue("invoiceAddress.city", postalAddress.city, { shouldDirty: true, shouldTouch: true });
    }
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1}>
        <TextInput source="invoiceAddress.firstName" label="Vorname" sx={{ width: "40%" }} required />
        <TextInput source="invoiceAddress.lastName" label="Nachname" sx={{ width: "60%" }} required />
      </Stack>
      <TextInput source="invoiceAddress.company" label="Firma" />
      <TextInput source="invoiceAddress.street" label="Straße" required />
      <Stack direction="row" spacing={1}>
        <TextInput
          className="plz-input"
          source="invoiceAddress.postalCode"
          label="PLZ"
          sx={{ width: "30%" }}
          required
        />
        <TextInput source="invoiceAddress.city" label="Stadt" sx={{ width: "70%" }} required />
      </Stack>
      <Button variant="outlined" onClick={setFromPostalAddress}>
        Von Wohnanschrift übernehmen
      </Button>
    </Stack>
  );
}
