import type { DialogProps } from "../../misc/DialogProps";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TheoryCourseWizard } from "./TheoryCourseWizard";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { DateTime } from "luxon";

export function AddTheoryCourseDialog({
  initialDateTime,
  ...dialogProps
}: DialogProps & { initialDateTime?: DateTime }) {
  return (
    <Dialog {...dialogProps} maxWidth="xl" data-testid="add-theory-course-dialog">
      <DialogTitle>Theoriekurs</DialogTitle>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent>
        <TheoryCourseWizard onSuccess={dialogProps.onClose} initialDateTime={initialDateTime} />
      </DialogContent>
    </Dialog>
  );
}
