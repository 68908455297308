import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DialogProps } from "../../misc/DialogProps";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { EditBase, useRecordContext } from "react-admin";
import { useQueryClient } from "react-query";
import { DrivingLicenseAuthorityInstructionForm } from "./DrivingLicenseAuthorityInstructionForm";

export function EditDrivingLicenseAuthorityInstructionDialog(dialogProps: DialogProps) {
  const { id } = useRecordContext();
  const queryClient = useQueryClient();

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Anleitung bearbeiten</DialogTitle>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent>
        <EditBase
          redirect={false}
          resource="drivingLicenseAuthorityInstructions"
          id={id}
          mutationMode="pessimistic"
          mutationOptions={{
            onSuccess: async () => {
              await queryClient.invalidateQueries(["drivingLicenseAuthorities"]);
              await queryClient.invalidateQueries(["drivingLicenseAuthorityInstructions"]);
              dialogProps.onClose();
            },
          }}
        >
          <DrivingLicenseAuthorityInstructionForm />
        </EditBase>
      </DialogContent>
    </Dialog>
  );
}
