import EditIcon from "@mui/icons-material/EditOutlined";
import { Box, IconButton, LinearProgress } from "@mui/material";
import { cloneElement, ReactElement, useEffect, useState } from "react";
import { EditBase, Form, Labeled, useNotify, useRecordContext, useResourceContext, useUpdate } from "react-admin";
import get from "lodash/get";
import set from "lodash/set";
import { YesNoInput } from "../inputs/YesNoInput";
import { Row } from "./Row";

interface LabeledAndEditableProps {
  label: string;
  disableEdit?: boolean;
  children: ReactElement;
  source?: string;
  renderInput: (args: { submit: (formData: { [k: string]: any }) => void }) => ReactElement;
}

export function LabeledAndEditable({
  label,
  disableEdit,
  children: field,
  source: sourceProp,
  renderInput,
}: LabeledAndEditableProps) {
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => setEditMode(!editMode);
  const resource = useResourceContext();
  const record = useRecordContext();
  const source = sourceProp ?? field.props.source;
  const [update, { isLoading, error, isError }] = useUpdate();
  const notify = useNotify();
  useEffect(() => {
    if (isError) {
      console.error("Update failed", error);
      notify("Fehler beim Speichern.", { type: "error" });
    }
  }, [isError, error, notify]);

  if (!record) {
    return null;
  }
  const { id } = record;
  const submit = (formData: { [k: string]: any }) => {
    setEditMode(false);
    if (typeof source === "string") {
      // lodash set & get methods handle nested fields.
      void update(resource, { id, data: set({}, source, get(formData, source)), previousData: record });
    } else {
      void update(resource, { id, data: formData, previousData: record });
    }
  };

  if (editMode) {
    let input = renderInput({ submit });
    if (source) {
      input = cloneElement(input, {
        label: label,
        autoFocus: true,
        helperText: false,
        onKeyDown: ({ key }: KeyboardEvent) => {
          if (key === "Escape") {
            toggleEditMode();
          }
        },
        onBlur: toggleEditMode,
      });
    }
    if (input.type === YesNoInput) {
      input = cloneElement(input, { onChange: (newValue: boolean) => submit({ [source]: newValue }) });
      input = <Labeled label={label}>{input}</Labeled>;
    }
    if (input.type !== Labeled) {
      input = (
        <Box mt="10px" width="225px">
          {input}
        </Box>
      );
    }
    return (
      <EditBase>
        <Form className="LabeledAndEditable-Form" onSubmit={submit}>
          {input}
        </Form>
      </EditBase>
    );
  } else {
    return (
      <Row spacing={1} sx={{ alignItems: "start" }}>
        <Labeled label={label}>{isLoading ? <LinearProgress /> : field}</Labeled>
        {!disableEdit && (
          <IconButton onClick={toggleEditMode}>
            <EditIcon />
          </IconButton>
        )}
      </Row>
    );
  }
}
