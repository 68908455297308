import { Box, Card, type IconButton, Typography } from "@mui/material";
import { overviewCardStyle } from "./backofficeTheme";

type DashboardCardProps = React.PropsWithChildren<{
  title: string;
  topRightCorner?: false | React.ReactElement<any, typeof IconButton>;
}>;

export function DashboardCard({ title, topRightCorner, children }: DashboardCardProps) {
  return (
    <Card sx={overviewCardStyle}>
      {topRightCorner && <div style={{ position: "absolute", top: "17px", right: "17px" }}>{topRightCorner}</div>}
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: 15,
          mb: "15px",
          ...(topRightCorner ? { mr: "50px" } : {}),
        }}
      >
        {title}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>{children}</Box>
    </Card>
  );
}
