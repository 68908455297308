import { useEffect, useMemo, useRef, useState } from "react";
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DeleteButton,
  Edit,
  FilterButton,
  List,
  maxValue,
  minValue,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  Resource,
  SaveButton,
  SelectArrayInput,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import BundleMenuIcon from "@mui/icons-material/Euro";
import { preventValueChangeOnWheel } from "../backoffice.utils";
import { ChipsField } from "../fields/ChipsField";
import { ChipsInput } from "../inputs/ChipsInput";
import {
  Bundle,
  BundleIconType,
  catalogProvider,
  fetchBundleTagsOnce,
  Product,
  salesCategories,
  Training,
} from "../providers/catalogProvider";
import Stack from "@mui/material/Stack";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { CloneIconButton } from "../buttons/CloneIconButton";
import { Box, Button, ButtonGroup } from "@mui/material";

import iconBolt from "../icons/assets/icon-bolt.svg";
import iconSavings from "../icons/assets/icon-savings.svg";
import iconSpeed from "../icons/assets/icon-speed.svg";
import { grants, restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { SelectDrivingLicenseClassesInput } from "../inputs/SelectDrivingLicenseClassesInput";
import { DrivingLicenseClassesField } from "../fields/DrivingLicenseClassesField";
import { PriceField } from "../fields/PriceField";
import { SalesCategoryField } from "../fields/SalesCategoryField";
import { DrivingSchoolInput } from "../inputs/DrivingSchoolInput";
import { ReferenceDrivingSchoolField } from "../fields/ReferenceDrivingSchoolField";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";

const validatePrice = (isRequired = false) => [
  ...(isRequired ? [required("Required")] : []),
  minValue(0, "Darf nicht negativ sein."),
  maxValue(9999.99, "Dieser Betrag scheint zu hoch zu sein."),
  (price: any) =>
    typeof price === "number" && Math.round(price * 1000) % 10 !== 0
      ? "Darf nur zwei Nachkommastellen haben."
      : undefined,
];

function BundleListActions() {
  return (
    <TopToolbar>
      <FilterButton />
      <SelectColumnsButton />
      {grants.includes("editBundles") && <CreateButton />}
    </TopToolbar>
  );
}

function BundleList() {
  const filters = useMemo(
    () => [
      <TextInput label="Suche" source="q" alwaysOn resettable />,
      ...(restrictAccessToDrivingSchoolIds?.length === 1
        ? []
        : [
            <ReferenceInput
              source="drivingSchoolId"
              alwaysOn
              reference="drivingSchools"
              page={1}
              perPage={999}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput label="Fahrschule" />
            </ReferenceInput>,
          ]),
      <SelectInput
        source="salesCategory"
        label="Kategorie"
        choices={Object.entries(salesCategories).map(([id, name]) => ({ id, name }))}
        alwaysOn
      />,
      <SelectInput
        source="isDrivingSchoolChange"
        label="für Wechsler"
        choices={[
          { id: true, name: "✓ ja" },
          { id: false, name: "✗ nein" },
        ]}
        alwaysOn
      />,
      <SelectDrivingLicenseClassesInput
        source="drivingLicenseClasses"
        label="Führerscheinklassen"
        alwaysOn
        sx={{ minWidth: 250 }}
      />,
    ],
    [],
  );
  return (
    <List title={<BackofficeListTitle />} filters={filters} actions={<BundleListActions />} exporter={false}>
      <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        {restrictAccessToDrivingSchoolIds?.length !== 1 && (
          <ReferenceDrivingSchoolField label="Fahrschule" source="drivingSchoolId" link />
        )}
        {grants.includes("editBundles") && <ChipsField label="Tags" source="tags" />}
        <SalesCategoryField label="Kategorie" source="salesCategory" sortBy="salesCategory" />
        <BooleanField label="für Wechsler" source="isDrivingSchoolChange" />
        <DrivingLicenseClassesField label="Klassen" />
        <NumberField
          source="baseFee"
          label="Grundbetrag"
          options={{ style: "currency", currency: "EUR" }}
          sortable={false}
        />
        <PriceField lessonName="Übungsstunde" label="Übungsstunde" />
        <PriceField lessonName="Überlandfahrt" label="Sonderfahrt" />
        <PriceField lessonName="Theorieprüfung" label="Theorieprüfung" />
        <PriceField lessonName="Fahrprüfung" label="Fahrprüfung" />
        <ShowIconButton />
        {grants.includes("editBundles") && <CloneIconButton />}
      </DatagridConfigurable>
    </List>
  );
}

function BundleCreate() {
  return (
    <Create title={<BackofficeRecordTitle />}>
      <BundleForm mode="create" />
    </Create>
  );
}

function BundleEdit() {
  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <BundleForm mode={grants.includes("editBundles") ? "edit" : "show"} />
    </Edit>
  );
}

const IconBlack = (props: { src: React.FC }) => (
  <img
    style={{ filter: "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)" }}
    src={props.src as any}
  />
);

const BundleIcon = (props: { icon: BundleIconType }) => {
  switch (props.icon) {
    case "bolt": {
      return <IconBlack src={iconBolt} />;
    }
    case "savings": {
      return <IconBlack src={iconSavings} />;
    }
    case "speed": {
      return <IconBlack src={iconSpeed} />;
    }
    default: {
      return null;
    }
  }
};

function EditToolbar({ enableSave }: { enableSave: boolean }) {
  return (
    <Toolbar>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <SaveButton alwaysEnable={enableSave} />
        <DeleteButton />
      </Box>
    </Toolbar>
  );
}

function BundleForm({ mode }: { mode: "create" | "edit" | "show" }) {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [knownBundleTags, setKnownBundleTags] = useState([] as Array<string>);
  useEffect(() => {
    void fetchBundleTagsOnce().then(setKnownBundleTags);
  }, []);

  const form = (
    <SimpleForm warnWhenUnsavedChanges toolbar={mode === "show" ? false : <EditToolbar enableSave={isFormDirty} />}>
      <TextInput label="Name" source="name" autoFocus={mode === "create"} fullWidth validate={required()} />
      <TextInput label="Titel" source="title" fullWidth />
      <TextInput label="Untertitel" source="subtitle" fullWidth />
      <TextInput label="Beschreibung" source="description" fullWidth />
      <NumberInput
        label="Garantierte Fahrstunden pro Woche"
        source="guaranteedDrivingLessonsPerWeek"
        validate={required()}
      />
      <SelectInput
        label="Icon"
        source="icon"
        choices={[{ id: "bolt" }, { id: "speed" }, { id: "savings" }]}
        optionText={(choice) => (
          <Box sx={{ display: "flex", direction: "row" }}>
            {choice.name}
            <BundleIcon icon={choice.id as BundleIconType} />
          </Box>
        )}
        sx={{ minWidth: "337px" }}
      />
      <BooleanInput label="highlight (in web-app)" source="isHighlight" />
      <BooleanInput label="disabled (in web-app)" source="disabled" />
      <BooleanInput label="für Fahrschulwechsler?" source="isDrivingSchoolChange" />
      <BooleanInput
        label="für Aufstieg? (zum Beispiel A1 🡒 A2 oder A2 🡒 A)"
        source="isDrivingLicenseClassAdvancement"
      />
      <SelectInput
        label="Kategorie (in web-app)"
        source="salesCategory"
        choices={Object.entries(salesCategories).map(([id, name]) => ({ id, name }))}
        sx={{ minWidth: "337px" }}
      />
      <ChipsInput label="Tags" source="tags" knownValues={knownBundleTags} fullWidth validate={required()} />
      <Box sx={{ height: "60px" }} />
      <FeatureInputs onDefaultFeatureSelect={() => setIsFormDirty(true)} />
      <Box sx={{ height: "60px" }} />
      <ReferenceArrayInput
        source="areaIds"
        reference="areas"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <SelectArrayInput
          label="Gebiete"
          className="fix-SelectArrayInput-label"
          optionText="name"
          optionValue="id"
          sx={{ minWidth: "337px" }}
        />
      </ReferenceArrayInput>
      <DrivingSchoolInput label="Fahrschule" source="drivingSchoolId" isRequired />
      <ReferenceArrayInput
        source="trainingIds"
        reference="trainings"
        page={1}
        perPage={999}
        sort={{ field: "name", order: "ASC" }}
        validate={isTrainingsOrProductsSpecified}
      >
        <SelectArrayInput
          label="Training(s)"
          className="fix-SelectArrayInput-label"
          optionText="name"
          optionValue="id"
          sx={{ minWidth: "337px" }}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="productIds"
        reference="products"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        validate={isTrainingsOrProductsSpecified}
      >
        <SelectArrayInput
          label="Produkt(e)"
          className="fix-SelectArrayInput-label"
          optionText="name"
          optionValue="id"
          sx={{ minWidth: "337px" }}
        />
      </ReferenceArrayInput>
      <PriceInput label="Grundbetrag" source="baseFee" required />
      <PriceInput label="Lehrmittelgebühr" source="teachingMaterialFee" />
      <PricesInput source="productPrices" labelGenerator={(product) => `Preis für ${product.name}`} />
      <PricesInput
        source="authorityFees"
        labelGenerator={(product) => `TÜV/DEKRA Gebühr für ${product.name}`}
        productFilter={(product) => product.type === "Theorieprüfung" || product.type === "Fahrprüfung"}
      />
    </SimpleForm>
  );
  if (mode !== "show") {
    return form;
  }
  // Overlay form with a div, so that the user can not interact with any input fields ...
  return (
    <div style={{ position: "relative" }}>
      {form}
      <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0 }} />
    </div>
  );
}

function FeatureInputs({ onDefaultFeatureSelect }: { onDefaultFeatureSelect: () => void }) {
  return (
    <>
      <p style={{ fontSize: "0.75rem", opacity: 0.6 }}>Features</p>
      <DefaultFeatureButtons onDefaultFeatureSelect={onDefaultFeatureSelect} />
      <Box sx={{ height: "20px" }} />
      <ArrayInput label="" source="featureList">
        <SimpleFormIterator>
          <Box sx={{ height: "20px" }} />
          <TextInput label="Name" source="name" validate={required()} />
          <TextInput label="Value" source="value" validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}

type Feature = { name: string; value: string };

function DefaultFeatureButtons({ onDefaultFeatureSelect }: { onDefaultFeatureSelect: () => void }) {
  const { setValue, getValues } = useFormContext();
  const setFeatures = (features: Feature[]) => {
    const oldFeatures = getValues().featureList.filter(
      (feature: Feature) =>
        feature.name !== "Ausbildungsdauer" &&
        feature.name !== "Fahrstunden pro Woche" &&
        feature.name !== "Zeitraum für Fahrstunden",
    );
    setValue("featureList", [...features, ...oldFeatures]);
    onDefaultFeatureSelect();
  };

  return (
    <Box sx={{ width: "min-content" }}>
      <ButtonGroup>
        <Button
          onClick={() =>
            setFeatures([
              { name: "Ausbildungsdauer", value: "ca. 15 Wochen" },
              { name: "Fahrstunden pro Woche", value: "2 Garantierte" },
              { name: "Zeitraum für Fahrstunden", value: "8-16 Uhr" },
            ])
          }
        >
          Standard
        </Button>
        <Button
          onClick={() =>
            setFeatures([
              { name: "Ausbildungsdauer", value: "ca. 10 Wochen" },
              { name: "Fahrstunden pro Woche", value: "4 Garantierte" },
              { name: "Zeitraum für Fahrstunden", value: "Ganzer Tag" },
            ])
          }
        >
          Schnell
        </Button>
        <Button
          onClick={() =>
            setFeatures([
              { name: "Ausbildungsdauer", value: "ca. 3 Wochen" },
              { name: "Fahrstunden pro Woche", value: "8 Garantierte" },
              { name: "Zeitraum für Fahrstunden", value: "8-16 Uhr" },
            ])
          }
        >
          Intensiv
        </Button>
      </ButtonGroup>
    </Box>
  );
}

function PricesInput({
  source,
  labelGenerator,
  productFilter,
}: {
  source: string;
  labelGenerator: (product: Product) => string;
  productFilter?: (product: Product) => boolean;
}) {
  const selectedTrainingIds = useRef<Array<string>>([]);
  const selectedTrainings = useRef<Array<Training>>([]);
  const selectedProductIds = useRef<Array<string>>([]);
  const selectedProducts = useRef<Array<Product>>([]);
  const [bundleProducts, setBundleProducts] = useState<Array<Product>>([]);

  const updateBundleProducts = () => {
    const products: Array<Product> = [];
    for (const training of selectedTrainings.current) {
      for (const product of training.products) {
        if (!products.some((it) => it.id === product.id)) {
          products.push(product);
        }
      }
    }
    for (const product of selectedProducts.current) {
      if (!products.some((it) => it.id === product.id)) {
        products.push(product);
      }
    }
    setBundleProducts(products);
  };

  // Rerender whenever trainingIds or productIds change ...
  const [trainingIds, productIds] = useWatch({ name: ["trainingIds", "productIds"] });
  useEffect(() => {
    Promise.all([
      (async () => {
        if (!haveSameItems(selectedTrainingIds.current, trainingIds)) {
          selectedTrainingIds.current = trainingIds;
          if (trainingIds.length > 0) {
            const trainings = await catalogProvider.getTrainings(trainingIds);
            if (haveSameItems(selectedTrainingIds.current, trainingIds)) {
              selectedTrainings.current = trainings;
              updateBundleProducts();
            }
          } else {
            selectedTrainings.current = [];
            updateBundleProducts();
          }
        }
      })(),
      (async () => {
        if (!haveSameItems(selectedProductIds.current, productIds)) {
          selectedProductIds.current = productIds;
          if (productIds.length > 0) {
            const products = await catalogProvider.getProducts(productIds);
            if (haveSameItems(selectedProductIds.current, productIds)) {
              selectedProducts.current = products;
              updateBundleProducts();
            }
          } else {
            selectedProducts.current = [];
            updateBundleProducts();
          }
        }
      })(),
    ]).catch((error) => console.error(error));
  }, [trainingIds, productIds]);

  return (
    <Stack width="100%">
      {bundleProducts.filter(productFilter || ((_) => true)).map((product) => (
        <PriceInput label={labelGenerator(product)} key={product.id} source={`${source}.${product.id}`} required />
      ))}
    </Stack>
  );
}

function PriceInput({ label, source, required }: { label: string; source: string; required?: boolean }) {
  return (
    <NumberInput
      label={`${label} (in €)`}
      fullWidth={true}
      inputRef={preventValueChangeOnWheel}
      source={source}
      validate={validatePrice(required ?? false)}
    />
  );
}

function haveSameItems<T>(a1: Array<T>, a2: Array<T>): boolean {
  if (a1.length !== a2.length) {
    return false;
  }
  for (const item of a1) {
    if (!a2.includes(item)) {
      return false;
    }
  }
  return true;
}

function isTrainingsOrProductsSpecified(value: any, values: any): string | undefined {
  const trainingsSpecified = Array.isArray(values.trainingIds) && values.trainingIds.length > 0;
  const productsSpecified = Array.isArray(values.productIds) && values.productIds.length > 0;
  if (!(trainingsSpecified || productsSpecified)) {
    return "A Bundle must have at least one training or one product";
  }
}

export const bundlesResource = (
  <Resource
    key="bundles"
    name="bundles"
    icon={BundleMenuIcon}
    list={BundleList}
    create={BundleCreate}
    edit={BundleEdit}
    options={{ label: "Pakete", createTitle: "Neues Paket" }}
    recordRepresentation={(bundle: Bundle) => bundle.name}
  />
);
