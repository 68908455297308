import { TextInput, useNotify, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { useDialog } from "../hooks/useDialog";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import UndoIcon from "@mui/icons-material/Undo";
import { DialogProps } from "../misc/DialogProps";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { reportError } from "../backoffice.utils";
import { LocalizedError } from "../utils/LocalizedError";
import { Invoice, invoicesProvider } from "../providers/invoicesProvider";
import { moneyFormatter } from "../misc/Money";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { FormProvider, useForm } from "react-hook-form";
import { setStudentStatus } from "../api/backoffice.api";
import { useQueryClient } from "react-query";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export const BookOutButton = () => {
  const student = useRecordContext<Student>();
  const notify = useNotify();
  const { dialogProps, openDialog } = useDialog();
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [invoices, setInvoices] = useState<Array<Invoice> | undefined>();

  const loadInvoicesAndOpenDialog = async (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLoadingInvoices(true);
    try {
      const { data: invoices } = await invoicesProvider.getList("b2cInvoices", {
        filter: { studentId: student.id },
        pagination: { page: 1, perPage: 9999 },
        sort: { field: "created_at", order: "ASC" },
      });
      const filteredInvoices = invoices.filter(({ status }) => status === "UNPAID" || status === "PAYMENT_REVOKED");
      if (filteredInvoices.length === 0) {
        notify("Keine Rechnung zum Ausbuchen gefunden.", { type: "error" });
        return;
      }
      setInvoices(filteredInvoices);
      openDialog();
    } catch (error) {
      if (error instanceof LocalizedError) {
        notify(error.message, { type: "error" });
      } else {
        reportError("Failed to retrieve invoices", error);
        notify("Fehler beim Laden der Rechnungen", { type: "error" });
      }
    } finally {
      setLoadingInvoices(false);
    }
  };

  return (
    <>
      <LoadingButton
        sx={{ px: 2 }}
        loading={loadingInvoices}
        startIcon={<UndoIcon />}
        disabled={!student}
        onClick={loadInvoicesAndOpenDialog}
      >
        {"Ausbuchen"}
      </LoadingButton>
      <BookOutDialog {...dialogProps} student={student} invoices={invoices ?? []} />
    </>
  );
};

function BookOutDialog({
  open,
  onClose,
  student,
  invoices,
}: DialogProps & {
  student: Student;
  invoices: Array<Invoice>;
}) {
  const formProps = useForm<{ note: string }>({
    defaultValues: { note: "Offene Posten ausgebucht. Kunde abgebrochen, keine Leistungen in Anspruch genommen." },
  });
  const notify = useNotify();
  const queryClient = useQueryClient();

  const bookOut = async ({ note }: { note: string }) => {
    let success = false;
    try {
      if (invoices.length === 0) {
        throw new Error("Unexpected state: invoices.length === 0");
      }
      const backendClient = await getAuthenticatedBackendClient();
      for (const invoice of invoices) {
        await backendClient.invoice.invoiceRefundCreate({ id: invoice.id });
      }
      await setStudentStatus(student, "cancelled", note); // <-- this will create the note on the student
      success = true;
    } catch (error) {
      reportError("Failed to book out student", error);
      notify(`Fehler beim Ausbuchen von ${student.name}`, { type: "error" });
    }
    if (success) {
      onClose();
      notify(
        `Alle offenen Rechnungen von ${student.name} wurden ausgebucht, der Status auf Abgebrochen geändert und die Notiz erstellt.`,
        { type: "success" },
      );
      await queryClient.invalidateQueries(["candidatesForFirstDunningNotice"]);
      await queryClient.invalidateQueries(["studentNotes"]);
    }
  };

  return (
    <FormProvider {...formProps}>
      <form>
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle>{student.name} ausbuchen</DialogTitle>
          <DialogCloseButton onClick={onClose} disabled={formProps.formState.isSubmitting} />
          <DialogContent>
            <DialogContentText>Folgende offene Rechnungen werden ausgebucht:</DialogContentText>
            <table>
              <tbody>
                {invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td style={{ paddingRight: "1em" }}>
                      <ul>
                        <li>{`Rechnung ${invoice.nr} ${invoice.type}`}</li>
                      </ul>
                    </td>
                    <td align="right">{moneyFormatter.format(invoice.total! / 100)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <TextInput style={{ marginTop: "1em", width: "100%" }} source="note" label="Notiz" multiline rows={3} />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={formProps.formState.isSubmitting}
              variant="contained"
              startIcon={<UndoIcon />}
              disabled={formProps.formState.isSubmitting}
              onClick={formProps.handleSubmit(bookOut)}
              autoFocus
            >
              Jetzt ausbuchen
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
}
