import { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { getPriceSuggestion, GetPriceSuggestionRequestSchema } from "../../api/backoffice.api";
import { Money } from "../../model/Money";
import { moneyFormatter } from "../../misc/Money";
import { ExamFormValues, BookTheoryExamParams, BookPracticalExamParams } from "./ExamForm";
import { Row } from "../../misc/Row";
import { BookDrivingLessonParams, DrivingLessonFormValues } from "./DrivingLessonForm";
import { LinearProgress } from "react-admin";

interface PriceSuggestionProps {
  validate: (params: any) => BookTheoryExamParams | BookPracticalExamParams | BookDrivingLessonParams;
}

export const PriceSuggestion = ({ validate }: PriceSuggestionProps) => {
  const [price, setPrice] = useState<undefined | "loading" | Money>();
  const [authorityFee, setAuthorityFee] = useState<undefined | Money>();
  const { getValues, watch } = useFormContext<ExamFormValues | DrivingLessonFormValues>();
  const priceSuggestionCache = useRef<Record<string, ReturnType<typeof getPriceSuggestion>>>({}).current;
  const formValues = watch();
  const { dateTime, instructorId, student, carId, motorcycleId, trailerId, drivingLicenseClass } = formValues;
  const drivingLessonType = "examType" in formValues ? formValues.examType : formValues.drivingLessonType;
  const theoryExamLocationId = "theoryExamLocationId" in formValues ? formValues.theoryExamLocationId : undefined;
  const practicalExamLocationId =
    "practicalExamLocationId" in formValues ? formValues.practicalExamLocationId : undefined;
  const durationInMinutes = "durationInMinutes" in formValues ? formValues.durationInMinutes : undefined;
  const secondMotorcycleOrCarId =
    "secondMotorcycleOrCarId" in formValues ? formValues.secondMotorcycleOrCarId : undefined;

  useEffect(() => {
    setPrice(undefined);
    setAuthorityFee(undefined);
    let params: undefined | ReturnType<typeof validate>;
    try {
      params = validate(getValues());
    } catch {
      // form is invalid
      return;
    }
    void (async () => {
      try {
        const duration = "duration" in params ? params.duration : undefined;
        const request = GetPriceSuggestionRequestSchema.parse({
          eventType: "DrivingLesson",
          drivingLessonType,
          ...(params.instructorId ? { instructorUid: params.instructorId } : {}),
          studentUid: params.studentId,
          bookedTrainingId: params.bookedTrainingId,
          start: params.dateTime,
          ...(duration ? { end: params.dateTime.plus(duration) } : {}),
        });
        const cacheKey = JSON.stringify(request);
        let promise = priceSuggestionCache[cacheKey];
        if (!promise) {
          setPrice("loading");
          priceSuggestionCache[cacheKey] = promise = getPriceSuggestion(request);
        }
        const priceSuggestion = await promise;
        let priceSuggestionMatchesFormValues = false;
        try {
          const params2 = validate(getValues());
          priceSuggestionMatchesFormValues =
            ("examType" in params && "examType" in params2 ? params.examType === params2.examType : true) &&
            ("duration" in params && "duration" in params2
              ? params.duration?.minutes === params2.duration?.minutes
              : true) &&
            params.instructorId === params2.instructorId &&
            params.studentId === params2.studentId &&
            params.bookedTrainingId === params2.bookedTrainingId &&
            params.dateTime === params2.dateTime;
        } catch {
          // Ignored: form is invalid
        }
        if (priceSuggestionMatchesFormValues) {
          setPrice(priceSuggestion.price);
          setAuthorityFee(priceSuggestion.authorityFee);
        }
      } catch (error) {
        console.error("Failed to get price suggestion", error);
      }
    })();
  }, [
    drivingLessonType,
    dateTime,
    durationInMinutes,
    instructorId,
    student?.id,
    drivingLicenseClass,
    carId,
    motorcycleId,
    trailerId,
    secondMotorcycleOrCarId,
    theoryExamLocationId,
    practicalExamLocationId,
  ]);

  const showPrice = !!price;
  const showAuthorityFee =
    authorityFee && (drivingLessonType === "theoretischePruefung" || drivingLessonType === "praktischePruefung");

  return (
    <Row sx={{ alignItems: "center" }}>
      {/* [UX] Render a non-breaking space if no price is shown to prevent the dialog from jumping ... */}
      {!(showPrice || showAuthorityFee) && <Typography>{"\u00A0"}</Typography>}
      {showPrice && <Typography>{"Preis:\u00A0 "}</Typography>}
      {price === "loading" ? (
        <div>
          <LinearProgress sx={{ width: "4em" }} timeout={0} />
        </div>
      ) : (
        price && <Typography>{moneyFormatter.format(price.amount)}</Typography>
      )}
      {showPrice && showAuthorityFee && <Typography>{"\u00A0+\u00A0"}</Typography>}
      {showAuthorityFee && <Typography>TÜV-/DEKRA-Gebühr: {moneyFormatter.format(authorityFee.amount)}</Typography>}
    </Row>
  );
};
