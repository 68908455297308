import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { LoadingButton } from "@mui/lab";
import { useDeleteEventMutation } from "../../api/backoffice.api";
import { useNotify } from "react-admin";
import { SlideUp } from "../SlideUp";
import { DialogCloseButton } from "../DialogCloseButton";

interface CancelAppointmentDialogProps {
  readonly isOpen: boolean;
  readonly calendarEvent: AutovioCalendarEvent;
  readonly onClose: (appointmentCancelled: boolean) => void;
}
export function CancelAppointmentDialog({ isOpen, calendarEvent, onClose }: CancelAppointmentDialogProps) {
  const deleteEventMutation = useDeleteEventMutation();
  const notify = useNotify();
  const formProps = useForm<{ reason: string }>({ defaultValues: { reason: "" } });

  const cancelAppointment = async () => {
    try {
      await deleteEventMutation.mutateAsync({
        calendarEventUid: calendarEvent.id,
        reason: formProps.getValues().reason,
      });
      notify("Termin erfolgreich abgesagt", { type: "success" });
      onClose(true);
    } catch (error) {
      console.error("Failed to delete event", error);
      notify("Fehler beim Absagen des Termins", { type: "error" });
    }
  };

  return (
    <FormProvider {...formProps}>
      <Dialog open={isOpen} onClose={() => onClose(false)} TransitionComponent={SlideUp}>
        <form onSubmit={formProps.handleSubmit(cancelAppointment)}>
          <DialogTitle>Termin absagen</DialogTitle>
          <DialogCloseButton onClick={() => onClose(false)} />
          <DialogContent>
            <FormControl sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }}>
              <Controller
                name="reason"
                control={formProps.control}
                rules={{
                  validate: (value) => {
                    const effectiveReason = (value ?? "").trim();
                    if (!effectiveReason) {
                      return "Bitte gib den Grund ein.";
                    } else if (effectiveReason.length < 5) {
                      return "Bitte formuliere den Grund etwas aussagekräftiger.";
                    } else {
                      return true;
                    }
                  },
                }}
                render={({ field, formState }) => {
                  const error = formState.errors.reason?.message;
                  return <TextField label="Grund" error={!!error} helperText={error} {...field} autoFocus />;
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={formProps.formState.isSubmitting}
              onClick={formProps.handleSubmit(cancelAppointment)}
            >
              Termin absagen
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}
