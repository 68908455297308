import { Box, Card, CardActionArea, type SxProps } from "@mui/material";

const _cardWidth = 200;
const _cardHeight = 260;
const _cardSpacing = 20;

interface CardsGridProps {
  sx?: SxProps;
  children: Array<React.ReactElement>;
}

interface CardsGridType {
  (props: CardsGridProps): React.ReactElement;

  Card: (props: React.PropsWithChildren<{ onClick?: () => void }>) => React.ReactElement;
}

function _CardsGrid({ sx, children }: CardsGridProps) {
  return (
    <Box
      sx={{
        ...sx,
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${_cardWidth}px, 1fr))`,
        rowGap: `${_cardSpacing}px`,
        columnGap: `${_cardSpacing}px`,
      }}
    >
      {children}
    </Box>
  );
}

function _CardsGridCard({ children, onClick }: React.PropsWithChildren<{ onClick?: () => void }>) {
  return (
    <Card
      sx={{
        width: `${_cardWidth}px`,
        height: `${_cardHeight}px`,
        borderRadius: "12px",
        boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.1);",
        ...(onClick ? { "&:hover": { boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 40px 0px" } } : { padding: "20px" }),
      }}
    >
      {onClick ? (
        <CardActionArea sx={{ padding: "20px", height: "100%" }} onClick={onClick}>
          {children}
        </CardActionArea>
      ) : (
        children
      )}
    </Card>
  );
}

export const CardsGrid: CardsGridType = _CardsGrid as any;
CardsGrid.Card = _CardsGridCard;
