import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tab as MuiTab } from "@mui/material";
import clsx from "clsx";
import { TabProps } from "react-admin";

/**
 * Alternative to the react-admin <Tab> component,
 * which does not wrap the tab content in a <Labeled> component.
 */
export const Tab2 = ({
  children,
  contentClassName,
  context,
  count,
  className,
  divider,
  icon,
  label,
  record,
  syncWithLocation = true,
  value,
  ...rest
}: TabProps) => {
  const location = useLocation();
  const propsForLink = {
    component: Link,
    to: { ...location, pathname: value },
  };

  const renderHeader = () => {
    let tabLabel = label;
    if (count !== undefined) {
      tabLabel = (
        <span>
          {tabLabel} ({count})
        </span>
      );
    }

    return (
      <MuiTab
        key={`tab-header-${value}`}
        label={tabLabel}
        value={value}
        icon={icon}
        className={clsx("show-tab", className)}
        {...(syncWithLocation ? propsForLink : {})} // to avoid TypeScript screams, see https://github.com/mui/material-ui/issues/9106#issuecomment-451270521
        {...rest}
      />
    );
  };

  return context === "header" ? renderHeader() : children;
};

const PREFIX = "RaTab";

export const TabClasses = {
  row: `${PREFIX}-row`,
};
