import { WeeklyHours } from "../model/WeeklyHours";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { GetOneParams, GetOneResult, Identifier, UpdateParams, UpdateResult } from "react-admin";

interface PlaygroundRecord {
  id: Identifier;
  someBoolean: boolean;
  somePriceInCents: number;
  someAvailabilities: WeeklyHours;
}

class PlaygroundProvider extends AbstractDataProvider<PlaygroundRecord> {
  private record: PlaygroundRecord = {
    id: "id",
    someBoolean: false,
    somePriceInCents: 12345,
    someAvailabilities: [
      ["Monday", "9:00", "12:00"],
      ["Monday", "13:00", "18:00"],
      ["Tuesday", "12:30", "19:30"],
    ],
  };

  async getOne(_: string, { id }: GetOneParams<PlaygroundRecord>): Promise<GetOneResult<PlaygroundRecord>> {
    return { data: { ...this.record, id } };
  }

  async update(
    _resource: string,
    { previousData, data }: UpdateParams<PlaygroundRecord>,
  ): Promise<UpdateResult<PlaygroundRecord>> {
    this.record = { ...previousData, ...data };
    return { data: this.record };
  }
}

export const playgroundProvider = new PlaygroundProvider();
