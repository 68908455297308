import { Form, SaveButton, SelectInput, TextInput, useGetOne, useRefresh } from "react-admin";
import { backendUserProvider, BackendUserRecord } from "../providers/backendUserProvider";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDialog } from "../hooks/useDialog";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { grants } from "../backoffice.access_control";

type GiftMoneyFormData = BackendUserRecord & {
  amount: string;
  reason: string;
  hq_contribution_percentage: string;
};

type GiftMoneyButtonProps = {
  drivingSchoolId: string;
  studentId: string;
};

export function GiftMoneyButton({ drivingSchoolId, studentId }: GiftMoneyButtonProps) {
  const { dialogProps, openDialog, closeDialog } = useDialog();
  // set the hq contribution percentage to application fee percentage
  const { data: student, isLoading, error } = useGetOne("students", { id: studentId });
  const refresh = useRefresh();

  const onSubmit = async (data: GiftMoneyFormData) => {
    let hqContributionPercentage = NaN;
    if (data.hq_contribution_percentage === "applicationFeePercentage") {
      hqContributionPercentage = student.applicationFeePercentage;
    } else {
      hqContributionPercentage = parseInt(data.hq_contribution_percentage);
    }
    if (isNaN(hqContributionPercentage) || hqContributionPercentage < 0 || hqContributionPercentage > 100) {
      throw new Error(`Invalid data.hq_contribution_percentage: ${data.hq_contribution_percentage}`);
    }
    const amount = data.amount.replace(",", "."); // <-- allow comma as decimal separator
    await backendUserProvider.giveMoneyToUser(
      data.id + "",
      drivingSchoolId,
      amount,
      "EUR",
      data.reason,
      hqContributionPercentage,
    );
    refresh();
    closeDialog();
  };

  const validateAmount = (value?: string) => {
    value = (value ?? "").replace(",", "."); // <-- allow comma as decimal separator
    if (!value.match(/^[0-9]+(\.[0-9]{2})?$/)) {
      return "Ungültiger Betrag, bitte geben Sie nur eine Zahl ein (kein €-Zeichen)";
    }
    const float = parseFloat(value);
    if (isNaN(float)) {
      return "Ungültiger Betrag";
    }
    if (float <= 0) {
      return "Der Betrag muss größer als 0 sein";
    }
    if (float !== parseFloat(float.toFixed(2))) {
      return "Der Betrag darf maximal 2 Nachkommastellen haben";
    }
    return undefined;
  };

  return (
    <>
      <Button startIcon={<AddIcon />} variant="outlined" onClick={openDialog} disabled={!!(isLoading || error)}>
        Geld verschenken
      </Button>
      <Dialog {...dialogProps}>
        <DialogTitle>Geld verschenken</DialogTitle>
        <DialogCloseButton onClick={closeDialog} />
        <Form
          onSubmit={(data) => onSubmit(data as GiftMoneyFormData)}
          defaultValues={{
            hq_contribution_percentage: grants.includes("giftCredits:listOptions") ? "100" : "0",
          }}
        >
          <DialogContent sx={{ paddingTop: "20px !important" }}>
            <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
              <Alert severity="info" sx={{ mb: 3.5 }}>
                <Typography variant="body2">
                  Verschenktes Guthaben wird mit Fahrstunden und Vorstellungsentgelten für Prüfungen automatisch
                  verrechnet. So reduziert sich zum Beispiel der Rechnungsbetrag für die nächsten Fahrstunden.
                  Verschenktes Guthaben reduziert also den Umsatz. Das geschenkte Guthaben ist für Fahrschüler in der
                  AUTOVIO App sichtbar.
                </Typography>
              </Alert>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextInput
                  source="amount"
                  required
                  validate={validateAmount}
                  label="Betrag in Euro"
                  style={{ width: "80%" }}
                />
                <Typography variant="h5">€</Typography>
              </Box>
              <TextInput source="reason" required label="Grund" />
              {grants.includes("giftCredits:listOptions") && (
                <SelectInput
                  source="hq_contribution_percentage"
                  label="Verteilung Kosten"
                  choices={[100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0]
                    .map((it) => ({
                      id: `${it}`,
                      name: `${it}% Autovio / ${100 - it}% Partner`,
                    }))
                    .concat({
                      id: "applicationFeePercentage",
                      name: "Fair - Anteilig der Kosten",
                    })}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <SaveButton icon={<AddIcon />} label="Jetzt Geld verschenken" />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
