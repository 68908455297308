import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AppliedReferralCode } from "../providers/appliedReferralCodesProvider";
import { useQuery } from "react-query";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { Datagrid, ListContextProvider, TextField } from "react-admin";
import { z } from "zod";
import { listContextFromArray } from "../utils/listContextFromArray";
import { DateField } from "../fields/DateField";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { DialogCloseButton } from "../misc/DialogCloseButton";

interface ReferralCodeApplicationsDialogProps {
  appliedReferralCode: undefined | AppliedReferralCode;
  onClose: () => void;
}

type ReferralCodeApplication = z.infer<typeof schemas.ReferralCodeApplicationDto>;

export function ReferralCodeApplicationsDialog({ appliedReferralCode, onClose }: ReferralCodeApplicationsDialogProps) {
  const { data } = useQuery({
    enabled: !!appliedReferralCode,
    queryKey: ["referralCodeApplications", appliedReferralCode?.id],
    queryFn: async () => {
      const serverClient = await getAuthenticatedServerClient();
      return serverClient.listReferralCodeApplications({ queries: { code: appliedReferralCode!.id } });
    },
  });

  return (
    <Dialog open={!!appliedReferralCode} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Anmeldungen mit dem Empfehlungscode {appliedReferralCode?.code}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <ListContextProvider value={listContextFromArray(data ?? [])}>
          <Datagrid
            bulkActionButtons={false}
            rowClick={(_, __, record) => `/students/${(record as any as ReferralCodeApplication).appliedBy.id}`}
            empty={
              <div style={{ margin: "20px", display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            }
          >
            <TextField label="Fahrschüler" source="appliedBy.name" sortable={false} />
            <DateField label="angemeldet am" source="appliedAt" showDate="with diff" sortable={false} />
            <div style={{ display: "flex", justifyContent: "end" }}>
              <ShowIconButton />
            </div>
          </Datagrid>
        </ListContextProvider>
      </DialogContent>
    </Dialog>
  );
}
