import { AbstractDataProvider } from "./AbstractDataProvider";
import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { DateTime } from "luxon";
import { Money } from "../model/Money";
import { ZodiosPlugin } from "@zodios/core";
import { z } from "zod";

type PromoCodeDto = z.infer<typeof schemas.PromoCodeDto>;
type CreatePromoCodeDto = z.infer<typeof schemas.CreatePromoCodeDto>;
type UpdatePromoCodeDto = z.infer<typeof schemas.UpdatePromoCodeDto>;

export interface PromoCode {
  id: string;
  code: string;
  description: string;
  drivingSchoolId?: string | null;
  isEnabled: boolean;
  startCredits: Money;
  start: DateTime;
  /** in ISO format: "yyyy-MM-dd" */
  startDate: string;
  end: DateTime;
  /** in ISO format: "yyyy-MM-dd" */
  endDate: string;
  autovioShare: number;
  maxUses: number;
  numUsed: number;
  createdAt: DateTime;
  createdById: string;
  updatedAt: DateTime;
  updatedById: string;
}

class PromoCodesProvider extends AbstractDataProvider<PromoCode> {
  async getList(_: string, { filter, sort, pagination }: GetListParams): Promise<GetListResult<PromoCode>> {
    const serverClient = await getAuthenticatedServerClient();
    let total: undefined | number;
    const extractTotalFromContentRangeHeader: ZodiosPlugin = {
      response: async (_api, _config, response) => {
        try {
          const contentRangeHeader = response.headers["content-range"];
          if (contentRangeHeader) {
            total = parseInt(contentRangeHeader.substring(contentRangeHeader.lastIndexOf("/") + 1));
          }
        } catch (error) {
          console.warn("Failed to extract total number of promo codes from Content-Range header", error);
        }
        return response;
      },
    };
    serverClient.use(extractTotalFromContentRangeHeader);
    const promoCodes = await serverClient.listPromoCodes({
      queries: {
        filter: JSON.stringify(filter),
        sort: JSON.stringify([sort.field, sort.order]),
        range: JSON.stringify([(pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage - 1]),
      },
    });
    return { data: promoCodes.map(_fromDto), total };
  }

  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<PromoCode>> {
    const serverClient = await getAuthenticatedServerClient();
    return { data: _fromDto(await serverClient.getPromoCode({ params: { id } })) };
  }

  async create(_: string, { data }: CreateParams<PromoCode>): Promise<CreateResult<PromoCode>> {
    const serverClient = await getAuthenticatedServerClient();
    return {
      data: _fromDto(await serverClient.createPromoCode(_toCreateDto(data))),
    };
  }

  async update(_: string, { id, data }: UpdateParams<PromoCode>): Promise<UpdateResult<PromoCode>> {
    const serverClient = await getAuthenticatedServerClient();
    return {
      data: _fromDto(await serverClient.updatePromoCode(_toUpdateDto(data), { params: { id } })),
    };
  }
}

export const promoCodesProvider = new PromoCodesProvider();

function _fromDto(dto: PromoCodeDto): PromoCode {
  const { startCredits, start: start_, end: end_, autovioShare, createdAt, updatedAt, ...rest } = dto;
  const start = DateTime.fromISO(start_!);
  const end = DateTime.fromISO(end_!);
  return {
    ...rest,
    startCredits: { amount: parseFloat(startCredits), currency: "EUR" },
    start,
    startDate: start.toISODate(),
    end,
    endDate: end.toISODate(),
    autovioShare: parseFloat(autovioShare),
    createdAt: DateTime.fromISO(createdAt!),
    updatedAt: DateTime.fromISO(updatedAt!),
  };
}

function _toCreateDto(data: Partial<PromoCode>): CreatePromoCodeDto {
  const { code, description, drivingSchoolId, isEnabled, autovioShare, startCredits, startDate, endDate, maxUses } =
    data;
  if (typeof code !== "string") throw new Error("Property code must be provided.");
  if (!code) throw new Error("Property code must not be empty.");
  if (typeof isEnabled !== "boolean") throw new Error("Property isEnabled must be provided.");
  if (!startCredits) throw new Error("Property startCredits must be provided.");
  if (!startDate) throw new Error("Property startDate must be provided.");
  if (!endDate) throw new Error("Property endDate must be provided.");
  if (typeof maxUses !== "number") throw new Error("Property maxUses must be provided.");
  if (maxUses <= 0) throw new Error("Property maxUses must be > 0.");
  if (typeof autovioShare !== "number") throw new Error("Property autovioShare must be provided.");
  return {
    code,
    description,
    ...(drivingSchoolId && { drivingSchoolId }),
    isEnabled,
    start: DateTime.fromISO(startDate, { zone: "Europe/Berlin" }).toISO(),
    end: DateTime.fromISO(endDate, { zone: "Europe/Berlin" }).endOf("day").toISO(),
    startCredits: startCredits.amount.toFixed(2),
    maxUses,
    autovioShare: autovioShare.toFixed(2),
  };
}

function _toUpdateDto(data: Partial<PromoCode>): UpdatePromoCodeDto {
  const { code, description, drivingSchoolId, isEnabled, autovioShare, startCredits, startDate, endDate, maxUses } =
    data;
  return Object.fromEntries(
    Object.entries({
      code,
      description,
      drivingSchoolId,
      isEnabled,
      start: startDate && DateTime.fromISO(startDate, { zone: "Europe/Berlin" }).toISO(),
      end: endDate && DateTime.fromISO(endDate, { zone: "Europe/Berlin" }).endOf("day").toISO(),
      startCredits: startCredits && startCredits.amount.toFixed(2),
      maxUses,
      autovioShare: autovioShare && autovioShare.toFixed(2),
    }).filter(([_, v]) => v !== undefined && v !== null),
  );
}
