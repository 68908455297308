import { LoadingButton } from "@mui/lab";
import BlockIcon from "@mui/icons-material/Block";
import { useNotify, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { useDialog } from "../hooks/useDialog";
import { useState } from "react";
import type { EmailDraft } from "../generated/backendClient";
import { useMutation } from "react-query";
import { draftDunningNotice } from "../api/backend.api";
import { LocalizedError } from "../utils/LocalizedError";
import { reportError } from "../backoffice.utils";
import { SendDunningNoticeDialog, SendDunningNoticeFormValues } from "../dialogs/SendDunningNoticeDialog";
import { serverAPI } from "../api/server.api";

export function BlockStudentAndSendPaymentReminderButton() {
  const student = useRecordContext<Student>();
  const notify = useNotify();
  const { dialogProps, openDialog, closeDialog } = useDialog();
  const [draftingEmail, setDraftingEmail] = useState(false);
  const [dunningProcessId, setDunningProcessId] = useState<string>();
  const [draft, setDraft] = useState<EmailDraft | undefined>();

  const draftEmailAndOpenDialog = async (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDraftingEmail(true);
    try {
      const { dunningProcess, draft } = await draftDunningNotice(student, "student_blocked");
      setDunningProcessId(dunningProcess.id);
      setDraft(draft);
      openDialog();
    } catch (error) {
      if (error instanceof LocalizedError) {
        notify(error.message, { type: "error" });
      } else {
        reportError("Failed to draft payment reminder", error);
        notify(
          "Oje, es ist ein Fehler beim Vorbereiten der Zahlungserinnerung-E-Mail aufgetreten. Bitte wende Dich an die Technik.",
          { type: "error" },
        );
      }
    } finally {
      setDraftingEmail(false);
    }
  };

  const { mutateAsync: blockStudentAndSendPaymentReminder } = useMutation(
    async ({ emailSubject, emailText }: SendDunningNoticeFormValues) => {
      if (!dunningProcessId) {
        throw new Error("dunningProcessId not set");
      }
      await serverAPI.sendDunningNotice({
        student,
        dunningProcessId,
        noticeType: "student_blocked",
        emailSubject,
        emailText,
      });
    },
    {
      onSuccess: () => {
        notify("Zahlungserinnerung-E-Mail erfolgreich versandt, Fahrschüler wurde geblockt.", { type: "success" });
        closeDialog();
      },
      onError: (error) => {
        reportError("Failed to send warn email", error);
        notify("Fehler beim Versenden der Zahlungserinnerung-E-Mail", { type: "error" });
      },
    },
  );

  return (
    <>
      <LoadingButton
        variant="outlined"
        loading={draftingEmail}
        startIcon={<BlockIcon />}
        onClick={draftEmailAndOpenDialog}
      >
        Blockieren und Zahlungserinnerung senden
      </LoadingButton>
      <SendDunningNoticeDialog
        title="Zahlungserinnerung"
        buttonLabel="Senden und Fahrschüler blockieren"
        {...dialogProps}
        initialFormValues={{
          emailSubject: draft?.subject ?? "",
          emailText: draft?.text ?? "",
        }}
        onSubmit={blockStudentAndSendPaymentReminder}
      />
    </>
  );
}
