import { and, collection, getDocs, query, Timestamp, where } from "firebase/firestore";
import { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { DrivingLesson } from "../model/autovioCalendarEvents";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { drivingLessonConverter } from "./converter/drivingLessonConverter";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { QueryFilterConstraint } from "@firebase/firestore";
import { Interval } from "luxon";

class DrivingLessonsProvider extends AbstractDataProvider<DrivingLesson> {
  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<DrivingLesson>> {
    if (target !== "drivingSchoolId") {
      throw new Error(`Invalid target: ${JSON.stringify(target)} -- expected: "drivingSchoolId"`);
    }
    const { dateRange, instructorId, ...otherFilters } = filter;
    if (!dateRange) {
      throw new Error("filter.dateRange is required");
    } else if (!Interval.isInterval(dateRange)) {
      throw new Error(`Invalid filter.dateRange: ${JSON.stringify(dateRange)} -- must be an Interval`);
    }
    const queryFilterConstraints: Array<QueryFilterConstraint> = [
      where("drivingSchoolUid", "==", id),
      where("type", "==", "DrivingLesson"),
      where("drivingLessonType", "in", ["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt"]),
      where("start", ">=", Timestamp.fromDate(dateRange.start.toJSDate())),
      where("start", "<=", Timestamp.fromDate(dateRange.end.toJSDate())),
      where("deleted", "==", false),
    ];
    if (instructorId) {
      if (instructorId && typeof instructorId !== "string") {
        throw new Error(`Invalid filter.instructorId: ${JSON.stringify(instructorId)} -- must be a string`);
      }
      queryFilterConstraints.push(where("derived.instructorUids", "array-contains", instructorId));
    }
    const snapshot = await getDocs(query(collection(firestore, "calendar_events"), and(...queryFilterConstraints)));
    const drivingLessonsFromServer = snapshot.docs.map(drivingLessonConverter.fromFirestore);
    const drivingLessons = applyFilter(drivingLessonsFromServer, otherFilters);
    console.info(`Retrieved ${drivingLessons.length} driving lesson(s)`, { [target]: id, ...filter });
    if (drivingLessons.length < drivingLessonsFromServer.length) {
      console.info(`Over-Fetched ${drivingLessonsFromServer.length - drivingLessons.length} driving lesson(s)`);
    }
    return applyPagination(applySort(drivingLessons, sort), pagination);
  }
}

export const drivingLessonsProvider = new DrivingLessonsProvider();
