import { type ReactNode } from "react";
import { SaveButton, useNotify } from "react-admin";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { Student } from "../../providers/studentsProvider";
import { StartingPointInput } from "../../inputs/StartingPointInput";
import { Instructor } from "../../providers/instructorsProvider";
import { AppointmentDurationInput } from "../../inputs/AppointmentDurationInput";
import { DrivingLicenseClassInput } from "../../inputs/DrivingLicenseClassInput";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { StudentInputField } from "./StudentInputField";
import { VehiclesInput, OWN_MOTORCYCLE_PSEUDO_ID } from "../../inputs/VehiclesInput";
import { DrivingLicenseClass, isForMotorcycle, isForTrailer } from "../../model/DrivingLicenseClass";
import { instructorRecommendationSettingsProvider } from "../../providers/instructorRecommendationSettingsProvider";
import { bookDrivingLesson } from "../../api/backoffice.api";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { DrivingLessonTypeInput } from "../../inputs/DrivingLessonTypeInput";
import { startingPointsProvider } from "../../providers/startingPointsProvider";
import { StudentSuggestions } from "./StudentSuggestions";
import { PriceSuggestion } from "./PriceSuggestion";
import { DrivingLessonRepetitionsField } from "./DrivingLessonRepetitionsField";
import { BookDrivingLessonWithoutInviteButton } from "../../buttons/BookDrivingLessonWithoutInviteButton";
import { grants } from "../../backoffice.access_control";
import { Vehicle } from "../../providers/resourceProviders";
import { Form2Theme } from "../../misc/Form2";
import { HiddenInput } from "../../inputs/HiddenInput";
import { branchesProvider } from "../../providers/branchesProvider";
import { serverAPI } from "../../api/server.api";
import { v4 as randomUuid } from "uuid";

interface DrivingLessonFormProps {
  drivingSchoolId: string;
  instructor?: Instructor;
  vehicle?: Vehicle;
  initialDateTime?: DateTime;
  onSuccess: () => Promise<void>;
  onFailure: (error: unknown, student?: Student) => Promise<void>;
}

export interface DrivingLessonFormValues {
  drivingLessonType: "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt" | "simulator";
  dateTime?: DateTime;
  durationInMinutes: number;
  startingPointId: undefined;
  instructorId?: string;
  student?: Student;
  drivingLicenseClass?: DrivingLicenseClass;
  carId?: string;
  motorcycleId?: string;
  trailerId?: string;
  simulatorId?: string;
  secondMotorcycleOrCarId?: string;
  repetitions: DateTime[];
}

export interface BookDrivingLessonParams {
  dateTime: DateTime;
  drivingLessonType: "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt" | "simulator";
  /** required unless drivingLessonType === "simulator" */
  instructorId?: string;
  studentId: string;
  bookedTrainingId: string;
  duration: { minutes: number };
  startingPointId: string;
  resourceIds: Array<string>;
  repetitions: DateTime[];
}

export const DrivingLessonForm = ({
  drivingSchoolId,
  instructor,
  vehicle,
  initialDateTime,
  onSuccess,
  onFailure,
}: DrivingLessonFormProps) => {
  const notify = useNotify();

  const defaultValues = {
    drivingLessonType: vehicle?.type === "simulator" ? "simulator" : "normal",
    dateTime: initialDateTime,
    durationInMinutes: vehicle?.type === "simulator" ? 45 : 0,
    instructorId: instructor?.id,
    student: undefined,
    carId: vehicle?.type === "car" ? vehicle.id : undefined,
    motorcycleId: vehicle?.type === "motorcycle" ? vehicle.id : undefined,
    trailerId: vehicle?.type === "trailer" ? vehicle.id : undefined,
    simulatorId: vehicle?.type === "simulator" ? vehicle.id : undefined,
    secondMotorcycleOrCarId: undefined,
    drivingLicenseClass: undefined,
    startingPointId: vehicle?.type === "simulator" ? vehicle.simulator.branchId : undefined,
    repetitions: [],
  } satisfies DrivingLessonFormValues;

  const formProps = useForm<DrivingLessonFormValues>({
    defaultValues: async () => {
      let durationInMinutes = defaultValues.durationInMinutes;
      if (instructor) {
        const { data } = await instructorRecommendationSettingsProvider.getOne("", { id: instructor.id });
        if (data) {
          durationInMinutes = data.preferredDurationMinsPerDrivingLessonType.normal[0];
        }
      }
      return { ...defaultValues, durationInMinutes };
    },
  });

  function validate(formValues: DrivingLessonFormValues): BookDrivingLessonParams {
    const {
      drivingLessonType,
      dateTime,
      durationInMinutes,
      instructorId,
      student,
      carId,
      motorcycleId,
      trailerId,
      simulatorId,
      secondMotorcycleOrCarId,
      drivingLicenseClass,
      startingPointId,
    } = formValues;
    if (!dateTime) {
      throw new Error(`Invalid dateTime: ${dateTime}`);
    }
    if (!drivingLessonType) {
      throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
    }
    if (!student) {
      throw new Error(`Invalid student: ${student}`);
    }
    if (!instructorId && vehicle?.type !== "simulator") {
      throw new Error(`Invalid instructorId: ${instructorId}`);
    }
    if (!drivingLicenseClass) {
      throw new Error(`Invalid drivingLicenseClass: ${drivingLicenseClass}`);
    }
    let resourceIds: Array<string>;
    if (drivingLessonType === "simulator") {
      if (!simulatorId) {
        throw new Error(`Invalid simulatorId: ${simulatorId}`);
      }
      resourceIds = [simulatorId];
    } else if (isForMotorcycle(drivingLicenseClass)) {
      if (!motorcycleId) {
        throw new Error(`Invalid motorcycleId: ${motorcycleId}`);
      }
      const studentUsesOwnMotorcycle = motorcycleId === OWN_MOTORCYCLE_PSEUDO_ID;
      if (!secondMotorcycleOrCarId) {
        throw new Error(`Invalid secondMotorcycleOrCarId: ${secondMotorcycleOrCarId}`);
      }
      if (secondMotorcycleOrCarId === motorcycleId) {
        throw new Error(`secondMotorcycleOrCarId must not be equal to motorcycleId`);
      }
      resourceIds = studentUsesOwnMotorcycle ? [secondMotorcycleOrCarId] : [motorcycleId, secondMotorcycleOrCarId];
    } else if (isForTrailer(drivingLicenseClass)) {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      if (!trailerId) {
        throw new Error(`Invalid trailerId: ${motorcycleId}`);
      }
      resourceIds = [carId, trailerId];
    } else {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      resourceIds = [carId];
    }
    const bookedTraining = student.bookedTrainings.find((it) => it.drivingLicenseClass === drivingLicenseClass);
    if (!bookedTraining) {
      throw new Error(`Student ${student.id} has no booked training for driving license class ${drivingLicenseClass}`);
    }
    if (!["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt", "simulator"].includes(drivingLessonType)) {
      throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
    }
    if (!startingPointId) {
      throw new Error(`Invalid startingPointId: ${startingPointId}`);
    }
    if (!durationInMinutes) {
      throw new Error(`Invalid durationInMinutes: ${durationInMinutes}`);
    }
    if (formValues.repetitions.some((date) => date.hasSame(dateTime, "day"))) {
      throw new Error("Invalid lesson repetition conflicting with main lesson time");
    }
    const repetitions = formValues.repetitions.map((date) =>
      date.startOf("day").set({ hour: dateTime.hour, minute: dateTime.minute }),
    );

    return {
      drivingLessonType,
      instructorId,
      studentId: student.id,
      bookedTrainingId: bookedTraining.id,
      dateTime,
      duration: { minutes: durationInMinutes },
      startingPointId,
      resourceIds,
      repetitions,
    } satisfies BookDrivingLessonParams;
  }

  const onSubmit =
    ({ studentRsvp }: { studentRsvp?: "pending" | "accepted" } = {}) =>
    async (formValues: DrivingLessonFormValues) => {
      try {
        const {
          dateTime,
          drivingLessonType,
          instructorId,
          studentId,
          bookedTrainingId,
          duration,
          startingPointId,
          resourceIds,
          repetitions,
        } = validate(formValues);
        studentRsvp = studentRsvp ?? (dateTime < DateTime.now() ? "accepted" : "pending");
        if (drivingLessonType === "simulator") {
          const { data: branch } = await branchesProvider.getOne("branches", { id: startingPointId });
          await serverAPI.createCalendarEvent({
            id: randomUuid(),
            type: "DrivingLesson",
            drivingLessonType,
            start: dateTime.toISO(),
            end: dateTime.plus(duration).toISO(),
            student: { id: studentId, bookedTrainingId, rsvp: studentRsvp },
            resourceIds,
            startLocation: branch.address,
          });
        } else if (instructorId) {
          const { data: startingPoint } = await startingPointsProvider.getOne("ignored", { id: startingPointId });
          const location = startingPoint.postalAddress;
          await bookDrivingLesson({
            drivingLessonType,
            instructorId,
            studentId,
            bookedTrainingId,
            studentRsvp,
            dateTime,
            duration,
            location,
            resourceIds,
            repetitions,
          });
        } else {
          throw new Error(`Invalid instructorId: ${instructorId}`);
        }
        notify(studentRsvp === "pending" ? "Einladung erfolgreich versandt" : "Fahrstunde erfolgreich eingetragen", {
          type: "success",
        });
        await onSuccess();
      } catch (error) {
        await onFailure(error, formValues.student);
      }
    };

  const { student, dateTime, drivingLessonType, drivingLicenseClass } = formProps.watch();

  return (
    <Form2Theme>
      <FormProvider {...formProps}>
        <form onSubmit={formProps.handleSubmit(onSubmit())} style={{ height: "100%" }}>
          <Column height="100%" minHeight={500} gap="1em">
            <WhenFormIsInitialized>
              <DateTimeInput source="dateTime" size="small" allowPastDates />
              <AppointmentDurationInput
                source="durationInMinutes"
                options={vehicle?.type === "simulator" ? [45, 90] : undefined}
                size="small"
              />
              {vehicle?.type === "simulator" ? (
                <HiddenInput source="drivingLessonType" value="simulator" />
              ) : (
                <DrivingLessonTypeInput source="drivingLessonType" options={_drivingLessonTypeOptions} size="small" />
              )}
              {!instructor && vehicle?.type === "simulator" && (
                <StudentInputField
                  drivingSchoolId={drivingSchoolId}
                  filterStudents={(student) =>
                    student.bookedTrainings.some((it) => !it.isFinished && it.includesSimulatorDrivingLessons)
                  }
                  size="small"
                />
              )}
              {instructor && <StudentInputField instructorId={instructor.id} size="small" />}
              {!student && vehicle?.type !== "simulator" && <StudentSuggestions />}
              {student && (
                <DrivingLicenseClassInput
                  student={student}
                  source="drivingLicenseClass"
                  label="Ausbildung für Führerscheinklasse"
                  size="small"
                />
              )}
              {instructor && student && (
                <VehiclesInput
                  student={student}
                  instructor={instructor}
                  drivingLessonType={drivingLessonType}
                  drivingLicenseClass={drivingLicenseClass ?? ""}
                  size="small"
                />
              )}
              {instructor && student && vehicle?.type !== "simulator" && (
                <StartingPointInput source="startingPointId" instructor={instructor} student={student} size="small" />
              )}
              {vehicle?.type !== "simulator" && <DrivingLessonRepetitionsField />}
              <PriceSuggestion validate={validate} />
              <Row sx={{ justifyContent: "flex-end", mt: "auto", gap: 1 }}>
                {grants.includes("DrivingLesson:bookWithoutInvite") && (
                  <BookDrivingLessonWithoutInviteButton
                    onSubmit={formProps.handleSubmit(onSubmit({ studentRsvp: "accepted" }))}
                  />
                )}
                <SaveButton
                  icon={<></>}
                  label={dateTime && dateTime < DateTime.now() ? "Termin eintragen" : "Einladung senden"}
                />
              </Row>
            </WhenFormIsInitialized>
          </Column>
        </form>
      </FormProvider>
    </Form2Theme>
  );
};

const _drivingLessonTypeOptions: Array<[string, string]> = [
  ["normal", "normale Übungsstunde"],
  ["autobahnfahrt", "Autobahnfahrt"],
  ["nachtfahrt", "Nachtfahrt"],
  ["ueberlandfahrt", "Überlandfahrt"],
];

function WhenFormIsInitialized({ children }: { children: ReactNode }) {
  const { formState } = useFormContext();
  return formState.isLoading ? null : children;
}
