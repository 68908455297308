import EditIcon from "@mui/icons-material/EditOutlined";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { EditBase, Form, SaveButton, TextInput, useNotify, useRecordContext, useRefresh, useUpdate } from "react-admin";
import { reportError } from "../../backoffice.utils";
import { useDialog } from "../../hooks/useDialog";
import { PostalAddress, PostalAddressSchema } from "../../model/PostalAddress";
import { Student } from "../../providers/studentsProvider";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";

export function EditPostalAddressButton() {
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <IconButton onClick={openDialog}>
        <EditIcon />
      </IconButton>
      <EditPostalAddressDialog {...dialogProps} />
    </>
  );
}

interface EditPostalAddressDialogProps {
  open: boolean;
  onClose: () => void;
}

function EditPostalAddressDialog({ open, onClose }: EditPostalAddressDialogProps) {
  const student = useRecordContext<Student>();
  const [update] = useUpdate<Student>();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (postalAddress: PostalAddress) => {
    try {
      if (!student) {
        throw new Error("Invalid state: !student");
      }
      await update("students", {
        id: student.id,
        data: { postalAddress: PostalAddressSchema.parse(postalAddress) },
        previousData: student,
      });
      refresh();
      notify(`Adresse von ${student.name} erfolgreich geändert.`, { type: "success" });
    } catch (error) {
      reportError(`Failed to change address of student ${student?.id}`, error);
      notify(`Fehler beim Ändern der Adresse von ${student?.name ?? "dem Schüler"}.`, { type: "error" });
    } finally {
      onClose();
    }
  };

  const validate = (data: { [field: string]: any }) => {
    const { postalAddress } = data;
    const errors: { [field: string]: string } = {};
    const cannotBeEmptyMessage = "Darf nicht leer sein.";
    if (!postalAddress.street) {
      errors["postalAddress.street"] = cannotBeEmptyMessage;
    }
    if (!postalAddress.postalCode) {
      errors["postalAddress.postalCode"] = cannotBeEmptyMessage;
    }
    if (!postalAddress.city) {
      errors["postalAddress.city"] = cannotBeEmptyMessage;
    }
    return errors;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Adresse von ${student?.name ?? "..."}`}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <EditBase>
        <Form onSubmit={(data) => onSubmit(data.postalAddress)} validate={validate} warnWhenUnsavedChanges>
          <DialogContent>
            <Column>
              <TextInput source="postalAddress.street" label="Straße" />
              <Row gap={1}>
                <TextInput
                  className="plz-input"
                  source="postalAddress.postalCode"
                  label="Postleitzahl"
                  sx={{ width: "30%" }}
                />
                <TextInput source="postalAddress.city" label="Stadt" sx={{ width: "70%" }} />
              </Row>
            </Column>
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </EditBase>
    </Dialog>
  );
}
