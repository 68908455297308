import { z } from "zod";
import { PostalAddressSchema } from "./PostalAddress";

export const InvoiceAddressSchema = PostalAddressSchema.extend({
  type: z.literal("InvoiceAddress").default("InvoiceAddress"),
  firstName: z.string(),
  lastName: z.string(),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  company: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export type InvoiceAddress = z.infer<typeof InvoiceAddressSchema>;
