import { useEffect, useRef, useState } from "react";
import { required, TextInput, useNotify, email } from "react-admin";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { type BorderUserConfig, table as renderTextTable, type TableUserConfig } from "table";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SendIcon from "@mui/icons-material/Send";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { OpenInvoice } from "../providers/invoicesProvider";
import { DialogProps } from "../misc/DialogProps";
import { LocalizedError } from "../utils/LocalizedError";
import { Form2Theme } from "../misc/Form2";
import { Column } from "../misc/Column";
import { Student } from "../providers/studentsProvider";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { formatDate } from "../utils/calendar";
import { formatMoney } from "../misc/Money";
import { centsToMoney } from "../model/Money";
import { autovioColors } from "../misc/backofficeTheme";
import { serverAPI } from "../api/server.api";
import { areSetsEqual, safeErrorToString } from "../backoffice.utils";

export function EmailSammelquittungDialog(
  props: DialogProps & {
    student: Student;
    openInvoices: Array<OpenInvoice>;
    drivingSchool: DrivingSchool;
    iban: string;
  },
) {
  const { student, openInvoices, drivingSchool, iban, ...dialogProps } = props;
  const { onClose: closeDialog } = dialogProps;
  const [page, setPage] = useState<"invoices" | "email">("invoices");
  const notify = useNotify();
  const formProps = useForm<_FormValues>({
    defaultValues: {
      invoices: Object.fromEntries(openInvoices.map((it) => [it.id, true])),
      emailRecipient: student.invoiceAddress?.email ?? "",
      emailSubject: `Offene Rechnung(en) für die Fahrausbildung von ${student.name}`,
      emailText: renderEmailText(student, openInvoices, drivingSchool, iban),
    },
  });

  async function sendEmail(formValues: _FormValues) {
    let success = true;
    try {
      const { invoices: selection, emailRecipient, emailSubject, emailText } = formValues;
      await serverAPI.sendSammelquittungEmail({
        student,
        emailRecipient,
        emailSubject,
        emailText,
        emailHtml: emailTextToEmailHtml(emailText),
        invoices: openInvoices.filter((it) => selection[it.id]),
      });
    } catch (error) {
      success = false;
      console.error("Failed to send email", error);
      notify(error instanceof LocalizedError ? error.message : "Fehler beim Versenden der E-Mail Sammelquittung", {
        type: "error",
      });
    } finally {
      if (success) {
        closeDialog();
        notify("E-Mail Sammelquittung erfolgreich gesendet.", { type: "success" });
      }
    }
  }

  if (!drivingSchool) {
    return null;
  }

  return (
    <FormProvider {...formProps}>
      <Dialog {...dialogProps} maxWidth="md" fullWidth>
        <DialogTitle>E-Mail Sammelquittung (Schritt {page === "invoices" ? 1 : 2}/2)</DialogTitle>
        <DialogCloseButton onClick={closeDialog} disabled={formProps.formState.isSubmitting} />
        <form>
          <DialogContent>
            <Form2Theme>
              {page === "invoices" && drivingSchool && (
                <_InvoicesForm
                  student={student}
                  openInvoices={openInvoices}
                  drivingSchool={drivingSchool}
                  iban={iban}
                />
              )}
              {page === "email" && <_EmailForm />}
            </Form2Theme>
          </DialogContent>
          <DialogActions>
            {page === "invoices" && (
              <Button
                endIcon={<ChevronRightIcon />}
                variant="contained"
                disabled={!!formProps.getFieldState("invoices").error}
                onClick={() => setPage("email")}
              >
                Weiter
              </Button>
            )}
            {page === "email" && (
              <Button startIcon={<ChevronLeftIcon />} variant="outlined" onClick={() => setPage("invoices")}>
                Zurück
              </Button>
            )}
            {page === "email" && (
              <LoadingButton
                startIcon={<SendIcon />}
                loading={formProps.formState.isSubmitting}
                variant="contained"
                disabled={formProps.formState.isSubmitting}
                onClick={formProps.handleSubmit(sendEmail)}
              >
                Senden
              </LoadingButton>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}

interface _FormValues {
  invoices: { [id: string]: boolean };
  emailRecipient: string;
  emailSubject: string;
  emailText: string;
}

function _InvoicesForm(props: {
  student: Student;
  openInvoices: Array<OpenInvoice>;
  drivingSchool: DrivingSchool;
  iban: string;
}) {
  const { student, openInvoices, drivingSchool, iban } = props;
  const { clearErrors, formState, setError, setValue, watch } = useFormContext<_FormValues>();
  const lastSelectionRef = useRef<Set<string>>(new Set());
  useEffect(() => {
    const { unsubscribe } = watch((formValues) => {
      const { invoices: selection } = formValues;
      if (!selection) {
        return;
      }
      const invoices = openInvoices.filter((it) => selection[it.id]);
      // Prevent an infinite loop, because we call setValue(...) below, which will trigger the watch callback again ...
      const selectedIds = new Set(invoices.map((it) => it.id));
      if (areSetsEqual(lastSelectionRef.current, selectedIds)) {
        return;
      }
      lastSelectionRef.current = selectedIds;
      if (invoices.length === 0) {
        setError("invoices", { message: "Bitte wähle mindestens eine Rechnung aus." });
        setValue("emailText", "");
      } else {
        clearErrors();
        const emailText = renderEmailText(student, invoices, drivingSchool, iban);
        setValue("emailText", emailText);
      }
    });
    return unsubscribe;
  }, [watch]);
  const validationErrorMessage = formState.errors.invoices?.message as undefined | string;
  return (
    <FormControl component="fieldset" error={!!validationErrorMessage}>
      <FormLabel component="legend">Rechnungen</FormLabel>
      <FormGroup>
        {openInvoices.map((invoice) => (
          <FormControlLabel
            key={invoice.id}
            control={
              <Controller
                name={`invoices.${invoice.id}`}
                render={({ field: props }) => (
                  <Checkbox {...props} checked={props.value} onChange={(e) => props.onChange(e.target.checked)} />
                )}
              />
            }
            label={`${invoice.nr} vom ${formatDate(invoice.createdAt)} (${formatMoney(centsToMoney(invoice.payment_amount))} ${invoice.type})`}
          />
        ))}
      </FormGroup>
      <FormHelperText>{validationErrorMessage}</FormHelperText>
    </FormControl>
  );
}

function _EmailForm() {
  return (
    <Column>
      <TextInput label="Empfänger" source="emailRecipient" validate={[required(), email()]} />
      <TextInput label="Betreff" source="emailSubject" validate={required()} />
      <TextInput
        source="emailText"
        label="Text"
        sx={{
          "& textarea": {
            fontFamily: "monospace",
          },
        }}
        multiline
        validate={required()}
      />
    </Column>
  );
}

const _tableConfig: TableUserConfig & {
  columns: Array<{ alignment: "left" | "right" }>;
  border: Defined<BorderUserConfig, "topLeft" | "bodyLeft" | "bodyJoin" | "bodyRight" | "bottomRight">;
} = {
  columns: [{ alignment: "left" }, { alignment: "left" }, { alignment: "left" }, { alignment: "right" }],
  border: {
    topBody: `─`,
    topJoin: `┬`,
    topLeft: `┌`,
    topRight: `┐`,
    bottomBody: `─`,
    bottomJoin: `┴`,
    bottomLeft: `└`,
    bottomRight: `┘`,
    bodyLeft: `│`,
    bodyRight: `│`,
    bodyJoin: `│`,
    joinBody: `─`,
    joinLeft: `├`,
    joinRight: `┤`,
    joinJoin: `┼`,
  },
};

/** Visible for testing. */
export function renderEmailText(
  student: Student,
  invoices: Array<OpenInvoice>,
  drivingSchool: DrivingSchool,
  iban: string,
): string {
  const tableData = [
    ["Datum", "Rechnungs-Nr.", "Leistung", "offener Betrag"],
    ...invoices.map((it) => [formatDate(it.createdAt), it.nr, it.type, formatMoney(centsToMoney(it.payment_amount))]),
  ];
  try {
    const textTable = renderTextTable(tableData, _tableConfig).trim();
    const sum = formatMoney(centsToMoney(invoices.reduce((sum, it) => sum + it.payment_amount, 0)));
    const todayPlus14Days = formatDate(DateTime.now().plus({ days: 14 }));

    return `Sehr geehrte Damen und Herren,

für die Fahrausbildung von ${student.name} bestehen derzeit folgende offene Rechnungen:

${textTable}

Bitte überweisen Sie den Gesamtbetrag von ${sum} bis zum ${todayPlus14Days} auf folgendes Konto:

  Kontoinhaber: ${drivingSchool.name}
  IBAN: ${iban}
  Verwendungszweck: Rechnung ${invoices[0].nr}${invoices.length > 1 ? " und folgende" : ""}, ${student.name}

Bitte beachten Sie, dass jeder Fahrschüler bei uns eine persönliche IBAN-Nummer für die Zuweisung der Zahlung hat. 

Sollte die Zahlung bereits erfolgt sein, betrachten Sie dieses Schreiben bitte als gegenstandslos.

Für Rückfragen stehen wir Ihnen gerne zur Verfügung.

Mit freundlichen Grüßen,
Ihr Team von ${drivingSchool.name}`;
  } catch (error) {
    console.error(`Failed to render Sammelquittung email
  tableData: ${JSON.stringify(tableData, null, 2)}
  error: ${safeErrorToString(error)}`);
    return "Fehler bei der Erstellung der E-Mail. Bitte wende dich an die Technik.";
  }
}

/** Visible for testing. */
export function emailTextToEmailHtml(text: string): string {
  const { topLeft, bottomRight } = _tableConfig.border;
  const tableStart = text.indexOf(topLeft);
  const tableEnd = text.indexOf(bottomRight);
  const table = text.substring(tableStart, tableEnd + 1);
  const htmlBeforeTable = text.substring(0, tableStart).trim().replaceAll("\n", "<br />\n");
  const htmlAfterTable = text
    .substring(tableEnd + 1)
    .trim()
    .replaceAll("\n", "<br />\n");
  return _html(`<p>
${htmlBeforeTable}
</p>
${_textTableToHtmlTable(table)}
<p>
${htmlAfterTable}
</p>`);
}

function _textTableToHtmlTable(table: string): string {
  const { bodyLeft, bodyJoin, bodyRight } = _tableConfig.border;
  const lines = table.split("\n").filter((_, i) => i % 2 === 1);
  const [header, ...data] = lines.map((line) => {
    const cells = line.substring(bodyLeft.length, line.length - bodyRight.length).split(bodyJoin);
    return cells.map((cell) => cell.trim());
  });
  const tdStyle = `border:1px solid ${autovioColors.greyLight};padding:4px`;
  const thStyle = `${tdStyle};background-color:${autovioColors.greyUltraLight}`;
  return `<table style="border-collapse: collapse">
  <thead>
    <tr>
      ${header
        .map((cell, i) => {
          const alignment = _tableConfig.columns[i].alignment;
          return `<th scope="col" align="${alignment}" style="${thStyle}">${cell}</th>`;
        })
        .join("\n      ")}
    </tr>
  </thead>
  <tbody>
${data
  .map((row) => {
    return `    <tr>
      ${row
        .map((cell, i) => {
          const alignment = _tableConfig.columns[i].alignment;
          return `<td align="${alignment}" style="${tdStyle}">${cell}</td>`;
        })
        .join("\n      ")}
    </tr>`;
  })
  .join("\n    ")}
  </tbody>
</table>`;
}

function _html(body: string): string {
  return `<!doctype html>
<html lang="de">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
</head>
<body>
${body}  
</body>  
</html>`;
}
