import type { Simulator } from "../providers/resourceProviders";
import { SimulatorIcon } from "../icons/SimulatorIcon";
import { Create, EditBase, Resource, Tab, useNotify, useRedirect } from "react-admin";
import { SimulatorForm } from "../details/vehicles/SimulatorForm";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { Column } from "../misc/Column";
import { overviewCardStyle } from "../misc/backofficeTheme";
import { Tabs } from "../misc/Tabs";
import { ResourceCalendar } from "../details/vehicles/ResourceCalendar";
import { grants } from "../backoffice.access_control";
import { VehicleDetailsHeader } from "../details/vehicles/VehicleDetailsHeader";
import { useSwitchDrivingSchoolIfNecessary } from "../hooks/useSwitchDrivingSchoolIfNecessary";

function _SimulatorsList() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  useEffect(() => {
    if (drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/fleet#simulators`);
    }
  });
  return drivingSchoolId ? null : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

function _SimulatorCreate() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  const notify = useNotify();
  useEffect(() => {
    if (!grants.includes("Vehicle:create") && drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/fleet#simulators`);
    }
  }, []);

  if (!grants.includes("Vehicle:create")) {
    return null;
  }

  return drivingSchoolId ? (
    <Create
      title={<BackofficeRecordTitle />}
      mutationOptions={{
        onSuccess: ({ name }: { name: string }) => {
          window.onNavigation = () => {
            delete window.onNavigation;
            notify(`Simulator ${name} erfolgreich gespeichert`, { type: "success" });
          };
          redirect(`/drivingSchools/${drivingSchoolId}/fleet#simulators`);
        },
      }}
    >
      <SimulatorForm mode="create" sx={{ padding: overviewCardStyle.padding }} />
    </Create>
  ) : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

function _SimulatorEdit() {
  useSwitchDrivingSchoolIfNecessary((simulator: Simulator) => simulator.entitledDrivingSchools[0]);
  const notify = useNotify();
  return (
    <EditBase
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess: () => notify("Änderungen erfolgreich gespeichert", { type: "success" }) }}
    >
      <Tabs
        sx={{ ...overviewCardStyle, padding: "10px 30px" }}
        header={<VehicleDetailsHeader />}
        syncWithLocation={false}
        syncWithHash
        separateCards
      >
        <Tab label="Kalender" value="calendar">
          <ResourceCalendar />
        </Tab>
        {grants.includes("Vehicle:edit") && (
          <Tab label="Simulator-Daten" value="data">
            <SimulatorForm mode="edit" />
          </Tab>
        )}
      </Tabs>
    </EditBase>
  );
}

export const simulatorsResource = (
  <Resource
    key="simulators"
    name="simulators"
    icon={SimulatorIcon}
    list={_SimulatorsList}
    create={_SimulatorCreate}
    edit={_SimulatorEdit}
    options={{ label: "Simulatoren", createTitle: "Neuer Simulator" }}
    recordRepresentation={(record: Simulator) => {
      return record.deletedAt
        ? `${record.name} (gelöscht am: ${record.deletedAt.toLocaleString(undefined, { locale: "de" })})`
        : record.name;
    }}
  />
);
