import { type ReactNode } from "react";
import { Tab } from "react-admin";
import { AddVehicleButton } from "../../buttons/AddVehicleButton";
import { PageTitle } from "../../misc/PageTitle";
import { Typography } from "@mui/material";
import { Row } from "../../misc/Row";
import { Tabs } from "../../misc/Tabs";
import { CarIcon } from "../../icons/CarIcon";
import { MotorcycleIcon } from "../../icons/MotorcycleIcon";
import { TrailerIcon } from "../../icons/TrailerIcon";
import { SimulatorIcon } from "../../icons/SimulatorIcon";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { useLocation } from "react-router-dom";
import { CarsList } from "../vehicles/CarsList";
import { MotorcyclesList } from "../vehicles/MotorcyclesList";
import { TrailersList } from "../vehicles/TrailersList";
import { SimulatorsList } from "../vehicles/SimulatorsList";
import { DeletedVehiclesList } from "../vehicles/DeletedVehiclesList";
import { grants } from "../../backoffice.access_control";

export function DrivingSchoolFleet() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { hash } = useLocation();
  let additionalButton: ReactNode;
  if (hash === "#motorcycles") {
    additionalButton = <AddVehicleButton type="motorcycle" data-testid="add-motorcycle-button" />;
  } else if (hash === "#trailers") {
    additionalButton = <AddVehicleButton type="trailer" data-testid="add-trailer-button" />;
  } else if (hash === "#simulators") {
    additionalButton = <AddVehicleButton type="simulator" data-testid="add-simulator-button" />;
  } else {
    additionalButton = <AddVehicleButton type="car" data-testid="add-car-button" />;
  }

  if (!drivingSchoolId) {
    return (
      <div className="centered">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          Bitte wähle eine Fahrschule aus.
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle>Fuhrpark</PageTitle>
      <Tabs
        sx={{ borderRadius: 0, backgroundColor: "#f8f8f8" }}
        syncWithLocation={false}
        syncWithHash
        additionalButtons={grants.includes("Vehicle:create") ? additionalButton : undefined}
      >
        <Tab
          value="cars"
          label={
            <Row spacing={1} sx={{ alignItems: "center" }}>
              <CarIcon />
              <Typography variant="body2">Autos</Typography>
            </Row>
          }
        >
          <CarsList />
          {grants.includes("Vehicle:viewDeleted") && <DeletedVehiclesList title="Gelöschte Autos" resource="cars" />}
        </Tab>
        <Tab
          value="motorcycles"
          label={
            <Row spacing={1} sx={{ alignItems: "center" }}>
              <MotorcycleIcon />
              <Typography variant="body2">Motorräder</Typography>
            </Row>
          }
        >
          <MotorcyclesList />
          {grants.includes("Vehicle:viewDeleted") && (
            <DeletedVehiclesList title="Gelöschte Motorräder" resource="motorcycles" />
          )}
        </Tab>
        <Tab
          value="trailers"
          label={
            <Row spacing={1} sx={{ alignItems: "center" }}>
              <TrailerIcon />
              <Typography variant="body2">Anhänger</Typography>
            </Row>
          }
        >
          <TrailersList />
          {grants.includes("Vehicle:viewDeleted") && (
            <DeletedVehiclesList title="Gelöschte Anhänger" resource="trailers" />
          )}
        </Tab>
        <Tab
          value="simulators"
          label={
            <Row spacing={1} sx={{ alignItems: "center" }}>
              <SimulatorIcon />
              <Typography variant="body2">Simulatoren</Typography>
            </Row>
          }
        >
          <SimulatorsList />
          {grants.includes("Vehicle:viewDeleted") && (
            <DeletedVehiclesList title="Gelöschte Simulatoren" resource="simulators" />
          )}
        </Tab>
      </Tabs>
    </>
  );
}
