import { useQueryClient } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { calendarEventsProvider } from "../providers/calendarEventsProvider";

/**
 * Sometimes a calendarEvents query stays in the fetching state forever
 * and the calendar shows outdated data. This hook mitigates this problem.
 * Use the return refetchCounter in the meta property when calling
 * `useGetManyReference` to fetch calendar events.
 */
export function useRefetchCalendarEventsCounter(): number {
  const queryClient = useQueryClient();
  const [refetchCounter, setRefetchCounter] = useState(0);
  const triggerRefetch = useCallback(() => setRefetchCounter((counter) => counter + 1), []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (calendarEventsProvider.numRunningQueries === 0) {
        const fetchingQueries = (queryClient as any).queryCache.findAll({
          fetching: true,
          queryKey: ["calendarEvents"],
        });
        if (fetchingQueries.length) {
          for (const query of fetchingQueries) {
            query.reset();
          }
          triggerRefetch();
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [queryClient, triggerRefetch]);
  return refetchCounter;
}
