import EditIcon from "@mui/icons-material/EditOutlined";
import { Form, required, SaveButton, SelectInput, useNotify, useRecordContext } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { PaymentStrategyEnum } from "../generated/backendClient";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDialog } from "../hooks/useDialog";

export type FrontendPaymentStrategyEnum = "upfrontPayment" | "payAsYouDrive" | "purchaseOnAccount";

export const PaymentStrategyChoices: { id: FrontendPaymentStrategyEnum; name: string }[] = [
  { id: "upfrontPayment", name: "nur Vorauszahlung" },
  { id: "payAsYouDrive", name: "Pay As You Drive" },
  { id: "purchaseOnAccount", name: "per Rechnung" },
];

export const frontendToBackend: { [k in FrontendPaymentStrategyEnum]: PaymentStrategyEnum } = {
  upfrontPayment: "upfront_payment",
  payAsYouDrive: "pay_as_you_drive",
  purchaseOnAccount: "purchase_on_account",
};

export function ChangeStudentPaymentStrategyButton() {
  const { dialogProps, openDialog } = useDialog("ChangeStudentPaymentStrategyButton");
  return (
    <>
      <IconButton sx={{ mt: "-20px" }} onClick={openDialog}>
        <EditIcon />
      </IconButton>
      <ChangeStudentPaymentStrategyDialog {...dialogProps} />
    </>
  );
}

function ChangeStudentPaymentStrategyDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const student = useRecordContext<Student>();
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();
  const notify = useNotify();

  const onSubmit = async ({ paymentStrategy }: Partial<Student>) => {
    if (!student) {
      throw new Error("Invalid state: !student");
    }
    if (!paymentStrategy) {
      throw new Error("No paymentStrategy selected");
    }
    setSaving(true);
    try {
      const updateReceived = new Promise<void>((resolve) => {
        const unsubscribe = studentsProvider.onUpdate(student, (student) => {
          if (student.paymentStrategy === paymentStrategy) {
            unsubscribe();
            resolve();
          }
        });
      });
      const backendValue = frontendToBackend[paymentStrategy];
      const backendClient = await getAuthenticatedBackendClient();
      await backendClient.user.userSetPaymentStrategyCreate({
        id: student.id,
        requestBody: { payment_strategy: backendValue },
      });
      await updateReceived;
      await queryClient.invalidateQueries(["students", "getOne", { id: student.id }]);
      notify(`Zahlungsmodus von ${student.name} erfolgreich geändert.`, { type: "success" });
    } catch (e) {
      console.error(e);
      notify(`Fehler beim Ändern des Zahlungsmodus von ${student.name}`, { type: "error" });
    } finally {
      setSaving(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Zahlungsmodus von {student.name} ändern</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Form warnWhenUnsavedChanges onSubmit={onSubmit}>
        <DialogContent sx={{ width: "360px" }}>
          <SelectInput
            sx={{ width: "15em" }}
            label="Payment Strategie"
            source="paymentStrategy"
            choices={PaymentStrategyChoices}
            disabled={saving}
            validate={required()}
          />
        </DialogContent>
        <DialogActions>
          <SaveButton />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
