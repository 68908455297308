import { type ReactNode } from "react";
import { SaveButton, useGetList, useNotify } from "react-admin";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { SelectInput } from "../../inputs/SelectInput";
import { type CreateCalendarEventDto, serverAPI } from "../../api/server.api";
import { Branch } from "../../providers/branchesProvider";
import { InstructorInput } from "../../inputs/InstructorInput";
import { Form2Theme } from "../../misc/Form2";

interface TheoryLessonFormProps {
  drivingSchoolId: string;
  initialDateTime?: DateTime;
  onSuccess: () => Promise<void>;
}

export interface TheoryLessonFormValues {
  unit: number;
  start?: DateTime;
  branchId?: string;
  instructorId?: string;
}

export interface TheoryLessonParams extends TheoryLessonFormValues {
  start: DateTime;
  instructorId: string;
  branchId: string;
}

export const TheoryLessonForm = ({ drivingSchoolId, initialDateTime, onSuccess }: TheoryLessonFormProps) => {
  const notify = useNotify();
  const { data: branches = [] } = useGetList<Branch>("branches", {
    filter: { drivingSchoolId },
  });

  const defaultValues = {
    unit: 1,
    start: initialDateTime ?? undefined,
  } satisfies TheoryLessonFormValues;

  const formProps = useForm<TheoryLessonFormValues>({ defaultValues });

  function validate(formValues: TheoryLessonFormValues): TheoryLessonParams {
    const { unit, start, branchId, instructorId } = formValues;
    if (!start) {
      throw new Error(`Invalid start time: ${start}`);
    }
    if (!branchId) {
      throw new Error(`Invalid branchId: ${branchId}`);
    }
    if (!instructorId) {
      throw new Error(`Invalid instructorId: ${instructorId}`);
    }

    return {
      unit,
      start,
      branchId,
      instructorId,
    } satisfies TheoryLessonParams;
  }

  const onSubmit = async (formValues: TheoryLessonFormValues) => {
    try {
      const { unit, start, ...params } = validate(formValues);
      const payload: CreateCalendarEventDto = {
        type: "TheoryLesson" as const,
        uid: uuidv4(),
        theoryUnit: unit,
        start: start.toISO(),
        end: start.plus({ minutes: 90 }).toISO(),
        courseUid: uuidv4(),
        ...params,
      };
      await serverAPI.createCalendarEvent(payload);
      notify("Termin erfolgreich gespeichert.", {
        type: "success",
      });
      await onSuccess();
    } catch (error) {
      console.error("Failed to create theory event", error);
      notify("Fehler beim Speichern des Termins", { type: "error" });
    }
  };

  return (
    <Form2Theme>
      <FormProvider {...formProps}>
        <form onSubmit={formProps.handleSubmit(onSubmit)} style={{ height: "100%" }}>
          <Column height="100%" minHeight={500} gap="1em">
            <WhenFormIsInitialized>
              <SelectInput
                label="Lektion"
                source="unit"
                size="small"
                options={TheoryLessonUnits.map((unit, index) => [index + 1, unit])}
                validate={(value) => (value ? true : "Bitte wähle die Lektion")}
              />
              <DateTimeInput size="small" source="start" allowPastDates={false} />
              <SelectInput
                label="Standort"
                source="branchId"
                size="small"
                options={branches.map((branch) => [branch.id, branch.name])}
                validate={(value) => (value ? true : "Bitte wähle die Standort")}
              />
              <InstructorInput size="small" drivingSchoolId={drivingSchoolId} source="instructorId" />
              <Row sx={{ justifyContent: "flex-end", mt: "auto" }}>
                <SaveButton icon={<></>} label="Termin speichern" />
              </Row>
            </WhenFormIsInitialized>
          </Column>
        </form>
      </FormProvider>
    </Form2Theme>
  );
};

function WhenFormIsInitialized({ children }: { children: ReactNode }) {
  const { formState } = useFormContext();
  return formState.isLoading ? null : children;
}

const TheoryLessonUnits = [
  "Grundstoff: Lektion 1",
  "Grundstoff: Lektion 2",
  "Grundstoff: Lektion 3",
  "Grundstoff: Lektion 4",
  "Grundstoff: Lektion 5",
  "Grundstoff: Lektion 6",
  "Grundstoff: Lektion 7",
  "Grundstoff: Lektion 8",
  "Grundstoff: Lektion 9",
  "Grundstoff: Lektion 10",
  "Grundstoff: Lektion 11",
  "Grundstoff: Lektion 12",
  "Zusatzstoff Klasse B: Lektion 13",
  "Zusatzstoff Klasse B: Lektion 14",
  "Zusatzstoff Klasse A: Lektion 15",
  "Zusatzstoff Klasse A: Lektion 16",
  "Zusatzstoff Klasse A: Lektion 17",
  "Zusatzstoff Klasse A: Lektion 18",
];
