import { DateTime } from "luxon";
import { GetListParams, GetListResult, type GetManyParams, type GetManyResult } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import type { AutovioPayout, AutovioPayoutStateEnum, ScheduleTypeEnum } from "../generated/backendClient";
import { Money, MoneySchema } from "../model/Money";
import { AbstractDataProvider } from "./AbstractDataProvider";
import {
  autovioPayoutItemToAutovioPayoutItemRecord,
  type AutovioPayoutItemRecord,
} from "./autovioPayoutItemProvider.js";

export interface AutovioPayoutRecord {
  id: string;
  company: string;
  drivingSchoolId: string;
  items: Array<AutovioPayoutItemRecord>;
  calculatedPayoutAmount: Money;
  statementDescriptor: string | null;
  state: AutovioPayoutStateEnum;
  availableBalance: Money;
  scheduleType: ScheduleTypeEnum;
  payoutDate: DateTime;
  stripePayout: StripePayoutRecord | undefined;
  pdf: string | null | undefined;
  pdfDownload: string | null | undefined;
}

export interface StripePayoutRecord {
  id: string;
  arrivalDate: DateTime;
}

function _autovioPayoutToAutovioPayoutRecord(payout: AutovioPayout): AutovioPayoutRecord {
  if (!payout.id) {
    throw new Error("AutovioPayout has no ID");
  }
  if (!payout.state) {
    throw new Error("AutovioPayout has no state");
  }
  return {
    id: payout.id ?? "",
    company: payout.company,
    drivingSchoolId: payout.driving_school_id,
    items: payout.items.map(autovioPayoutItemToAutovioPayoutItemRecord),
    calculatedPayoutAmount:
      safeParseMoney(payout.calculated_payout_amount) ?? MoneySchema.parse({ amount: "0", currency: "EUR" }),
    statementDescriptor: payout.statement_descriptor ?? null,
    state: payout.state,
    availableBalance: safeParseMoney(payout.available_balance) ?? MoneySchema.parse({ amount: "0", currency: "EUR" }),
    scheduleType: payout.schedule_type ?? "AUTOMATIC",
    payoutDate: DateTime.fromISO(payout.payout_date),
    stripePayout: payout.stripe_payout
      ? { id: payout.stripe_payout.id, arrivalDate: DateTime.fromISO(payout.stripe_payout.arrival_date) }
      : undefined,
    pdf: payout.pdf,
    pdfDownload: payout.pdf_download,
  };
}

class AutovioPayoutProvider extends AbstractDataProvider<AutovioPayoutRecord> {
  async getList(
    resource: string,
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<AutovioPayoutRecord>> {
    const backendClient = await getAuthenticatedBackendClient();
    const { drivingSchoolId, ...clientSideFilter } = filter;
    if (!drivingSchoolId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId": ..., ...}`);
    }
    const { results } = await backendClient.payout.payoutList({ companyDrivingSchoolId: drivingSchoolId });
    const records = results.map(_autovioPayoutToAutovioPayoutRecord);
    return applyPagination(applySort(applyFilter(records, clientSideFilter), sort), pagination);
  }

  async getMany(resource: string, { ids }: GetManyParams): Promise<GetManyResult<AutovioPayoutRecord>> {
    const results = await Promise.all(ids.map(async (id) => (await this.getOne(resource, { id: id as string })).data));
    return { data: results };
  }

  async getOne(resource: string, { id }: { id: string }): Promise<{ data: AutovioPayoutRecord }> {
    const backendClient = await getAuthenticatedBackendClient();
    const payout = await backendClient.payout.payoutRetrieve({ id });
    const record = _autovioPayoutToAutovioPayoutRecord(payout);
    return { data: record };
  }
}

export const autovioPayoutProvider = new AutovioPayoutProvider();

function safeParseMoney(x: any): undefined | Money {
  try {
    return MoneySchema.parse(x);
  } catch {
    return undefined;
  }
}
