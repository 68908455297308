import type { DialogProps } from "../../misc/DialogProps";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { StudentDetails } from "../../details/StudentDetails";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { Row } from "../../misc/Row";
import { DunningProcessTimeline } from "./DunningProcessTimeline";
import { Dialog, DialogContent, MenuItem, Menu, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Identifier, RecordContextProvider, useGetOne } from "react-admin";
import { EventPropagationStopper } from "../../misc/EventPropagationStopper";
import { autovioColors } from "../../misc/backofficeTheme";
import { useMenu } from "../../hooks/useMenu";

export function DunningStudentDialog({
  studentId,
  actions,
  ...dialogProps
}: DialogProps & { studentId: Identifier; actions: React.ReactNode[] }) {
  const { data: student } = useGetOne("students", { id: studentId }, { enabled: !!studentId });
  return (
    <Dialog {...dialogProps} maxWidth="xl" fullWidth>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent sx={{ backgroundColor: "#f8f8f8", pt: 8 }}>
        {student ? (
          <RecordContextProvider value={student}>
            <DialogActions actions={actions} />
            <DunningProcessTimeline />
            <StudentDetails compactView />
          </RecordContextProvider>
        ) : (
          <Row minHeight="80px" pt="80px">
            <LoadingIndicator />
          </Row>
        )}
      </DialogContent>
    </Dialog>
  );
}

function DialogActions({ actions }: { actions: React.ReactNode[] }) {
  const { anchorEl, open, openMenu, closeMenu } = useMenu();

  if (actions.length === 0) {
    return null;
  }

  return (
    <EventPropagationStopper>
      <IconButton
        sx={{
          position: "absolute",
          top: "17px",
          right: "57px",
        }}
        onClick={openMenu}
      >
        <MoreVertIcon sx={{ fill: autovioColors.grey }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "dialog-actions",
        }}
      >
        {actions.map((action) => (
          <MenuItem sx={{ p: 0 }} onClick={closeMenu}>
            {action}
          </MenuItem>
        ))}
      </Menu>
    </EventPropagationStopper>
  );
}
