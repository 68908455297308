import { Button, Typography } from "@mui/material";
import { useListContext } from "react-admin";

export function EmptyState(props: { label?: string; labelWhenFilter?: string }) {
  const { filterValues, setFilters } = useListContext();
  const isFilterActive = Object.keys(filterValues).length > 0;
  const label = (isFilterActive ? props.labelWhenFilter : "") || props.label || "Keine Einträge.";
  return (
    <div className="RaList-main">
      <div style={{ textAlign: "center", flex: 1, paddingBottom: "20px" }}>
        <img src="/time_off.png" alt="" style={{ maxWidth: "400px", opacity: 0.5 }} />
        <div style={{ opacity: 0.5 }}>
          <Typography>{label}</Typography>
        </div>
        {isFilterActive && (
          <Button sx={{ my: "20px " }} variant="outlined" onClick={() => setFilters({}, {}, false)}>
            Filter zurücksetzen
          </Button>
        )}
      </div>
    </div>
  );
}
