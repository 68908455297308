import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { Datagrid, FunctionField, useNotify } from "react-admin";
import { grants } from "../backoffice.access_control";
import { PartialRefundPaymentDialogButton } from "../buttons/PartialRefundInvoiceDialogButton";
import { InvoiceStateBadge } from "../fields/InvoiceStateField";
import { OpenInStripeIconButton } from "../buttons/OpenInStripeIconButton";
import { MoneyField } from "../fields/MoneyField";
import { RetryPaymentButton } from "../buttons/RetryPaymentButton";
import { Invoice } from "../providers/invoicesProvider";
import type { Student } from "../providers/studentsProvider.js";
import { formatDate, formatTime } from "../utils/calendar";
import { UpdateAddressOnPDFButton } from "../buttons/UpdateAddressOnPDFButton";
import { InvoicePosition } from "../generated/backendClient";
import { Row } from "../misc/Row";

export function InvoicesList({ student }: { student: Student }) {
  // All columns are marked as not sortable, because sort order must always be: createdAt DESC ...

  const notify = useNotify();

  const getLastInvoiceChars = (num: string) => {
    return num.slice(-5);
  };

  const getValue = (position: InvoicePosition) => {
    const { driving_lesson_type, driving_license_class, ue_count, description } = position;

    if (!driving_lesson_type || !driving_license_class || !ue_count) {
      return description;
    }

    const parsedCount = Math.round(parseFloat(ue_count));
    return `${driving_license_class}: ${parsedCount} x ${driving_lesson_type}`;
  };

  const copyInvoiceNumberToClipboard = (invoiceNumber: string) => {
    navigator.clipboard
      .writeText(invoiceNumber)
      .then(() => {
        notify("Vollständige Rechnungsnummer in die Zwischenablage kopiert.", { type: "success" });
      })
      .catch(() => {
        notify("Fehler beim Kopieren der Rechnungsnummer in die Zwischenablage", { type: "error" });
      });
  };

  return (
    <Datagrid
      bulkActionButtons={false}
      empty={<span style={{ opacity: 0.5 }}>Keine Rechnungen im neuen Rechnungssystem vorhanden</span>}
      className="overflow-x-scroll"
      sx={{ minWidth: "1060px" }}
    >
      <FunctionField
        render={(invoice: Invoice) => {
          return (
            <Tooltip title={invoice.nr}>
              <Box sx={{ display: "flex", cursor: "pointer" }} onClick={() => copyInvoiceNumberToClipboard(invoice.nr)}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "62px",
                  }}
                >
                  {invoice.nr}
                </Box>
                {getLastInvoiceChars(invoice.nr)}
              </Box>
            </Tooltip>
          );
        }}
        label="Rechnungs-Nr."
        sortable={false}
        noWrap
      />
      <FunctionField
        render={(invoice: Invoice) => {
          return (
            <>
              {invoice.payload.positions.map((p) => (
                <div key={p.id}>{getValue(p)}</div>
              ))}
            </>
          );
        }}
        label="Leistungen"
        sortable={false}
      />
      <FunctionField
        label="Leistungs&shy;zeitpunkt"
        sortable={false}
        render={(invoice: Invoice) => {
          const dateTimeArray = invoice.payload.positions.map((p) =>
            p.service_at
              ? {
                  date: formatDate(DateTime.fromISO(p.service_at)),
                  time: formatTime(DateTime.fromISO(p.service_at)),
                }
              : null,
          );
          const filteredDateTimeArray = dateTimeArray.filter((it) => it !== null)?.[0];
          const { date, time } = filteredDateTimeArray as { date: string; time: string };

          return (
            <>
              <Box>{date}</Box>
              <Box>{time} Uhr</Box>
            </>
          );
        }}
      />
      <MoneyField label="Betrag" source="total" textAlign="right" sortable={false} />
      <MoneyField label="Offen" source="payment_amount" textAlign="right" sortable={false} />
      <MoneyField label="Erstattet" source="refunded_amount" textAlign="right" sortable={false} />
      <FunctionField
        label="Status"
        source="status"
        render={(invoice: Invoice) => <InvoiceStateBadge invoice={invoice.payload} />}
      />
      <FunctionField
        label=""
        sortable={false}
        render={(invoice: Invoice) => {
          const pdf_url = invoice.downloadUrl;
          return (
            <Row gap="16px">
              {pdf_url && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    open(pdf_url, "_blank");
                  }}
                  title="Rechnung anzeigen"
                >
                  <OpenInNewIcon />
                </IconButton>
              )}
              {grants.includes("retryPayments") && (
                <RetryPaymentButton
                  student={student}
                  invoiceState={invoice.payload.state}
                  invoiceId={invoice.payload.id}
                  paymentAmount={invoice.payload.payment_amount}
                  description=""
                />
              )}
              {grants.includes("refundPayments") && <PartialRefundPaymentDialogButton invoice={invoice.payload} />}
              {grants.includes("viewStripe") && <OpenInStripeIconButton source="id" />}
              {grants.includes("updateInvoicePDF") && <UpdateAddressOnPDFButton invoice={invoice.payload} />}
            </Row>
          );
        }}
      />
    </Datagrid>
  );
}
