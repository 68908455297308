import * as React from "react";
import Typography from "@mui/material/Typography";
import { LinearProgress, PublicFieldProps, useGetMany, useRecordContext } from "react-admin";
import { Instructor } from "../providers/instructorsProvider";
import ErrorIcon from "@mui/icons-material/Error";

export function ActiveStudentsField(_: PublicFieldProps) {
  const instructor = useRecordContext<Instructor>();
  const { drivingSchoolId } = instructor;
  const studentIds = instructor.studentIds ?? [];
  const { data: students, isLoading, error } = useGetMany("students", { ids: studentIds, meta: { drivingSchoolId } });
  if (isLoading) {
    return <LinearProgress style={{ maxWidth: "2em" }} />;
  }
  if (error) {
    return <ErrorIcon role="presentation" color="error" fontSize="small" />;
  }
  const activeStudents = students?.filter((it) => it.verboseStatus === "Aktiv") ?? [];
  return (
    <Typography component="span" variant="body2">
      {activeStudents.length}
    </Typography>
  );
}
