import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Tab, useRecordContext } from "react-admin";
import { grants } from "../backoffice.access_control";
import { OpenInFirestoreButton } from "../buttons/OpenInFirestoreButton";
import { OpenInHubspotButton } from "../buttons/OpenInHubspotButton";
import { ActiveStudentsField } from "../fields/ActiveStudentsField";
import { InstructorCalendar } from "../misc/calendar/InstructorCalendar";
import { Instructor } from "../providers/instructorsProvider";
import { autovioColors, overviewCardStyle } from "../misc/backofficeTheme";
import { ShowBase } from "../misc/ShowBase";
import { InstructorAdminForm } from "./instructor/InstructorAdminForm";
import { Tabs } from "../misc/Tabs";
import { Row } from "../misc/Row";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { InstructorInAppNotificationsList } from "./instructor/InstructorInAppNotificationsList";
import { useEffect } from "react";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NameHeader } from "../misc/NameHeader";
import { HeaderField } from "../misc/HeaderField";
import { InstructorMap } from "../misc/maps";
import { InstructorStudentsList } from "./instructor/InstructorStudentsList";
import { Tab2 } from "../misc/Tab2";
import { InstructorAppointmentsList } from "./instructor/InstructorAppointmentsList";
import { useMenu } from "../hooks/useMenu";
import { useSwitchDrivingSchoolIfNecessary } from "../hooks/useSwitchDrivingSchoolIfNecessary";
import { InstructorVehiclesList } from "./instructor/InstructorVehiclesList";

export function InstructorDetails() {
  useEffect(() => {
    // For backwards compatibility ...
    if (location.pathname.endsWith("/calendar")) {
      const url = new URL(location.href);
      url.pathname = url.pathname.substring(0, url.pathname.length - "/calendar".length);
      location.replace(url.toString());
    }
  }, []);
  if (location.pathname.endsWith("/calendar")) {
    return null;
  }
  return (
    <ShowBase>
      <InstructorDetailsTabs />
    </ShowBase>
  );
}

function InstructorButtons() {
  const instructor = useRecordContext<Instructor>();
  const { anchorEl, open, openMenu, closeMenu } = useMenu();

  const handleEmailClick = () => {
    const url = `https://app.hubspot.com/contacts/19951845/record/0-1/${instructor.hubspotContactId}/?interaction=email`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Row sx={{ display: "block" }}>
      <Box sx={{ display: "flex" }}>
        {grants.includes("viewHubspot") && (
          <IconButton onClick={handleEmailClick}>
            <AlternateEmailIcon />
          </IconButton>
        )}
        {grants.includes("admin") && (
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu} sx={{ width: 220 }}>
        {grants.includes("viewHubspot") && (
          <MenuItem onClick={closeMenu}>
            <OpenInHubspotButton showTitle />
          </MenuItem>
        )}

        {grants.includes("viewFirestore") && (
          <MenuItem onClick={closeMenu}>
            <OpenInFirestoreButton showTitle />
          </MenuItem>
        )}
      </Menu>
    </Row>
  );
}

function InstructorDetailsHeader({ instructor }: { instructor: Instructor }) {
  return (
    <Row sx={{ my: "20px" }} spacing="50px" alignItems="start">
      <Row spacing="20px" alignItems="center">
        <Avatar src={instructor.avatarOverrideUrl ?? instructor.avatarUrl} sx={{ width: "60px", height: "60px" }} />
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <NameHeader>{instructor.name}</NameHeader>
          <Typography sx={{ color: autovioColors.grey, fontSize: "14px", lineHeight: "1.43" }}>
            {instructor.isDrivingSchoolManager ? "Fahrschulleiter" : "Fahrlehrer"}
          </Typography>
        </Box>
      </Row>
      <Row sx={{ flexWrap: "wrap" }} alignItems="center">
        <HeaderField label="aktive Fahrschüler">
          <ActiveStudentsField />
        </HeaderField>
      </Row>
    </Row>
  );
}

function InstructorDetailsTabs() {
  useSwitchDrivingSchoolIfNecessary((instructor: Instructor) => instructor.drivingSchoolId);
  const instructor = useRecordContext<Instructor>();

  if (!instructor) {
    return <LoadingIndicator />;
  }

  return (
    <Tabs
      sx={{ ...overviewCardStyle, padding: "5px 30px 10px 30px" }}
      header={<InstructorDetailsHeader instructor={instructor} />}
      additionalButtons={<InstructorButtons />}
      separateCards
      className="InstructorDetailsTabs"
    >
      <Tab label="Kalender" /* no path attribute here, so the tab is shown by default */>
        <InstructorCalendar />
      </Tab>
      <Tab label="Terminliste" path="appointments">
        <InstructorAppointmentsList />
      </Tab>
      <Tab label="Fahrschüler" path="students">
        <InstructorStudentsList />
      </Tab>
      <Tab2 label="Karte" path="map">
        <InstructorMap instructor={instructor} />
      </Tab2>
      {grants.includes("viewInAppNotifications") && (
        <Tab label="In-App-Benachrichtigungen" path="in-app-notifications">
          <InstructorInAppNotificationsList />
        </Tab>
      )}
      <Tab label="Fahrzeuge" path="vehicles">
        <InstructorVehiclesList />
      </Tab>
      {grants.includes("admin") && (
        <Tab label="Admin" path="admin">
          <InstructorAdminForm />
        </Tab>
      )}
    </Tabs>
  );
}
