import { auth } from "../firebase";
import { BackendClient, DunningProcess, EmailDraft, ManualInvoice } from "../generated/backendClient";
import { Student } from "../providers/studentsProvider";

const useLocalBackend = import.meta.env.VITE_USE_LOCAL_BACKEND || false;
const baseUrl = useLocalBackend
  ? "http://localhost:8000"
  : location.hostname === "backoffice.autovio.de"
    ? "https://backend.autovio.de"
    : "https://backend.autovio.dev";

export async function getAuthenticatedBackendClient() {
  const jwt = await auth.currentUser?.getIdToken();
  return new BackendClient({ BASE: `${baseUrl}/api`, TOKEN: jwt });
}

export async function createManualInvoice(data: ManualInvoice): Promise<void> {
  const backendClient = await getAuthenticatedBackendClient();
  await backendClient.order.orderManualInvoiceCreate({ requestBody: data });
}

export async function draftDunningNotice(
  student: Student,
  noticeType: "student_blocked" | "first_dunning_notice",
): Promise<{ dunningProcess: DunningProcess; draft: EmailDraft }>;
export async function draftDunningNotice(
  context: Student | DunningProcess,
  noticeType: "second_dunning_notice" | "inkasso_notice",
): Promise<{ dunningProcess: DunningProcess; draft: EmailDraft }>;
export async function draftDunningNotice(
  context: Student | DunningProcess,
  noticeType: "student_blocked" | "first_dunning_notice" | "second_dunning_notice" | "inkasso_notice",
): Promise<{ dunningProcess: DunningProcess; draft: EmailDraft }> {
  const backendClient = await getAuthenticatedBackendClient();
  let dunningProcess: DunningProcess;
  if (noticeType === "second_dunning_notice" || noticeType === "inkasso_notice") {
    dunningProcess = await backendClient.dunningProcess.dunningProcessRetrieve({
      id: isDunningProcess(context) ? context.id : context.dunningProcess!.id,
    });
  } else {
    dunningProcess = await backendClient.dunningProcess.dunningProcessPrepareForDraftRetrieve({
      studentId: (context as Student).autovioUserId,
    });
  }
  const draft = await backendClient.dunningProcess.dunningProcessDraftRetrieve({ id: dunningProcess.id, noticeType });
  return { dunningProcess, draft };
}

function isDunningProcess(x: any): x is DunningProcess {
  if (x && typeof x === "object") {
    const { id, student, invoices } = x;
    return typeof id === "string" && typeof student === "string" && Array.isArray(invoices);
  }
  return false;
}
