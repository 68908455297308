import { AbstractDataProvider } from "./AbstractDataProvider";
import { AutovioCalendarEvent } from "../model/autovioCalendarEvents";
import { GetOneParams, GetOneResult } from "react-admin";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { autovioCalendarEventConverter } from "./converter/autovioCalendarEventConverter";
import { orderBy } from "@firebase/firestore";

export interface AutovioCalendarEventHistory {
  id: string;
  /** sorted by time descending (events[0] is the current event, events[events.length - 1] is the original event. */
  events: Array<AutovioCalendarEvent>;
  /** sorted by time descending. */
  changes: Array<[/* oldEvent */ AutovioCalendarEvent, /* newEvent */ AutovioCalendarEvent]>;
}

class CalendarEventHistoryProvider extends AbstractDataProvider<AutovioCalendarEventHistory> {
  async getOne(
    _: string,
    { id, meta }: GetOneParams<AutovioCalendarEventHistory>,
  ): Promise<GetOneResult<AutovioCalendarEventHistory>> {
    const { drivingSchoolId } = meta;
    if (!drivingSchoolId) {
      throw new Error("meta.drivingSchoolId not set");
    }
    if (restrictAccessToDrivingSchoolIds && !restrictAccessToDrivingSchoolIds.includes(drivingSchoolId)) {
      throw new Error(`Access to history of event of driving school ${drivingSchoolId} denied`);
    }
    const historyCollection = collection(firestore, `/calendar_events/${id}/history`);
    const changes: Array<[/* oldEvent */ AutovioCalendarEvent, /* newEvent */ AutovioCalendarEvent]> = [];
    for (const doc of (
      await getDocs(
        query(historyCollection, where("drivingSchoolUid", "==", drivingSchoolId), orderBy("after.updatedAt", "desc")),
      )
    ).docs) {
      const data = doc.data();
      const { before, after } = data;
      const oldEvent = autovioCalendarEventConverter.fromFirestoreData(before);
      const newEvent = autovioCalendarEventConverter.fromFirestoreData(after);
      changes.push([oldEvent, newEvent]);
    }
    if (changes.length === 0) {
      throw new Error(`No update documents found in ${historyCollection.path}`);
    }
    const events = [changes[0][1], ...changes.map((it) => it[0])];
    return { data: { id: id, events, changes } };
  }
}

export const calendarEventHistoryProvider = new CalendarEventHistoryProvider();
