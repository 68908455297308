import EditIcon from "@mui/icons-material/EditOutlined";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { EditBase, Form, required, SaveButton, SelectInput, useGetOne, useNotify, useRecordContext } from "react-admin";
import { useQueryClient } from "react-query";
import { setBranch } from "../api/backoffice.api";
import { reportError } from "../backoffice.utils";
import { useDialog } from "../hooks/useDialog";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Student } from "../providers/studentsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { useState } from "react";
import { FieldValues } from "react-hook-form";

export function ChangeStudentBranchButton() {
  const { dialogProps, openDialog } = useDialog("ChangeStudentBranchDialog");
  return (
    <>
      <IconButton sx={{ mt: "-20px" }} onClick={openDialog}>
        <EditIcon />
      </IconButton>
      <ChangeStudentBranchDialog {...dialogProps} />
    </>
  );
}

function ChangeStudentBranchDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const student = useRecordContext<Student>();
  const [saving, setSaving] = useState(false);
  const { data: drivingSchool, error: drivingSchoolError } = useGetOne<DrivingSchool>("drivingSchools", {
    id: student.drivingSchoolId,
  });
  const queryClient = useQueryClient();
  const notify = useNotify();

  const onSubmit = async (data: FieldValues) => {
    if (!student) {
      throw new Error("Invalid state: !student");
    }
    const { branchId } = data as { branchId: string };
    if (!branchId) {
      throw new Error("No branch selected");
    }
    setSaving(true);
    try {
      await setBranch(student, branchId === "NONE" ? "" : branchId);
      await queryClient.invalidateQueries(["students", "getOne", { id: student.id }]);
      notify(`Filiale von ${student.name} erfolgreich geändert.`, { type: "success" });
    } catch (error) {
      reportError(`Failed to set branch of student ${student.id}`, error);
      notify(`Fehler beim Ändern der Filiale von ${student.name}.`, { type: "error" });
    } finally {
      setSaving(false);
      onClose();
    }
  };

  const choices = drivingSchool?.branches.map((branch) => ({
    id: branch.uid,
    name: branch.name,
  }));
  const choicesWithNone = [{ id: "NONE", name: "Keine feste Filiale" }, ...(choices ?? [])];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filiale von {student.name} ändern</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <EditBase redirect={false} mutationMode="pessimistic" mutationOptions={{ onSuccess: onClose }}>
        <Form onSubmit={onSubmit}>
          <DialogContent sx={{ width: "360px" }}>
            <SelectInput
              fullWidth
              source="branchId"
              label="Filiale"
              choices={choicesWithNone}
              validate={required()}
              disabled={saving}
              helperText={drivingSchoolError ? <Box color="red">Fehler beim Laden der Filialen.</Box> : undefined}
            />
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </EditBase>
    </Dialog>
  );
}
