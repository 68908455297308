import { BookedQuote } from "../providers/bookedQuotesProvider";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/SaveAlt";
import * as React from "react";
import { useState } from "react";
import { Bundle, catalogProvider } from "../providers/catalogProvider";
import { Labeled, PaginationPayload, useGetList, useNotify, useRefresh } from "react-admin";
import {
  CAR_DRIVING_LICENSE_CLASSES,
  DrivingLicenseClass,
  MOTORCYCLE_DRIVING_LICENSE_CLASSES,
  TRAILER_DRIVING_LICENSE_CLASSES,
} from "../model/DrivingLicenseClass";
import { areSetsEqual, assertNever, postCondition, reportError } from "../backoffice.utils";
import { studentsProvider } from "../providers/studentsProvider";
import { PricesOverview } from "../misc/PricesOverview";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { atom, useRecoilState } from "recoil";
import SwapIcon from "@mui/icons-material/SwapHoriz";
import { Row } from "../misc/Row";
import EditIcon from "@mui/icons-material/EditOutlined";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { Column } from "../misc/Column";
import { PopupMenu } from "../misc/PopupMenu";

export const bookedQuoteShownInBookedQuoteDialogState = atom<null | BookedQuote>({
  key: "bookedQuoteShownInBookedQuoteDialog",
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        const url = new URL(location.href);
        if (newValue) {
          if (url.searchParams.get("openDialog") !== `BookedQuoteDialog!${newValue.id}`) {
            url.searchParams.set("openDialog", `BookedQuoteDialog!${newValue.id}`);
            browserHistory.replace(url.toString());
          }
        } else if (url.searchParams.has("openDialog")) {
          url.searchParams.delete("openDialog");
          browserHistory.replace(url.toString());
        }
      });
    },
  ],
});

type BookedQuoteDialogState =
  | "prices overview"
  | "change driving license class"
  | "change price class"
  | "change price class > new prices overview"
  | "replace bundle"
  | "replace bundle > new prices overview";

export function BookedQuoteDialog() {
  const [bookedQuote, setBookedQuoteShownInDialog] = useRecoilState(bookedQuoteShownInBookedQuoteDialogState);
  const closeDialog = () => setBookedQuoteShownInDialog(null);

  if (bookedQuote) {
    return <_BookedQuoteDialog bookedQuote={bookedQuote} closeDialog={closeDialog} />;
  } else {
    return null;
  }
}

function _BookedQuoteDialog({ bookedQuote, closeDialog }: { bookedQuote: BookedQuote; closeDialog: () => void }) {
  const [dialogState, setDialogState] = useState<BookedQuoteDialogState>("prices overview");
  const [selectedDrivingLicenseClasses, setSelectedDrivingLicenseClasses] = useState(bookedQuote.drivingLicenseClasses);
  const [selectedBundle, setSelectedBundle] = useState<Bundle | undefined>(undefined);
  const notify = useNotify();
  const refresh = useRefresh();
  const [saving, setSaving] = useState(false);

  const changeDrivingLicenseClasses = async () => {
    setSaving(true);
    try {
      const changes = bookedQuote.drivingLicenseClasses
        .map((a, i) => [a, selectedDrivingLicenseClasses[i]])
        .filter(([a, b]) => a !== b);
      if (changes.length === 0) {
        throw new Error("Invalid state: No driving license class change");
      }
      await catalogProvider.changeDrivingLicenseClasses({
        studentId: bookedQuote.studentId,
        quoteId: bookedQuote.id,
        changes: Object.fromEntries(changes),
      });
      await postCondition(async () => {
        const { data: student } = await studentsProvider.getOne("students", { id: bookedQuote.studentId });
        const expectedDrivingLicenseClasses = changes.map(([_, newDrivingLicenseClass]) => newDrivingLicenseClass);
        const actualDrivingLicenseClasses = new Set(student.bookedTrainings.map((it) => it.drivingLicenseClass));
        return expectedDrivingLicenseClasses.every((it) => actualDrivingLicenseClasses.has(it));
      });
      if (changes.length === 1) {
        notify(`Führerscheinklasse erfolgreich von ${changes[0][0]} auf ${changes[0][1]} geändert.`);
      } else {
        notify(`Führerscheinklassen erfolgreich geändert.`);
      }
      refresh();
    } catch (error) {
      reportError("Failed to change driving license classes", error);
      notify("Oje, das hat leider nicht geklappt.", { type: "error" });
    } finally {
      closeDialog();
    }
  };

  const changeBundle = async () => {
    setSaving(true);
    try {
      const newBundle = selectedBundle;
      if (!newBundle) {
        throw new Error("Invalid state: No bundle selected");
      }
      await catalogProvider.changeBundle({
        studentId: bookedQuote.studentId,
        oldQuoteId: bookedQuote.id,
        newBundleId: newBundle.id,
      });
      await postCondition(async () => {
        const { data: student } = await studentsProvider.getOne("students", { id: bookedQuote.studentId });
        return student.bookedTrainings.some((it) => it.bundleName === newBundle.name);
      });
      notify(`Paket ${bookedQuote.bundleName} erfolgreich durch Paket ${newBundle.name} ersetzt.`);
      refresh();
    } catch (error) {
      reportError("Failed to change bundle", error);
      notify("Oje, das hat leider nicht geklappt.", { type: "error" });
    } finally {
      closeDialog();
    }
  };

  return (
    <Dialog open onClose={closeDialog}>
      <DialogTitle sx={{ mr: "100px" }}>
        {dialogState === "prices overview"
          ? `Paket ${bookedQuote.bundleName}`
          : `Paket ${bookedQuote.bundleName} ändern`}
      </DialogTitle>
      {dialogState === "prices overview" && (
        <PopupMenu>
          <MenuItem onClick={() => setDialogState("replace bundle")}>
            <ListItemIcon>
              <SwapIcon />
            </ListItemIcon>
            <ListItemText>Paket ersetzen</ListItemText>
          </MenuItem>
        </PopupMenu>
      )}
      <DialogCloseButton onClick={closeDialog} />
      <DialogContent>
        <Column spacing={1}>
          {dialogState === "prices overview" && bookedQuote.drivingLicenseClasses.length > 0 && (
            <Row spacing={1} sx={{ alignItems: "start" }}>
              <Labeled
                label={bookedQuote.drivingLicenseClasses.length === 1 ? "Führerscheinklasse" : "Führerscheinklassen"}
              >
                <Row spacing={1}>
                  {bookedQuote.drivingLicenseClasses.map((it) => (
                    <Chip key={it} label={it} />
                  ))}
                </Row>
              </Labeled>
              <IconButton onClick={() => setDialogState("change driving license class")}>
                <EditIcon />
              </IconButton>
            </Row>
          )}
          {dialogState === "prices overview" && (
            <Box sx={{ paddingTop: "22px" }}>
              <Labeled label="Preise">
                <PricesOverview bookedQuote={bookedQuote} />
              </Labeled>
            </Box>
          )}
        </Column>
        {dialogState === "change driving license class" && (
          <>
            <_ChooseDrivingLicenseClassList
              bookedQuote={bookedQuote}
              selectedDrivingLicenseClasses={selectedDrivingLicenseClasses}
              setSelectedDrivingLicenseClasses={setSelectedDrivingLicenseClasses}
            />
            <DialogContentText sx={{ mt: "1em", fontStyle: "italic" }}>
              Hinweis: Preise bleiben gleich.
            </DialogContentText>
          </>
        )}
        {dialogState === "change price class" && (
          <_ChooseBundleList
            bookedQuote={bookedQuote}
            mode="identical driving license classes"
            selectedBundle={selectedBundle}
            setSelectedBundle={setSelectedBundle}
          />
        )}
        {dialogState === "replace bundle" && (
          <_ChooseBundleList
            bookedQuote={bookedQuote}
            mode="compatible driving license classes"
            selectedBundle={selectedBundle}
            setSelectedBundle={setSelectedBundle}
          />
        )}
        {dialogState.endsWith("> new prices overview") && selectedBundle && (
          <>
            <DialogContentText sx={{ mt: "1em", mb: "1em" }}>
              Paket "{bookedQuote.bundleName}" durch Paket "{selectedBundle.name}" ersetzen.
            </DialogContentText>
            <PricesOverview bookedQuote={bookedQuote} newBundle={selectedBundle} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {dialogState === "prices overview" && (
          <Button
            variant="outlined"
            startIcon={<NavigateNextIcon />}
            onClick={() => setDialogState("change price class")}
          >
            Preise ändern
          </Button>
        )}
        {dialogState === "change driving license class" && (
          <>
            <Button
              variant="outlined"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setDialogState("prices overview")}
              disabled={saving}
            >
              Zurück
            </Button>
            <LoadingButton
              variant="contained"
              loading={saving}
              startIcon={<SaveIcon />}
              disabled={areSetsEqual(
                new Set(bookedQuote.drivingLicenseClasses),
                new Set(selectedDrivingLicenseClasses),
              )}
              onClick={changeDrivingLicenseClasses}
            >
              Speichern
            </LoadingButton>
          </>
        )}
        {dialogState === "change price class" && (
          <>
            <Button
              variant="outlined"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setDialogState("prices overview")}
            >
              Zurück
            </Button>
            <Button
              variant="outlined"
              startIcon={<NavigateNextIcon />}
              disabled={!selectedBundle}
              onClick={() => setDialogState("change price class > new prices overview")}
            >
              Zur Preisübersicht
            </Button>
          </>
        )}
        {dialogState === "change price class > new prices overview" && (
          <>
            <Button
              variant="outlined"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => {
                setSelectedBundle(undefined);
                setDialogState("change price class");
              }}
            >
              Zurück
            </Button>
            <LoadingButton variant="contained" loading={saving} startIcon={<SaveIcon />} onClick={changeBundle}>
              Speichern
            </LoadingButton>
          </>
        )}
        {dialogState === "replace bundle" && (
          <>
            <Button
              variant="outlined"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setDialogState("prices overview")}
            >
              Zurück
            </Button>
            <Button
              variant="outlined"
              startIcon={<NavigateNextIcon />}
              disabled={!selectedBundle}
              onClick={() => setDialogState("replace bundle > new prices overview")}
            >
              Zur Preisübersicht
            </Button>
          </>
        )}
        {dialogState === "replace bundle > new prices overview" && (
          <>
            <Button
              variant="outlined"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => {
                setSelectedBundle(undefined);
                setDialogState("replace bundle");
              }}
            >
              Zurück
            </Button>
            <LoadingButton variant="contained" loading={saving} startIcon={<SaveIcon />} onClick={changeBundle}>
              Speichern
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

function _ChooseDrivingLicenseClassList({
  bookedQuote,
  selectedDrivingLicenseClasses,
  setSelectedDrivingLicenseClasses,
}: {
  bookedQuote: BookedQuote;
  selectedDrivingLicenseClasses: Array<DrivingLicenseClass>;
  setSelectedDrivingLicenseClasses: (selection: Array<DrivingLicenseClass>) => void;
}) {
  return (
    <>
      <DialogContentText sx={{ mt: "1em", mb: "1em" }}>
        {bookedQuote.trainingIds.length === 1
          ? "Bitte wähle die neue Führerscheinklasse aus:"
          : "Bitte wähle die neuen Führerscheinklassen aus:"}
      </DialogContentText>
      <Stack direction="column" spacing={2}>
        {bookedQuote.drivingLicenseClasses.map((drivingLicenseClass, index) => (
          <_ChooseDrivingLicenseClassListItem
            key={drivingLicenseClass}
            oldValue={drivingLicenseClass}
            newValue={selectedDrivingLicenseClasses[index]}
            onChange={(newValue) => {
              const a = selectedDrivingLicenseClasses.map((v, i) => (i === index ? newValue : v));
              setSelectedDrivingLicenseClasses(a);
            }}
          />
        ))}
      </Stack>
    </>
  );
}

function _ChooseDrivingLicenseClassListItem({
  oldValue,
  newValue,
  onChange,
}: {
  oldValue: DrivingLicenseClass;
  newValue: DrivingLicenseClass;
  onChange: (value: DrivingLicenseClass) => void;
}) {
  const drivingLicenseClasses = _getAlternativeDrivingLicenseClasses(oldValue);
  return (
    <Container>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <span>{oldValue}</span>
        <span>{"➔"}</span>
        <Select value={newValue} onChange={({ target: { value } }) => onChange(value as DrivingLicenseClass)}>
          {drivingLicenseClasses.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Container>
  );
}

function _getAlternativeDrivingLicenseClasses(drivingLicenseClass: DrivingLicenseClass): Array<DrivingLicenseClass> {
  if (CAR_DRIVING_LICENSE_CLASSES.has(drivingLicenseClass)) {
    return [...CAR_DRIVING_LICENSE_CLASSES];
  } else if (TRAILER_DRIVING_LICENSE_CLASSES.has(drivingLicenseClass)) {
    return [...TRAILER_DRIVING_LICENSE_CLASSES];
  } else if (MOTORCYCLE_DRIVING_LICENSE_CLASSES.has(drivingLicenseClass)) {
    return [...MOTORCYCLE_DRIVING_LICENSE_CLASSES];
  } else {
    return [drivingLicenseClass];
  }
}

function _areCompatible(a: DrivingLicenseClass, b: DrivingLicenseClass) {
  return (
    a === b ||
    (CAR_DRIVING_LICENSE_CLASSES.has(a) && CAR_DRIVING_LICENSE_CLASSES.has(b)) ||
    (TRAILER_DRIVING_LICENSE_CLASSES.has(a) && TRAILER_DRIVING_LICENSE_CLASSES.has(b)) ||
    (MOTORCYCLE_DRIVING_LICENSE_CLASSES.has(a) && MOTORCYCLE_DRIVING_LICENSE_CLASSES.has(b))
  );
}

function _ChooseBundleList({
  bookedQuote,
  mode,
  selectedBundle,
  setSelectedBundle,
}: {
  bookedQuote: BookedQuote;
  mode: "identical driving license classes" | "compatible driving license classes";
  selectedBundle: Bundle | undefined;
  setSelectedBundle: (selection: Bundle | undefined) => void;
}) {
  const { bundleName, drivingSchoolId, drivingLicenseClasses } = bookedQuote;
  const { data } = useGetList<Bundle>("bundles", {
    filter: { drivingSchoolId },
    sort: { field: "name", order: "ASC" },
    pagination: {} as any as PaginationPayload,
  });
  return (
    <>
      <DialogContentText sx={{ mt: "1em", mb: "1em" }}>Bitte wähle ein alternatives Paket aus:</DialogContentText>
      {!data && (
        <Box sx={{ height: "150px" }}>
          <LoadingIndicator />
        </Box>
      )}
      {data &&
        (() => {
          const a = [...drivingLicenseClasses].sort();
          const isAlternativeBundle =
            mode === "identical driving license classes"
              ? (bundle: Bundle) => {
                  const b = bundle.trainings.map((it) => it.drivingLicenseClass).sort();
                  return a.length === b.length && b.every((v, i) => a[i] === v);
                }
              : mode === "compatible driving license classes"
                ? (bundle: Bundle) => {
                    const b = bundle.trainings.map((it) => it.drivingLicenseClass);
                    return a.length === b.length && b.every((c1) => a.some((c2) => _areCompatible(c1, c2)));
                  }
                : assertNever(mode);
          const alternativeBundles = data.filter((bundle) => bundle.name !== bundleName).filter(isAlternativeBundle);
          if (alternativeBundles.length === 0) {
            if (drivingLicenseClasses.length === 1) {
              return (
                <p className="error">
                  Keine alternativen Pakete für die Führerscheinklasse {drivingLicenseClasses[0]} gefunden.
                </p>
              );
            } else {
              return (
                <p className="error">
                  Keine alternativen Pakete für die Führerscheinklassen {drivingLicenseClasses.join(", ")} gefunden.
                </p>
              );
            }
          }
          return (
            <List>
              {alternativeBundles.map((bundle) => {
                const isSelected = selectedBundle?.id === bundle.id;
                return (
                  <ListItemButton key={bundle.id} selected={isSelected} onClick={() => setSelectedBundle(bundle)}>
                    <ListItemText>{bundle.name}</ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          );
        })()}
    </>
  );
}
