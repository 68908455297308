import { RecordContextProvider, useGetList, useGetRecordId } from "react-admin";
import { PageTitle } from "../../misc/PageTitle";
import { type Employee } from "../../providers/employeesProvider";
import * as React from "react";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { Avatar, Typography } from "@mui/material";
import { Column } from "../../misc/Column";
import { CardsGrid } from "../../misc/CardsGrid";
import { autovioColors } from "../../misc/backofficeTheme";
import { useNavigate } from "react-router";

const { grey, red } = autovioColors;

export function DrivingSchoolCustomerService() {
  const drivingSchoolId = useGetRecordId();
  const { data: customerService } = useGetList<Employee>(
    "employees",
    {
      filter: {
        drivingSchoolId,
        roles: (it: Array<Role>) => it.includes("drivingSchoolCustomerService") && !it.includes("instructor"),
      },
      pagination: { page: 1, perPage: 9999 },
      sort: { field: "_sortOrder", order: "ASC" },
    },
    { enabled: !!drivingSchoolId },
  );

  return (
    <>
      <PageTitle>Support</PageTitle>
      {!customerService && <LoadingIndicator />}
      {customerService && (
        <CardsGrid>
          {customerService.map((employee) => (
            <_CustomerServiceCard key={employee.id} employee={employee} />
          ))}
        </CardsGrid>
      )}
    </>
  );
}

function _CustomerServiceCard({ employee }: { employee: Employee }) {
  const navigate = useNavigate();

  return (
    <RecordContextProvider value={employee}>
      <CardsGrid.Card onClick={() => navigate(`/employees/${employee.id}`)}>
        <Column sx={{ mt: "28px", height: "192px", alignItems: "center" }} gap="8px">
          <Avatar src={employee.avatarUrl} sx={{ width: 60, height: 60 }} />
          <_Name employee={employee} />
          <Typography variant="body2" color={grey}>
            Support
          </Typography>
        </Column>
      </CardsGrid.Card>
    </RecordContextProvider>
  );
}

function _Name({ employee }: { employee: Employee }) {
  return (
    <Typography
      sx={{
        fontSize: "19px",
        fontWeight: 600,
        lineHeight: "23px",
        textAlign: "center",
        ...(employee.disabled && { color: red }),
      }}
    >
      {employee.firstName || "\u00A0"}
      <br />
      {employee.lastName || "\u00A0"}
    </Typography>
  );
}
