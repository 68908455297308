import type { Trailer } from "../providers/resourceProviders";
import { TrailerIcon } from "../icons/TrailerIcon";
import { Create, EditBase, Resource, Tab, useNotify, useRedirect } from "react-admin";
import { TrailerForm } from "../details/vehicles/TrailerForm";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { Column } from "../misc/Column";
import { overviewCardStyle } from "../misc/backofficeTheme";
import { Tabs } from "../misc/Tabs";
import { ResourceCalendar } from "../details/vehicles/ResourceCalendar";
import { grants } from "../backoffice.access_control";
import { VehicleDetailsHeader } from "../details/vehicles/VehicleDetailsHeader";
import { useSwitchDrivingSchoolIfNecessary } from "../hooks/useSwitchDrivingSchoolIfNecessary";

function _TrailersList() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  useEffect(() => {
    if (drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/fleet#trailers`);
    }
  });
  return drivingSchoolId ? null : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

function _TrailerCreate() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  const notify = useNotify();
  useEffect(() => {
    if (!grants.includes("Vehicle:create") && drivingSchoolId) {
      redirect(`/drivingSchools/${drivingSchoolId}/fleet#trailers`);
    }
  }, []);

  if (!grants.includes("Vehicle:create")) {
    return null;
  }

  return drivingSchoolId ? (
    <Create
      title={<BackofficeRecordTitle />}
      mutationOptions={{
        onSuccess: ({ name }: { name: string }) => {
          window.onNavigation = () => {
            delete window.onNavigation;
            notify(`Anhänger ${name} erfolgreich gespeichert`, { type: "success" });
          };
          redirect(`/drivingSchools/${drivingSchoolId}/fleet#trailers`);
        },
      }}
    >
      <TrailerForm mode="create" sx={{ padding: overviewCardStyle.padding }} />
    </Create>
  ) : (
    <Box className="centered">
      <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
    </Box>
  );
}

function _TrailerEdit() {
  useSwitchDrivingSchoolIfNecessary((trailer: Trailer) => trailer.entitledDrivingSchools[0]);
  const notify = useNotify();
  return (
    <EditBase
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess: () => notify("Änderungen erfolgreich gespeichert", { type: "success" }) }}
    >
      <Tabs
        sx={{ ...overviewCardStyle, padding: "10px 30px" }}
        header={<VehicleDetailsHeader />}
        syncWithLocation={false}
        syncWithHash
        separateCards
      >
        <Tab label="Kalender" value="calendar">
          <ResourceCalendar />
        </Tab>
        {grants.includes("Vehicle:edit") && (
          <Tab label="Anhänger-Daten" value="data">
            <TrailerForm mode="edit" />
          </Tab>
        )}
      </Tabs>
    </EditBase>
  );
}

export const trailersResource = (
  <Resource
    key="trailers"
    name="trailers"
    icon={TrailerIcon}
    list={_TrailersList}
    create={_TrailerCreate}
    edit={_TrailerEdit}
    options={{ label: "Anhänger", createTitle: "Neuer Anhänger" }}
    recordRepresentation={(record: Trailer) => {
      return record.deletedAt
        ? `${record.name} (gelöscht am: ${record.deletedAt.toLocaleString(undefined, { locale: "de" })})`
        : record.name;
    }}
  />
);
