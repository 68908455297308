import { Box } from "@mui/material";
import { overviewCardStyle } from "../../misc/backofficeTheme";
import { Labeled, TextField, useRecordContext } from "react-admin";
import { Column } from "../../misc/Column";
import { Employee } from "../../providers/employeesProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { EmailField } from "../../fields/EmailField";
import { PhoneNumberField } from "../../fields/PhoneNumberField";

export function EmployeeData() {
  const employee = useRecordContext<Employee>();

  if (!employee) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={overviewCardStyle}>
      <Column gap={1}>
        <Labeled label="Vorname">
          <TextField source="firstName" />
        </Labeled>
        <Labeled label="Nachname">
          <TextField source="lastName" />
        </Labeled>
        <Labeled label="E-Mail">
          <EmailField source="email" />
        </Labeled>
        <Labeled label="Telefon">
          <PhoneNumberField source="phoneNumber" emptyText="Nicht hinterlegt" />
        </Labeled>
      </Column>
    </Box>
  );
}
