import { styled } from "@mui/material/styles";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { CommonInputProps, FieldTitle, InputHelperText, useInput } from "react-admin";
import { TimeOfDay, TimeOfDayRegex } from "../model/TimeOfDay";
import { Weekday } from "../model/Weekday";
import { WeeklyHoursSchema } from "../model/WeeklyHours";
import { DateTime } from "luxon";

type _Line = [DateTime | undefined, DateTime | undefined];
type _Lines = Array<_Line>;
type _FieldValue = Record<Weekday, _Lines>;
type _WeeklyHoursInputValue = Array<[Weekday, TimeOfDay | undefined, TimeOfDay | undefined]>;

/**
 * Custom input for `WeeklyHours`.
 */
export function WeeklyHoursInput(props: Omit<CommonInputProps, "parse" | "format">) {
  const {
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput<_WeeklyHoursInputValue>({
    ...props,
    parse: (val: _FieldValue) => {
      const weeklyHours: _WeeklyHoursInputValue = [];
      for (const [weekday, lines] of Object.entries(val)) {
        for (const [from, to] of lines) {
          weeklyHours.push([weekday as Weekday, _dateTime2TimeOfDay(from), _dateTime2TimeOfDay(to)]);
        }
      }
      return weeklyHours;
    },
    format: (weeklyHours: undefined | _WeeklyHoursInputValue) => {
      const val: _FieldValue = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      };
      if (weeklyHours) {
        for (const [weekday, from, to] of weeklyHours) {
          val[weekday].push([_timeOfDay2DateTime(from), _timeOfDay2DateTime(to)]);
        }
      }
      return val;
    },
    validate: [
      (weeklyHours: undefined | _WeeklyHoursInputValue) => {
        if (WeeklyHoursSchema.safeParse(weeklyHours).success) {
          return null;
        } else {
          return "Bitte vervollständige deine Eingabe.";
        }
      },
      ...(Array.isArray(props.validate) ? props.validate : props.validate ? [props.validate] : []),
    ],
  });
  return (
    <div>
      <FormControl>
        <InputLabel>
          <FieldTitle {...props} isRequired={isRequired} />
        </InputLabel>
        <Table sx={{ width: "auto", "& .MuiTableCell-root": { border: "none", py: 0 } }}>
          <TableBody>
            {(Object.entries(field.value as _FieldValue) as Array<[Weekday, _Lines]>)
              .map(([weekday, lines]) => {
                if (lines.length === 0) {
                  return (
                    <_WeeklyHoursInputLine
                      key={`${weekday}-line-0`}
                      weekday={weekday}
                      onFromChange={(newValue) => {
                        field.value[weekday] = [[newValue, undefined]];
                        field.onChange(field.value);
                      }}
                      onToChange={(newValue) => {
                        field.value[weekday] = [[undefined, newValue]];
                        field.onChange(field.value);
                      }}
                    />
                  );
                } else {
                  const lineElements = lines.map((line, i) => (
                    <_WeeklyHoursInputLine
                      key={`${weekday}-line-${i}`}
                      weekday={i === 0 ? weekday : null /* ... only show the weekday in the first line */}
                      value={line}
                      onFromChange={(newValue) => {
                        line[0] = newValue;
                        field.onChange(field.value);
                      }}
                      onToChange={(newValue) => {
                        line[1] = newValue;
                        field.onChange(field.value);
                      }}
                      onRemoveLine={() => {
                        lines.splice(i, 1);
                        field.onChange(field.value);
                      }}
                      onAddLine={
                        _isValid(line)
                          ? () => {
                              lines.splice(i + 1, 0, [undefined, undefined]);
                              field.onChange(field.value);
                            }
                          : undefined
                      }
                    />
                  ));
                  return lineElements;
                }
              })
              .flat()}
          </TableBody>
        </Table>
        <FormHelperText error={(isTouched || isSubmitted) && invalid}>
          <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={props.helperText} />
        </FormHelperText>
      </FormControl>
    </div>
  );
}

function _WeeklyHoursInputLine({
  weekday,
  value,
  onFromChange,
  onToChange,
  onRemoveLine,
  onAddLine,
}: {
  weekday: Weekday | null;
  value?: _Line;
  onFromChange: (value: DateTime) => void;
  onToChange: (value: DateTime) => void;
  onRemoveLine?: () => void;
  onAddLine?: () => void;
}) {
  return (
    <TableRow key={weekday} sx={{ border: "none" }}>
      <TableCell component="th" sx={{ px: 0 }}>
        {weekday ? _weekday2Abbreviation[weekday] : null}
      </TableCell>
      <TableCell sx={{ pr: 0 }}>
        <_TimePicker
          value={value ? value[0] || DateTime.invalid("undefined") : null}
          onChange={
            (value) =>
              onFromChange(value as DateTime) /* ... value is a DateTime because of the AdapterLuxon (see main.tsx) */
          }
        />
      </TableCell>
      <TableCell>
        <Typography>-</Typography>
      </TableCell>
      <TableCell sx={{ p: 0 }}>
        <_TimePicker
          value={value ? value[1] || DateTime.invalid("undefined") : null}
          onChange={
            (value) =>
              onToChange(value as DateTime) /* ... value is a DateTime because of the AdapterLuxon (see main.tsx) */
          }
        />
      </TableCell>
      <TableCell sx={{ pl: "5px", pr: 0 }}>
        <IconButton
          disabled={!onRemoveLine}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onRemoveLine?.();
          }}
        >
          <ClearIcon />
        </IconButton>
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        <IconButton
          disabled={!onAddLine}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onAddLine?.();
          }}
        >
          <AddIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const _TimePicker = styled(TimePicker)({
  "& .MuiInputBase-root": { width: "110px", minWidth: "110px", height: "50px" },
  "& input": { paddingLeft: "10px", width: "90px" },
  "& .MuiInputAdornment-root": { ml: 0, mr: "5px" },
});

const _weekday2Abbreviation: Record<Weekday, string> = {
  Monday: "Mo",
  Tuesday: "Di",
  Wednesday: "Mi",
  Thursday: "Do",
  Friday: "Fr",
  Saturday: "Sa",
  Sunday: "So",
};

function _timeOfDay2DateTime(timeOfDay: TimeOfDay | undefined): DateTime | undefined {
  if (timeOfDay) {
    const match = TimeOfDayRegex.exec(timeOfDay);
    if (!match) {
      return DateTime.invalid(`invalid TimeOfDay: ${JSON.stringify(timeOfDay)}`);
    }
    return DateTime.fromObject({ hour: parseInt(match[1], 10), minute: parseInt(match[2], 10) });
  }
}

function _dateTime2TimeOfDay(dateTime: DateTime | undefined): TimeOfDay | undefined {
  return dateTime?.isValid ? dateTime.toFormat("HH:mm") : undefined;
}

function _isValid(line: _Line): boolean {
  return !!(_dateTime2TimeOfDay(line[0]) && _dateTime2TimeOfDay(line[1]));
}
