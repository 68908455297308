import { Stack, Typography } from "@mui/material";
import { getDownloadURL } from "firebase/storage";
import { useState } from "react";
import {
  CheckboxGroupInput,
  EditBase,
  SaveButton,
  SimpleForm,
  TextInput,
  useEditContext,
  useGetRecordId,
  useNotify,
} from "react-admin";
import { useForm } from "react-hook-form";
import { ImageUploadField } from "../../fields/ImageUploadField";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { isDefined } from "../../utils/filters";
import { gcs } from "../../utils/storage";
import { OfferFeedbackChart } from "./OfferFeedbackChart";
import { CustomizationFlag } from "../../model/customizationFlags";

export function DrivingSchoolAdminForm() {
  return (
    <EditBase redirect={false} mutationMode="pessimistic">
      <SimpleForm sx={{ padding: "1em 0" }} warnWhenUnsavedChanges toolbar={<></>}>
        <AdminFormFields />
      </SimpleForm>
    </EditBase>
  );
}

interface CustomizationMapping {
  readonly id: CustomizationFlag;
  readonly name: string;
}

export const CUSTOMIZATION_CHOICES: CustomizationMapping[] = [
  { id: "asfCourses", name: "ASF-Kurse" },
  { id: "carSharing", name: "Car Sharing" },
  { id: "paymentAndInvoicing", name: "Payment & Invoicing" },
  { id: "reports", name: "Fahrlehrer können Reports in der AUTOVIO-App einsehen" },
  { id: "studentAdministrativeOnboarding", name: "Student Administrative Onboarding" },
  { id: "zoomMeetings", name: "Zoom Meetings" },
  { id: "googleCalendarSync", name: "Google Calendar Sync" },
  { id: "theoryLearning", name: "Fahrschüler können die Theorie mit der AUTOVIO-App lernen" },
  { id: "pickupService", name: "Pickup Service" },
  { id: "pickupFromHomeAddress", name: "Pickup From Home Address" },
  { id: "cancellationPolicyPerLesson", name: "Cancellation Policy per Lesson" },
  { id: "newStudentsCanBookDrivingLessons", name: "Neue Fahrschüler können Fahrstunden buchen" },
  { id: "referralProgram", name: "Empfehlungsprogramm" },
  { id: "canAddGiftedCredits", name: "Darf Guthaben verschenken (100% Fahrschule/0% AUTOVIO)" },
  { id: "canManageStripeIBAN", name: "Kann Stripe IBAN-Daten einsehen und erstellen" },
];

const AdminFormFields = () => {
  const id = useGetRecordId();
  const { record, save } = useEditContext<DrivingSchool>();
  const { getValues } = useForm();
  const [files, setFiles] = useState<File[]>([]);
  const notify = useNotify();

  if (!record) {
    return null;
  }

  return (
    <>
      <Typography fontWeight="bold" gutterBottom>
        Empfehlungskarte
      </Typography>
      <Stack pt="1em">
        <TextInput source="referralContent.title" label="Titel" multiline resettable />
        <TextInput source="referralContent.text" label="Text" multiline resettable />
      </Stack>
      <Typography fontWeight="bold" gutterBottom>
        Customizations
      </Typography>
      <CheckboxGroupInput label="" source="customizations" choices={CUSTOMIZATION_CHOICES} row={false} />
      <Typography fontWeight="bold" gutterBottom>
        Fahrschul-Logo
      </Typography>
      <ImageUploadField defaultImageUrl={record.logoUrl} onDropImage={(files) => setFiles(files)} />
      <Typography fontWeight="bold" gutterBottom pt="2em">
        Link zum Fahrschulprofil
      </Typography>
      <TextInput source="profileUrl" label="Fahrschulprofil" resettable />
      <Typography fontWeight="bold" gutterBottom>
        Feedback zu Angeboten
      </Typography>
      <OfferFeedbackChart />
      {/* We "force" enable the button when there is a logo file change */}
      <SaveButton
        alwaysEnable={files.length !== 0}
        sx={{ mt: "2em" }}
        onClick={async () => {
          const definedChanges = Object.fromEntries(
            Object.entries(getValues()).filter(([, value]) => isDefined(value)),
          );
          let updatedRecord = { ...record, ...definedChanges };
          if (files.length === 1) {
            const file = files[0];
            try {
              const result = await gcs.uploadFile(file, (ext) => `/driving-schools/${id}/logo/logo.${ext}`);
              updatedRecord = { ...updatedRecord, logoUrl: await getDownloadURL(result.ref) };
            } catch (error) {
              console.error("Failed to upload image", error);
              notify("Fehler beim Hochladen des Bilds", { type: "error" });
              return;
            }
          }
          await save?.(updatedRecord);
        }}
      />
    </>
  );
};
