import { Resource } from "react-admin";
import { EmployeeDetails } from "../details/EmployeeDetails";

export const employeesResource = (
  <Resource
    key="employees"
    name="employees"
    edit={EmployeeDetails}
    options={{ label: "Angestellte" }}
    recordRepresentation={(employees) => employees.name.replaceAll(" ", "\u00A0")}
  />
);
