import { SvgIconProps } from "@mui/material";
import { CarIcon } from "./CarIcon";
import { MotorcycleIcon } from "./MotorcycleIcon";
import { TrailerIcon } from "./TrailerIcon";
import { SimulatorIcon } from "./SimulatorIcon";
import { ResourceType } from "../api/resource.api";
import { assertNever } from "../backoffice.utils";

interface VehicleIconProps extends SvgIconProps {
  type: ResourceType;
}

export function VehicleIcon({ type, ...svgIconProps }: VehicleIconProps) {
  switch (type) {
    case "car":
      return <CarIcon {...svgIconProps} />;
    case "motorcycle":
      return <MotorcycleIcon {...svgIconProps} />;
    case "trailer":
      return <TrailerIcon {...svgIconProps} />;
    case "simulator":
      return <SimulatorIcon {...svgIconProps} />;
    default:
      assertNever(type);
  }
}
