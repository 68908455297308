import type { ReactElement, ReactNode } from "react";
import { Tab, TabbedShowLayoutProps, TabProps } from "react-admin";
import { NewDesignTabbedShowLayout } from "./NewDesignTabbedShowLayout";

type TabElement = ReactElement<TabProps, typeof Tab>;

type TabsProps = {
  header?: ReactNode;
  additionalButtons?: ReactNode;
  children: Array<TabElement | false | undefined | null>;
  separateCards?: boolean;
  fullWidth?: boolean;
  syncWithHash?: boolean;
} & Omit<TabbedShowLayoutProps, "tabs">;

export function Tabs({ header, additionalButtons, separateCards, fullWidth = false, children, ...rest }: TabsProps) {
  return (
    <NewDesignTabbedShowLayout
      header={header}
      additionalButtons={additionalButtons}
      tabs={children}
      separateCards={separateCards}
      fullWidth={fullWidth}
      {...rest}
    />
  );
}
