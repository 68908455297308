import { Link, Typography } from "@mui/material";
import { FieldProps, sanitizeFieldRestProps, useRecordContext } from "react-admin";
import get from "lodash/get";
import {} from "ra-ui-materialui/src/field/sanitizeFieldRestProps";

interface EmailFieldProps extends FieldProps {
  source: string;
  /** default: "body2" */
  variant?: "body1" | "body2";
}

export function EmailField(props: EmailFieldProps) {
  const { source, variant = "body2", ...rest } = props;
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  const email = get(record, source);
  return (
    <Typography variant={variant} {...sanitizeFieldRestProps(rest)}>
      {email && (
        <Link href={`mailto:${email}`} target="_blank">
          {email}
        </Link>
      )}
      {!email && <i>{props.emptyText ?? "Nicht hinterlegt"}</i>}
    </Typography>
  );
}
