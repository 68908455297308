import { FilterPayload, GetListResult, PaginationPayload, RaRecord, SortPayload } from "react-admin";
import { DateTime, Duration } from "luxon";
import { DocumentData, Timestamp } from "firebase/firestore";
import { z, ZodType } from "zod";
import get from "lodash/get";
import { DurationLikeObject } from "luxon/src/duration";
import { InvoiceStateEnum } from "./generated/backendClient";
import { autovioColors } from "./misc/backofficeTheme";
import { compile } from "html-to-text";
import safeJsonStringify from "safe-json-stringify";

export function safeToString(x: any): string {
  try {
    return `${x}`;
  } catch (error) {
    try {
      return `Could not be converted to a string -- ${safeErrorToString(error)}`;
    } catch {
      return "Could not be converted to a string";
    }
  }
}

export function safeErrorToString(error: any) {
  let s = "Error";
  try {
    try {
      s = `${error}`;
    } catch {
      // Ignored
    }
    try {
      let stack: string = error.stacktrace || error.stack;
      if (stack) {
        stack = `${stack}`;
        if (error.message && stack.indexOf(error.message) >= 0) {
          s = stack;
        } else if (s.indexOf(stack) < 0) {
          s += "\n" + stack;
        }
      }
    } catch {
      // Ignored
    }
    if (s.startsWith("[object")) {
      // No error? What do we have here? Assemble a string with all properties ...
      const a = [];
      for (const k in error) {
        // Because we want *all* properties of the error (except functions), we don't filter with hasOwnProperty ...
        const v: any = error[k];
        if (typeof v !== "function") {
          a.push(`${k}: ${safeToString(v)}`);
        }
      }
      s = a.join("\n\t");
    }
    try {
      const t = classNameOf(error);
      if (s.indexOf(t) < 0) {
        s = t + ": " + s;
      }
    } catch {
      // Ignored
    }
  } catch {
    // Ignored
  }
  return s;
}

export function classNameOf(x: any) {
  let result: string = typeof x;
  if (result === "object") {
    if (x === null) {
      result = "null";
    } else {
      const c = x.constructor;
      if (c) {
        result = c.displayName || c.name;
        if (!result) {
          try {
            // Workaround for IE -- see https://goo.gl/IUuSNO ...
            const a = /function\s+([^\s(]+)/.exec(c.toString());
            result = a![1];
          } catch {
            // Ignored
          }
        }
        if (!result) {
          result = "object";
        }
      }
    }
  }
  return result;
}

export class ReportedError extends Error {
  constructor(public error: any) {
    super(`ReportedError: ${error}`);
    this.name = "ReportedError";
  }
}

export function reportError(message: string, error?: any): void {
  if (error instanceof ReportedError) {
    return;
  }
  if (error) {
    console.error(message, error);
  } else {
    console.error(message);
  }
}

export function preventValueChangeOnWheel(inputElm: HTMLInputElement | null) {
  inputElm?.addEventListener("wheel", () => inputElm.blur());
}

const _escapeHtmlReplacements: { [c: string]: string } = {
  "<": "&lt;",
  ">": "&gt;",
  "&": "&amp;",
  '"': "&quot;",
  "'": "&#39;",
};

export function escapeHtml(s: string): SafeHtml {
  return s.replace(/[<>&"']/g, (c: string) => _escapeHtmlReplacements[c]) as SafeHtml;
}

export function applyFilter<T extends RaRecord>(records_: Iterable<T>, filter: FilterPayload): Array<T> {
  const { q, ...fieldFilters } = filter ?? {};
  const today = DateTime.now().toISODate();
  let records = Array.isArray(records_) ? records_ : [...records_];
  for (const [key, filterValue] of Object.entries(fieldFilters)) {
    records = records.filter((record) => {
      if (key === "__valid_examination_assignments__") {
        return record.examinationAssignmentExpiresAt >= today;
      } else if (key === "__expired_examination_assignments__") {
        return record.examinationAssignmentExpiresAt < today;
      } else {
        const fieldValue = key === "__record__" ? record : record[key];
        if (typeof filterValue === "function") {
          return filterValue(fieldValue);
        } else if (Array.isArray(fieldValue)) {
          return Array.isArray(filterValue)
            ? filterValue.some((it) => fieldValue.includes(it))
            : fieldValue.includes(filterValue);
        } else {
          if (Array.isArray(filterValue)) {
            return filterValue.includes(fieldValue);
          } else {
            if (filterValue === "!") {
              return !fieldValue;
            }
            return fieldValue === filterValue;
          }
        }
      }
    });
  }
  if (q) {
    const qLowerCased = q.toLowerCase();
    records = records.filter((record) => {
      for (const value of Object.values(record)) {
        if (value) {
          if (Array.isArray(value)) {
            for (const item of value) {
              if (item.toString().toLowerCase().indexOf(qLowerCased) >= 0) {
                return true;
              }
            }
          } else if (value.toString().toLowerCase().indexOf(qLowerCased) >= 0) {
            return true;
          }
        }
      }
      return false;
    });
  }
  return records;
}

export function applySort<T extends RaRecord>(records: Array<T>, { field, order }: SortPayload): Array<T> {
  // Sort ...
  return records.sort((record1, record2) => {
    let a = get<any, string>(record1, field);
    let b = get<any, string>(record2, field);
    if (Array.isArray(a)) {
      a = a.join(", ");
    }
    if (Array.isArray(b)) {
      b = b.join(", ");
    }
    let d: number;
    if (typeof a === "number" || typeof b === "number") {
      if (typeof a !== "number") {
        d = 1;
      } else if (typeof b !== "number") {
        d = -1;
      } else {
        d = a - b;
      }
    } else {
      d = (a ?? "").toString().localeCompare(b ?? "");
    }
    return order === "DESC" ? -1 * d : d;
  });
}

export function applyPagination<T extends RaRecord>(
  records: Array<T>,
  { page, perPage }: PaginationPayload,
): GetListResult<T> {
  let data: Array<T>;
  if (perPage) {
    const start = Math.min((page - 1) * perPage, records.length);
    const end = Math.min(start + perPage, records.length);
    data = records.slice(start, end);
  } else {
    data = records;
  }
  return { data, total: records.length };
}

export function fromFirestore(x: Timestamp, fieldPath?: string): DateTime;
export function fromFirestore(x: Timestamp | undefined, fieldPath?: string): DateTime | undefined;
export function fromFirestore(x: DocumentData, fieldPath?: string): { [property: string]: unknown };
export function fromFirestore(x: unknown, fieldPath = ""): unknown {
  if (Array.isArray(x)) {
    return x.map((item, index) => fromFirestore(item, `${fieldPath}[${index}]`));
  }
  if (typeof x === "object") {
    if (x === null) {
      return null;
    } else if (x instanceof Timestamp) {
      return DateTime.fromJSDate(x.toDate());
    }
    const { constructor } = x;
    if (!constructor) {
      throw new Error(`Object has no constructor (fieldPath: ${fieldPath})`);
    }
    if (constructor.name === "Object") {
      return Object.fromEntries(
        Object.entries(x).map(([k, v]) => {
          const propertyPath = fieldPath ? `${fieldPath}.${k}` : k;
          return [k, fromFirestore(v, propertyPath)];
        }),
      );
    }
  }
  return x;
}

export function zodDateTime(): ZodType<DateTime> {
  return z
    .any()
    .transform((arg: unknown) => {
      if (typeof arg === "string") {
        return DateTime.fromISO(arg);
      } else if (arg instanceof DateTime) {
        return arg;
      } else if (arg instanceof Date) {
        return DateTime.fromJSDate(arg);
      } else if (arg instanceof Timestamp) {
        return fromFirestore(arg);
      } else {
        return DateTime.invalid(
          "unexpected type",
          `unexpected type: ${(arg && (arg as any).constructor?.name) || typeof arg}`,
        );
      }
    })
    .refine(
      (it) => it.isValid,
      (it) => ({
        message: it.invalidExplanation ?? "invalid DateTime",
      }),
    );
}

export function assertNever(shouldBeNever: never): never {
  throw new Error(`Unexpected value: ${safeJsonStringify(shouldBeNever)}`);
}

export function areSetsEqual<T>(a: Set<T>, b: Set<T>): boolean {
  return a.size === b.size && [...a].every((value) => b.has(value));
}

/**
 * Repeatedly executes the given callback function (with exponential backoff)
 * until it either resolves to/returns `true` or until the timeout is reached (in which case an Error is thrown).
 */
export async function postCondition(f: () => boolean | Promise<boolean>, timeoutMilliseconds = 10000) {
  const timeout = Date.now() + timeoutMilliseconds;
  let lastRetry = false;
  // eslint-disable-next-line no-constant-condition
  for (let attempt = 1; true; ++attempt) {
    try {
      const result = f();
      if (typeof result === "boolean") {
        if (result) {
          return;
        }
        throw new Error(`postCondition callback returned: ${result}`);
      } else {
        const asyncResult = await result;
        if (asyncResult) {
          return;
        }
        throw new Error(`postCondition callback resolve to: ${asyncResult}`);
      }
    } catch (error) {
      if (Date.now() >= timeout) {
        if (lastRetry) {
          throw new Error(`postCondition not satisfied within ${timeoutMilliseconds} ms -- last error: ${error}`);
        } else {
          lastRetry = true;
        }
      } else {
        await new Promise((resolve) => setTimeout(resolve, attempt * 50));
      }
    }
  }
}

export function to45MinUnits({ start, end }: { start: DateTime; end: DateTime }): number {
  return end.diff(start).shiftTo("minute").minutes / 45;
}

export async function sleep(duration: Duration | DurationLikeObject) {
  const duration_ = Duration.isDuration(duration) ? duration : Duration.fromObject(duration);
  await new Promise((resolve) => setTimeout(resolve, duration_.toMillis()));
}

export function calculateTheoryLearningProgress(theoryLearningProgress?: {
  numQuestions: number;
  numCorrectlyAnsweredQuestions: number;
  numIncorrectlyAnsweredQuestions: number;
}) {
  if (!theoryLearningProgress) return "0,0";
  const { numCorrectlyAnsweredQuestions, numQuestions } = theoryLearningProgress;
  const progress = numQuestions > 0 ? numCorrectlyAnsweredQuestions / numQuestions : 0;
  const progressPercent = progress * 100;
  // Avoid rounding to 100% when progress is 99,999%, which can happen when a student has answered all questions correctly except one or two.
  const floorFixedProgressPercent = (Math.floor(progressPercent * 10) / 10).toFixed(1);
  return floorFixedProgressPercent.replace(".", ",");
}

export const INVOICE_STATUS_LABEL_AND_COLOR: {
  [K in InvoiceStateEnum]: { label: string; color: string; bgColor: string };
} = {
  UNPAID: { label: "️Warte auf Zahlung", bgColor: autovioColors.greyUltraLight, color: autovioColors.grey },
  UNPAID_WITH_PREPAID_CREDITS: {
    label: "️Guthaben nicht anwendbar",
    bgColor: autovioColors.orangeUltraLight,
    color: autovioColors.orange,
  },
  AWAITING_PAYMENT: { label: "In Arbeit", bgColor: autovioColors.greyUltraLight, color: autovioColors.grey },
  PAID: { label: "Bezahlt", bgColor: autovioColors.greenUltraLight, color: autovioColors.green },
  REFUNDED: { label: "Erstattet", bgColor: autovioColors.greyUltraLight, color: autovioColors.grey },
  PAYMENT_REVOKED: { label: "Angefochten", bgColor: autovioColors.orangeUltraLight, color: autovioColors.orange },
  PARTIALLY_REFUNDED: {
    label: "Teilw. rückerstattet",
    bgColor: autovioColors.greyUltraLight,
    color: autovioColors.grey,
  },
};

export function replaceCommasWithDot(input: string) {
  const dotSymbol = "•";
  return input.replace(/,/g, ` ${dotSymbol}`);
}

// See https://github.com/html-to-text/node-html-to-text/tree/master/packages/html-to-text#options
export const htmlToPlainText = compile({});
