import { Box, Stack, Typography } from "@mui/material";
import {
  Labeled,
  Link,
  NumberField,
  NumberInput,
  TextField,
  TextInput,
  email,
  useCreatePath,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { grants } from "../../backoffice.access_control";
import { AgeField } from "../../fields/AgeField";
import { DateField } from "../../fields/DateField";
import { PostalAddressField } from "../../fields/address/PostalAddressField";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { CommaSeparatedFieldList } from "../../misc/CommaSeparatedFieldList";
import { Student } from "../../providers/studentsProvider";
import { MoneyField } from "../../fields/MoneyField";
import { PhoneNumberField } from "../../fields/PhoneNumberField";
import { AuthProvidersField } from "../../fields/AuthProvidersField";
import { LabeledAndEditable } from "../../misc/LabeledAndEditable";
import { YesNoInput } from "../../inputs/YesNoInput";
import { YesNoField } from "../../fields/YesNoField";
import { FundingInstructionsField } from "../../fields/FundingInstructionsField";
import { EditPostalAddressButton } from "../../buttons/address/EditPostalAddressButton";
import { InvoiceAddressField } from "../../fields/address/InvoiceAddressField";
import { EditInvoiceAddressButton } from "../../buttons/address/EditInvoiceAddressButton";
import { ResetPasswordLink } from "../../links/ResetPasswordLink";
import { MoneyInput } from "../../inputs/MoneyInput";
import { DrivingSchoolChangeField } from "../../fields/DrivingSchoolChangeField";
import { DrivingSchoolChangeInput } from "../../inputs/DrivingSchoolChangeInput";
import { autovioColors, overviewCardStyle } from "../../misc/backofficeTheme";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { EditStudentGuardianButton } from "../../buttons/EditStudentGuardianButton";
import { StudentTab } from "../student/StudentTab";
import { DateInput } from "../../inputs/DateInput";

export function StudentProfile() {
  const student = useRecordContext<Student>();

  return (
    <Box sx={overviewCardStyle}>
      <Stack direction="row" spacing={8}>
        {/* --- First column --- */}
        <Stack spacing={2}>
          <Labeled label="Nutzer erstellt am">
            <DateField source="startDate" emptyText="Keine Angabe" />
          </Labeled>
          <Labeled
            label={
              grants.includes("resetPassword") ? (
                <>
                  Login (<ResetPasswordLink label="Passwort setzen" />)
                </>
              ) : (
                "Login"
              )
            }
          >
            <AuthProvidersField />
          </Labeled>
          <LabeledAndEditable
            label="E-Mail-Adresse für Benachrichtigungen"
            renderInput={() => <TextInput source="email" sx={{ ml: 0 }} />}
            disableEdit={!grants.includes("editStudentPersonalData")}
          >
            <TextField source="email" />
          </LabeledAndEditable>
          <Labeled label="Letzter Login">
            <Box>
              <DateField source="lastLogin.at" showTime />
              &nbsp;
              {student.lastLogin && (
                <>
                  (<TextField source="lastLogin.platform" sx={{ ml: 0 }} />)
                </>
              )}
            </Box>
          </Labeled>
          <LabeledAndEditable
            label="Wechsel"
            renderInput={({ submit }) => (
              <Labeled label="Wechsel" color={autovioColors.green}>
                <DrivingSchoolChangeInput submit={submit} />
              </Labeled>
            )}
            disableEdit={!student.isSchoolChanger}
          >
            <DrivingSchoolChangeField />
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Vorname"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <TextInput source="firstName" />}
          >
            <TextField source="firstName" />
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Nachname"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <TextInput source="lastName" />}
          >
            <TextField source="lastName" />
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Geburtsdatum"
            source="dateOfBirth"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <DateInput source="dateOfBirth" allowPastDates />}
          >
            <Row spacing={0.5}>
              <DateField source="dateOfBirth" />
              <AgeField source="dateOfBirth" />
            </Row>
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Geburtsort"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <TextInput source="cityOfBirth" />}
          >
            <TextField source="cityOfBirth" />
          </LabeledAndEditable>
          <Labeled label="Wohnort">
            <Stack direction="row" spacing={1} alignItems="center">
              <PostalAddressField source="postalAddress" emptyText="Keine Angabe" />
              {grants.includes("editStudentPersonalData") && <EditPostalAddressButton />}
            </Stack>
          </Labeled>
          <Stack direction="row" spacing={1} alignItems="center" whiteSpace="nowrap">
            <Labeled label="Rechnungsadresse">
              <InvoiceAddressField source="invoiceAddress" emptyText="Keine Angabe" />
            </Labeled>
            {grants.includes("editStudentPersonalData") && <EditInvoiceAddressButton />}
          </Stack>
          <LabeledAndEditable
            label="E-Mail-Adresse für Rechnungen"
            renderInput={() => <TextInput source="invoiceAddress.email" validate={email()} />}
            disableEdit={!grants.includes("editStudentPersonalData")}
          >
            <TextField source="invoiceAddress.email" />
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Telefonnummer"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <TextInput source="phoneNumber" />}
          >
            <PhoneNumberField source="phoneNumber" />
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Benötigt Sehhilfe"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <YesNoInput source="needsGlasses" />}
          >
            <YesNoField source="needsGlasses" />
          </LabeledAndEditable>
          <Labeled label={<BookedQuotesFieldLabel />}>
            <ReferenceManyField reference="bookedQuotes" target="studentId" sort={{ field: "name", order: "ASC" }}>
              <Box /* Box used here to prevent HTML elements in the list from being rendered in a column */
                sx={{ mt: "-3.5px" }}
              >
                <CommaSeparatedFieldList newLines>
                  <TextField label="Name" source="bundleName" />
                </CommaSeparatedFieldList>
              </Box>
            </ReferenceManyField>
          </Labeled>
          <LabeledAndEditable
            label="Fahrstunden Wochenlimit (UE)"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => (
              <NumberInput
                source="maxBookableDrivingLessonsPerWeekLU"
                min={0}
                max={20}
                step={1}
                sx={{ width: "210px" }}
              />
            )}
          >
            <NumberField source="maxBookableDrivingLessonsPerWeekLU" emptyText="Kein Limit konfiguriert" />
          </LabeledAndEditable>
        </Stack>

        {/* --- Second column --- */}
        <Stack spacing={2}>
          {grants.includes("viewDunning") && student.paymentStrategy === "purchaseOnAccount" && (
            <LabeledAndEditable
              label="Budget"
              disableEdit={!grants.includes("editStudentBudget")}
              renderInput={() => <MoneyInput label="Budget" source="budget" />}
            >
              <MoneyField source="budget" />
            </LabeledAndEditable>
          )}

          {(grants.includes("viewDunning") || grants.includes("FundingInstructions:view")) && (
            <Labeled label="Banküberweisungsdaten">
              <FundingInstructionsField />
            </Labeled>
          )}
          <StudentGuardianDetails student={student} />
        </Stack>
      </Stack>
    </Box>
  );
}

function StudentGuardianDetails({ student }: { student: Student }) {
  if (!student.guardian) return null;
  const { firstName, lastName, email, phone } = student.guardian;
  return (
    <Labeled label="Gesetzlicher Vertreter">
      <Row spacing={1} alignItems="center">
        <Column>
          <Typography component="span" variant="body2">
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography component="span" variant="body2">
            {email}
          </Typography>
          <Typography component="span" variant="body2">
            {phone}
          </Typography>
        </Column>
        {grants.includes("editStudentPersonalData") && <EditStudentGuardianButton />}
      </Row>
    </Labeled>
  );
}

function BookedQuotesFieldLabel() {
  const createPath = useCreatePath();
  const recordId = useGetRecordId();
  return (
    <Typography color="textSecondary" variant="caption">
      Gebuchte Pakete (
      <Link
        to={createPath({ resource: "students", id: recordId, type: "packages" })}
        variant="caption"
        underline="none"
      >
        ändern
      </Link>
      )
    </Typography>
  );
}

export function StudentProfileTab() {
  return (
    <StudentTab>
      <StudentProfile />
    </StudentTab>
  );
}
