import type { DialogProps } from "../misc/DialogProps";
import { Box, Dialog, DialogContent } from "@mui/material";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { AccountBalance, DrivingSchoolAccounts } from "../details/student/AccountBalance";
import { PrepaidInvoicesCard } from "../details/student/PrepaidInvoicesCard";
import { RefundMultipleDialogButton } from "../buttons/RefundMultipleDialogButton";
import { grants } from "../backoffice.access_control";
import { useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import React from "react";

export function PrepaidCreditsDialog({ accounts, ...dialogProps }: { accounts?: DrivingSchoolAccounts } & DialogProps) {
  const student = useRecordContext<Student>();
  return (
    <Dialog {...dialogProps}>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent sx={{ width: 500, height: 680 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
          <div />
          {grants.includes("refundPayments") && <RefundMultipleDialogButton studentId={student.id} />}
        </Box>
        <PrepaidInvoicesCard studentId={student.id} title={"Prepaid-Quittungen"} />
        <AccountBalance
          key="prepaid"
          title="Prepaid/Vorauszahlungen"
          accountInfo={accounts?.prepaid_credits}
          showAmount={false}
        />
      </DialogContent>
    </Dialog>
  );
}
