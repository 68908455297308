import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { LinearProgress, useGetList, useGetRecordId } from "react-admin";
import { OfferFeedback } from "../../providers/catalogProvider";
import { useRecharts } from "../../hooks/useRecharts";
import { DialogCloseButton } from "../../misc/DialogCloseButton";

const barColors = ["#221D23", "#D1603D", "#4F3824", "#DDB967", "#D0E37F"];
export function OfferFeedbackChart() {
  const drivingSchoolId = useGetRecordId() as string;
  const { data, isLoading, error } = useGetList<OfferFeedback>("offerFeedback", { filter: { drivingSchoolId } });
  const plotData = useMemo(() => toBarChartData(data), [data?.length]);
  const { Bar, BarChart, CartesianGrid, Legend, Tooltip: ChartTooltip, XAxis, YAxis } = useRecharts();

  if (isLoading || !(Bar && BarChart && CartesianGrid && Legend && ChartTooltip && XAxis && YAxis)) {
    return <LinearProgress timeout={0} />;
  }

  if (!plotData || error) {
    return (
      <Typography component="span" color="red" variant="body2">
        Fehler beim Laden der Daten
      </Typography>
    );
  }

  if (plotData.length === 0) {
    return (
      <Typography pt="0.2em" maxWidth="800px">
        {`Bisher wurde kein Feedback zu Angebot abgegeben`}
      </Typography>
    );
  }
  const renderLegend = (props: any, answerData: { [answerId: string]: AnswerData }) => {
    const { payload } = props;
    return (
      <Stack direction="row" gap="15px" justifyContent="center">
        {payload.map((it: any) => {
          const extraData = answerData[it.dataKey].extras;
          const hasExtraData = extraData.length > 0;
          const [isOpen, setIsOpen] = useState(false);
          return (
            <Stack key={it.dataKey} color={it.color} direction="row" alignItems="center">
              <Box width="14px" height="10px" bgcolor={it.color} mr="5px"></Box>
              <Typography>{it.value}</Typography>
              {hasExtraData && (
                <>
                  <IconButton onClick={() => setIsOpen(true)} size="small" aria-label="info">
                    <Tooltip title="Antwortdetails anzeigen">
                      <InfoIcon />
                    </Tooltip>
                  </IconButton>
                  <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg" fullWidth>
                    <DialogTitle>{it.value}</DialogTitle>
                    <DialogCloseButton onClick={() => setIsOpen(false)} />
                    <DialogContent>
                      {extraData.map((it, index) =>
                        Object.values(it).map((it) => (
                          <>
                            {index !== 0 && <Divider sx={{ my: "5px" }} />}
                            <Typography>{it}</Typography>
                          </>
                        )),
                      )}
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return (
    <>
      {plotData.map((questionData) => {
        return (
          <Box key={questionData.questionId} mb="0.8em">
            <Typography pt="0.2em" maxWidth="800px" gutterBottom whiteSpace="pre">
              {`Antworten zu:\n"${questionData.questionText}"`}
            </Typography>
            <Stack direction="row">
              <BarChart width={1000} height={250} data={questionData.plotData} maxBarSize={20}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="value" tickLine={false} />
                <YAxis />
                <ChartTooltip />
                <Legend content={(props) => renderLegend(props, questionData.answerData)} />
                {Object.entries(questionData.answerData)
                  .sort(([_, dataA], [__, dataB]) => dataA.answerText.localeCompare(dataB.answerText))
                  .map(([answerId, data], index) => (
                    <Bar key={answerId} name={data.answerText} dataKey={answerId} fill={barColors[index]} />
                  ))}
              </BarChart>
            </Stack>
          </Box>
        );
      })}
    </>
  );
}

interface AnswerData {
  readonly answerText: string;
  readonly extras: { [key: string]: string }[];
}
interface QuestionPlotData {
  readonly questionId: string;
  readonly questionText: string;
  readonly plotData: { [answerId: string]: number }[];
  readonly answerData: {
    [answerId: string]: AnswerData;
  };
}

const toBarChartData = (feedback: OfferFeedback[] | undefined): QuestionPlotData[] | undefined => {
  if (!feedback) {
    return undefined;
  }

  const questionTexts: { [questionId: string]: string } = {};
  const answerCounts: { [questionId: string]: { [answerId: string]: number } } = {};
  const answerData: { [questionId: string]: { [answerId: string]: AnswerData } } = {};
  for (const feedbackItem of feedback) {
    const feedbackData = feedbackItem.feedback;

    // count how often every answer was given
    for (const [questionId, data] of Object.entries(feedbackData)) {
      questionTexts[questionId] = data.questionText;
      if (answerCounts[questionId] === undefined) {
        answerCounts[questionId] = {};
      }
      if (answerCounts[questionId][data.answerId] === undefined) {
        answerCounts[questionId][data.answerId] = 0;
      }
      answerCounts[questionId][data.answerId] += 1;

      if (answerData[questionId] === undefined) {
        answerData[questionId] = {};
      }
      const existingAnswerData = answerData[questionId][data.answerId];
      answerData[questionId][data.answerId] = {
        answerText: data.answerText,
        extras: [...(existingAnswerData?.extras ?? []), ...(data.extra ? [data.extra] : [])],
      };
    }
  }

  const data: QuestionPlotData[] = Object.entries(questionTexts).map(([questionId, questionText]) => ({
    questionId,
    questionText,
    plotData: [answerCounts[questionId]],
    answerData: answerData[questionId],
  }));

  return data;
};
