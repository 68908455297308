import { useQueryClient } from "react-query";
import { Link, RecordContextProvider, useGetOne, useNotify } from "react-admin";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { atom, useRecoilState } from "recoil";
import { TheoryExamWorkflow } from "../model/TheoryExamWorkflow";
import { Column } from "./Column";
import { Fragment, useCallback, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/SaveAlt";
import { reportError } from "../backoffice.utils";
import { Row } from "./Row";
import { autovioColors } from "./backofficeTheme";
import { workflowsProvider } from "../providers/workflowsProvider";
import { deleteField, serverTimestamp } from "firebase/firestore";
import { StudentAvatar } from "./StudentAvatar";
import { StudentDisplayName } from "./StudentDisplayName";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { PracticalExamWorkflow } from "../model/PracticalExamWorkflow";
import { Student } from "../providers/studentsProvider";
import { DateTime } from "luxon";
import { renderAddress } from "./AddressDisplay";
import { InstructorAvatar } from "./InstructorAvatar";
import { InstructorDisplayName } from "./InstructorDisplayName";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { NotesList } from "../lists/NotesList";
import { DialogCloseButton } from "./DialogCloseButton";
import { useMenu } from "../hooks/useMenu";

export const workflowShownInWorkflowDialogState = atom<null | TheoryExamWorkflow | PracticalExamWorkflow>({
  key: "workflowShownInWorkflowDialog",
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        const url = new URL(location.href);
        if (newValue) {
          if (url.searchParams.get("openDialog") !== `WorkflowDialog!${newValue.id}`) {
            url.searchParams.set("openDialog", `WorkflowDialog!${newValue.id}`);
            browserHistory.replace(url.toString());
          }
        } else if (url.searchParams.has("openDialog")) {
          url.searchParams.delete("openDialog");
          browserHistory.replace(url.toString());
        }
      });
    },
  ],
});

export function WorkflowDialog() {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [workflow, setWorkflowShownInDialog] = useRecoilState(workflowShownInWorkflowDialogState);
  const [formState, setFormState] = useState<{ [taskUid: string]: boolean }>({});
  const markedAsDone = (uid: string) => formState[uid] ?? workflow!.tasks[uid].isDone;
  const open = !!workflow;
  const dirty = workflow && Object.entries(formState).some(([uid, checked]) => checked !== workflow.tasks[uid].isDone);
  const studentUid = workflow?.workflowData.studentUid ?? "";
  const { data: student } = useGetOne<Student>("students", { id: studentUid }, { enabled: !!studentUid });
  const bookedTraining =
    workflow &&
    workflow.type === "practicalExamSignUp" &&
    student?.bookedTrainings.find((it) => it.id === workflow.workflowData.bookedTrainingId);
  const [saving, setSaving] = useState(false);
  const ref = useRef<{ showForm: () => void }>();

  const closeDialog = useCallback(() => {
    setFormState({});
    setWorkflowShownInDialog(null);
  }, [setFormState, setWorkflowShownInDialog]);

  async function save() {
    if (!workflow) {
      throw new Error(`Unexpected state: workflow: ${workflow}`);
    }
    let success = false;
    try {
      setSaving(true);
      const taskUids = Object.keys(workflow.tasks);
      const numFinishedTasks = taskUids.filter((uid) => markedAsDone(uid)).length;
      const isWorkflowFinished = numFinishedTasks === taskUids.length;
      await workflowsProvider.update("workflows", {
        id: workflow.id,
        data: {
          tasks: Object.fromEntries(Object.entries(formState).map(([uid, isDone]) => [uid, { isDone }])),
          finished: isWorkflowFinished,
          finishedAt: isWorkflowFinished ? serverTimestamp() : deleteField(),
        },
        previousData: workflow,
      });
      success = true;
    } catch (error) {
      reportError(`Failed to update workflow ${workflow?.id}`, error);
      notify("Fehler beim Speichern", { type: "error" });
    } finally {
      setSaving(false);
    }
    if (success) {
      closeDialog();
      void queryClient.invalidateQueries(["workflows"]);
    }
  }

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="lg">
      <DialogTitle>
        {workflow?.type === "theoryExamSignUp"
          ? "Theorieprüfung"
          : workflow?.type === "practicalExamSignUp"
            ? `Praktische Prüfung für ${bookedTraining ? bookedTraining.drivingLicenseClass : "..."}`
            : ""}
      </DialogTitle>
      {workflow && <_PopupMenu workflow={workflow} closeDialog={closeDialog} />}
      <DialogCloseButton onClick={closeDialog} />
      <DialogContent>
        <Row gap={2}>
          <Column>
            {workflow && <_WorkflowData workflow={workflow} />}
            {workflow?.taskTree.map(({ uid, subtasks }) => {
              const task = workflow.tasks[uid];
              const numFinishedSubtasks = subtasks.filter((it) => markedAsDone(it.uid)).length;
              return (
                <Fragment key={uid}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControlLabel
                      key={uid}
                      control={
                        <Checkbox
                          disabled={saving}
                          checked={formState[uid] ?? task.isDone}
                          onChange={(event) => {
                            setFormState({
                              ...formState,
                              [uid]: event.target.checked,
                            });
                          }}
                        />
                      }
                      label={
                        subtasks.length > 0 ? (
                          <Row>
                            <Typography>{task.title}</Typography>
                            <Typography
                              sx={{ color: autovioColors.grey }}
                            >{`\u00A0\u00A0${numFinishedSubtasks} / ${subtasks.length}`}</Typography>
                          </Row>
                        ) : (
                          task.title
                        )
                      }
                    />
                  </Box>
                  {subtasks.map(({ uid }) => {
                    const subtask = workflow.tasks[uid];
                    return (
                      <FormControlLabel
                        key={uid}
                        sx={{ paddingLeft: "30px" }}
                        control={
                          <Checkbox
                            disabled={saving}
                            checked={formState[uid] ?? subtask.isDone}
                            onChange={(event) => {
                              setFormState({
                                ...formState,
                                [uid]: event.target.checked,
                              });
                            }}
                          />
                        }
                        label={subtask.title}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
          </Column>
          {student && (
            <RecordContextProvider value={student}>
              <NotesList
                resource="studentNotes"
                ref={ref}
                title={
                  <table style={{ borderSpacing: "4px" }}>
                    <tbody>
                      <tr>
                        <th align="left" style={{ verticalAlign: "top" }}>
                          Notizen:
                        </th>
                      </tr>
                    </tbody>
                  </table>
                }
                style={{ flex: 1 }}
              />
            </RecordContextProvider>
          )}
        </Row>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" loading={saving} disabled={!dirty} startIcon={<SaveIcon />} onClick={save}>
          Speichern
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function _WorkflowData({ workflow }: { workflow: TheoryExamWorkflow | PracticalExamWorkflow }) {
  const { studentUid, preferredDates, preferredTimeOfDay, examLocation } = workflow.workflowData;
  const { data: student } = useGetOne<Student>("students", { id: studentUid });
  return (
    <table style={{ borderSpacing: "4px" }}>
      <tbody>
        <tr>
          <th align="left" style={{ verticalAlign: "top" }}>
            Fahrschüler:
          </th>
          <td>
            <Row spacing={1} sx={{ alignItems: "center" }}>
              <StudentAvatar studentId={studentUid} size="40px" />
              <Column>
                <StudentDisplayName variant="body1" studentId={studentUid} sx={{ whiteSpace: "nowrap" }} />
                {student?.dateOfBirth && (
                  <Typography variant="body2">({student?.dateOfBirth.toFormat("dd.MM.yyyy")})</Typography>
                )}
              </Column>
              <Link to={`/students/${studentUid}`} target="_blank">
                <OpenInNewIcon sx={{ mt: "6px" }} />
              </Link>
            </Row>
          </td>
        </tr>
        {workflow.type === "practicalExamSignUp" && (
          <tr>
            <th align="left" style={{ verticalAlign: "top" }}>
              Fahrlehrer:
            </th>
            <td>
              <Column spacing="4px">
                {(student?.instructorIds ?? []).map((instructorId) => (
                  <Row key={instructorId} spacing={1} sx={{ alignItems: "center" }}>
                    <InstructorAvatar instructorId={instructorId} size="40px" />
                    <InstructorDisplayName instructorId={instructorId} variant="body1" sx={{ whiteSpace: "nowrap" }} />
                    <Link to={`/instructors/${instructorId}/calendar`} target="_blank">
                      <OpenInNewIcon sx={{ mt: "6px" }} />
                    </Link>
                  </Row>
                ))}
              </Column>
            </td>
          </tr>
        )}
        <tr>
          <th align="left" style={{ verticalAlign: "top" }}>
            Terminwünsche:
          </th>
          <td>
            <Column>
              {preferredDates
                .map((it) => DateTime.fromFormat(it, "yyyy-MM-dd", { locale: "de", zone: "Europe/Berlin" }))
                .sort()
                .map((date, index) => (
                  <Typography key={index} variant="body1">
                    {date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                  </Typography>
                ))}
            </Column>
          </td>
        </tr>
        <tr>
          <th align="left" style={{ verticalAlign: "top" }}>
            Uhrzeit:
          </th>
          <td style={{ whiteSpace: "nowrap" }}>
            {preferredTimeOfDay.includes("morning")
              ? preferredTimeOfDay.includes("afternoon")
                ? "vormittags oder nachmittags"
                : "vormittags"
              : preferredTimeOfDay.includes("afternoon")
                ? "nachmittags"
                : /* Should never happen: */ preferredTimeOfDay.join(", ")}
          </td>
        </tr>
        <tr>
          <th align="left" style={{ verticalAlign: "top" }}>
            Prüfort:
          </th>
          <td>
            <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
              {examLocation.name}
            </Typography>
            <Typography variant="body1">{renderAddress(examLocation.postalAddress, { oneLine: true })}</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function _PopupMenu({
  workflow,
  closeDialog,
}: {
  workflow: TheoryExamWorkflow | PracticalExamWorkflow;
  closeDialog: () => void;
}) {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { anchorEl, openMenu, closeMenu } = useMenu();
  const [deleting, setDeleting] = useState(false);

  const deleteWorkflow = async () => {
    setDeleting(true);
    let success = false;
    try {
      await workflowsProvider.delete("workflows", { id: workflow.id });
      success = true;
    } catch (error) {
      console.error(`Failed to delete workflow ${workflow.id}`, error);
      notify("Fehler beim Löschen der Anfrage.", { type: "error" });
    } finally {
      closeMenu();
      // [UX] the menu item should stay disabled while the close menu animation is running ...
      setTimeout(() => setDeleting(false), 500);
    }
    if (success) {
      closeDialog();
      notify("Anfrage erfolgreich gelöscht.", { type: "success" });
      void queryClient.invalidateQueries(["workflows"]);
    }
  };

  return (
    <div>
      <IconButton sx={{ position: "absolute", top: "17px", right: "57px" }} onClick={openMenu}>
        <MoreVertIcon sx={{ fill: autovioColors.grey }} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={deleteWorkflow} disabled={deleting}>
          <ListItemIcon>
            {deleting ? <CircularProgress size={24} color="inherit" /> : <DeleteOutlineIcon />}
          </ListItemIcon>
          <ListItemText>Anfrage löschen</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
