import { AbstractDataProvider } from "./AbstractDataProvider";
import { GetListParams, GetListResult } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import type { PerformanceOverviewReport } from "../generated/backendClient";
import { applyPagination, applySort } from "../backoffice.utils";

class PerformanceOverviewReportsProvider extends AbstractDataProvider<PerformanceOverviewReport> {
  async getList(
    _resource: string,
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<PerformanceOverviewReport>> {
    const backendClient = await getAuthenticatedBackendClient();
    const { drivingSchoolId } = filter;
    if (!drivingSchoolId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId": ..., ...}`);
    }
    const paginatedList = await backendClient.performanceOverviewReport.performanceOverviewReportList({
      companyDrivingSchoolId: drivingSchoolId,
      offset: 0,
      limit: 9999,
    });
    return applyPagination(applySort(paginatedList.results, sort), pagination);
  }
}

export const performanceOverviewReportsProvider = new PerformanceOverviewReportsProvider();
