import { DateTime } from "luxon";
import { FieldValues, useFormContext, Controller, Path } from "react-hook-form";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { FormControl } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

interface TimeInputProps<T extends FieldValues> {
  source: Path<T>;
  sx?: SxProps<Theme>;
  label?: string;
  size?: "small";
}

export const TimeInput = <T extends FieldValues>({ source, sx, label = "Startzeit", size }: TimeInputProps<T>) => {
  const { control } = useFormContext<T>();
  return (
    <Controller
      name={source}
      control={control}
      render={({ field: { onChange, value, onBlur }, formState }) => {
        const error = formState.errors[source]?.message;
        const errorMessage = typeof error === "string" ? error : undefined;
        return (
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                label={label}
                value={value}
                sx={sx}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    size: size,
                    onBlur,
                    ...(errorMessage ? { error: true, helperText: errorMessage } : {}),
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        );
      }}
      rules={{
        validate: (value) => {
          if (!value) {
            return "Bitte wähle die Uhrzeit aus.";
          }
          const dateTimeValue = value as DateTime;
          if (dateTimeValue.hour === 0 && dateTimeValue.minute === 0) {
            return "Bitte wähle die Uhrzeit aus.";
          } else {
            return true;
          }
        },
      }}
    />
  );
};
