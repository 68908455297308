import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DialogProps } from "../../misc/DialogProps";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { DrivingLicenseAuthorityInstructionForm } from "./DrivingLicenseAuthorityInstructionForm";
import { CreateBase, useGetRecordId } from "react-admin";
import { useQueryClient } from "react-query";

export function AddDrivingLicenseAuthorityInstructionDialog(dialogProps: DialogProps) {
  const authorityId = useGetRecordId();
  const queryClient = useQueryClient();

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Anleitung hinzufügen</DialogTitle>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent>
        <CreateBase
          resource="drivingLicenseAuthorityInstructions"
          mutationOptions={{
            onSuccess: async () => {
              await queryClient.invalidateQueries(["drivingLicenseAuthorities"]);
              dialogProps.onClose();
            },
          }}
          transform={(formValues) => ({ authorityId, ...formValues })}
        >
          <DrivingLicenseAuthorityInstructionForm />
        </CreateBase>
      </DialogContent>
    </Dialog>
  );
}
