import { GetListParams, GetListResult, GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { z } from "zod";
import { ZodiosPlugin } from "@zodios/core";

export type GiftVoucher = z.infer<typeof schemas.GiftVoucherDto>;

class GiftVouchersProvider extends AbstractDataProvider<GiftVoucher> {
  async getList(_resource: string, { filter, sort, pagination }: GetListParams): Promise<GetListResult<GiftVoucher>> {
    const serverClient = await getAuthenticatedServerClient();
    let total: undefined | number;
    const extractTotalFromContentRangeHeader: ZodiosPlugin = {
      response: async (_api, _config, response) => {
        try {
          const contentRangeHeader = response.headers["content-range"];
          if (contentRangeHeader) {
            total = parseInt(contentRangeHeader.substring(contentRangeHeader.lastIndexOf("/") + 1));
          }
        } catch (error) {
          console.warn("Failed to extract total number of gift vouchers from Content-Range header", error);
        }
        return response;
      },
    };
    serverClient.use(extractTotalFromContentRangeHeader);
    const data = await serverClient.findGiftVouchers({
      queries: {
        filter: JSON.stringify(filter),
        sort: JSON.stringify([sort.field, sort.order]),
        range: JSON.stringify([(pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage - 1]),
      },
    });
    return { data, total };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<GiftVoucher>> {
    const { target, id, filter, sort, pagination } = params;
    if (target !== "beneficiary") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "beneficiary"`);
    }
    return this.getList(resource, { filter: { ...(filter ?? {}), beneficiary: id }, sort, pagination });
  }
}

export const giftVouchersProvider = new GiftVouchersProvider();
