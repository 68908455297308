import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { useDialog } from "../hooks/useDialog";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import React, { useState } from "react";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { type RefundInstruction } from "../generated/backendClient";
import { Money } from "../misc/Money";

type RefundMultipleDialogButtonProps = {
  studentId: string;
};

export function RefundMultipleDialogButton({ studentId }: RefundMultipleDialogButtonProps) {
  const { dialogProps, openDialog, closeDialog } = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [refundInstructions, setRefundInstructions] = useState<RefundInstruction[] | undefined>();

  const openDialogAndLoadRefundableInvoices = async () => {
    openDialog();
    setIsLoading(true);
    const backendClient = await getAuthenticatedBackendClient();
    const response = await backendClient.user.userPrepareRefundPrepaidCreditsCreate({
      id: studentId,
      requestBody: { refund_all: true },
    });
    setRefundInstructions(response as unknown as RefundInstruction[]);
    setIsLoading(false);
  };

  const executeRefundInstructions = async (refundInstructions: RefundInstruction[]) => {
    if (!refundInstructions) return;
    setIsLoading(true);
    const backendClient = await getAuthenticatedBackendClient();
    await backendClient.user.userExecuteRefundPrepaidCreditsCreate({ id: studentId, requestBody: refundInstructions });
    setIsLoading(false);
    closeDialog();
  };

  return (
    <>
      <Button
        startIcon={<RequestQuoteIcon />}
        variant="outlined"
        onClick={openDialogAndLoadRefundableInvoices}
        disabled={isLoading}
      >
        Prepaid-Guthaben zurückerstatten
      </Button>
      <Dialog {...dialogProps} fullWidth={true} maxWidth="md">
        <DialogTitle>Prepaid-Guthaben zurückerstatten</DialogTitle>
        <DialogCloseButton onClick={closeDialog} />
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          {isLoading && <LoadingIndicator />}
          {refundInstructions &&
            (refundInstructions.length === 0 ? (
              <Typography variant="body1">
                Kein Guthaben zurückerstattbar.
                <br />
                <Typography variant="caption">
                  Beachte: Zahlungen welche über 180 Tage alt sind, können nicht per Stripe zurückerstattet werden.
                </Typography>
              </Typography>
            ) : (
              <Box>
                <Typography variant="body1">Folgende Rechnungen werden erstattet:</Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Rechnung</TableCell>
                        <TableCell align="right">Geplante Aktion</TableCell>
                        <TableCell align="right">Betrag</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {refundInstructions.map((instruction) => (
                        <TableRow
                          key={instruction.invoice_number}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell align="right">{instruction.invoice_number}</TableCell>
                          <TableCell align="right">
                            {instruction.action === "refund" ? "Vollständig erstatten" : "Teilweise erstatten"}
                          </TableCell>
                          <TableCell align="right">
                            <Money cents={parseFloat(instruction.amount) * 100} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell align="right" colSpan={3}>
                          Gesamt:{" "}
                          <Money
                            cents={
                              100 *
                              refundInstructions.reduce((acc, instruction) => acc + parseFloat(instruction.amount), 0)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>

                <DialogActions>
                  <Button
                    startIcon={<RequestQuoteIcon />}
                    onClick={() => executeRefundInstructions(refundInstructions)}
                    variant="outlined"
                    style={{ marginTop: "20px" }}
                  >
                    Jetzt Prepaid-Guthaben zurückerstatten
                  </Button>
                </DialogActions>
              </Box>
            ))}
        </DialogContent>
      </Dialog>
    </>
  );
}
