import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { LoadingButton } from "@mui/lab";
import { useDeleteEventMutation } from "../../api/backoffice.api";
import { useNotify } from "react-admin";
import { SlideUp } from "../SlideUp";
import { DialogCloseButton } from "../DialogCloseButton";

interface DeleteInvitationDialogProps {
  readonly isOpen: boolean;
  readonly invitation: AutovioCalendarEvent;
  readonly onClose: (invitationDeleted: boolean) => void;
}

export function DeleteInvitationDialog({ isOpen, invitation, onClose }: DeleteInvitationDialogProps) {
  const deleteEventMutation = useDeleteEventMutation();
  const notify = useNotify();

  const deleteInvitation = async () => {
    try {
      await deleteEventMutation.mutateAsync({ calendarEventUid: invitation.id });
      notify("Einladung erfolgreich gelöscht", { type: "success" });
      onClose(true);
    } catch (error) {
      console.error("Failed to delete event", error);
      notify("Fehler beim Löschen der Einladung", { type: "error" });
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose(false)} TransitionComponent={SlideUp}>
      <DialogTitle>Einladung löschen?</DialogTitle>
      <DialogCloseButton onClick={() => onClose(false)} />
      <DialogContent>
        <Typography>Bist du dir sicher, dass die Einladung gelöscht werden soll?</Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" loading={deleteEventMutation.isLoading} onClick={deleteInvitation}>
          Ja, Einladung löschen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
