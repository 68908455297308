import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

export class AbstractDataProvider<T extends RaRecord> {
  async getList(_resource: string, _params: GetListParams): Promise<GetListResult<T>> {
    throw new Error("Not implemented");
  }

  async getOne(_resource: string, _params: GetOneParams<T>): Promise<GetOneResult<T>> {
    throw new Error("Not implemented");
  }

  async getMany(resource: string, { ids, meta }: GetManyParams): Promise<GetManyResult<T>> {
    const records = await Promise.all(ids.map(async (id) => (await this.getOne(resource, { id, meta })).data));
    return { data: records };
  }

  async getManyReference(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<T>> {
    const { target, id, pagination, sort, filter, meta } = params;
    return this.getList(resource, { pagination, sort, filter: { ...filter, [target]: id }, meta });
  }

  async create(_resource: string, _params: CreateParams<any>): Promise<CreateResult<T>> {
    throw new Error("Not implemented");
  }

  async update(_resource: string, _params: UpdateParams<T>): Promise<UpdateResult<T>> {
    throw new Error("Not implemented");
  }

  async updateMany(_resource: string, _params: UpdateManyParams<any>): Promise<UpdateManyResult> {
    throw new Error("Not implemented");
  }

  async delete(_resource: string, _params: DeleteParams): Promise<DeleteResult<T>> {
    throw new Error("Not implemented");
  }

  async deleteMany(_resource: string, _params: DeleteManyParams): Promise<DeleteManyResult> {
    throw new Error("Not implemented");
  }
}
