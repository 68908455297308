import { Datagrid, List, TextField, useGetList } from "react-admin";
import { ShowIconButton } from "../../buttons/ShowIconButton";
import { DateField } from "../../fields/DateField";
import { Typography } from "@mui/material";
import { autovioColors } from "../../misc/backofficeTheme";
import { useAutovioContext } from "../../hooks/useAutovioContext";

export function DeletedVehiclesList({
  resource,
  title,
}: {
  resource: "cars" | "motorcycles" | "trailers" | "simulators";
  title: string;
}) {
  const [{ drivingSchoolId }] = useAutovioContext();
  const filter = { entitledDrivingSchoolId: drivingSchoolId, withDeleted: true, deletedAt: (val: any) => !!val };
  const { data } = useGetList(resource, { filter }, { enabled: !!drivingSchoolId });

  if (!drivingSchoolId || !data || data.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="body2" style={{ margin: "0 0 30px 16px", lineHeight: 0.83, color: autovioColors.grey }}>
        {title}
      </Typography>
      <List
        title=" " // <-- prevent that the default list title is rendered
        resource={resource}
        storeKey={`DeletedVehiclesList-${resource}`}
        filter={filter}
        sort={/* initial sort order: */ { field: "name", order: "ASC" }}
        exporter={false}
        empty={false}
        hasCreate={false}
      >
        <Datagrid bulkActionButtons={false} rowClick="edit" empty={<div />}>
          <TextField label="Name" source="name" />
          <TextField label="Notizen" source="description" />
          <TextField label="Kennzeichen" source="car.plateNumber" />
          <DateField label="Gelöscht am" source="deletedAt" showDate="with diff" />
          <ShowIconButton />
        </Datagrid>
      </List>
    </>
  );
}
