import { type AutovioContext, persistentAutovioContext } from "../persistent_state";
import { useRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";

export function useAutovioContext(): [AutovioContext, (newContext: AutovioContext) => void] {
  const { pathname: path } = useLocation();
  const [autovioContext, setAutovioContext] = useRecoilState(persistentAutovioContext);
  // Make sure, if access is restricted to one driving school, that this driving school is present in the context ...
  if (restrictAccessToDrivingSchoolIds?.length === 1) {
    return [{ drivingSchoolId: restrictAccessToDrivingSchoolIds[0] }, () => {}];
  }
  const result = useMemo<ReturnType<typeof useAutovioContext>>(() => {
    const match = /^\/drivingSchools\/([^/]+)/.exec(path);
    if (match) {
      const drivingSchoolId = match[1];
      if (autovioContext.drivingSchoolId !== drivingSchoolId) {
        setTimeout(() => setAutovioContext({ drivingSchoolId }));
      }
      return [{ drivingSchoolId }, setAutovioContext];
    }
    // These pages are not driving school specific ...
    if (
      path.startsWith("/dunning") ||
      path.startsWith("/invoiceCustomers") ||
      path.startsWith("/drivingLicenseAuthorities") ||
      path.startsWith("/areas") ||
      path.startsWith("/products") ||
      path.startsWith("/trainings") ||
      path.startsWith("/bundles") ||
      path.startsWith("/promoCodes") ||
      path.startsWith("/appliedReferralCodes") ||
      path.startsWith("/adas") ||
      path.startsWith("/autovioEmployees") ||
      path.startsWith("/usersToBeDeleted") ||
      path.startsWith("/bi")
    ) {
      return [{}, setAutovioContext];
    }
    return [autovioContext, setAutovioContext];
  }, [path, autovioContext, setAutovioContext]);
  return result;
}
