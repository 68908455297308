import { useWatch } from "react-hook-form";
import { ReferenceArrayInput, SelectArrayInput } from "react-admin";

export function EntitledUsersInput() {
  const [drivingSchoolIds] = useWatch({ name: ["entitledDrivingSchools"] });
  const filter = drivingSchoolIds && drivingSchoolIds.length > 0 ? { drivingSchoolId: drivingSchoolIds } : undefined;
  return (
    <ReferenceArrayInput source="entitledUsers" reference="instructors" filter={filter}>
      <SelectArrayInput label="zugeordnete(r) Fahrlehrer" fullWidth />
    </ReferenceArrayInput>
  );
}
