import { EventContentArg, EventInput } from "@fullcalendar/core";
import deLocale from "@fullcalendar/core/locales/de";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import {
  FunctionField,
  RecordContextProvider,
  ReferenceOneField,
  TextField,
  useGetManyReference,
  useRecordContext,
} from "react-admin";
import { grants } from "../../backoffice.access_control";
import { LinkToFirestoreField } from "../../fields/LinkToFirestoreField";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { calendarEventBackgroundColor, calendarEventTitle, renderLessonStatus } from "../../utils/calendar";
import { Delayed } from "../../misc/Delayed";
import { EventStatusReasonTooltip } from "../../misc/EventStatusReasonTooltip";
import { EventDialog, useEventDialog } from "../../misc/calendar/EventDialog";
import { Instructor } from "../../providers/instructorsProvider";
import { autovioColors } from "../../misc/backofficeTheme";

export function InstructorAppointmentsList() {
  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: DateTime.now().startOf("week").toISODate(),
    // DateTime.now().endOf("week") returns a DateTime for this week's sunday at 23:59:59.999,
    // but we need the date of next weeks monday, therefore we add 13 hours ...
    to: DateTime.now().endOf("week").plus({ hour: 13 }).toISODate(),
  });
  const { data, isLoading } = useCalendarData(dateRange);
  const { canBeOpenedInDialog } = useEventDialog();
  const [selectedEvent, setSelectedEvent] = useState<AutovioCalendarEvent | undefined>(undefined);

  return (
    <div style={{ position: "relative" }}>
      <FullCalendar
        timeZone="Europe/Berlin"
        locale={deLocale}
        plugins={[listPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "listWeek,listMonth",
        }}
        views={{
          listWeek: { buttonText: "Woche" },
          listMonth: { buttonText: "Monat" },
        }}
        initialView="listWeek"
        events={[...(data.instructorEvents?.map(_autovioCalendarEventToEventInput) ?? [])]}
        datesSet={(dates) => {
          const from = DateTime.fromJSDate(dates.start, { zone: "Europe/Berlin" }).toISODate();
          const to = DateTime.fromJSDate(dates.end, { zone: "Europe/Berlin" }).toISODate();
          if (dateRange.from !== from || dateRange.to !== to) {
            setDateRange({ from, to });
          }
        }}
        eventContent={(eventInfo) => <InstructorAppointmentsListItem eventInfo={eventInfo} />}
        eventClick={(eventInfo) => {
          const autovioCalendarEvent = eventInfo.event.extendedProps.event;
          if (canBeOpenedInDialog(autovioCalendarEvent)) {
            setSelectedEvent(autovioCalendarEvent);
          }
        }}
      />
      {isLoading && (
        <Delayed ms={100}>
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              right: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "#FFFFFF",
              zIndex: 50,
              opacity: "50%",
            }}
          >
            <CircularProgress />
          </div>
        </Delayed>
      )}
      {selectedEvent && <EventDialog event={selectedEvent} isOpen onClose={() => setSelectedEvent(undefined)} />}
    </div>
  );
}

export const _autovioCalendarEventToEventInput = (event: AutovioCalendarEvent): EventInput => ({
  id: event.id,
  title: calendarEventTitle(event),
  backgroundColor: calendarEventBackgroundColor(event),
  editable: false,
  start: event.start.toISO(),
  end: event.end.toISO(),
  extendedProps: {
    event,
  },
});

function InstructorAppointmentsListItem({ eventInfo }: { eventInfo: EventContentArg }) {
  const { event } = eventInfo;
  const autovioCalendarEvent = event.extendedProps.event as AutovioCalendarEvent;
  const { canBeOpenedInDialog } = useEventDialog();
  return (
    <RecordContextProvider value={event.extendedProps.event}>
      <Grid
        container
        columns={grants.includes("viewFirestore") ? 10 : 9}
        style={canBeOpenedInDialog(autovioCalendarEvent) ? { cursor: "pointer" } : {}}
      >
        <Grid item xs={2}>
          <Typography variant="body2">{event.title}</Typography>
        </Grid>
        <Grid item xs={3}>
          {autovioCalendarEvent.student && (
            <ReferenceOneField reference="students" target="id" source="student.uid">
              <TextField source="name" />
            </ReferenceOneField>
          )}
        </Grid>
        <Grid item xs={2}>
          <EventStatusReasonTooltip event={autovioCalendarEvent}>
            <FunctionField render={renderLessonStatus} />
          </EventStatusReasonTooltip>
        </Grid>
        <Grid item xs={2}>
          <FunctionField
            render={({ editCount, editWithReasonCount }: AutovioCalendarEvent) => {
              const s = editCount === 1 ? "1 x geändert" : editCount > 1 ? `${editCount}x geändert` : null;
              if (editWithReasonCount > 0) {
                return <span style={{ color: autovioColors.red }}>{s}</span>;
              } else {
                return s;
              }
            }}
          />
        </Grid>
        {grants.includes("viewFirestore") && (
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <LinkToFirestoreField label="" source="id" collection="/calendar_events" />
            </Box>
          </Grid>
        )}
      </Grid>
    </RecordContextProvider>
  );
}

interface CalendarData {
  data: {
    instructorEvents?: AutovioCalendarEvent[];
  };
  isLoading: boolean;
}

function useCalendarData(dateRange: { from: string; to: string }): CalendarData {
  const instructor = useRecordContext<Instructor>();
  const { id: instructorId, drivingSchoolId } = instructor;

  const { data: instructorEvents, isLoading: isLoadingEvents } = useGetManyReference<AutovioCalendarEvent>(
    "calendarEvents",
    {
      target: "drivingSchoolId",
      id: drivingSchoolId,
      filter: { dateRange, instructorId },
      pagination: { page: 1, perPage: 9999 },
      meta: { includeDeleted: true },
    },
  );

  const isLoading = isLoadingEvents;
  return { data: { instructorEvents }, isLoading };
}
