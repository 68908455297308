import { ReferenceArrayInput, required, SelectArrayInput, TextInput } from "react-admin";
import { VehicleFormToolbar } from "../../misc/VehicleFormToolbar";
import { EntitledUsersInput } from "../../inputs/EntitledUsersInput";
import { Form2 } from "../../misc/Form2";
import { Column } from "../../misc/Column";
import { HiddenInput } from "../../inputs/HiddenInput";
import type { SxProps } from "@mui/material";
import { useAutovioContext } from "../../hooks/useAutovioContext";

export function MotorcycleForm({ mode, sx }: { mode: "create" | "edit"; sx?: SxProps }) {
  const [{ drivingSchoolId }] = useAutovioContext();

  if (!drivingSchoolId) {
    return null;
  }

  return (
    <Form2 warnWhenUnsavedChanges>
      <HiddenInput source="type" value="motorcycle" />
      <HiddenInput source="entitledDrivingSchools" value={[drivingSchoolId]} />
      <Column sx={sx}>
        <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
        <TextInput label="Notizen" source="description" fullWidth />
        <EntitledUsersInput />
        <TextInput label="Kennzeichen" source="motorcycle.plateNumber" validate={required()} sx={{ width: "150px" }} />
        <ReferenceArrayInput source="motorcycle.advancedDriverAssistanceSystemsIds" reference="adas">
          <SelectArrayInput label="Assistenzsysteme" defaultValue={[]} sx={{ minWidth: "337px" }} />
        </ReferenceArrayInput>
        <VehicleFormToolbar />
      </Column>
    </Form2>
  );
}
