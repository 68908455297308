import { DateTime } from "luxon";
import { autovioColors } from "../misc/backofficeTheme";
import { AutovioCalendarEvent, isPaidLesson, isTheoryLesson, TheoryLesson } from "../model/autovioCalendarEvents";
import { replaceCommasWithDot } from "../backoffice.utils";
import { type EventContentArg } from "@fullcalendar/core";

export function calendarEventTitle(event: AutovioCalendarEvent): string {
  const title = _calendarEventTitle(event);
  // with prefix "Abgesagt: " if the event was cancelled (APP-4596) ...
  return wasCancelled(event) ? `Abgesagt: ${title}` : title;
}

function _calendarEventTitle(event: AutovioCalendarEvent): string {
  switch (event.type) {
    case "DrivingLesson":
      switch (event.drivingLessonType) {
        case "normal":
          return "Übungsstunde";
        case "unterweisungAmFahrzeug":
          return "Unterweisung am Fahrzeug";
        case "praktischeUnterweisung":
          return "Praktische Unterweisung";
        case "ueberlandfahrt":
          return "Überlandfahrt";
        case "autobahnfahrt":
          return "Autobahnfahrt";
        case "nachtfahrt":
          return "Nachtfahrt";
        case "simulator":
          return "Simulator-Fahrstunde";
        case "praktischePruefung":
          return "Praktische Prüfung";
        case "schaltkompetenz":
          return "Schaltkompetenz";
        case "fahrprobe":
          return "ASF Fahrprobe";
        default:
          return `[${event.drivingLessonType}]`;
      }
    case "TheoryLesson":
      return "Theorieunterricht";
    case "TheoryExam":
      return "Theorieprüfung";
    case "RemoteLesson":
      return "Online Meeting";
    case "ASFCourseSession":
      switch (event.sessionType) {
        case "sitzung1":
          return "ASF Kurs Sitzung 1";
        case "fahrprobe":
          return "ASF Kurs Fahrprobe (Blocker)";
        case "sitzung2":
          return "ASF Kurs Sitzung 2";
        case "sitzung3":
          return "ASF Kurs Sitzung 3";
        case "sitzung4":
          return "ASF Kurs Sitzung 4";
        default:
          return `[${event.sessionType}]`;
      }
    case "Other":
      switch (event.category) {
        case "businessTrip":
          return "Dienstfahrt";
        case "cancelledDrivingLesson":
          return "Abgesagte Fahrstunde";
        case "carCare":
          return "Fahrzeugwartung";
        case "changeCars":
          return "Fahrzeugwechsel";
        case "chargeCars":
          return "Fahrzeug laden";
        case "examPlanning":
          return "Prüfungsplanung";
        case "journeyFromAndToCustomer":
          return "Anfahrt & Abfahrt Kunde";
        case "meeting":
          return "Meeting";
        case "office":
          return "Büro";
        case "other":
          return "Sonstiges";
        case "pause":
          return "Pause";
        case "preparationTheory":
          return "Vorbereitung Theorie";
        case "private":
          return "Privates";
        case "publicHoliday":
          return "Feiertag";
        case "sickness":
          return "Krankheit";
        case "training":
          return "Weiterbildung";
        case "vacation":
          return "Urlaub";
        default:
          return `[${event.category}]`;
      }
    case "Unknown":
      return "Fehler beim Anzeigen";
  }
}

export function calendarEventBackgroundColor(event: AutovioCalendarEvent): string {
  const color = calendarEventColor(event);
  if (wasCancelled(event)) {
    if (!color.startsWith("#") || color.length !== 7) {
      throw new Error(`Unexpected color: ${JSON.stringify(color)} -- don't know how to apply opacity`);
    }
    return `${color}7f`; // ... with opacity 0.5 (APP-4566, APP-4596)
  }
  return color;
}

export function calendarEventColor(
  event:
    | {
        type: "DrivingLesson";
        drivingLessonType:
          | "normal"
          | "unterweisungAmFahrzeug"
          | "praktischeUnterweisung"
          | "ueberlandfahrt"
          | "autobahnfahrt"
          | "nachtfahrt"
          | "simulator"
          | "praktischePruefung"
          | "schaltkompetenz"
          | "theoretischePruefung"
          | "fahrprobe";
      }
    | { type: "ASFCourseSession"; sessionType: "sitzung1" | "fahrprobe" | "sitzung2" | "sitzung3" | "sitzung4" }
    | { type: "TheoryLesson" | "TheoryExam" | "RemoteLesson" | "Other" | "Unknown" },
): string {
  switch (event.type) {
    case "DrivingLesson":
      switch (event.drivingLessonType) {
        case "normal":
        case "unterweisungAmFahrzeug":
        case "praktischeUnterweisung":
        case "fahrprobe":
          return autovioColors.green;
        case "ueberlandfahrt":
          return autovioColors.yellow;
        case "autobahnfahrt":
          return autovioColors.blue;
        case "nachtfahrt":
          return autovioColors.black;
        case "praktischePruefung":
        case "theoretischePruefung":
        case "schaltkompetenz":
          return autovioColors.orange;
        case "simulator":
          return autovioColors.greyLight;
        default:
          console.warn(`Unknown driving lesson type ${event.drivingLessonType} -- using color: red`);
          return autovioColors.red;
      }
    case "TheoryLesson":
      return autovioColors.violet;
    case "TheoryExam":
      return autovioColors.orange;
    case "RemoteLesson":
      return autovioColors.greyUltraLight;
    case "ASFCourseSession":
      return event.sessionType === "fahrprobe" ? autovioColors.green : autovioColors.violet;
    case "Other":
      return autovioColors.grey;
    default:
      // Fallback for unknown events ...
      return autovioColors.red;
  }
}

export const calendarEventTextColor = (event: AutovioCalendarEvent): string => {
  if (event.type === "RemoteLesson") {
    return autovioColors.black;
  }
  return autovioColors.white;
};

export const renderTheoryLessonSlots = (theoryLesson: TheoryLesson) => {
  const { numBookedStudents, maxStudents } = theoryLesson;
  return `${numBookedStudents}/${maxStudents}`;
};

export function renderLessonStatus(calendarEvent: AutovioCalendarEvent): string {
  if (!calendarEvent.status) {
    return "";
  }

  switch (calendarEvent.status) {
    case "invited":
      return "⏳️ Eingeladen";
    case "booked":
      return "✔ Gebucht";
    case "declinedByStudent":
      return "❌ Einladung abgelehnt";
    case "canceledByStudent":
      return "❌ Abgesagt vom Fahrschüler";
    case "canceledByInstructor":
      return "❌ Abgesagt vom Fahrlehrer";
    case "finished":
      return "🏁 Beendet";
    case "passed":
      return "👍 Bestanden";
    case "failed":
      return "👎 Durchgefallen";
    case "no-show":
      return "❌ No-Show";
    default:
      console.error("Could not render status of calendarEvent", calendarEvent);
      return "? Unbekannt";
  }
}

/**
 * Format: 09:46
 */
export const formatTime = (date: DateTime) => {
  return `${date.toLocaleString(DateTime.TIME_SIMPLE, { locale: "de-DE" })}`;
};

export const formatDate = (input?: string | DateTime) => {
  if (input) {
    const dateTime = typeof input === "string" ? DateTime.fromISO(input, { zone: "Europe/Berlin" }) : input;
    return dateTime.setLocale("de-DE").toLocaleString(DateTime.DATE_SHORT);
  }
  return "";
};

export const formatDateTime = (date: DateTime, asToday?: boolean) => {
  const today = DateTime.now().setLocale("de-DE").startOf("day");
  const isToday = date.hasSame(today, "day");

  if (isToday && asToday) {
    return replaceCommasWithDot(`Heute, ${date.toLocaleString(DateTime.TIME_SIMPLE, { locale: "de-DE" })} Uhr`);
  }

  return replaceCommasWithDot(`${date.toLocaleString(DateTime.DATETIME_MED, { locale: "de-DE" })} Uhr`);
};

export const formatDateOrTime = (date: DateTime, asToday?: boolean) => {
  const today = DateTime.now().setLocale("de-DE").startOf("day");
  const isToday = date.hasSame(today, "day");

  if (isToday && asToday) {
    return `${date.toLocaleString(DateTime.TIME_SIMPLE, { locale: "de-DE" })} Uhr`;
  }

  return formatDate(date);
};

/**
 * Format: 12.10.2021 • 09:00 - 10:00 or Heute • 09:00 - 10:00
 * */
export const formatDateTimeRange = (start: DateTime, end: DateTime) => {
  // if the start date is today or tomorrow, we want to display "today" or "tomorrow" instead of the date
  let startDate =
    Math.abs(DateTime.now().endOf("day").diff(start.endOf("day"), "days").days) <= 1
      ? start.toRelativeCalendar({
          locale: "de-DE",
        })
      : start.toLocaleString(DateTime.DATE_SHORT, { locale: "de-DE" });
  startDate = startDate ?? start.toLocaleString(DateTime.DATE_SHORT, { locale: "de-DE" });

  const capitalizedStartDate = startDate.charAt(0).toUpperCase() + startDate.slice(1);

  return `${capitalizedStartDate} • ${start.toLocaleString(DateTime.TIME_SIMPLE, {
    locale: "de-DE",
  })} - ${end.toLocaleString(DateTime.TIME_SIMPLE, { locale: "de-DE" })}`;
};

export function formatCalendarWeek(date: DateTime, now?: DateTime): string {
  if (!date.isValid) {
    throw new Error(`Invalid date: ${date.invalidReason}`);
  }
  let result = `KW ${date.weekNumber}`;
  let year = date.get("year");
  // Adjust the displayed year if the date lies in january but still
  // belongs to the calendar week of the previous year to avoid confusion.
  // Example: 2023-01-01 will be formatted to "KW 52, 2022".
  if (date.weekNumber >= 52 && date.get("month") === 1) {
    year -= 1;
  }
  if (year !== (now ?? DateTime.now()).get("year")) {
    result += `, ${year}`;
  }
  return result;
}

export function wasCancelled(event: AutovioCalendarEvent): boolean {
  // Cancelled by instructor?
  if (event.deleted) {
    return true;
  }
  // Cancelled by student?
  return (
    isPaidLesson(event) &&
    event.student.rsvp !== "pending" &&
    event.student.rsvp !== "accepted" &&
    event.student.rsvp !== "no-show"
  );
}

/**
 * Use this function as the eventClassNames property of a `FullCalendar` element.
 */
export function eventClassNames(arg: EventContentArg) {
  const classNames: string[] = [];
  classNames.push("overflow-hidden");
  const event = arg.event.extendedProps?.event as AutovioCalendarEvent | undefined;
  if (!event) {
    return classNames;
  }
  if (event.student?.rsvp === "pending" || (isTheoryLesson(event) && event.numBookedStudents === 0)) {
    classNames.push("pending-event");
  }
  if (wasCancelled(event)) {
    classNames.push("canceled-event");
  }
  return classNames;
}
