import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteRenderGroupParams,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  SxProps,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useCallback, useRef, useState, HTMLAttributes } from "react";
import { useRedirect } from "react-admin";
import { useHotkeys } from "react-hotkeys-hook";
import {
  getAuthenticatedServerClient,
  type DrivingSchoolSearchResultDto,
  type GuardianSearchResultDto,
  type InstructorSearchResultDto,
  type StudentSearchResultDto,
} from "../api/server.api.js";
import { reportError } from "../backoffice.utils.js";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect.js";
import { DrivingSchoolSearchIcon } from "../icons/DrivingSchoolSearchIcon";
import { InstructorSearchIcon } from "../icons/InstructorSearchIcon";
import { StudentSearchIcon } from "../icons/StudentSearchIcon";
import { autovioColors } from "./backofficeTheme";
import { Delayed } from "./Delayed.js";
import { Column } from "./Column.js";
import { Row } from "./Row.js";

export default function GlobalSearchBox() {
  const { open, setOpen, value, setValue, inputValue, setInputValue, loading, options } = useAutocompleteSearch();
  const redirect = useRedirect();

  const resetInputValue = () => {
    setInputValue("");
  };

  return (
    <Autocomplete
      sx={{
        borderRadius: "20px",
        backgroundColor: "white",
        border: "1px solid #d8d8d8",
        "&:hover": {
          border: "1px solid #9b9b9b",
        },
        "& .MuiInputBase-root": { borderRadius: "20px !important", paddingLeft: "20px !important" },
      }}
      blurOnSelect
      autoHighlight
      clearOnEscape
      value={value}
      inputValue={inputValue}
      componentsProps={{
        paper: {
          id: "global-search-results",
        },
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      open={open && inputValue !== ""}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      filterOptions={(options, _) => options} // Disable the UI filtering here, we do it on the server
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      loadingText="Lädt..."
      noOptionsText="Keine Ergebnisse"
      openText="Öffnen"
      clearText="Löschen"
      closeText="Schließen"
      groupBy={(option) => option.group}
      onChange={(_, value, reason) => {
        if (value && reason === "selectOption") {
          redirect("edit", value.resource, value.id);
          setValue(null);
          resetInputValue();
        } else {
          setValue(value);
        }
      }}
      renderOption={renderSearchResultOption}
      renderInput={(params) => <SearchInput {...params} loading={loading} resetValue={resetInputValue} />}
      renderGroup={(params) => <SearchResultGroup {...params} />}
    />
  );
}

function renderSearchResultOption(
  props: HTMLAttributes<HTMLLIElement>,
  option: SearchOption,
  { inputValue }: AutocompleteRenderOptionState,
) {
  const { id, name, subTextDetails, group } = option;

  // Render the results with match highlighting
  // The highlighted parts are shown in regular font weight, the rest in bold
  const nameMatches = match(name, inputValue, { insideWords: true });
  const nameParts = parse(name, nameMatches);

  const subText = subTextDetails?.text ?? "";
  const subTextMatches = subText ? match(subText, inputValue, { insideWords: true }) : [];
  const subTextParts = parse(subText, subTextMatches);

  const icon =
    group === "Fahrschulen" ? (
      <DrivingSchoolSearchIcon />
    ) : group === "Fahrlehrer" ? (
      <InstructorSearchIcon />
    ) : group === "Fahrschüler" || group === "Gesetzliche Vertreter" ? (
      <StudentSearchIcon />
    ) : null;

  return (
    <Box component="li" {...props} sx={{ px: "16px" }} key={id}>
      <Stack direction="row" style={{ display: "flex", alignItems: "start", gap: "10px", width: "100%" }}>
        <Box sx={{ pt: "2px" }}>{icon}</Box>
        <Column width="calc(100% - 28px)">
          {" "}
          {/* 100% parent width - (icon width + gap width) to apply ellipsis on relatedResource */}
          <Row>
            {nameParts.map((part, index) => (
              <StringChunk key={index + part.text} text={part.text} highlight={part.highlight} />
            ))}
          </Row>
          {subTextDetails && (
            <Row sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {subTextParts.map((part, index) => (
                <StringChunk
                  key={index + part.text}
                  text={part.text}
                  type="secondary"
                  highlight={part.highlight}
                  sx={{ wordBreak: "keep-all" }}
                />
              ))}
              {subTextDetails.relatedResource && (
                <StringChunk text={subTextDetails.relatedResource} type="secondary" highlight sx={{ ml: "4px" }} />
              )}
            </Row>
          )}
        </Column>
      </Stack>
    </Box>
  );
}

interface StringChunkProps {
  text: string;
  type?: "primary" | "secondary";
  highlight: boolean;
  sx?: SxProps;
}

function StringChunk({ text, type = "primary", highlight = false, sx = {} }: StringChunkProps) {
  const typeStyles = {
    primary: {
      fontSize: 15,
      color: autovioColors.black,
    },
    secondary: {
      fontSize: 11,
      color: autovioColors.grey,
    },
  };

  return (
    <Box
      component="span"
      sx={{
        fontWeight: highlight ? 400 : 700,
        ...typeStyles[type],
        ...sx,
      }}
    >
      {text.replace(" ", "\u00A0")}
    </Box>
  );
}

function SearchResultGroup(params: AutocompleteRenderGroupParams) {
  if (!Array.isArray(params.children) || params.children.length === 0) return null;
  return (
    <>
      <Box key={`${params.group}-group-header`} component="li" sx={{ p: "14px 20px 9px 20px" }}>
        <Typography sx={{ fontSize: 12, fontWeight: "normal", color: autovioColors.grey }}>{params.group}</Typography>
      </Box>
      {params.children}
      {/* Extra space after the group options */}
      <Box key={`${params.group}-hidden-divider`} component="li" sx={{ height: "8px" }} />
    </>
  );
}

function SearchInput(props: AutocompleteRenderInputParams & { loading: boolean; resetValue: () => void }) {
  const { loading, resetValue, ...params } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const userAgent = navigator.userAgent;
  const isMacOS = /Mac OS X/.test(userAgent);
  useHotkeys("ctrl+f", () => inputRef.current?.focus(), { preventDefault: true });
  useHotkeys("esc", () => inputRef.current?.blur(), { enableOnFormTags: true });
  const { greyUltraLight, grey } = autovioColors;
  const searchFieldPlaceholder = (
    <InputAdornment position="start" sx={{ color: grey }}>
      <Typography sx={{ fontSize: 14 }}>Suche</Typography>
      <Chip
        sx={{
          background: greyUltraLight,
          color: "#bbb",
          marginLeft: 1,
          fontWeight: "bold",
          fontSize: 10,
          height: 23,
        }}
        label={isMacOS ? "CTRL + F" : "STRG + F"}
      />
    </InputAdornment>
  );
  const [placeholder, setPlaceholder] = useState<JSX.Element | string>(searchFieldPlaceholder);

  const handleFocus = () => {
    setPlaceholder("");
  };

  const handleBlur = () => {
    setPlaceholder(searchFieldPlaceholder);
  };

  return (
    <TextField
      {...params}
      sx={{
        "& .MuiInputBase-input::placeholder": {
          color: "transparent",
        },
      }}
      label=""
      placeholder="Suche"
      inputRef={inputRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      size="small"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <SearchIcon sx={{ fontSize: 20, color: "#9b9b9b" }} />
            {placeholder}
          </Box>
        ),
        endAdornment: (
          <>
            {props.inputProps.value && (
              <>
                <Delayed ms={500}>
                  <InputAdornment position="end">{loading && <CircularProgress size={20} />}</InputAdornment>
                </Delayed>
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      height: 32,
                      width: 32,
                      marginRight: -40,
                    }}
                    onClick={resetValue}
                  >
                    <CloseIcon sx={{ fontSize: 20, color: "#9b9b9b" }} />
                  </IconButton>
                </InputAdornment>
              </>
            )}
          </>
        ),
      }}
    />
  );
}

function useAutocompleteSearch(): {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: SearchOption | null;
  setValue: (value: SearchOption | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  loading: boolean;
  options: SearchOption[];
} {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<SearchOption | null>(null);
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState<SearchOption[]>([]);
  const [loading, setLoading] = useState(true);

  const currentRequestRef = useRef(0);

  const fetchData = useCallback(async () => {
    if (!inputValue) {
      return;
    }
    const requestId = ++currentRequestRef.current;
    setLoading(true);
    try {
      const serverClient = await getAuthenticatedServerClient();
      const query = inputValue.trim();
      if (query && query.length > 1) {
        const { students, instructors, drivingSchools, guardians } = await serverClient.search({
          queries: { query },
        });
        if (requestId === currentRequestRef.current) {
          setOptions(
            [...(students ?? []), ...(instructors ?? []), ...(drivingSchools ?? []), ...(guardians ?? [])].map(
              _serverResultsToOptions,
            ),
          );
        }
      } else {
        setOptions([]);
      }
    } finally {
      if (requestId === currentRequestRef.current) {
        setLoading(false);
      }
    }
  }, [inputValue]);

  useDebouncedEffect(fetchData, 150, [inputValue]);

  return { open, setOpen, value, setValue, inputValue, setInputValue, loading, options };
}

interface SearchOption {
  id: string;
  resource: "students" | "instructors" | "drivingSchools" | "unknown";
  name: string;
  group: "Fahrschüler" | "Fahrlehrer" | "Fahrschulen" | "Gesetzliche Vertreter" | "Unbekannt";
  subTextDetails?: {
    text: string;
    relatedResource?: string;
  };
}

function _serverResultsToOptions(
  record: StudentSearchResultDto | InstructorSearchResultDto | DrivingSchoolSearchResultDto | GuardianSearchResultDto,
): SearchOption {
  const { id, uiString, relatedSearchResultUiString, relatedSearchFieldUiString } = record;

  type CommonFieldsKeys = "id" | "name" | "subTextDetails";
  const commonValues: Pick<SearchOption, CommonFieldsKeys> = { id, name: uiString };
  if (relatedSearchResultUiString) {
    commonValues.subTextDetails = {
      text: relatedSearchResultUiString,
      relatedResource: relatedSearchFieldUiString,
    };
  }

  if (record.drivingSchoolId && record.user && record.referralCode) {
    // Student
    return { ...commonValues, resource: "students", group: "Fahrschüler" };
  } else if (record.user) {
    // Instructor
    return { ...commonValues, resource: "instructors", name: record.uiString, group: "Fahrlehrer" };
  } else if (record.name) {
    // DrivingSchool
    return { ...commonValues, resource: "drivingSchools", name: record.uiString, group: "Fahrschulen" };
  } else if (record.studentDrivingSchoolId && typeof record.studentId === "string" && record.phone) {
    // Guardian
    return {
      ...commonValues,
      id: record.studentId,
      resource: "students",
      group: "Gesetzliche Vertreter",
    };
  } else {
    // Should never happen 🤞
    reportError("Could not create option out of search result", record);
    return { id: record.id, resource: "unknown", name: "Unbekannt", group: "Unbekannt" };
  }
}
