import { BooleanInput, useGetOne, useRecordContext } from "react-admin";
import { Instructor } from "../providers/instructorsProvider";
import { isStudent, Student } from "../providers/studentsProvider";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";

export function FeatureTogglesInput() {
  const user = useRecordContext<Student | Instructor>();
  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: user.drivingSchoolId },
    { enabled: !!user.drivingSchoolId },
  );

  if (!user || (isStudent(user) && !drivingSchool)) {
    return null;
  }

  const theoryLearningEnabled = drivingSchool?.customizations.includes("theoryLearning");

  const canLearnTheoryInMobileAppProps = theoryLearningEnabled
    ? {
        label: "canLearnTheoryInMobileApp (kann in der App Theorie lernen)",
      }
    : {
        label: "canLearnTheoryInMobileApp (kann in der App Theorie lernen) 🡐 deaktiviert für die Fahrschule",
        disabled: true,
      };

  return (
    <>
      {isStudent(user) && (
        <BooleanInput source="featureToggles.canLearnTheoryInMobileApp" {...canLearnTheoryInMobileAppProps} />
      )}
      {isStudent(user) && (
        <BooleanInput
          label="canBookTheoryLessons (kann Theorieunterricht buchen)"
          source="featureToggles.canBookTheoryLessons"
        />
      )}
      <BooleanInput label="lab (erlaube Zugriff auf das AUTOVIO Lab in der App)" source="featureToggles.lab" />
    </>
  );
}
