import { collection, doc, FieldValue, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import {
  DeleteParams,
  DeleteResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import type { DeepPartial } from "ts-essentials";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { z } from "zod";
import { TheoryExamWorkflowSchema } from "../model/TheoryExamWorkflow";
import { PracticalExamWorkflowSchema } from "../model/PracticalExamWorkflow";
import { AbstractDataProvider } from "./AbstractDataProvider";

export const WorkflowSchema = z.discriminatedUnion("type", [TheoryExamWorkflowSchema, PracticalExamWorkflowSchema]);
export type Workflow = z.infer<typeof WorkflowSchema> & { id: string };

class WorkflowsProvider extends AbstractDataProvider<Workflow> {
  async getOne(_resource: string, { id }: GetOneParams<Workflow>): Promise<GetOneResult<Workflow>> {
    const docRef = doc(firestore, `exam_planning/${id}`);
    const data = (await getDoc(docRef)).data();
    if (!data) {
      throw new Error(`${docRef.path} not found`);
    }
    if (data.deleted) {
      throw new Error(`${docRef.path} is marked as deleted`);
    }
    return {
      data: {
        id,
        ...WorkflowSchema.parse(data),
      },
    };
  }

  async getManyReference(_resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<Workflow>> {
    const { target, id, filter, sort, pagination } = params;
    if (target !== "drivingSchoolUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "drivingSchoolUid"`);
    }
    const { type, finished, ...unexpectedFilters } = filter;
    if (Object.keys(unexpectedFilters).length > 0) {
      throw new Error(`Unexpected filter(s): ${Object.keys(unexpectedFilters).join(", ")} -- expected: type, finished`);
    }
    const queryConstraints = [where("drivingSchoolUid", "==", id), where("deleted", "==", false)];
    if (type) {
      queryConstraints.push(where("type", "==", type));
    }
    if (typeof finished === "boolean") {
      queryConstraints.push(where("finished", "==", finished));
    }
    const snapshot = await getDocs(query(collection(firestore, `exam_planning`), ...queryConstraints));
    const workflows: Array<Workflow> = [];
    for (const doc of snapshot.docs) {
      try {
        workflows.push({
          id: doc.id,
          ...WorkflowSchema.parse(doc.data()),
        });
      } catch (error) {
        console.error(`Failed to parse Firestore document ${doc.ref.path}: ${error}`);
      }
    }
    return applyPagination(applySort(applyFilter(workflows, filter), sort), pagination);
  }

  async update(
    resource: string,
    { id, data }: UpdateParams<DeepPartial<Workflow> & { id: string; finishedAt?: FieldValue }>,
  ): Promise<UpdateResult<Workflow>> {
    console.info(`Updating exam_planning/${id} ...`, data);
    await setDoc(doc(firestore, `exam_planning/${id}`), data, { merge: true });
    return this.getOne(resource, { id });
  }

  async delete(resource: string, { id }: DeleteParams<Workflow> & { id: string }): Promise<DeleteResult<Workflow>> {
    const { data } = await this.getOne(resource, { id });
    await setDoc(doc(firestore, `exam_planning/${id}`), { deleted: true }, { merge: true });
    return { data };
  }
}

export const workflowsProvider = new WorkflowsProvider();
