import { StudentTab } from "./StudentTab";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import {
  LinearProgress,
  ListBase,
  Pagination,
  ReferenceManyField,
  useGetList,
  useGetOne,
  useGetRecordId,
  useListContext,
  useRecordContext,
} from "react-admin";
import { CreateManualInvoiceButton } from "../../buttons/CreateManualInvoiceButton";
import { InvoicesList } from "../../lists/InvoicesList";
import { DunningButtons } from "../../buttons/DunningButtons";
import { Row } from "../../misc/Row";
import type { Student } from "../../providers/studentsProvider.js";
import { LoadingIndicator } from "../../misc/LoadingIndicator.js";
import { KPIEuroIcon } from "../../icons/KPIEuroIcon";
import IconInACircle from "../../misc/IconInACircle";
import { autovioColors } from "../../misc/backofficeTheme";
import { KPICreditCardIcon } from "../../icons/KPICreditCardIcon";
import { KPICreditsIcon } from "../../icons/KPICreditsIcon";
import { KPIVouchersIcon } from "../../icons/KPIVouchersIcon";
import EditIcon from "@mui/icons-material/EditOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { PrepaidCreditsDialog } from "../../dialogs/PrepaidCreditsDialog";
import { useState } from "react";
import { DrivingSchoolAccounts } from "./AccountBalance";
import { AppliedCreditsDialog } from "../../dialogs/AppliedCreditsDialog";
import { VouchersDialog } from "../../dialogs/VouchersDialog";
import { GiftVoucher } from "../../providers/giftVouchersProvider";
import { moneyFormatter } from "../../misc/Money";
import { grants } from "../../backoffice.access_control";

export function StudentInvoicesList() {
  const studentId = useGetRecordId();
  const student = useRecordContext<Student>();
  const [openPrepaidCreditDialog, setOpenPrepaidCreditDialog] = useState<boolean>(false);
  const [openFreeCreditDialog, setOpenFreeCreditDialog] = useState<boolean>(false);
  const [openVouchersDialog, setOpenVouchersDialog] = useState<boolean>(false);
  const { data } = useGetOne("backendUser", { id: student.id });
  const { data: invoices } = useGetList("b2cInvoices", {
    filter: { studentId: student.id, excludeType: ["PREPAID_CREDITS"] },
    pagination: { page: 1, perPage: 9999 },
    sort: { field: "name", order: "ASC" },
  });
  const accounts =
    typeof data?.payload?.accounts === "object"
      ? (Object.values(data?.payload?.accounts)[0] as DrivingSchoolAccounts)
      : undefined;
  const totalCreditsAmount =
    parseFloat(accounts?.free_credits.balance.amount ?? "0.00") +
    parseFloat(accounts?.start_credits.balance.amount ?? "0.00") +
    parseFloat(accounts?.credits_contingents?.balance?.amount ?? "0.00");
  const totalInCents = invoices
    ?.filter((it) => it.status === "PAID")
    ?.reduce((sum, invoice) => sum + parseFloat(invoice.total), 0);
  const totalExpenditure = totalInCents / 100;
  const monetaryFlow = data?.payload?.monetary_flow;

  if (!student) {
    return <LoadingIndicator />;
  }
  return (
    <StudentTab>
      <ListBase resource="b2cInvoices" filter={{ studentId, excludeType: ["PREPAID_CREDITS"] }}>
        <Grid container spacing={3}>
          <Grid item lg={2.4}>
            <_InvoiceKPI
              icon={<KPIEuroIcon />}
              amount={moneyFormatter.format(totalExpenditure)}
              name="Gesamtausgaben"
              isLoading={Number.isNaN(totalExpenditure)}
            />
          </Grid>
          <Grid item lg={2.4}>
            <_InvoiceKPI
              icon={<KPIEuroIcon />}
              amount={moneyFormatter.format(parseFloat(accounts?.balance?.balance?.amount ?? "0"))}
              name="Saldo"
            />
          </Grid>
          <Grid item lg={2.4}>
            <_InvoiceKPI
              icon={<KPICreditCardIcon />}
              amount={moneyFormatter.format(parseFloat(accounts?.prepaid_credits?.balance?.amount ?? "0"))}
              name="Prepaid Guthaben"
              actionIcon="info"
              handleModalOpen={() => setOpenPrepaidCreditDialog(true)}
            />
          </Grid>
          <Grid item lg={2.4}>
            <_InvoiceKPI
              icon={<KPICreditsIcon />}
              amount={moneyFormatter.format(totalCreditsAmount)}
              name="Gutschriften"
              actionIcon="edit"
              handleModalOpen={() => setOpenFreeCreditDialog(true)}
            />
          </Grid>
          <Grid item lg={2.4}>
            <ReferenceManyField
              reference="giftVouchers"
              target="beneficiary"
              sort={{ field: "createdAt", order: "DESC" }}
              page={1}
              perPage={9999}
            >
              <_GiftVoucherKPI handleModalOpen={() => setOpenVouchersDialog(true)} />
            </ReferenceManyField>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "24px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 19, mb: "20px" }}>Rechnungen</Typography>
          <Row spacing={1}>
            <DunningButtons />
            {grants.includes("Invoice:createManual") && <CreateManualInvoiceButton />}
          </Row>
        </Box>
        <div>
          <InvoicesList student={student} />
          <Pagination />
        </div>
        <PrepaidCreditsDialog
          accounts={accounts}
          open={openPrepaidCreditDialog}
          onClose={() => setOpenPrepaidCreditDialog(false)}
        />
        <AppliedCreditsDialog
          open={openFreeCreditDialog}
          onClose={() => setOpenFreeCreditDialog(false)}
          accounts={accounts}
          monetaryFlow={monetaryFlow}
        />
        <VouchersDialog accounts={accounts} open={openVouchersDialog} onClose={() => setOpenVouchersDialog(false)} />
      </ListBase>
    </StudentTab>
  );
}

function _GiftVoucherKPI({ handleModalOpen }: { handleModalOpen?: () => void }) {
  const { data } = useListContext<GiftVoucher>();
  const totalAmount = data?.reduce((sum, voucher) => sum + parseFloat(voucher.amount), 0.0);
  return (
    <_InvoiceKPI
      icon={<KPIVouchersIcon />}
      amount={moneyFormatter.format(totalAmount)}
      name="Gutscheine"
      actionIcon={grants.includes("giftVoucher:view") ? "edit" : undefined}
      handleModalOpen={handleModalOpen}
    />
  );
}

function _InvoiceKPI({
  icon,
  amount,
  name,
  isLoading,
  actionIcon,
  handleModalOpen,
}: {
  icon: JSX.Element;
  amount: string;
  name: string;
  isLoading?: boolean;
  actionIcon?: "edit" | "info";
  handleModalOpen?: () => void;
}) {
  const { green, red } = autovioColors;
  const balance = parseFloat(amount) ?? 0.0;
  const isBalanceGreaterThanZero = !!balance;
  const isSaldoBalanceLessThanZero = balance < 0 && name === "Saldo";

  return (
    <Card
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.06) 0 2px 5px",
        height: "95px",
        borderRadius: "8px",
        background: "white",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {actionIcon === "edit" && (
        <div style={{ position: "relative" }}>
          <IconButton style={{ position: "absolute", right: -20, top: 0 }} onClick={() => handleModalOpen?.()}>
            <EditIcon />
          </IconButton>
        </div>
      )}
      {actionIcon === "info" && (
        <div style={{ position: "relative" }}>
          <IconButton style={{ position: "absolute", right: -20, top: 0 }} onClick={() => handleModalOpen?.()}>
            <InfoIcon style={{ fontSize: 20 }} />
          </IconButton>
        </div>
      )}
      <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}>
        <Box sx={{ display: "flex", gap: "11px", alignItems: "center" }}>
          <IconInACircle
            icon={icon}
            style={{
              size: 32,
              circleColor: isSaldoBalanceLessThanZero ? red : isBalanceGreaterThanZero ? green : "#9b9b9b",
            }}
          />
          <Box>
            <Box>
              {isLoading ? (
                <LinearProgress
                  timeout={50}
                  style={{ margin: "9px 0", width: "3em", display: "inline-block", verticalAlign: "center" }}
                />
              ) : (
                <Typography sx={{ fontWeight: 600, fontSize: "19px" }}>{amount}</Typography>
              )}
            </Box>
            <Box>
              <Typography sx={{ color: "#888", fontWeight: "normal", fontSize: "14px" }}>{name}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
