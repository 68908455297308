import { useMemo } from "react";
import { useGetList, useGetOne } from "react-admin";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Student } from "../providers/studentsProvider";
import { useDialog } from "../hooks/useDialog";
import { EmailSammelquittungDialog } from "../dialogs/EmailSammelquittungDialog";
import { type Invoice, isOpenInvoice } from "../providers/invoicesProvider";
import { type DrivingSchool } from "../providers/drivingSchoolsProvider";
import { type FundingInstructions } from "../providers/backendUserProvider";

export function EmailSammelquittungButton({ student }: { student: Student }) {
  const drivingSchoolId = student?.drivingSchoolId;
  const { data: drivingSchool } = useGetOne<DrivingSchool>("drivingSchools", { id: drivingSchoolId });
  const { data: invoices } = useGetList<Invoice>("b2cInvoices", {
    filter: { studentId: student.id, excludeType: ["PREPAID_CREDITS"] },
    pagination: { page: 1, perPage: 9999 },
    sort: { field: "createdAt", order: "ASC" },
  });
  const openInvoices = useMemo(() => invoices?.filter(isOpenInvoice), [invoices]);
  const { data: fundingInstructions } = useGetOne<FundingInstructions>("fundingInstructions", { id: student.id });
  const iban = fundingInstructions?.iban;
  const { dialogProps, openDialog } = useDialog();

  if (!(drivingSchool && openInvoices?.length && iban)) {
    return null;
  }

  return (
    <>
      <Button startIcon={<AddIcon />} variant="outlined" onClick={openDialog}>
        E-Mail Sammelquittung
      </Button>
      <EmailSammelquittungDialog {...dialogProps} {...{ student, openInvoices, drivingSchool, iban }} />
    </>
  );
}
