import { ShowBase } from "../misc/ShowBase";
import { Route, Routes } from "react-router-dom";
import { DrivingSchoolCalendar } from "./drivingSchool/DrivingSchoolCalendar";
import { DrivingSchoolInstructors } from "./drivingSchool/DrivingSchoolInstructors";
import { DrivingSchoolCustomerService } from "./drivingSchool/DrivingSchoolCustomerService";
import { DrivingSchoolFleet } from "./drivingSchool/DrivingSchoolFleet";
import { DrivingSchoolPracticalExamWorkflowsBoard } from "./drivingSchool/DrivingSchoolPracticalExamWorkflowsBoard";
import { DrivingSchoolStudentsList } from "./drivingSchool/DrivingSchoolStudentsList";
import { DrivingSchoolTheoryExamWorkflowsBoard } from "./drivingSchool/DrivingSchoolTheoryExamWorkflowsBoard";
import { DrivingSchoolExaminationAssignmentsList } from "./drivingSchool/DrivingSchoolExaminationAssignmentsList";
import { DrivingSchoolPerformanceOverview } from "./drivingSchool/DrivingSchoolPerformanceOverview";
import { PayoutOverview } from "./drivingSchool/DrivingSchoolPayouts.js";
import { TaxConsultantExports } from "./drivingSchool/DrivingSchoolTaxConsultantsDownloads.js";
import { BusinessIntelligencePage } from "../misc/BusinessIntelligencePage";
import { DrivingSchoolDashboard } from "./drivingSchool/DrivingSchoolDashboard";
import { DrivingSchoolAdminForm } from "./drivingSchool/DrivingSchoolAdminForm";
import { DrivingSchoolCourses } from "./drivingSchool/DrivingSchoolCourses";
import { DrivingSchoolBranchesList } from "./drivingSchool/DrivingSchoolBranchesList";
import { EmptyState } from "../misc/EmptyState";
import { useGetRecordId } from "react-admin";

export function DrivingSchoolRoutes() {
  const drivingSchoolId = useGetRecordId();
  return (
    <ShowBase key={drivingSchoolId /* ... to prevent that old data is shown when the driving school is switched. */}>
      <Routes>
        <Route path="/" element={<DrivingSchoolDashboard />} />
        <Route path="calendar" element={<DrivingSchoolCalendar />} />
        <Route path="instructors" element={<DrivingSchoolInstructors />} />
        <Route path="customerService" element={<DrivingSchoolCustomerService />} />
        <Route
          path="students"
          element={
            <DrivingSchoolStudentsList
              key="students"
              storeKey="drivingSchoolStudents"
              empty={
                <EmptyState
                  label="Noch keine Fahrschüler vorhanden."
                  labelWhenFilter="Keine passenden Fahrschüler gefunden."
                />
              }
            />
          }
        />
        <Route
          path="studentsWithoutInstructors"
          element={
            <DrivingSchoolStudentsList
              key="studentsWithoutInstructors"
              title="Fahrschüler ohne Fahrlehrer"
              storeKey="studentsWithoutInstructors"
              filter={{ instructorIds: (val: Array<string>) => val.length === 0 }}
              empty={
                <EmptyState
                  label="Alle Fahrschüler sind einem Fahrlehrer zugeordnet."
                  labelWhenFilter="Keine passenden Fahrschüler gefunden."
                />
              }
            />
          }
        />
        <Route path="examinationAssignments" element={<DrivingSchoolExaminationAssignmentsList />} />
        <Route path="theoryExams" element={<DrivingSchoolTheoryExamWorkflowsBoard />} />
        <Route path="practicalExams" element={<DrivingSchoolPracticalExamWorkflowsBoard />} />
        <Route
          path="performanceOverview"
          element={<DrivingSchoolPerformanceOverview title="Leistungsübersicht" resource="performanceOverview" />}
        />
        <Route
          path="advancePayments"
          element={<DrivingSchoolPerformanceOverview title="Vorauszahlungen" resource="advancePayments" />}
        />
        <Route
          path="openInvoices"
          element={<DrivingSchoolPerformanceOverview title="Offene Posten" resource="openInvoices" />}
        />
        <Route path="payouts" element={<PayoutOverview />} />
        <Route path="tax" element={<TaxConsultantExports />} />
        <Route path="bi" element={<BusinessIntelligencePage />} />
        <Route path="branches" element={<DrivingSchoolBranchesList />} />
        <Route path="fleet" element={<DrivingSchoolFleet />} />
        <Route path="courses" element={<DrivingSchoolCourses />} />
        <Route path="admin" element={<DrivingSchoolAdminForm />} />
      </Routes>
    </ShowBase>
  );
}
