import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import type { DialogProps } from "../../misc/DialogProps";
import { DrivingLessonRepetitionsCalendar } from "./DrivingLessonRepetitionsCalendar";

export function DrivingLessonRepetitionsDialog(dialogProps: DialogProps) {
  return (
    <Dialog {...dialogProps} maxWidth="xl" data-testid="select-driving-lesson-repetitions-dialog">
      <DialogTitle>Fahrstundenwiederholungen</DialogTitle>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent>
        <DrivingLessonRepetitionsCalendar onBack={dialogProps.onClose} />
      </DialogContent>
    </Dialog>
  );
}
