import { Datagrid, ListContextProvider, NumberField, TextField, useList } from "react-admin";
import { DateField } from "../../fields/DateField";
import { type ReactNode } from "react";
import { BackendUserRecord } from "../../providers/backendUserProvider";
import { Card, CardContent, Typography } from "@mui/material";
import { User } from "../../generated/backendClient";

// infer type information from the generated backendClient (all accounts have the same structure, so we just pick the first one)
export type AccountInfo = User["accounts"][0]["balance"];

export const AccountBalance = ({
  title,
  accountInfo,
  children,
  showAmount = true,
}: {
  title: string;
  accountInfo?: AccountInfo;
  children?: ReactNode;
  showAmount?: boolean;
}) => {
  return (
    <Card sx={{ marginTop: "1em" }}>
      <CardContent>
        <span style={{ fontWeight: 900 }}>{title}</span>
        <br />
        {children ? (
          children
        ) : accountInfo ? (
          <>
            {showAmount && (
              <NumberField
                source="amount"
                record={{ amount: parseFloat(accountInfo.balance.amount) }}
                options={{ style: "currency", currency: accountInfo.balance.currency }}
                style={{ fontWeight: 900, fontSize: "1.5rem" }}
              />
            )}
            <br />
            <TransactionList transactions={accountInfo.transactions} />
          </>
        ) : (
          <>
            <span>Keine Transaktionen</span>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const moneyForNumberField = (money: AccountInfo["balance"]): { amount: number; currency: string } => {
  return { amount: parseFloat(money.amount), currency: money.currency };
};

const TransactionList = ({ transactions }: { transactions: AccountInfo["transactions"] }) => {
  const listContext = useList({
    data: transactions.map((t) => {
      return {
        ...t,
        amount: moneyForNumberField(t.amount),
        description: t.description,
      };
    }),
  });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
        isRowSelectable={() => false}
        empty={<Typography>Keine Transaktionen gefunden.</Typography>}
      >
        <DateField source="created_at" label="Datum" />
        <NumberField
          source="amount.amount"
          label="Betrag"
          options={{ style: "currency", currency: "EUR" }}
          style={{ whiteSpace: "nowrap" }}
        />
        <TextField source="description" label="Beschreibung" />
        {/*<TextField source="payload" />*/}
      </Datagrid>
    </ListContextProvider>
  );
};

export type DrivingSchoolAccounts = BackendUserRecord["payload"]["accounts"][0];
