import { Instructor } from "../../providers/instructorsProvider";
import { Student } from "../../providers/studentsProvider";
import { BookedTraining } from "../../model/BookedTraining";
import { PostalAddress } from "../../model/PostalAddress";
import { StudentSelect } from "../../inputs/StudentSelect";
import { BookedTrainingSelect } from "../../inputs/BookedTrainingSelect";
import { DrivingLessonStartLocationSelect } from "../../inputs/DrivingLessonStartLocationSelect";
import { Row } from "../Row";
import { Button, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { autovioColors } from "../backofficeTheme";

interface InstructorCalendarRecommendationFilterProps {
  instructor: Instructor;
  selectedStudent?: Student;
  selectedBookedTraining?: BookedTraining;
  setSelectedStudent: (student: Student | undefined) => void;
  setSelectedBookedTraining: (bookedTraining: BookedTraining | undefined) => void;
  setSelectedStartLocation: (postalAddress: PostalAddress | undefined) => void;
  setShowRecommednationFilters: (state: boolean) => void;
}
export const InstructorCalendarRecommendationFilter = ({
  instructor,
  selectedStudent,
  selectedBookedTraining,
  setSelectedStudent,
  setSelectedBookedTraining,
  setSelectedStartLocation,
  setShowRecommednationFilters,
}: InstructorCalendarRecommendationFilterProps) => {
  return (
    <Row sx={{ alignItems: "center", gap: "30px", width: "100%" }}>
      <Typography fontSize="15px" lineHeight={1.2} fontWeight={600}>
        Termin-
        <br />
        vorschläge:
      </Typography>
      <Row sx={{ gap: "16px", alignItems: "center", width: "100%" }}>
        <StudentSelect
          label="Fahrschüler"
          sx={{ minWidth: "250px", maxWidth: "400px" }}
          size="small"
          onChange={(student) => setSelectedStudent(student ?? undefined)}
          instructorId={instructor.id}
          value={selectedStudent}
        />
        {selectedStudent && (
          <BookedTrainingSelect
            sx={{ minWidth: "250px", maxWidth: "400px", margin: 0 }}
            student={selectedStudent}
            onChange={setSelectedBookedTraining}
            value={selectedBookedTraining ?? selectedStudent.activeOrMostRecentBookedTraining}
          />
        )}
        {selectedStudent && instructor.drivingSchoolId && (
          <DrivingLessonStartLocationSelect
            sx={{ minWidth: "250px", maxWidth: "400px" }}
            onChange={(postalAddress) => setSelectedStartLocation(postalAddress ?? undefined)}
            instructorId={instructor.id}
            studentId={selectedStudent.id}
            drivingSchoolId={instructor.drivingSchoolId}
          />
        )}
        <Button
          onClick={() => {
            setShowRecommednationFilters(false);
          }}
          sx={{
            textTransform: "none",
            color: autovioColors.green,
            ml: "auto",
          }}
          endIcon={<ClearIcon />}
        >
          Schließen
        </Button>
      </Row>
    </Row>
  );
};
