import { auth } from "../firebase";
import { createApiClient, schemas } from "../generated/resourceClient";
import type { z } from "zod";

export { schemas } from "../generated/resourceClient";

export type ResourceClient = ReturnType<typeof createApiClient>;

const useLocalBackend = import.meta.env.VITE_USE_LOCAL_BACKEND || false;
export const baseUrl = useLocalBackend
  ? "http://localhost:3003"
  : location.hostname === "backoffice.autovio.de"
    ? "https://resource.autovio.de"
    : "https://resource.autovio.dev";

export async function getAuthenticatedResourceClient(): Promise<ResourceClient> {
  const jwt = await auth.currentUser?.getIdToken();
  const resourceClient = createApiClient(baseUrl, { axiosConfig: { headers: { Authorization: `Bearer ${jwt}` } } });
  if (window.unitTest) {
    resourceClient.use({
      name: "intercept all requests",
      request: async (_api, config) => {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw config;
      },
    });
  } else if (window.e2eTest) {
    // Redirect all PATCH, POST, and PUT requests to a dummy server address
    // to prevent that any e2e test accidentally creates or changes test data ...
    // see https://www.zodios.org/docs/client/plugins#write-your-own-plugin
    resourceClient.use({
      name: "redirect PATCH, POST, and PUT requests",
      request: async (_api, config) => {
        if (config.method?.toLowerCase().startsWith("p")) {
          (config as Writable<typeof config>).baseURL = "https://pseudo-resource.autovio.dev";
        }
        return config;
      },
    });
  }
  return resourceClient;
}

export type ResourceType = z.infer<typeof schemas.ResourceTypeEnum>;
export type ResourceDto = z.infer<typeof schemas.ResourceDto>;
export type AdasDto = z.infer<typeof schemas.AdvancedDriverAssistanceSystemDto>;
