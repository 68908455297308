import type {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { applyPagination, applySort } from "../backoffice.utils";
import { DrivingLicenseClass } from "../model/DrivingLicenseClass";
import { studentsProvider } from "./studentsProvider";

export interface BookedQuote extends RaRecord {
  id: string;
  bundleName: string;
  drivingLicenseClasses: Array<DrivingLicenseClass>;
  drivingSchoolId: string;
  instructorId?: string | null;
  studentId: string;
  trainingIds: Array<string>;
  prices: { [productName: string]: number };
}

class BookedQuotesProvider {
  async create(_: string, __: CreateParams): Promise<CreateResult<BookedQuote>> {
    throw new Error("Not implemented");
  }

  async delete(_: string, __: DeleteParams<BookedQuote>): Promise<DeleteResult<BookedQuote>> {
    throw new Error("Not implemented");
  }

  async deleteMany(_: string, __: DeleteManyParams<BookedQuote>): Promise<DeleteManyResult<BookedQuote>> {
    throw new Error("Not implemented");
  }

  async getList(_: string, { filter, sort, pagination }: GetListParams): Promise<GetListResult<BookedQuote>> {
    const { studentId } = filter;
    if (!studentId || Object.keys(filter).length !== 1) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"studentId": ...}`);
    }
    const { data: student } = await studentsProvider.getOne("students", { id: studentId });
    const bookedQuotes: Array<BookedQuote> = [];
    for (const bookedTraining of student.bookedTrainings) {
      const { quoteId } = bookedTraining;
      const bookedQuote = bookedQuotes.find((it) => it.id === quoteId);
      if (bookedQuote) {
        if (bookedTraining.isForDrivingLicense) {
          bookedQuote.drivingLicenseClasses.push(bookedTraining.drivingLicenseClass);
          bookedQuote.drivingLicenseClasses.sort();
        }
        bookedQuote.trainingIds.push(bookedTraining.trainingId);
        Object.assign(bookedQuote.prices, bookedTraining.prices);
      } else {
        bookedQuotes.push({
          id: quoteId,
          bundleName: bookedTraining.bundleName,
          drivingLicenseClasses: bookedTraining.isForDrivingLicense ? [bookedTraining.drivingLicenseClass] : [],
          prices: bookedTraining.prices,
          drivingSchoolId: bookedTraining.drivingSchoolId,
          instructorId: bookedTraining.instructorId,
          trainingIds: [bookedTraining.trainingId],
          studentId,
        });
      }
    }
    return applyPagination(applySort(bookedQuotes, sort), pagination);
  }

  async getMany(_: string, __: GetManyParams): Promise<GetManyResult<BookedQuote>> {
    throw new Error("Not implemented");
  }

  async getManyReference(
    resource: string,
    { id, target, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<BookedQuote>> {
    if (filter && Object.keys(filter).length > 0) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {}`);
    }
    if (target !== "studentId") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentId"`);
    }
    return this.getList(resource, { filter: { studentId: id }, sort, pagination });
  }

  async getOne(_: string, __: GetOneParams): Promise<GetOneResult<BookedQuote>> {
    throw new Error("Not implemented");
  }

  async update(_: string, __: UpdateParams): Promise<UpdateResult<BookedQuote>> {
    throw new Error("Not implemented");
  }

  async updateMany(_: string, __: UpdateManyParams): Promise<UpdateManyResult<BookedQuote>> {
    throw new Error("Not implemented");
  }
}

export const bookedQuotesProvides = new BookedQuotesProvider();
