import { Avatar, Box, Typography } from "@mui/material";
import { Tab, useRecordContext } from "react-admin";
import { grants } from "../backoffice.access_control";
import { autovioColors, overviewCardStyle } from "../misc/backofficeTheme";
import { ShowBase } from "../misc/ShowBase";
import { Tabs } from "../misc/Tabs";
import { Row } from "../misc/Row";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { NameHeader } from "../misc/NameHeader";
import { Employee } from "../providers/employeesProvider";
import { EmployeeAdminForm } from "./employee/EmployeeAdminForm";
import { EmployeeData } from "./employee/EmployeeData";
import { useSwitchDrivingSchoolIfNecessary } from "../hooks/useSwitchDrivingSchoolIfNecessary";

export function EmployeeDetails() {
  return (
    <ShowBase>
      <_EmployeeDetailsTabs />
    </ShowBase>
  );
}

function _EmployeeDetailsHeader({ employee }: { employee: Employee }) {
  return (
    <Row sx={{ my: "20px" }} spacing="50px" alignItems="start">
      <Row spacing="20px" alignItems="center">
        <Avatar src={employee.avatarUrl} sx={{ width: "60px", height: "60px" }} />
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <NameHeader>{employee.name}</NameHeader>
          <Typography sx={{ color: autovioColors.grey, fontSize: "14px", lineHeight: "1.43" }}>Support</Typography>
        </Box>
      </Row>
      <Row sx={{ flexWrap: "wrap" }} alignItems="center">
        {/*  <HeaderField ... */}
      </Row>
    </Row>
  );
}

function _EmployeeDetailsTabs() {
  useSwitchDrivingSchoolIfNecessary((employee: Employee) => employee.drivingSchoolId);
  const employee = useRecordContext<Employee>();

  if (!employee) {
    return <LoadingIndicator />;
  }

  return (
    <Tabs
      sx={{ ...overviewCardStyle, padding: "5px 30px 10px 30px" }}
      header={<_EmployeeDetailsHeader employee={employee} />}
      separateCards
    >
      <Tab label="Stammdaten" /* no path attribute here, so the tab is shown by default */>
        <EmployeeData />
      </Tab>
      {grants.includes("admin") && (
        <Tab label="Admin" path="admin">
          <EmployeeAdminForm />
        </Tab>
      )}
    </Tabs>
  );
}
