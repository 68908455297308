import { AutovioCalendarEvent, isDrivingLesson, isTheoryLesson } from "../../model/autovioCalendarEvents";
import { Typography } from "@mui/material";
import { assertNever, to45MinUnits } from "../../backoffice.utils";
import { Column } from "../Column";
import { Row } from "../Row";
import { autovioColors } from "../backofficeTheme";
import { DateTime } from "luxon";

const _numberFormatter = new Intl.NumberFormat("de", { maximumFractionDigits: 2 });

interface InstructorCalendarStatsProps {
  dateRange: { from: string; to: string };
  events?: AutovioCalendarEvent[];
}

export const InstructorCalendarStats = ({ dateRange, events = [] }: InstructorCalendarStatsProps) => {
  const filteredEvents = excludeEventsOutsideCurrentMonth(events, dateRange); // Filter applied for month calendar view only.
  const bookedUnits: Record<string, { amount: number; unit: string }> = {
    Fahrstunden: { amount: 0, unit: "UE" },
    Theorie: { amount: 0, unit: "UE" },
    "Kurzfristig abgesagt": { amount: 0, unit: "UE" },
    Abwesenheiten: { amount: 0, unit: "Tage" },
    Sonstiges: { amount: 0, unit: "UE" },
  };
  for (const event of filteredEvents) {
    if (isDrivingLesson(event)) {
      if (event.student.canceledAtShortNotice) {
        bookedUnits["Kurzfristig abgesagt"].amount += to45MinUnits(event);
      } else {
        bookedUnits.Fahrstunden.amount += to45MinUnits(event);
      }
    } else if (isTheoryLesson(event)) {
      bookedUnits.Theorie.amount += to45MinUnits(event);
    } else if (event.type === "Other") {
      if (["sickness", "vacation", "training"].includes(event.category)) {
        if (!event.otherEventDuration) continue;
        switch (event.otherEventDuration) {
          case "fullDay":
            bookedUnits.Abwesenheiten.amount += 1;
            break;
          case "halfDay":
          case "halfDayInTheMorning":
          case "halfDayInTheAfternoon":
            bookedUnits.Abwesenheiten.amount += 0.5;
            break;
          default:
            assertNever(event.otherEventDuration);
        }
      } else {
        bookedUnits.Sonstiges.amount += to45MinUnits(event);
      }
    }
  }

  return Object.entries(bookedUnits).map(([type, { amount, unit }]) => (
    <State key={type} label={type} amount={amount} unit={unit} />
  ));
};

const State = ({ label, amount, unit }: { label: string; amount: number; unit: string }) => {
  return (
    <Column>
      <Row sx={{ gap: "4px", alignItems: "baseline" }}>
        <Typography variant="h6" fontWeight={600} lineHeight={1.33}>
          {_numberFormatter.format(amount)}
        </Typography>
        <Typography variant="subtitle2" fontSize="15px" fontWeight={600}>
          {unit}
        </Typography>
      </Row>
      <Row>
        <Typography variant="body2" color={autovioColors.grey}>
          {label}
        </Typography>
      </Row>
    </Column>
  );
};

const excludeEventsOutsideCurrentMonth = (events: AutovioCalendarEvent[], dateRange: { from: string; to: string }) => {
  const from = DateTime.fromFormat(dateRange.from, "yyyy-MM-dd");
  const to = DateTime.fromFormat(dateRange.to, "yyyy-MM-dd");
  if (Math.abs(to.diff(from).as("days")) <= 7) return events; // Not a month calendar view (day or week view).
  let startOfMonth = from.startOf("month");
  let endOfMonth = from.endOf("month");
  if (!from.hasSame(from.startOf("month"), "day")) {
    startOfMonth = from.plus({ month: 1 }).startOf("month");
    endOfMonth = startOfMonth.endOf("month");
  }
  return events.filter((event) => event.start >= startOfMonth && event.start <= endOfMonth);
};
