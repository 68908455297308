import { DateTime } from "luxon";
import { useLocation } from "react-router";

export function useDateFromHashOrNow(): DateTime {
  let { hash } = useLocation();
  if (hash) {
    // Remove leading "#" ...
    hash = hash.substring(1);
    const date = DateTime.fromISO(hash);
    if (date.isValid) {
      return date;
    }
    const hashKeyValue: { [K in string]: string } = hash
      .split("&")
      .map((it) => it.split("="))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    if (hashKeyValue.date) {
      const date = DateTime.fromISO(hashKeyValue.date);
      if (date.isValid) {
        return date;
      }
    }
  }
  return DateTime.now();
}
