import { doc, onSnapshot } from "firebase/firestore";
import type { z } from "zod";
import { assertNever, reportError } from "../backoffice.utils";
import { auth, firestore } from "../firebase";
import { createApiClient, schemas } from "../generated/serverClient";
import { isASFCourseSession, isTheoryLesson } from "../model/autovioCalendarEvents";
import { CancellationPolicy } from "../model/CancellationPolicy";
import { Course } from "../providers/coursesProvider";
import { studentNotesProvider } from "../providers/notesProvider";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { createNote } from "./backoffice.api";
import { theoryLessonConverter } from "../providers/converter/theoryLessonConverter";
import { updateApplied } from "../utils/updateApplied";
import { Invoice } from "../providers/invoicesProvider";

export { schemas } from "../generated/serverClient";

export type ServerClient = ReturnType<typeof createApiClient>;

const useLocalBackend = import.meta.env.VITE_USE_LOCAL_BACKEND || false;
export const baseUrl = useLocalBackend
  ? "http://localhost:4242"
  : location.hostname === "backoffice.autovio.de"
    ? "https://server.autovio.de"
    : "https://server.autovio.dev";

export async function getAuthenticatedServerClient(): Promise<ServerClient> {
  const jwt = await auth.currentUser?.getIdToken();
  const serverClient = createApiClient(baseUrl, { axiosConfig: { headers: { Authorization: `Bearer ${jwt}` } } });
  if (window.unitTest) {
    serverClient.use({
      name: "intercept all requests",
      request: async (_api, config) => {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw config;
      },
    });
  } else if (window.e2eTest) {
    // Redirect all PATCH, POST, and PUT requests to a dummy server address
    // to prevent that any e2e test accidentally creates or changes test data ...
    // see https://www.zodios.org/docs/client/plugins#write-your-own-plugin
    serverClient.use({
      name: "redirect PATCH, POST, and PUT requests",
      request: async (_api, config) => {
        if (config.method?.toLowerCase().startsWith("p")) {
          (config as Writable<typeof config>).baseURL = "https://pseudo-server.autovio.dev";
        }
        return config;
      },
    });
  }
  return serverClient;
}

class ServerAPI {
  async getUser(id: string): Promise<UserDto> {
    const serverClient = await getAuthenticatedServerClient();
    const dto = await serverClient.get_v2User({ params: { id } });
    return { id, ...dto };
  }

  /**
   * Side effects:
   * - If the given noticeType is "student_blocked" the status of the student will be changed to "outstandingPayments".
   * - If the given noticeType is "inkasso_notice" a PAIR Finance case file will be created.
   * - A note is added to the student with the content of the email.
   */
  async sendDunningNotice({
    student,
    dunningProcessId,
    noticeType,
    emailSubject,
    emailText,
  }: {
    student: Student;
    dunningProcessId: string;
    noticeType: "student_blocked" | "first_dunning_notice" | "second_dunning_notice" | "inkasso_notice";
    emailSubject: string;
    emailText: string;
  }) {
    const updateReceived = new Promise<void>((resolve) => {
      const oldDunningProcess = JSON.stringify(student.dunningProcess);
      const unsubscribe = studentsProvider.onUpdate(student, (student) => {
        const newDunningProcess = JSON.stringify(student.dunningProcess);
        if (newDunningProcess !== oldDunningProcess) {
          unsubscribe();
          resolve();
        }
      });
    });
    const serverClient = await getAuthenticatedServerClient();
    let localizedNoticeType: string;
    if (noticeType === "student_blocked") {
      const studentUid = student.id;
      await serverClient.blockStudentAndSendPaymentReminder({ studentUid, dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "Zahlungserinnerung";
    } else if (noticeType === "first_dunning_notice") {
      await serverClient.sendFirstDunningNotice({ dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "1. Mahnung";
    } else if (noticeType === "second_dunning_notice") {
      await serverClient.sendSecondDunningNotice({ dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "2. Mahnung";
    } else if (noticeType === "inkasso_notice") {
      await serverClient.handoverToPairFinance({ dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "Inkasso";
    } else {
      assertNever(noticeType);
    }
    try {
      await createNote({
        studentUid: student.id,
        body: `<p style="font-weight: bold; color: red">Mahnwesen - ${localizedNoticeType}</p>
<p>Wir haben an ${student.firstName} folgende Nachricht geschickt:</p>
<p>${emailText.replaceAll("\n", "<br />\n")}</p>`,
      });
    } catch (error) {
      reportError(`Failed to create note "Mahnwesen - ${localizedNoticeType} ..."`, error);
    }
    await updateReceived;
    await queryClient.invalidateQueries();
  }

  /**
   * Side effect:
   * - A note is added to the student with the content of the email.
   */
  async sendSammelquittungEmail({
    student,
    emailRecipient,
    emailSubject,
    emailText,
    emailHtml,
    invoices,
  }: {
    student: Student;
    emailRecipient: string;
    emailSubject: string;
    emailText: string;
    emailHtml: string;
    invoices: Array<Invoice>;
  }) {
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.sendSammelquittungEmail({
      recipient: emailRecipient,
      subject: emailSubject,
      text: emailText,
      html: emailHtml,
      invoices: invoices.map((it) => it.downloadUrl!),
    });
    try {
      await createNote({
        studentUid: student.id,
        body: `<p style="font-weight: bold; color: red">Mahnwesen - Sammelquittung</p>
<p>Wir haben an ${emailRecipient} folgende Nachricht geschickt:</p>
<pre>${emailText}</pre>`,
      });
    } catch (error) {
      reportError(`Failed to create note "Mahnwesen - Sammelquittung ..."`, error);
    }
    await queryClient.invalidateQueries();
  }

  async sendShoppingVoucher({
    idempotencyKey,
    studentId,
    amount,
    messageToStudent,
    noteForInstructor,
  }: {
    idempotencyKey: string;
    studentId: string;
    amount: number;
    messageToStudent: string;
    noteForInstructor: string;
  }) {
    const oldNotes = await studentNotesProvider.fetchIfNeeded(studentId);
    const noteInFirestoreCreated = new Promise<void>((resolve, reject) => {
      const unsubscribe = studentNotesProvider.onUpdate(async () => {
        try {
          const newNotes = await studentNotesProvider.fetchIfNeeded(studentId);
          if (newNotes.length > oldNotes.length) {
            unsubscribe();
            resolve();
          }
        } catch (error) {
          unsubscribe();
          reject(error);
        }
      });
    });
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.createGiftVoucher({
      id: idempotencyKey,
      beneficiary: studentId,
      value: { amount, currency: "EUR" },
      description: messageToStudent,
      note: noteForInstructor,
    });
    await noteInFirestoreCreated;
    await queryClient.invalidateQueries();
  }

  async addStudentToCourse({
    student,
    course,
    agreedCancellationPolicy,
  }: {
    student: Student;
    course: Course;
    agreedCancellationPolicy?: CancellationPolicy;
  }) {
    const lastEvent = course.appointments.at(-1)!;
    const studentWasAdded = new Promise<void>((resolve) => {
      const unsubscribe = onSnapshot(doc(firestore, `calendar_events/${lastEvent.id}`), (snapshot) => {
        const studentUids = snapshot.data()?.derived?.studentUids;
        if (Array.isArray(studentUids) && studentUids.includes(student.id)) {
          unsubscribe();
          resolve();
        }
      });
    });
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.addStudentToCourse({ studentUid: student.id, courseUid: course.id, agreedCancellationPolicy });
    await studentWasAdded;
    void queryClient.invalidateQueries();
  }

  async removeStudentFromCourse({ student, course }: { student: Student; course: Course }) {
    const watchedEvent = course.appointments.findLast((it) =>
      isTheoryLesson(it)
        ? it.students[student.id]?.rsvp === "accepted"
        : isASFCourseSession(it)
          ? it.participants[student.id]
          : false,
    );
    if (!watchedEvent) {
      throw new Error(`Could not find an event in course ${course.id} which is booked by ${student.id}`);
    }
    const studentWasRemoved = new Promise<void>((resolve) => {
      const unsubscribe = onSnapshot(doc(firestore, `calendar_events/${watchedEvent.id}`), (snapshot) => {
        const studentUids = snapshot.data()?.derived?.studentUids;
        if (Array.isArray(studentUids) && !studentUids.includes(student.id)) {
          unsubscribe();
          resolve();
        }
      });
    });
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.removeStudentFromCourse({ studentUid: student.id, courseUid: course.id });
    await studentWasRemoved;
    void queryClient.invalidateQueries();
  }

  async updateTheoryLessonAttendance({
    eventId,
    attendance,
  }: {
    eventId: string;
    attendance: Record<string, boolean>;
  }): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    const payload = schemas.UpdateAttendanceDto.parse({ attendance });

    const expectedStudents = Object.entries(attendance).reduce(
      (acc, [studentId, attended]) => ({
        ...acc,
        [studentId]: {
          attended,
          noShow: !attended,
        },
      }),
      {},
    );

    const updateReceived = new Promise<void>((resolve) => {
      const unsubscribe = onSnapshot(doc(firestore, `calendar_events/${eventId}`), (snapshot) => {
        const updatedTheoryLesson = theoryLessonConverter.fromFirestore(snapshot);
        if (updateApplied({ students: expectedStudents }, updatedTheoryLesson)) {
          unsubscribe();
          resolve();
        }
      });
    });
    await serverClient.updateAttendance(payload, { params: { eventId } });
    await updateReceived;
  }

  async createCalendarEvent(dto: CreateCalendarEventDto): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.createCalendarEvent(dto);
  }

  async getBranch({ id }: { id: string }): Promise<BranchDto> {
    const serverClient = await getAuthenticatedServerClient();
    return serverClient.getBranch({ params: { id } });
  }

  async getBranches({ drivingSchoolId }: { drivingSchoolId: string }): Promise<BranchDto[]> {
    const serverClient = await getAuthenticatedServerClient();
    return serverClient.listBranches({ queries: { drivingSchoolId } });
  }

  async createBranch({ payload }: { payload: CreateBranchDto }): Promise<BranchDto> {
    const serverClient = await getAuthenticatedServerClient();
    return serverClient.createBranch(payload);
  }

  async updateBranch({ id, payload }: { id: string; payload: UpdateBranchDto }): Promise<BranchDto> {
    const serverClient = await getAuthenticatedServerClient();
    return serverClient.updateBranch(payload, { params: { id } });
  }

  async createDrivingLicenseAuthorityForm({
    authorityId,
    dto,
  }: {
    authorityId: string;
    dto: CreateDrivingLicenseAuthorityFormDto;
  }): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.createDrivingLicenseAuthorityForm(dto, { params: { id: authorityId } });
  }

  async updateDrivingLicenseAuthorityForm({
    authorityId,
    formId,
    dto,
  }: {
    authorityId: string;
    formId: string;
    dto: UpdateDrivingLicenseAuthorityFormDto;
  }): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.updateDrivingLicenseAuthorityForm(dto, { params: { authorityId, formId } });
  }

  async createDrivingLicenseAuthorityInstruction({
    authorityId,
    dto,
  }: {
    authorityId: string;
    dto: CreateDrivingLicenseAuthorityInstructionDto;
  }): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.createDrivingLicenseAuthorityInstruction(dto, { params: { id: authorityId } });
  }

  async updateDrivingLicenseAuthorityInstruction({
    authorityId,
    instructionId,
    dto,
  }: {
    authorityId: string;
    instructionId: string;
    dto: UpdateDrivingLicenseAuthorityInstructionDto;
  }): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.updateDrivingLicenseAuthorityInstruction(dto, { params: { authorityId, instructionId } });
  }

  async assignBranchToDrivingLicenseAuthority({
    branchId,
    authorityId,
  }: {
    branchId: string;
    authorityId: string;
  }): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.assignBranchToDrivingLicenseAuthority(undefined, { params: { authorityId, branchId } });
  }

  async getEmployee(id: string): Promise<EmployeeDto> {
    const serverClient = await getAuthenticatedServerClient();
    return serverClient.getEmployee({ params: { id } });
  }

  async getDrivingSchoolEmployees(drivingSchoolId: string): Promise<Array<EmployeeDto>> {
    const serverClient = await getAuthenticatedServerClient();
    return serverClient.getDrivingSchoolEmployees({ params: { id: drivingSchoolId } });
  }

  async updateAuthEmail({ studentId, email }: { studentId: string; email: string }): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    const { data: student } = await studentsProvider.getOne("students", { id: studentId });
    const updateReceived = new Promise<void>((resolve) => {
      const unsubscribe = studentsProvider.onUpdate(student, (student) => {
        if (student.email === email) {
          unsubscribe();
          resolve();
        }
      });
    });
    await serverClient.updateAuthEmail({ email }, { params: { id: studentId } });
    await updateReceived;
  }

  async updateUserGrants(id: string, grants: Array<string>): Promise<void> {
    const serverClient = await getAuthenticatedServerClient();
    const updateDto = schemas.UpdateUserGrantsDto.parse({ grants });
    await serverClient.updateGrants({ grants: updateDto.grants }, { params: { id } });
  }
}

export type BranchDto = z.infer<typeof schemas.BranchDto>;
export type CreateBranchDto = z.infer<typeof schemas.CreateBranchDto>;
export type UpdateBranchDto = z.infer<typeof schemas.UpdateBranchDto>;
export type CreateCalendarEventDto = z.infer<typeof schemas.CreateCalendarEventDto>;

export const serverAPI = new ServerAPI();

export type UserDto = z.infer<typeof schemas.UserDto_v2> & { id: string };
export type CreateDrivingLicenseAuthorityFormDto = z.infer<typeof schemas.CreateDrivingLicenseAuthorityFormDto>;
export type UpdateDrivingLicenseAuthorityFormDto = z.infer<typeof schemas.UpdateDrivingLicenseAuthorityFormDto>;
export type CreateDrivingLicenseAuthorityInstructionDto = z.infer<
  typeof schemas.CreateDrivingLicenseAuthorityInstructionDto
>;
export type UpdateDrivingLicenseAuthorityInstructionDto = z.infer<
  typeof schemas.UpdateDrivingLicenseAuthorityInstructionDto
>;
export type StudentFilterDto = z.infer<typeof schemas.StudentFilterDto>;
export type StudentDto = z.infer<typeof schemas.StudentDto>;
export type StudentSearchResultDto = z.infer<typeof schemas.StudentSearchResultDto>;
export type InstructorSearchResultDto = z.infer<typeof schemas.InstructorSearchResultDto>;
export type DrivingSchoolSearchResultDto = z.infer<typeof schemas.DrivingSchoolSearchResultDto>;
export type GuardianSearchResultDto = z.infer<typeof schemas.GuardianSearchResultDto>;
export const CreateInstructorDtoSchema = schemas.CreateInstructorDto;
export type CreateInstructorDto = z.infer<typeof CreateInstructorDtoSchema>;
export type EmployeeDto = z.infer<typeof schemas.EmployeeDto>;
