import { collection, getDocs, query, where } from "firebase/firestore";
import { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { Notification, NotificationSchema } from "../model/Notification";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { orderBy } from "@firebase/firestore";
import { DateTime } from "luxon";
import { AbstractDataProvider } from "./AbstractDataProvider";

export type StickyNote = { id: string; createdAt: DateTime } & Defined<Notification["channels"], "sticky">["sticky"];

class StudentStickyNotesProvider extends AbstractDataProvider<StickyNote> {
  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<StickyNote>> {
    if (target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    const snapshot = await getDocs(
      query(
        collection(firestore, `/users/${id}/notifications`),
        where("channels.sticky.type", "==", "sticky"),
        orderBy("createdAt", "desc"),
      ),
    );
    const stickyNotes = snapshot.docs.map((doc) => {
      const notification = NotificationSchema.parse(doc.data());
      const stickyNote: StickyNote = {
        id: doc.id,
        createdAt: notification.createdAt,
        ...notification.channels.sticky!,
      };
      return stickyNote;
    });
    console.info(`Retrieved ${stickyNotes.length} sticky message(s) for student ${id}`);
    return applyPagination(applySort(applyFilter(stickyNotes, filter), sort), pagination);
  }
}

export const studentStickyNotesProvider = new StudentStickyNotesProvider();
