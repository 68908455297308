import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  onSnapshot,
  query,
  Query,
  QueryDocumentSnapshot,
  setDoc,
  where,
} from "firebase/firestore";
import { RaRecord, UpdateParams, UpdateResult, type CreateParams, type CreateResult } from "react-admin";
import {
  CreateInstructorDtoSchema,
  getAuthenticatedServerClient,
  baseUrl as serverBaseUrl,
  serverAPI,
} from "../api/server.api";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { firestore } from "../firebase";
import type { DrivingLicenseClass } from "../model/DrivingLicenseClass.js";
import { FeatureTogglesForInstructors } from "../model/FeatureToggles";
import { PostalAddress } from "../model/PostalAddress";
import { FirestoreProvider } from "./FirestoreProvider";
import { DateTime } from "luxon";

export interface Instructor {
  id: string;
  autovioUserId: string;
  firstName: string;
  lastName: string;
  name: string;
  avatarUrl?: string;
  avatarOverrideUrl?: string;
  hubspotContactId?: string;
  applicationFeePercentage?: number;
  postalAddress?: Partial<PostalAddress>;
  drivingSchoolId: string;
  isDrivingSchoolManager: boolean;
  instructing: {
    drivingLicenseClasses: Array<DrivingLicenseClass>;
  };
  studentIds: Array<string>;
  featureToggles: FeatureTogglesForInstructors;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  roles: Array<"instructor" | "drivingSchoolManager" | string>;
  grants: {
    [key: string]: boolean;
  };
  _sortOrder: string;
}

export function isInstructor(record: RaRecord): record is Instructor {
  return Array.isArray(record.instructing?.drivingLicenseClasses);
}

interface InstructorDocumentData {
  autovioUserId: string;
  hubspotContactId?: string;
  applicationFeePercentage?: number;
  publicProfile: {
    firstName?: string;
    lastName?: string;
    avatarOverrideUrl?: string;
  };
  postalAddress?: Partial<PostalAddress>;
  drivingSchoolUid: string;
  instructing?: {
    drivingLicenseClasses?: Array<DrivingLicenseClass>;
  };
  myStudents: Array<string>;
  roles?: Array<string>;
  featureToggles?: FeatureTogglesForInstructors;
  grants?: Array<string>;
}

class InstructorProvider extends FirestoreProvider<Instructor> {
  private instructorsByAutovioUserId: Map<string, Instructor> = new Map();
  querySingle(id: string): [DocumentReference | Query, (record: Instructor) => void] {
    return [
      doc(firestore, `/users/${id}`),
      (instructor) => {
        if (
          restrictAccessToDrivingSchoolIds &&
          !restrictAccessToDrivingSchoolIds.includes(instructor.drivingSchoolId)
        ) {
          throw new Error(`Access to instructor ${id} denied`);
        }
      },
    ];
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult<Instructor>> {
    const serverClient = await getAuthenticatedServerClient();
    const createData = params.data;
    const data = CreateInstructorDtoSchema.parse({
      ...createData,
      startDate: createData.startDate ? DateTime.fromISO(createData.startDate).toISO() : null,
      endDate: createData.endDate ? DateTime.fromISO(createData.endDate).toISO() : null,
    });
    const instructor = await serverClient.createInstructor(data);
    return new Promise<CreateResult<Instructor>>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        doc(firestore, `users/${instructor.id}`),
        async (snapshot) => {
          const updatedInstructorDoc = snapshot.data();
          if (
            updatedInstructorDoc?.publicProfile.firstName === instructor.firstName &&
            updatedInstructorDoc?.instructing !== undefined
          ) {
            unsubscribe();
            try {
              const fetchedInstructor = await instructorsProvider.fetchOne(instructor.id);
              if (fetchedInstructor === undefined) {
                throw new Error(`Failed to fetch instructor ${instructor.id} after creation`);
              }
              resolve({ data: fetchedInstructor });
            } catch (error) {
              reject(error);
            }
          }
        },
        reject,
      );
    });
  }

  queryAll(): Query {
    return query(
      collection(firestore, `/${this.collection}`),
      restrictAccessToDrivingSchoolIds?.length === 1
        ? where("drivingSchoolUid", "==", restrictAccessToDrivingSchoolIds[0])
        : restrictAccessToDrivingSchoolIds
          ? where("drivingSchoolUid", "in", restrictAccessToDrivingSchoolIds)
          : where("drivingSchoolUid", "!=", ""),
    );
  }

  async fromFirestore(snapshot: QueryDocumentSnapshot<InstructorDocumentData>): Promise<Instructor> {
    const data = snapshot.data();
    const firstName = data.publicProfile.firstName ?? "???";
    const lastName = data.publicProfile.lastName ?? "???";
    const grants: { [key: string]: boolean } = {
      // defaults ...
      changePrice: (data.roles ?? []).includes("drivingSchoolManager"),
    };
    for (const grant of data.grants ?? []) {
      if (grant.startsWith("!")) {
        grants[grant.substring(1)] = false;
      } else {
        grants[grant] = true;
      }
    }
    const { id } = snapshot;
    const name = `${firstName} ${lastName}`;
    const isDrivingSchoolManager = data.roles?.includes("drivingSchoolManager") ?? false;
    const instructor: Instructor = {
      id,
      autovioUserId: data.autovioUserId,
      hubspotContactId: data.hubspotContactId,
      firstName,
      lastName,
      name,
      avatarUrl: `${serverBaseUrl}/user/${id}/avatar`,
      avatarOverrideUrl: data.publicProfile.avatarOverrideUrl,
      drivingSchoolId: data.drivingSchoolUid,
      instructing: {
        drivingLicenseClasses: data.instructing?.drivingLicenseClasses ?? [],
      },
      isDrivingSchoolManager,
      studentIds: data.myStudents ?? [],
      postalAddress: data.postalAddress,
      featureToggles: {
        ..._defaultFeatureToggles,
        ...(data.featureToggles ?? {}),
      },
      grants,
      roles: data.roles ?? [],
      // Sort all driving school managers before normal instructors ...
      _sortOrder: `${isDrivingSchoolManager ? 1 : 2} ${name}`,
    };
    this.instructorsByAutovioUserId.set(instructor.autovioUserId, instructor);
    return instructor;
  }

  getOneFromCache(id: string): Instructor | undefined {
    return this.instructorsByAutovioUserId.get(id) ?? super.getOneFromCache(id);
  }

  async getByDrivingSchoolId(drivingSchoolId: string): Promise<Array<Instructor>> {
    const { records } = await this.snapshot();
    return records.filter((it) => it.drivingSchoolId === drivingSchoolId);
  }

  async update(resource: string, update: UpdateParams<Instructor>): Promise<UpdateResult<Instructor>> {
    const updatePayload = this.toFirestore(update.data);
    if (!Object.keys(updatePayload).length) {
      throw new Error("InstructorProvider.toFirestore(...) returned empty object");
    }
    const { grants, ...firestorePayload } = updatePayload;
    console.info(`Updating users/${update.id} ...`, updatePayload);
    if (grants) {
      await serverAPI.updateUserGrants(update.id, grants);
    }
    await setDoc(doc(firestore, `users/${update.id}`), firestorePayload, { merge: true });
    return super.getOne(resource, { id: update.id });
  }

  toFirestore(updateData: Partial<Instructor>): DocumentData {
    let grants: undefined | Array<string>;
    if (updateData.grants) {
      grants = Object.entries(updateData.grants).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, [] as Array<string>);
    }
    return {
      ...(Object.keys(updateData.featureToggles ?? {}).length > 0 ? { featureToggles: updateData.featureToggles } : {}),
      ...(grants ? { grants } : {}),
    };
  }
}

const _defaultFeatureToggles: FeatureTogglesForInstructors = {
  lab: false,
};

export const instructorsProvider = new InstructorProvider("Instructor", "users");
