import { Menu, MenuItemLink, useGetList, useSidebarState } from "react-admin";
import EventIcon from "@mui/icons-material/Event";
import EuroIcon from "@mui/icons-material/Euro";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import PlaygroundIcon from "@mui/icons-material/SportsEsports";
import { Box, Link, SxProps, Theme, Typography } from "@mui/material";
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { autovioColors } from "./backofficeTheme";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { InstructorIcon } from "../icons/InstructorIcon";
import { StudentsIcon } from "../icons/StudentsIcon";
import { ExamIcon } from "../icons/ExamIcon";
import { ReportIcon } from "../icons/ReportIcon";
import { DrivingSchoolIcon } from "../icons/DrivingSchoolIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { grants } from "../backoffice.access_control";
import { useThemeColor } from "../hooks/useThemeColor";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import CollapseIcon from "@mui/icons-material/ExpandLess";
import { Row } from "./Row";
import { type Student } from "../providers/studentsProvider";
import { type Employee } from "../providers/employeesProvider";

const { grey, white } = autovioColors;

type SecondLevelMenuItem = [
  /* name: */ string,
  /* path: */ string,
  /* isActive: */ boolean,
  /* badge: */ { element: JSX.Element; showInTopLevel: boolean }?,
];

export function BackofficeSideBar() {
  useEffect(() => {
    setSidebarOpen(true); // <-- Hack to prevent that tooltips are rendered in <MenuItemLink> elements
  }, []);
  const [{ drivingSchoolId }] = useAutovioContext();
  const { total: studentsWithoutInstructorsCount = 0 } = useGetList<Student>(
    "students",
    { filter: { drivingSchoolId, instructorIds: (val: Array<string>) => val.length === 0 } },
    { enabled: !!drivingSchoolId },
  );
  const { data: customerServiceEmployees = [], isLoading: loadingCustomerServiceEmployees } = useGetList<Employee>(
    "employees",
    {
      filter: {
        drivingSchoolId,
        roles: (it: Array<Role>) => it.includes("drivingSchoolCustomerService") && !it.includes("instructor"),
      },
      pagination: { page: 1, perPage: 9999 },
      sort: { field: "_sortOrder", order: "ASC" },
    },
    { enabled: !!drivingSchoolId && grants.includes("Employee:list") },
  );
  const { pathname } = useLocation();
  const [_, setSidebarOpen] = useSidebarState();

  const isDashboard = pathname === "/" || /^\/drivingSchools\/([^/]+)$/.test(pathname);
  const isInstructors = pathname.includes("/instructors");
  const isStudentsWithoutInstructors = pathname.includes("/studentsWithoutInstructors");
  const isAllStudents = pathname.includes("/students") && !(isInstructors || isStudentsWithoutInstructors);
  const isStudents = isStudentsWithoutInstructors || isAllStudents;
  const isCustomerService = pathname.includes("/customerService") || pathname.startsWith("/employees/");
  const isCalendar = pathname.includes("/calendar") && !isInstructors;
  const isReports = pathname.includes("/bi");
  const isAdmin = pathname.includes("/admin") && !(isStudents || isInstructors);

  const staffItems = useMemo<Array<SecondLevelMenuItem>>(
    () => [
      ["Fahrlehrer", `/drivingSchools/${drivingSchoolId}/instructors`, isInstructors],
      ["Support", `/drivingSchools/${drivingSchoolId}/customerService`, isCustomerService],
    ],
    [drivingSchoolId, isInstructors, isCustomerService],
  );

  const studentItems = useMemo<Array<SecondLevelMenuItem>>(
    () => [
      ["Alle Fahrschüler", `/drivingSchools/${drivingSchoolId}/students`, isAllStudents],
      [
        "Warten auf Zuweisung",
        `/drivingSchools/${drivingSchoolId}/studentsWithoutInstructors`,
        isStudentsWithoutInstructors,
        studentsWithoutInstructorsCount > 0
          ? {
              element: (
                <MenuItemBadge>
                  {<Typography sx={{ fontSize: "10px" }}>{studentsWithoutInstructorsCount}</Typography>}
                </MenuItemBadge>
              ),
              showInTopLevel: true,
            }
          : undefined,
      ],
    ],
    [drivingSchoolId, isAllStudents, isStudentsWithoutInstructors, studentsWithoutInstructorsCount],
  );

  const isExamAssignment = pathname.includes("/examinationAssignments");
  const isTheoryExams = pathname.includes("/theoryExams");
  const isPracticalExams = pathname.includes("/practicalExams");
  const examItems = useMemo<Array<SecondLevelMenuItem>>(
    () => [
      ["Prüfaufträge", `/drivingSchools/${drivingSchoolId}/examinationAssignments`, isExamAssignment],
      ["Theorieprüfungen", `/drivingSchools/${drivingSchoolId}/theoryExams`, isTheoryExams],
      ["Praktische Prüfungen", `/drivingSchools/${drivingSchoolId}/practicalExams`, isPracticalExams],
    ],
    [drivingSchoolId, isExamAssignment, isTheoryExams, isPracticalExams],
  );

  const isPerformanceOverview = pathname.includes("/performanceOverview");
  const isAdvancePayments = pathname.includes("/advancePayments");
  const isOpenInvoices = pathname.includes("/openInvoices");
  const isPayouts = pathname.includes("/payouts");
  const isTax = pathname.includes("/tax");
  const financeItems = useMemo<Array<SecondLevelMenuItem>>(() => {
    let result: Array<SecondLevelMenuItem> = [];
    if (grants.includes("viewPerformanceOverview")) {
      result = result.concat([
        ["Leistungsübersicht", `/drivingSchools/${drivingSchoolId}/performanceOverview`, isPerformanceOverview],
        ["Vorauszahlungen", `/drivingSchools/${drivingSchoolId}/advancePayments`, isAdvancePayments],
        ["Offene Posten", `/drivingSchools/${drivingSchoolId}/openInvoices`, isOpenInvoices],
      ]);
    }
    if (grants.includes("viewPayoutInformation")) {
      result = result.concat([
        ["Auszahlungen", `/drivingSchools/${drivingSchoolId}/payouts`, isPayouts],
        ["Steuerbüro-Exports", `/drivingSchools/${drivingSchoolId}/tax`, isTax],
      ]);
    }
    return result;
  }, [drivingSchoolId, isPerformanceOverview, isAdvancePayments, isOpenInvoices, isPayouts, isTax]);

  const isBranches = pathname.includes("/branches");
  const isFleet =
    pathname.includes("/fleet") ||
    (pathname.includes("/vehicles") && !isInstructors) ||
    ["/cars/", "/motorcycles", "/trailers", "/simulators"].some((it) => pathname.startsWith(it));
  const isCourses = pathname.includes("/courses");
  const drivingSchoolItems = useMemo<Array<SecondLevelMenuItem>>(() => {
    const result: Array<SecondLevelMenuItem> = [];
    if (grants.includes("Branch:list")) {
      result.push(["Filialen", `/drivingSchools/${drivingSchoolId}/branches`, isBranches]);
    }
    result.push(["Fuhrpark", `/drivingSchools/${drivingSchoolId}/fleet`, isFleet]);
    if (grants.includes("Course:list")) {
      result.push(["Kurse", `/drivingSchools/${drivingSchoolId}/courses`, isCourses]);
    }
    return result;
  }, [drivingSchoolId, isBranches, isFleet, isCourses]);

  const isFieldsPlayground = pathname === "/playground/fields";
  const isInputsPlayground = pathname === "/playground/inputs";
  const playgroundItems: Array<SecondLevelMenuItem> = [
    ["Fields", "/playground/fields", isFieldsPlayground],
    ["Inputs", "/playground/inputs", isInputsPlayground],
  ];

  if (!drivingSchoolId || loadingCustomerServiceEmployees) {
    return <div id="sidebar" style={{ width: 280, background: "#f8f8f8", paddingRight: 20 }} />;
  }

  return (
    <div id="sidebar" style={{ width: 280, background: "#f8f8f8", paddingRight: 20 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TopLevelSideBarLink icon={<HomeIcon />} label="Dashboard" to="/" isActive={isDashboard} />

        <TopLevelSideBarLink
          icon={<EventIcon />}
          label="Kalender"
          to={`/drivingSchools/${drivingSchoolId}/calendar`}
          isActive={isCalendar}
        />

        {customerServiceEmployees.length > 0 ? (
          <TopLevelSideBarMenu icon={<InstructorIcon />} label="Personal" menuItems={staffItems} />
        ) : (
          <TopLevelSideBarLink
            icon={<InstructorIcon />}
            label="Fahrlehrer"
            to={`drivingSchools/${drivingSchoolId}/instructors`}
            isActive={isInstructors}
          />
        )}

        <TopLevelSideBarMenu icon={<StudentsIcon />} label="Fahrschüler" menuItems={studentItems} />

        <TopLevelSideBarMenu icon={<ExamIcon />} label="Prüfungen" menuItems={examItems} />

        {financeItems.length > 0 && (
          <TopLevelSideBarMenu icon={<EuroIcon />} label="Finanzen" menuItems={financeItems} />
        )}

        {(grants.includes("viewAutovioBusinessIntelligenceData") ||
          grants.includes("viewDrivingSchoolBusinessIntelligenceData")) && (
          <TopLevelSideBarLink
            icon={<ReportIcon />}
            label="Reports"
            to={`/drivingSchools/${drivingSchoolId}/bi`}
            isActive={isReports}
          />
        )}

        {drivingSchoolItems.length > 0 && (
          <TopLevelSideBarMenu icon={<DrivingSchoolIcon />} label="Fahrschule" menuItems={drivingSchoolItems} />
        )}

        {grants.includes("admin") && (
          <TopLevelSideBarLink
            icon={<AdminIcon />}
            label="Admin"
            to={`/drivingSchools/${drivingSchoolId}/admin`}
            isActive={isAdmin}
          />
        )}

        {location.hostname !== "backoffice.autovio.de" && !location.search.includes("visualTest") && (
          <TopLevelSideBarMenu icon={<PlaygroundIcon />} label="Playground" menuItems={playgroundItems} />
        )}
      </Box>
    </div>
  );
}

type TopLevelSideBarLinkProps = {
  sx?: SxProps<Theme>;
  icon: JSX.Element;
  label: string;
  trailing?: JSX.Element;
  isActive?: boolean;
  isHovered?: boolean;
} & (
  | {
      to: string;
      onClick?: never;
    }
  | {
      to?: never;
      onClick: MouseEventHandler<HTMLElement>;
    }
);

function TopLevelSideBarLink(props: TopLevelSideBarLinkProps) {
  const navigate = useNavigate();
  const onClick = props.onClick ?? (() => navigate(props.to));
  const primaryColor = useThemeColor("primary");
  return (
    <Link
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
        color: props.isActive ? primaryColor.contrastText : grey,
      }}
      sx={
        [
          {
            "&": {
              p: "10px 20px",
              gap: 1,
              borderRadius: 8,
              fontWeight: 500, // <-- Poppins Medium
              fontSize: "14px",
              ...(props.isActive
                ? { color: primaryColor.contrastText, backgroundColor: primaryColor.main }
                : props.isHovered
                  ? { color: white, backgroundColor: "rgba(255, 255, 255, 0.1)" }
                  : { color: grey }),
              ...(props.sx ?? {}),
            },
          },
          {
            "&:hover": { textDecoration: "none" },
          },
          {
            "&:hover": props.isActive ? {} : { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          },
        ] as any
      }
      onClick={onClick}
    >
      <Row sx={{ alignItems: "center" }}>
        <div style={{ marginRight: "14px", height: "24px" }}>{props.icon}</div>
        <div>{props.label}</div>
      </Row>
      {props.trailing ?? <div />}
    </Link>
  );
}

function TopLevelSideBarMenu(props: { icon: JSX.Element; label: string; menuItems: Array<SecondLevelMenuItem> }) {
  const isActive = props.menuItems.some((it) => it[2]);
  const [open, setOpen] = useState(isActive);
  const badge = open ? null : (props.menuItems.find((it) => it[3]?.showInTopLevel)?.[3]?.element ?? null);
  const navigate = useNavigate();
  const openMenu = useCallback(() => {
    setOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    setOpen(false);
  }, []);
  const toggleMenu: MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (open) {
        closeMenu();
      } else {
        openMenu();
      }
    },
    [open],
  );
  const onClick: MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      if (isActive) {
        toggleMenu(event);
      } else {
        if (!open) {
          openMenu();
        }
        navigate(props.menuItems[0][1]);
      }
    },
    [isActive, open, props.menuItems[0][1]],
  );
  const primaryColor = useThemeColor("primary");
  const expandCollapseIconSx = {
    borderRadius: "12px",
    transition: "none",
    "&:hover": { backgroundColor: "#f8f8f8", fill: primaryColor.main },
  };

  return (
    <>
      <TopLevelSideBarLink
        icon={props.icon}
        label={props.label}
        trailing={
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            {badge}
            <Box sx={{ height: "24px", ml: "auto" }} onClick={toggleMenu}>
              {open && <CollapseIcon sx={expandCollapseIconSx} />}
              {!open && <ExpandIcon sx={expandCollapseIconSx} />}
            </Box>
          </Box>
        }
        isActive={isActive}
        onClick={onClick}
        isHovered={open}
      />
      {open ? (
        <Menu
          open={open}
          onClose={closeMenu}
          style={{ marginTop: -10 }}
          sx={{
            "& .MuiMenu-paper": { borderRadius: "4px" },
            "&.RaMenu-open": {
              width: "260px",
            },
          }}
        >
          {props.menuItems.map(([name, to, isActive, badge]) => (
            <MenuItemLink
              key={name}
              className={isActive ? "RaMenuItemLink-active" : undefined}
              sx={{
                display: "flex",
                gap: 1,
                ml: "40px",
                p: "10px 10px 10px 20px",
                borderRadius: 8,
                color: grey,
                fontSize: "14px",
                fontWeight: 500,
                "&.RaMenuItemLink-active": {
                  backgroundColor: primaryColor.ultraLight,
                  color: primaryColor.contrastTextUltraLight,
                },
              }}
              to={to}
            >
              <Typography variant="body2" fontWeight={500}>
                {name}
              </Typography>
              {badge && badge.element}
            </MenuItemLink>
          ))}
        </Menu>
      ) : null}
    </>
  );
}

const MenuItemBadge = ({ children }: { children: JSX.Element }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      p: "4px",
      maxHeight: "16px",
      minWidth: "16px",
      borderRadius: "50%",
      backgroundColor: autovioColors.red,
      color: autovioColors.white,
    }}
  >
    {children}
  </Box>
);
