import { StudentTab } from "./StudentTab";
import { Datagrid, FunctionField, Identifier, Pagination, useGetRecordId } from "react-admin";
import Box from "@mui/material/Box";
import { DateField } from "../../fields/DateField";
import { LinkToFirestoreField } from "../../fields/LinkToFirestoreField";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { AppointmentEmptyState } from "../../misc/AppointmentEmptyState";
import { AutovioCalendarEvent, TheoryExam, TheoryLesson } from "../../model/autovioCalendarEvents";
import { grants } from "../../backoffice.access_control";
import { useState } from "react";
import { EventDialog } from "../../misc/calendar/EventDialog";
import { InstructorField } from "../../fields/InstructorField";
import { renderLessonStatus } from "../../utils/calendar";

export function StudentTheoryLessonsList() {
  const studentId = useGetRecordId();
  const [selectedEvent, setSelectedEvent] = useState<AutovioCalendarEvent | undefined>(undefined);
  return (
    <StudentTab>
      <ReferenceManyField
        label=""
        pagination={<Pagination />}
        reference="theoryLessons"
        target="studentUids"
        sort={{ field: "start", order: "DESC" }}
      >
        <Datagrid
          bulkActionButtons={false}
          empty={
            <Box sx={{ pt: 30 }}>
              <AppointmentEmptyState label="Noch keine Theorie-Lektionen gebucht" />
            </Box>
          }
          rowClick={(_, __, theoryLessonOrTheoryExam) => {
            setSelectedEvent(theoryLessonOrTheoryExam as AutovioCalendarEvent);
            return false;
          }}
        >
          <DateField label="Datum" source="start" showTime />
          <FunctionField label="Art" render={_renderType} />
          <InstructorField label="Fahrlehrer" source="instructorId" />
          <FunctionField label="Status" render={(record: TheoryLesson) => _renderLessonStatus(studentId, record)} />
          {grants.includes("viewFirestore") && (
            <LinkToFirestoreField label="" source="id" collection="/calendar_events" />
          )}
        </Datagrid>
        {selectedEvent && <EventDialog event={selectedEvent} isOpen onClose={() => setSelectedEvent(undefined)} />}
      </ReferenceManyField>
    </StudentTab>
  );
}

function _renderType(record?: TheoryLesson | TheoryExam): string {
  if (record?.type === "TheoryLesson") {
    return `Lektion ${record.theoryUnit}`;
  } else if (record?.type === "TheoryExam") {
    return "Theorieprüfung";
  }
  return "";
}

function _renderLessonStatus(studentId: Identifier, record: TheoryLesson | TheoryExam): string {
  if (record.type === "TheoryLesson") {
    if (record.students[studentId].attended) {
      return "✔️ Teilgenommen";
    } else if (record.students[studentId].noShow) {
      return "❌ No-Show";
    } else if (record.students[studentId].rsvp === "rejected") {
      return "❌ Abgesagt";
    } else if (record.students[studentId].rsvp === "accepted") {
      return "⏳️ Gebucht";
    } else {
      console.error("Could not render status of theory lesson", { theoryLessonId: record.id, studentId });
      return "? Unbekannt";
    }
  } else {
    return renderLessonStatus(record);
  }
}
