import { IconButton } from "@mui/material";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { RaRecord, useCreatePath, useRecordContext, useResourceContext } from "react-admin";
import { Link } from "react-router-dom";

export function ShowIconButton({ resourceGetter }: { resourceGetter?: (record: RaRecord) => string }) {
  const record = useRecordContext();
  const resource = resourceGetter?.(record) ?? useResourceContext();
  const createPath = useCreatePath();

  if (!record) {
    return null;
  }

  return (
    <IconButton component={Link} to={createPath({ type: "edit", resource, id: record.id })}>
      <EyeIcon />
    </IconButton>
  );
}
