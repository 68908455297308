import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  FilterPayload,
  GetListParams,
  GetListResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import cloneDeep from "lodash/cloneDeep";
import { applyFilter, applyPagination, applySort, areSetsEqual } from "../backoffice.utils";
import { DateTime } from "luxon";
import { getAuthenticatedResourceClient, type ResourceDto, type ResourceType, schemas } from "../api/resource.api";
import { WeeklyHours } from "../model/WeeklyHours";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { ZodSchema } from "zod";

type CarDto = Omit<ResourceDto, "type" | "car" | "motorcycle" | "trailer" | "simulator"> & {
  type: "car";
  car: Defined<ResourceDto, "car">["car"];
  motorcycle: never;
  trailer: never;
  simulator: never;
};

export type Car = RaRecord<string> &
  Omit<CarDto, "id" | "deletedAt"> & {
    car: Defined<CarDto["car"], "advancedDriverAssistanceSystems"> & {
      advancedDriverAssistanceSystemsIds: Array<string>;
    };
    deletedAt?: DateTime;
  };

type MotorcycleDto = Omit<ResourceDto, "type" | "car" | "motorcycle" | "trailer" | "simulator"> & {
  type: "motorcycle";
  car: never;
  motorcycle: Defined<ResourceDto, "motorcycle">["motorcycle"];
  trailer: never;
  simulator: never;
};

export type Motorcycle = RaRecord<string> &
  Omit<MotorcycleDto, "id" | "deletedAt"> & {
    motorcycle: Defined<MotorcycleDto["motorcycle"], "advancedDriverAssistanceSystems"> & {
      advancedDriverAssistanceSystemsIds: Array<string>;
    };
    deletedAt?: DateTime;
  };

type TrailerDto = Omit<ResourceDto, "type" | "car" | "motorcycle" | "trailer" | "simulator"> & {
  type: "trailer";
  car: never;
  motorcycle: never;
  trailer: Defined<ResourceDto, "trailer">["trailer"];
  simulator: never;
};

export type Trailer = RaRecord<string> &
  Omit<TrailerDto, "id" | "deletedAt"> & {
    deletedAt?: DateTime;
  };

type SimulatorDto = Omit<ResourceDto, "type" | "car" | "motorcycle" | "trailer" | "simulator"> & {
  type: "simulator";
  car: never;
  motorcycle: never;
  trailer: never;
  simulator: Defined<ResourceDto, "simulator">["simulator"];
};

export type Simulator = RaRecord<string> &
  Omit<SimulatorDto, "id" | "deletedAt"> & {
    simulator: Omit<SimulatorDto["simulator"], "availabilities"> & {
      availabilities: WeeklyHours;
    };
    deletedAt?: DateTime;
  };

export type Vehicle = Car | Motorcycle | Trailer | Simulator;

class ResourceProvider extends AbstractDataProvider<Vehicle> {
  constructor(private resourceType: "vehicle" | ResourceType) {
    super();
  }

  async getOne(_resource: string, { id }: GetOneParams): Promise<GetOneResult<Vehicle>> {
    const resourceClient = await getAuthenticatedResourceClient();
    const dto = await resourceClient.findOneResource({ params: { id } });
    const record = _dtoToRecord(dto);
    if (this.resourceType !== "vehicle" && !record[this.resourceType]) {
      throw new Error(`Resource ${id} is of type ${record.type}, not of type ${this.resourceType}`);
    }
    return { data: record };
  }

  async getMany(_resource: string, { ids }: { ids: Array<string> }): Promise<GetManyResult<Vehicle>> {
    const resourceClient = await getAuthenticatedResourceClient();
    const records = await Promise.all(
      ids.map(async (id) => {
        const dto = await resourceClient.findOneResource({ params: { id } });
        const record = _dtoToRecord(dto);
        if (this.resourceType !== "vehicle" && !record[this.resourceType]) {
          throw new Error(`Resource ${id} is of type ${record.type}, not of type ${this.resourceType}`);
        }
        return record;
      }),
    );
    return { data: records };
  }

  async getList(_resource: string, { pagination, sort, filter }: GetListParams): Promise<GetListResult<Vehicle>> {
    const { entitledDrivingSchoolId, entitledUserId, withDeleted, ...clientFilter } = filter;
    const serverFilter: FilterPayload = { withDeleted: !!withDeleted };
    if (!(entitledDrivingSchoolId || entitledUserId)) {
      throw new Error(`Invalid filter -- expected: {entitledDrivingSchoolId: ...} or {entitledUserId: ...}`);
    }
    if (entitledDrivingSchoolId) {
      serverFilter.entitledDrivingSchoolId = entitledDrivingSchoolId;
    }
    if (entitledUserId) {
      serverFilter.entitledUserId = entitledUserId;
    }
    if (this.resourceType !== "vehicle") {
      serverFilter.type = this.resourceType;
    }
    const resourceClient = await getAuthenticatedResourceClient();
    // Note: The REST API of the resource microservice does not support pagination nor sorting
    const dtos = await resourceClient.findAllResources({ queries: { filter: JSON.stringify(serverFilter) } });
    const records = dtos.map(_dtoToRecord);
    return applyPagination(applySort(applyFilter(records, clientFilter), sort), pagination);
  }

  async getManyReference(
    _resource: string,
    { target, id, pagination, sort, filter }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Vehicle>> {
    const { withDeleted, ...clientFilter } = filter;
    const serverFilter: FilterPayload = { withDeleted: !!withDeleted };
    if (target === "entitledDrivingSchoolId") {
      serverFilter.entitledDrivingSchoolId = id;
    } else if (target === "entitledUserId") {
      serverFilter.entitledUserId = id;
    } else {
      throw new Error(`Invalid target: ${target} -- expected: entitledDrivingSchoolId or entitledUserId`);
    }
    if (this.resourceType !== "vehicle") {
      serverFilter.type = this.resourceType;
    }
    const resourceClient = await getAuthenticatedResourceClient();
    // Note: The REST API of the resource microservice does not support pagination nor sorting
    const dtos = await resourceClient.findAllResources({ queries: { filter: JSON.stringify(serverFilter) } });
    const records = dtos.map(_dtoToRecord);
    return applyPagination(applySort(applyFilter(records, clientFilter), sort), pagination);
  }

  async create(_resource: string, { data }: CreateParams): Promise<CreateResult<Vehicle>> {
    const createDto = _dataToDto(data, schemas.CreateResourceDto);
    const resourceClient = await getAuthenticatedResourceClient();
    const dto = await resourceClient.createResource(createDto);
    return { data: _dtoToRecord(dto) };
  }

  async update(_resource: string, { id, data, previousData }: UpdateParams): Promise<UpdateResult<Vehicle>> {
    const updateDto = _dataToDto(data, schemas.UpdateResourceDto);
    if (areSetsEqual(new Set(updateDto.entitledDrivingSchools), new Set(previousData.entitledDrivingSchools))) {
      delete updateDto.entitledDrivingSchools;
    }
    const resourceClient = await getAuthenticatedResourceClient();
    const dto = await resourceClient.updateResource(updateDto, { params: { id } });
    return { data: _dtoToRecord(dto) };
  }

  async delete(resource: string, { id }: DeleteParams): Promise<DeleteResult<Vehicle>> {
    const { data: record } = await this.getOne(resource, { id });
    const resourceClient = await getAuthenticatedResourceClient();
    await resourceClient.removeResource(undefined, { params: { id } });
    return { data: record };
  }

  async restore(id: string): Promise<void> {
    const resourceClient = await getAuthenticatedResourceClient();
    await resourceClient.restore(undefined, { params: { id } });
  }
}

function _dtoToRecord(dto: ResourceDto): Vehicle {
  const record = cloneDeep(dto) as Vehicle;
  if (dto.type === "car") {
    const a = dto.car!.advancedDriverAssistanceSystems;
    if (a) {
      (record as Car).car.advancedDriverAssistanceSystemsIds = a.map((it) => it.id);
    } else {
      (record as Car).car.advancedDriverAssistanceSystems = [];
      (record as Car).car.advancedDriverAssistanceSystemsIds = [];
    }
  } else if (dto.type === "motorcycle") {
    const a = dto.motorcycle!.advancedDriverAssistanceSystems;
    if (a) {
      (record as Motorcycle).motorcycle.advancedDriverAssistanceSystemsIds = a.map((it) => it.id);
    } else {
      (record as Motorcycle).motorcycle.advancedDriverAssistanceSystems = [];
      (record as Motorcycle).motorcycle.advancedDriverAssistanceSystemsIds = [];
    }
  }
  if (!dto.entitledDrivingSchools) {
    record.entitledDrivingSchools = [];
  }
  if (!dto.entitledUsers) {
    dto.entitledUsers = [];
  }
  if (dto.deletedAt) {
    record.deletedAt = DateTime.fromISO(dto.deletedAt);
  }
  return record;
}

function _dataToDto<T>(data: any, schema: ZodSchema<T>): T {
  const dto = cloneDeep(data);
  if (!dto.description) {
    dto.description = "";
  }
  if (data.type === "car") {
    const adasIds = (data as Car).car.advancedDriverAssistanceSystemsIds;
    delete dto.car.advancedDriverAssistanceSystemsIds;
    if (adasIds && adasIds.length > 0) {
      dto.car.advancedDriverAssistanceSystems = adasIds.map((id) => ({ id }));
    } else {
      delete dto.car.advancedDriverAssistanceSystems;
    }
  } else if (data.type === "motorcycle") {
    const adasIds = (data as Motorcycle).motorcycle.advancedDriverAssistanceSystemsIds;
    delete dto.motorcycle.advancedDriverAssistanceSystemsIds;
    if (adasIds && adasIds.length > 0) {
      dto.motorcycle.advancedDriverAssistanceSystems = adasIds.map((id) => ({ id }));
    } else {
      delete dto.motorcycle.advancedDriverAssistanceSystems;
    }
  }
  return schema.parse(dto);
}

export const vehiclesProvider = new ResourceProvider("vehicle");
export const carsProvider = new ResourceProvider("car");
export const motorcyclesProvider = new ResourceProvider("motorcycle");
export const trailersProvider = new ResourceProvider("trailer");
export const simulatorsProvider = new ResourceProvider("simulator");
