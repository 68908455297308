import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { type AdasDto, getAuthenticatedResourceClient, schemas } from "../api/resource.api";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import _isEqual from "lodash/isEqual";

export type Adas = AdasDto;

class AdasProvider extends AbstractDataProvider<Adas> {
  async getOne(_resource: string, { id }: GetOneParams<Adas>): Promise<GetOneResult<Adas>> {
    const resourceClient = await getAuthenticatedResourceClient();
    const dto = await resourceClient.findOneAdvancedDriverAssistanceSystem({ params: { id } });
    return { data: _dtoToRecord(dto) };
  }

  async getMany(_resource: string, { ids }: { ids: Array<string> }): Promise<GetManyResult<Adas>> {
    const resourceClient = await getAuthenticatedResourceClient();
    const dtos = await Promise.all(
      ids.map((id) => resourceClient.findOneAdvancedDriverAssistanceSystem({ params: { id } })),
    );
    return { data: dtos.map(_dtoToRecord) };
  }

  async getList(_resource: string, { pagination, sort, filter }: GetListParams): Promise<GetListResult<Adas>> {
    const resourceClient = await getAuthenticatedResourceClient();
    const dtos = await resourceClient.findAllAdvancedDriverAssistanceSystems();
    const records = dtos.map(_dtoToRecord);
    return applyPagination(applySort(applyFilter(records, filter), sort), pagination);
  }

  async getManyReference(
    resource: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Adas>> {
    return this.getList(resource, { filter: { ...filter, [target]: id }, sort, pagination });
  }

  async create(_resource: string, { data }: CreateParams<Adas>): Promise<CreateResult<Adas>> {
    const createDto = schemas.CreateAdvancedDriverAssistanceSystemDto.parse(data);
    const resourceClient = await getAuthenticatedResourceClient();
    const dto = await resourceClient.createAdvancedDriverAssistanceSystem(createDto);
    return { data: _dtoToRecord(dto) };
  }

  async update(_resource: string, { id, previousData, data }: UpdateParams<Adas>): Promise<UpdateResult<Adas>> {
    const delta = Object.fromEntries(Object.entries(data).filter(([k, v]) => !_isEqual(v, previousData[k])));
    const updateDto = schemas.UpdateAdvancedDriverAssistanceSystemDto.parse(delta);
    const resourceClient = await getAuthenticatedResourceClient();
    const dto = await resourceClient.updateAdvancedDriverAssistanceSystem(updateDto, { params: { id } });
    return { data: _dtoToRecord(dto) };
  }
}

function _dtoToRecord(dto: AdasDto): Adas {
  // Make sure we got a valid Adas record from the server ...
  return schemas.AdvancedDriverAssistanceSystemDto.parse(dto);
}

export const adasProvider = new AdasProvider() as DataProvider;
