import { MouseEventHandler, useState } from "react";

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu: MouseEventHandler<HTMLElement> = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return { anchorEl, open, openMenu, closeMenu };
};
