import AdasIcon from "@mui/icons-material/BlurOn";
import { Create, Datagrid, Edit, List, required, Resource, SaveButton, TextField, TextInput } from "react-admin";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { CloneIconButton } from "../buttons/CloneIconButton";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { Form2 } from "../misc/Form2";
import { Column } from "../misc/Column";
import { overviewCardStyle } from "../misc/backofficeTheme";
import SaveIcon from "@mui/icons-material/SaveAlt";
import { Row } from "../misc/Row";
import { type Adas } from "../providers/adasProvider";

const filters = [<TextInput label="Suche" source="q" alwaysOn resettable />];

function AdasList() {
  return (
    <List
      title={<BackofficeListTitle />}
      filters={filters}
      pagination={false}
      sort={/* initial sort order: */ { field: "name", order: "ASC" }}
      exporter={false}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false} sx={{ "& td:last-child": { width: "1px" } }}>
        <TextField label="Name" source="name" />
        <TextField label="Beschreibung" source="description" />
        <Row gap={1}>
          <ShowIconButton />
          <CloneIconButton />
        </Row>
      </Datagrid>
    </List>
  );
}

function AdasCreate() {
  return (
    <Create title={<BackofficeRecordTitle />}>
      <AdasForm mode="create" />
    </Create>
  );
}

function AdasEdit() {
  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <AdasForm mode="edit" />
    </Edit>
  );
}

function AdasForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <Form2 warnWhenUnsavedChanges>
      <Column sx={{ padding: overviewCardStyle.padding }}>
        <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
        <TextInput label="Beschreibung" source="description" fullWidth validate={required()} />
        <Row>
          <SaveButton icon={<SaveIcon />} />
        </Row>
      </Column>
    </Form2>
  );
}

export const adasResource = (
  <Resource
    key="adas"
    name="adas"
    icon={AdasIcon}
    list={AdasList}
    create={AdasCreate}
    edit={AdasEdit}
    options={{ label: "Assistenzsysteme", createTitle: "Neues Assistenzsystem" }}
    recordRepresentation={(adas: Adas) => adas.name}
  />
);
