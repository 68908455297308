import { Box, Button, Grid, Typography } from "@mui/material";
import { getDownloadURL } from "firebase/storage";
import { useState } from "react";
import { useGetOne, useNotify, useRecordContext } from "react-admin";
import { setUserAvatarOverride } from "../../api/backoffice.api";
import { ImageUploadField } from "../../fields/ImageUploadField";
import { Instructor } from "../../providers/instructorsProvider";
import { gcs } from "../../utils/storage";
import { FeatureTogglesInput } from "../../inputs/FeatureTogglesInput";
import { InstructorGrantsInput } from "../../inputs/InstructorGrantsInput";
import { Column } from "../../misc/Column";
import { RolesInput, EntitledForDrivingSchoolsBox } from "../../resources/autovioEmployeesResource";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { AutovioEmployee } from "../../providers/autovioEmployeesProvider";
import { Form2 } from "../../misc/Form2";
import { overviewCardStyle } from "../../misc/backofficeTheme";
import { FormCard } from "../../misc/FormCard";

export function InstructorAdminForm() {
  return (
    <Grid container spacing="30px">
      <Grid item xs={6}>
        <Column gap="30px">
          <AvatarOverrideEdit />
          <InstructorRolesForm />
        </Column>
      </Grid>
      <Grid item xs={6}>
        <FormCard title="Feature Toggles">
          <FeatureTogglesInput />
          <Typography sx={{ fontWeight: "bold", fontSize: 15, my: "15px" }}>Berechtigungen</Typography>
          <InstructorGrantsInput />
        </FormCard>
      </Grid>
    </Grid>
  );
}

const AvatarOverrideEdit = () => {
  const instructor = useRecordContext<Instructor>();
  const instructorId = instructor.id;
  const notify = useNotify();
  const [files, setFiles] = useState<File[]>([]);
  return (
    <Box sx={overviewCardStyle}>
      <Form2>
        <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "15px" }}>Avatar Bild</Typography>
        <ImageUploadField defaultImageUrl={instructor.avatarOverrideUrl} onDropImage={(files) => setFiles(files)} />
        <Button
          sx={{ maxWidth: "200px", mt: "20px" }}
          variant="contained"
          disabled={files.length !== 1}
          onClick={async () => {
            const file = files[0];
            try {
              const result = await gcs.uploadFile(
                file,
                (ext) => `/users/${instructorId.toString()}/avatarOverride/avatar_override.${ext}`,
              );
              await setUserAvatarOverride(instructorId.toString(), await getDownloadURL(result.ref));
            } catch (error) {
              console.error("Failed to upload avatar image", error);
              notify("Fehler beim Hochladen des Avatar Bildes", { type: "error" });
              return;
            }
            notify("Avatar Bild erfolgreich gespeichert", { type: "success" });
          }}
        >
          Speichern
        </Button>
      </Form2>
    </Box>
  );
};

const InstructorRolesForm = () => {
  const instructor = useRecordContext<Instructor>();
  // FYI: A record with null value was found in the dev environment.
  const { data: instructorUser, isLoading } = useGetOne<AutovioEmployee>("autovioEmployees", { id: instructor.id });

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!instructorUser) {
    // Checking the record (instructorUser) before rendering the form to avoid the "EditBase" default behaviour
    // of redirecting to the listing view when the record is not found.
    return null;
  }

  return (
    <FormCard
      title="Rollen"
      resource="autovioEmployees"
      id={instructor.id}
      transform={(data) => ({
        ...data,
        entitledForDrivingSchools:
          data.roles?.includes("dispatcher") || data.roles?.includes("drivingSchoolCustomerService")
            ? data.entitledForDrivingSchools
            : [],
      })}
    >
      <RolesInput label="" />
      <EntitledForDrivingSchoolsBox />
    </FormCard>
  );
};
