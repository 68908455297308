import type { DialogProps } from "../misc/DialogProps";
import { Box, Dialog, DialogContent } from "@mui/material";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { AccountBalance, DrivingSchoolAccounts } from "../details/student/AccountBalance";
import { grants } from "../backoffice.access_control";
import { useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { GiftMoneyButton } from "../buttons/GiftMoneyButton";
import { MonetaryFlowEnum } from "../generated/backendClient";

export function AppliedCreditsDialog({
  accounts,
  monetaryFlow,
  ...dialogProps
}: { accounts?: DrivingSchoolAccounts; monetaryFlow?: MonetaryFlowEnum } & DialogProps) {
  const student = useRecordContext<Student>();
  const [{ drivingSchoolId }] = useAutovioContext();
  return (
    <Dialog {...dialogProps}>
      <DialogCloseButton onClick={dialogProps.onClose} />
      <DialogContent sx={{ width: 500, height: 680 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
          <div />
          {grants.includes("giftCredits:add") && (
            <GiftMoneyButton drivingSchoolId={drivingSchoolId ?? ""} studentId={student.id} />
          )}
        </Box>
        {monetaryFlow === "TRANSFER" ? (
          <AccountBalance key="contingents" title="Verschenktes Guthaben" accountInfo={accounts?.credits_contingents} />
        ) : (
          <>
            <AccountBalance
              key="freecredits"
              title="Verschenktes Guthaben"
              accountInfo={accounts?.free_credits}
              showAmount={false}
            />
            <AccountBalance
              key="startcredits"
              title="Start Credits"
              accountInfo={accounts?.start_credits}
              showAmount={false}
            />
            <AccountBalance key="balance" title="Saldo" accountInfo={accounts?.balance} showAmount={false} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
