import { DateTime } from "luxon";
import { useDateFromHashOrNow } from "../../hooks/useDateFromHashOrNow";
import { useState } from "react";
import deLocale from "@fullcalendar/core/locales/de";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { autovioCalendarEventToEventInput, EventContent } from "../../misc/calendar/CalendarEventCardContent";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { eventClassNames } from "../../utils/calendar";
import { AddAppointmentDialog } from "../../dialogs/AddAppointmentDialog/AddAppointmentDialog";
import FullCalendar from "@fullcalendar/react";
import { Column } from "../../misc/Column";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { useDialog } from "../../hooks/useDialog";
import { useGetManyReference, useGetRecordId, useRecordContext } from "react-admin";
import { useRefetchCalendarEventsCounter } from "../../hooks/useRefetchCalendarEventsCounter";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { Vehicle } from "../../providers/resourceProviders";

export function ResourceCalendar() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const resourceId = useGetRecordId();
  const vehicle = useRecordContext<Vehicle>();
  const date = useDateFromHashOrNow();
  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: date.startOf("week").toISODate(),
    // date.endOf("week") returns a DateTime for the week's sunday at 23:59:59.999,
    // but we need the date of next weeks monday, therefore we add 13 hours ...
    to: date.endOf("week").plus({ hour: 13 }).toISODate(),
  });
  const refetchCounter = useRefetchCalendarEventsCounter();
  const { data, isLoading, error } = useGetManyReference<AutovioCalendarEvent>(
    "calendarEvents",
    {
      target: "resourceId",
      id: resourceId,
      filter: { dateRange, drivingSchoolId },
      pagination: { page: 1, perPage: 9999 },
      meta: { refetchCounter },
    },
    {
      enabled: !!(resourceId && drivingSchoolId),
    },
  );
  const minHour = data?.reduce((acc, it) => Math.min(acc, it.start.hour), 6) ?? 6;
  const { dialogProps, openDialog } = useDialog();

  if (!drivingSchoolId) {
    return null;
  }

  return (
    <Column>
      <FullCalendar
        timeZone="Europe/Berlin"
        locales={[deLocale]}
        height="auto"
        slotMinTime={`0${minHour}:00:00`}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        eventMinHeight={1}
        headerToolbar={{
          left: "title",
          center: "",
          right: `prev today next ${vehicle.type === "simulator" ? "addAppointmentButton " : ""}timeGridDay,timeGridWeek,dayGridMonth`,
        }}
        customButtons={{
          addAppointmentButton: { text: "＋\u00A0Termin", click: openDialog, hint: "Neuen Termin hinzufügen" },
        }}
        initialDate={date.toISO()}
        initialView="timeGridWeek"
        scrollTime="08:00:00"
        allDaySlot={false}
        editable={false}
        weekNumbers={true}
        dayMaxEvents={true}
        nowIndicator={true}
        events={data?.map(autovioCalendarEventToEventInput) ?? []}
        eventClassNames={eventClassNames}
        eventContent={(eventInfo) => <EventContent eventInfo={eventInfo} mode="ResourceCalendar" />}
        datesSet={(dates) => {
          const from = DateTime.fromJSDate(dates.start, { zone: "Europe/Berlin" }).toISODate();
          const to = DateTime.fromJSDate(dates.end, { zone: "Europe/Berlin" }).toISODate();
          if (dateRange.from !== from || dateRange.to !== to) {
            setDateRange({ from, to });
          }
        }}
        dateClick={AddAppointmentDialog.fullCalendarDateClickHandler(openDialog)}
      />
      <AddAppointmentDialog
        {...dialogProps}
        drivingSchoolId={drivingSchoolId}
        vehicle={vehicle}
        tabs={["simulatorDrivingLesson"]}
      />
      {(isLoading || error || !vehicle) && <LoadingIndicator />}
    </Column>
  );
}
