import type { Student } from "../providers/studentsProvider";
import { DialogProps } from "../misc/DialogProps";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { Datagrid, List, TextField } from "react-admin";
import { EmptyState } from "../misc/EmptyState";
import { ChipsField } from "../fields/ChipsField";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import * as React from "react";
import { cloneElement } from "react";
import { useSetRecoilState } from "recoil";
import { bookedQuoteShownInBookedQuoteDialogState } from "./BookedQuoteDialog";
import { useThemeColor } from "../hooks/useThemeColor";
import { BookedQuote } from "../providers/bookedQuotesProvider";

export function BookedQuotesDialog({
  student,
  addBundleButton,
  ...dialogProps
}: { student: Student; addBundleButton: React.ReactElement } & DialogProps) {
  const closeDialog = dialogProps.onClose;
  const setBookedQuoteShownInDialog = useSetRecoilState(bookedQuoteShownInBookedQuoteDialogState);
  const primaryColor = useThemeColor("primary");

  return (
    <Dialog {...dialogProps} maxWidth="md">
      <DialogTitle>Gebuchte Pakete</DialogTitle>
      <DialogCloseButton onClick={closeDialog} />
      <DialogContent>
        <List
          resource="bookedQuotes"
          filter={{ studentId: student.id }}
          sort={{ field: "name", order: "ASC" }}
          exporter={false}
          pagination={false}
        >
          <Datagrid
            rowClick={(_, __, bookedQuote) => {
              closeDialog();
              setBookedQuoteShownInDialog(bookedQuote as BookedQuote);
              return false;
            }}
            bulkActionButtons={false}
            empty={<EmptyState label="Noch keine Pakete gebucht." />}
          >
            <TextField label="Name" source="bundleName" />
            <ChipsField label="Führerscheinklasse(n)" source="drivingLicenseClasses" />
            <EyeIcon className="only-shown-when-row-is-hovered" style={{ fill: primaryColor.main }} />
          </Datagrid>
        </List>
      </DialogContent>
      <DialogActions>
        {cloneElement(addBundleButton, {
          onClick: () => {
            closeDialog();
            addBundleButton.props.onClick();
          },
        })}
      </DialogActions>
    </Dialog>
  );
}
