import { List, useGetList, useGetOne, useRecordContext } from "react-admin";
import { Instructor } from "../../providers/instructorsProvider";
import { StudentsDataGrid } from "../../lists/StudentsDataGrid";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";

export function InstructorStudentsList() {
  const instructor = useRecordContext<Instructor>();
  const drivingSchoolId = instructor?.drivingSchoolId;
  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: drivingSchoolId },
    { enabled: !!drivingSchoolId },
  );
  const { data: simulators } = useGetList(
    "simulators",
    {
      filter: { entitledDrivingSchoolId: drivingSchoolId },
      pagination: { page: 1, perPage: 2 },
      sort: { field: "id", order: "DESC" },
    },
    {
      enabled: !!drivingSchoolId,
    },
  );
  const showSimulatorDrivingLessons = !!simulators?.length;

  if (!(instructor && drivingSchool && simulators)) {
    return <LoadingIndicator />;
  }

  return (
    <List
      resource="students"
      storeKey={`instructorStudents@${instructor.id}`}
      title=" " // <-- prevent that the default list title is rendered
      filter={{ instructorIds: [instructor.id], status: ["active", "onHold", "outstandingPayments"] }}
      sort={/* initial sort order: */ { field: "name", order: "ASC" }}
      empty={false}
      exporter={false}
    >
      <StudentsDataGrid drivingSchool={drivingSchool} showSimulatorDrivingLessons={showSimulatorDrivingLessons} />
    </List>
  );
}
