import EditIcon from "@mui/icons-material/EditOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import {
  LinearProgress,
  useGetIdentity,
  useGetOne,
  useGetRecordId,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api.js";
import { grants } from "../backoffice.access_control.js";
import { reportError } from "../backoffice.utils.js";
import { useDialog } from "../hooks/useDialog.js";
import { DialogCloseButton } from "../misc/DialogCloseButton.js";
import { FundingInstructions } from "../providers/backendUserProvider";
import type { Student } from "../providers/studentsProvider.js";

function CreateFundingInstructionsDialogButton() {
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <IconButton onClick={openDialog}>
        <EditIcon />
      </IconButton>
      <CreateFundingInstructionsDialog {...dialogProps} />
    </>
  );
}

interface CreateFundingInstructionsDialogProps {
  open: boolean;
  onClose: () => void;
}

function CreateFundingInstructionsDialog({ open, onClose }: CreateFundingInstructionsDialogProps) {
  const student = useRecordContext<Student>();
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const { identity } = useGetIdentity();

  const createFundingInstructions = useCallback(async () => {
    try {
      const backendClient = await getAuthenticatedBackendClient();
      setIsLoading(true);
      await backendClient.user.userFundingInstructionsRetrieve({ id: student.id, forceShow: true });
      setIsLoading(false);
      refresh();
      notify("Kontodaten erfolgreich angelegt.", { type: "success" });
    } catch (error) {
      reportError(`Failed to create funding instructions for student ${student?.id}`, error);
      notify("Fehler beim Anlegen der Kontodaten.", { type: "error" });
    } finally {
      onClose();
    }
  }, [student]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Kontodaten für Überweisung anlegen</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <DialogContentText>
          Möchtest du für <strong>{student.name}</strong> die Kontodaten für Überweisungen anlegen?
        </DialogContentText>
        <Alert severity="warning" style={{ margin: "20px 0" }}>
          Bitte bedenke, dass diese Kontodaten nur für diesen Fahrschüler gültig sind und nicht an andere Fahrschüler
          weitergegeben werden dürfen, da es sonst zu Fehlbuchungen kommen kann.
        </Alert>
        {(identity?.id === "AcZqhBM83gYUOhtbE6MIMB2v5Sg2" || identity?.id === "4klU40hJrva63mL8fX8ictOYReD3") && (
          <Alert severity="info" style={{ margin: "20px 0" }}>
            Der Mitarbeiter des Monats ist <strong>Carsten</strong>!
            <img src="/carsten_logo.jpg" alt="Carsten" style={{ maxWidth: "100%" }} />
            Das AUTOVIO Team dankt dir für deinen Einsatz! Wir merken wie sehr du dich bemühst...
            <br />
            <br />
            <br /> ❤️
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={createFundingInstructions} autoFocus>
          Anlegen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export function FundingInstructionsField() {
  const studentId = useGetRecordId() as string;
  const { data, isLoading, error } = useGetOne<FundingInstructions>("fundingInstructions", { id: studentId });
  if (isLoading) {
    return <LinearProgress timeout={0} />;
  }
  if (!data || error) {
    return (
      <Typography component="span" variant="body2">
        Fehler bei der Abfrage
      </Typography>
    );
  }
  if (!data.use_bank_transfer) {
    return (
      <Stack direction="row" spacing={1} alignItems="center" whiteSpace={"pre-wrap"}>
        <Typography component="span" variant="body2">
          Kunde ist nicht für Überweisung freigeschaltet
        </Typography>
        {grants.includes("FundingInstructions:create") && <CreateFundingInstructionsDialogButton />}
      </Stack>
    );
  }
  return (
    <Typography component="span" variant="body2">
      Name: {data.account_holder_name}
      <br />
      IBAN: {data.iban}
      <br />
      BIC: {data.bic}
      <br />
      Referenz: Bitte Rechnungsnummer angeben
    </Typography>
  );
}
