import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const ResourceTypeEnum = z.enum(["car", "motorcycle", "trailer", "simulator"]);
const GearTypeEnum = z.enum(["automatic", "manual", "electric"]);
const CreateResourceDto = z
  .object({
    name: z.string().min(1),
    type: ResourceTypeEnum,
    description: z.string().optional(),
    entitledUsers: z.array(z.string()).optional(),
    entitledDrivingSchools: z.array(z.string()).optional(),
    car: z
      .object({
        gearType: GearTypeEnum,
        plateNumber: z.string(),
        advancedDriverAssistanceSystems: z.array(z.object({ id: z.string() }).passthrough()).optional(),
      })
      .passthrough()
      .nullish(),
    motorcycle: z
      .object({
        plateNumber: z.string().min(1),
        advancedDriverAssistanceSystems: z.array(z.object({ id: z.string() }).passthrough()).optional(),
      })
      .passthrough()
      .nullish(),
    trailer: z.object({ plateNumber: z.string().nullable() }).partial().passthrough().nullish(),
    simulator: z
      .object({ branchId: z.string().uuid(), availabilities: z.array(z.array(z.unknown())) })
      .passthrough()
      .nullish(),
  })
  .passthrough();
const ResourceDto = z
  .object({
    id: z.string(),
    name: z.string().min(1),
    type: ResourceTypeEnum,
    description: z.string().optional(),
    isShared: z.boolean(),
    entitledUsers: z.array(z.string()).optional(),
    entitledDrivingSchools: z.array(z.string()).optional(),
    car: z
      .object({
        gearType: GearTypeEnum,
        plateNumber: z.string(),
        advancedDriverAssistanceSystems: z
          .array(z.object({ id: z.string(), name: z.string(), description: z.string() }).passthrough())
          .optional(),
      })
      .passthrough()
      .nullish(),
    motorcycle: z
      .object({
        plateNumber: z.string().min(1),
        advancedDriverAssistanceSystems: z
          .array(z.object({ id: z.string(), name: z.string(), description: z.string() }).passthrough())
          .optional(),
      })
      .passthrough()
      .nullish(),
    trailer: z.object({ plateNumber: z.string().nullable() }).partial().passthrough().nullish(),
    simulator: z
      .object({ branchId: z.string().uuid(), availabilities: z.array(z.array(z.unknown())) })
      .passthrough()
      .nullish(),
    deletedAt: z.string().datetime({ offset: true }).nullish(),
  })
  .passthrough();
const UpdateResourceDto = z
  .object({
    name: z.string().min(1),
    type: ResourceTypeEnum,
    description: z.string(),
    entitledUsers: z.array(z.string()),
    entitledDrivingSchools: z.array(z.string()),
    car: z
      .object({
        gearType: GearTypeEnum,
        plateNumber: z.string(),
        advancedDriverAssistanceSystems: z.array(z.object({ id: z.string() }).passthrough()),
      })
      .partial()
      .passthrough(),
    motorcycle: z
      .object({
        plateNumber: z.string().min(1),
        advancedDriverAssistanceSystems: z.array(z.object({ id: z.string() }).passthrough()),
      })
      .partial()
      .passthrough(),
    trailer: z.object({ plateNumber: z.string().nullable() }).partial().passthrough(),
    simulator: z
      .object({ branchId: z.string().uuid(), availabilities: z.array(z.array(z.unknown())) })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const CreateAdvancedDriverAssistanceSystemDto = z.object({ name: z.string(), description: z.string() }).passthrough();
const AdvancedDriverAssistanceSystemDto = z
  .object({ id: z.string(), name: z.string(), description: z.string() })
  .passthrough();
const UpdateAdvancedDriverAssistanceSystemDto = z
  .object({ name: z.string(), description: z.string() })
  .partial()
  .passthrough();

export const schemas = {
  ResourceTypeEnum,
  GearTypeEnum,
  CreateResourceDto,
  ResourceDto,
  UpdateResourceDto,
  CreateAdvancedDriverAssistanceSystemDto,
  AdvancedDriverAssistanceSystemDto,
  UpdateAdvancedDriverAssistanceSystemDto,
};

const endpoints = makeApi([
  {
    method: "post",
    path: "/adas",
    alias: "createAdvancedDriverAssistanceSystem",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateAdvancedDriverAssistanceSystemDto,
      },
    ],
    response: AdvancedDriverAssistanceSystemDto,
  },
  {
    method: "get",
    path: "/adas",
    alias: "findAllAdvancedDriverAssistanceSystems",
    requestFormat: "json",
    parameters: [
      {
        name: "filter",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.array(AdvancedDriverAssistanceSystemDto),
  },
  {
    method: "patch",
    path: "/adas/:id",
    alias: "updateAdvancedDriverAssistanceSystem",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateAdvancedDriverAssistanceSystemDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: AdvancedDriverAssistanceSystemDto,
  },
  {
    method: "get",
    path: "/adas/:id",
    alias: "findOneAdvancedDriverAssistanceSystem",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: AdvancedDriverAssistanceSystemDto,
  },
  {
    method: "delete",
    path: "/adas/:id",
    alias: "removeAdvancedDriverAssistanceSystem",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/healthz",
    alias: "getHealthz",
    requestFormat: "json",
    response: z.string(),
  },
  {
    method: "post",
    path: "/resource",
    alias: "createResource",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateResourceDto,
      },
    ],
    response: ResourceDto,
  },
  {
    method: "get",
    path: "/resource",
    alias: "findAllResources",
    requestFormat: "json",
    parameters: [
      {
        name: "filter",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.array(ResourceDto),
  },
  {
    method: "patch",
    path: "/resource/:id",
    alias: "updateResource",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateResourceDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: ResourceDto,
  },
  {
    method: "get",
    path: "/resource/:id",
    alias: "findOneResource",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: ResourceDto,
  },
  {
    method: "delete",
    path: "/resource/:id",
    alias: "removeResource",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/resource/:id",
    alias: "restore",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
