import { BooleanField, Datagrid, List, TextField, useGetRecordId, useCreatePath } from "react-admin";
import { useNavigate } from "react-router";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { EmptyState } from "../../misc/EmptyState";
import { Row } from "../../misc/Row";
import { ShowIconButton } from "../../buttons/ShowIconButton";

export function InstructorVehiclesList() {
  const instructorId = useGetRecordId();
  const createPath = useCreatePath();
  const navigate = useNavigate();
  const [{ drivingSchoolId }] = useAutovioContext();

  return (
    <List
      resource="vehicles"
      filter={{ entitledDrivingSchoolId: drivingSchoolId, entitledUserId: instructorId }}
      sort={{ field: "name", order: "ASC" }}
      title=" "
      empty={false}
      exporter={false}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={(_, __, record) => {
          navigate(createPath({ type: "edit", resource: `${record.type}s`, id: record.id }));
          return false;
        }}
        empty={<EmptyState label="Keine Fahrzeuge zugewiesen." />}
      >
        <TextField label="Name" source="name" />
        <TextField label="Notizen" source="description" />
        <TextField label="Kennzeichen" source="car.plateNumber" />
        <BooleanField label="Geteilt" source="isShared" />
        <Row gap={1}>
          <ShowIconButton resourceGetter={(record) => `${record.type}s`} />
        </Row>
      </Datagrid>
    </List>
  );
}
