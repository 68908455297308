import { Button, useNotify } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { DialogCloseButton } from "./DialogCloseButton";

export function SimpleConfirmDialogButton({
  icon,
  label,
  dialogTitle,
  action,
  actionDisabled,
  dialogCtaTitle,
  content,
  variant = "outlined",
  color,
  disabled,
  onSuccess,
}: {
  icon?: ReactNode;
  label: string;
  disabled?: boolean;
  dialogTitle: string;
  action: () => void | Promise<void>;
  actionDisabled?: boolean;
  dialogCtaTitle: string;
  content: ReactNode | string;
  variant?: "outlined" | "contained";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  onSuccess?: () => void;
}) {
  if (disabled === undefined) disabled = false;
  if (actionDisabled === undefined) actionDisabled = false;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const onClose = () => setOpen(false);

  const actionWrapper = async () => {
    setLoading(true);
    let success = false;
    try {
      await action();
      success = true;
    } catch (e) {
      console.error(e);
      notify("Fehler: " + (e as Error).message, { type: "error" });
    } finally {
      setLoading(false);
    }
    setOpen(false);
    if (success) {
      if (onSuccess) {
        onSuccess();
      } else {
        notify("Aktion erfolgreich ausgeführt!", { type: "success" });
      }
    }
  };
  const onClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };
  return (
    <>
      <Button variant={variant} color={color} startIcon={icon} label={label} disabled={disabled} onClick={onClick} />
      <Dialog
        open={open}
        onClose={(_, __) => {
          // prevent closing the dialog when the action is still running
          if (loading) return;
          onClose();
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogCloseButton onClick={onClose} disabled={loading} />
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color={color}
            onClick={actionWrapper}
            loading={loading}
            disabled={actionDisabled}
          >
            {dialogCtaTitle}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
