import { Row } from "../../misc/Row";
import { Student } from "../../providers/studentsProvider";
import { Money } from "../../misc/Money";
import { Column } from "../../misc/Column";
import { Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { autovioColors } from "../../misc/backofficeTheme";

export const DunningProcessTimeline = () => {
  const student = useRecordContext<Student>();

  return (
    <Row
      sx={{
        height: "55px",
        mb: "32px",
        width: "100%",
        border: `1px solid ${autovioColors.borderGrey}`,
        borderRadius: "12px",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {_stages.map(({ key, title, stageDateProp, renderSubtitle, isLastFulfilledStep }, index) => {
        const subTitle = renderSubtitle ? renderSubtitle(student) : null;
        const stageFulfilmentDate = stageDateProp ? student.dunningProcess?.[stageDateProp] : undefined;
        const isFulfilledStage = key === "negativeBalance" || stageFulfilmentDate !== undefined;
        const isLastFulfilledStage = isFulfilledStage && isLastFulfilledStep ? isLastFulfilledStep(student) : false;
        const titleColor = isFulfilledStage ? autovioColors.white : "#9b9b9b";
        const subTitleColor = isFulfilledStage ? titleColor : autovioColors.green;
        let backgroundColor = autovioColors.white;
        if (key === "paid") {
          backgroundColor = autovioColors.greyUltraLight;
        } else if (isFulfilledStage) {
          if (isLastFulfilledStage) {
            backgroundColor = autovioColors.red;
          } else {
            backgroundColor = "#f0a8a8";
          }
        }

        let arrowColor = autovioColors.white;
        if (key === "paid") {
          arrowColor = autovioColors.greyUltraLight;
        } else if (isFulfilledStage) {
          if (isLastFulfilledStage) {
            arrowColor = autovioColors.red;
          } else {
            arrowColor = "#f0a8a8";
          }
        }

        return (
          <Row
            key={key}
            sx={{
              flex: 1,
              height: "100%",
              alignItems: "center",
              backgroundColor,
            }}
          >
            {index > 0 && (
              <TriangleDivider
                arrowColor={arrowColor}
                borderColor={isFulfilledStage ? autovioColors.white : autovioColors.greyUltraLight}
              />
            )}
            <Column
              sx={{
                flex: 1,
                alignItems: "center",
                // pl: "16px",
              }}
            >
              <Typography variant="body2" fontSize="14px" fontWeight={600} color={titleColor}>
                {title}
              </Typography>
              {subTitle ? (
                <Typography variant="subtitle1" fontSize="12px" color={subTitleColor}>
                  {subTitle}
                </Typography>
              ) : null}
            </Column>
          </Row>
        );
      })}
    </Row>
  );
};

interface _Stage {
  key: "negativeBalance" | "blocked" | "reminder1" | "reminder2" | "inkasso" | "paid";
  stageDateProp?:
    | "paymentReminderSentAt"
    | "firstDunningNoticeSentAt"
    | "secondDunningNoticeSentAt"
    | "handedOverToPairFinanceAt";
  title: string;
  renderSubtitle?: (student: Student) => React.ReactNode;
  isLastFulfilledStep?: (student: Student) => boolean;
}

const _stages: Array<_Stage> = [
  {
    key: "negativeBalance",
    title: "Offener Betrag",
    renderSubtitle: (student: Student) => (
      <Money cents={student.dunningProcess?.outstandingAmount ?? student.balance} />
    ),
    isLastFulfilledStep: (student: Student) => !student.dunningProcess?.paymentReminderSentAt,
  },
  {
    key: "blocked",
    stageDateProp: "paymentReminderSentAt",
    title: "Blockiert",
    renderSubtitle: (student: Student) =>
      student.dunningProcess?.paymentReminderSentAt?.toFormat("dd.MM.yyyy") ?? "Schüler blockieren",
    isLastFulfilledStep: (student: Student) => !student.dunningProcess?.firstDunningNoticeSentAt,
  },
  {
    key: "reminder1",
    title: "1. Mahnung",
    stageDateProp: "firstDunningNoticeSentAt",
    renderSubtitle: (student: Student) =>
      student.dunningProcess?.firstDunningNoticeSentAt?.toFormat("dd.MM.yyyy") ??
      (student.dunningProcess?.paymentReminderSentAt ? "Mahnung senden" : ""),
    isLastFulfilledStep: (student: Student) => !student.dunningProcess?.secondDunningNoticeSentAt,
  },
  {
    key: "reminder2",
    title: "2. Mahnung",
    stageDateProp: "secondDunningNoticeSentAt",
    renderSubtitle: (student: Student) =>
      student.dunningProcess?.secondDunningNoticeSentAt?.toFormat("dd.MM.yyyy") ??
      (student.dunningProcess?.firstDunningNoticeSentAt ? "Mahnung senden" : ""),
    isLastFulfilledStep: (student: Student) => !student.dunningProcess?.handedOverToPairFinanceAt,
  },
  {
    key: "inkasso",
    title: "Inkasso",
    stageDateProp: "handedOverToPairFinanceAt",
    renderSubtitle: (student: Student) =>
      student.dunningProcess?.handedOverToPairFinanceAt?.toFormat("dd.MM.yyyy") ?? "",
    isLastFulfilledStep: () => true,
  },
  {
    key: "paid",
    title: "Bezahlt",
  },
];

const TriangleDivider = ({ arrowColor, borderColor }: { arrowColor: string; borderColor: string }) => (
  <Column sx={{ marginLeft: "-20px", border: 0, outline: 0 }}>
    <Row
      sx={{
        height: "56px",
        width: "20px",
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%, 18px 50%)",
        backgroundColor: borderColor,
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          height: "56px",
          width: "18px",
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%, 100% 50%)",
          backgroundColor: arrowColor,
        }}
      />
    </Row>
  </Column>
);
