import { z } from "zod";
import { Cents } from "./Cents";

export const MoneySchema = z.object({
  amount: z.union([z.number(), z.string()]).transform((val) => (typeof val === "string" ? parseFloat(val) : val)),
  currency: z.literal("EUR"),
});

export type Money = z.infer<typeof MoneySchema>;

export function isMoney(x: any): x is Money {
  return x && typeof x === "object" && typeof x.amount === "number" && x.currency === "EUR";
}

export function centsToMoney(cents: Cents): Money {
  return { amount: cents / 100, currency: "EUR" };
}
