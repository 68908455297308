import * as Sentry from "@sentry/react";
import { Loader } from "@googlemaps/js-api-loader";
import { MutableRefObject, useEffect, useRef } from "react";
import { useGetList, useGetOne, useRecordContext } from "react-admin";
import { GeoPoint } from "ts-geopoint";
import { grants } from "../backoffice.access_control";
import { escapeHtml, safeErrorToString } from "../backoffice.utils";
import { Area } from "../providers/catalogProvider";
import { Branch, DrivingSchool, drivingSchoolsProvider } from "../providers/drivingSchoolsProvider";
import { Instructor, instructorsProvider } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import { autovioColors } from "./backofficeTheme";

/** Shows all areas of all driving schools. */
export function AutovioMap() {
  const divRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef(null);
  const { data: areas } = useGetList<Area>("areas", { pagination: { page: 1, perPage: 9999 } });
  const { data: activeDrivingSchools } = useGetList<DrivingSchool>("drivingSchools", {
    filter: { isActive: true },
    pagination: { page: 1, perPage: 999 },
  });
  useEffect(() => {
    const div = divRef.current;
    if (!(areas && activeDrivingSchools && div)) {
      return;
    }
    const activeDrivingSchoolsIds = new Set(activeDrivingSchools.map((it) => it.id));
    void _initMap(div, mapRef, {
      areas: areas.filter((it) => it.drivingSchoolId && activeDrivingSchoolsIds.has(it.drivingSchoolId)),
      renderAreaInfoWindow: async (area) => {
        const safeHtmlSnippets: Array<SafeHtml> = [];
        if (grants.includes("viewAreas")) {
          safeHtmlSnippets.push(_linkToArea(area));
        }
        safeHtmlSnippets.push(await _linkToDrivingSchool(area));
        safeHtmlSnippets.push(await _linksToInstructors(area));
        return safeHtmlSnippets.join("<br /><br />") as SafeHtml;
      },
      activeStudents: [], // <-- don't render student markers on dashboard
      drivingSchools: activeDrivingSchools,
    });
  }, [areas, activeDrivingSchools]);
  return <div ref={divRef} style={{ height: "100%", borderRadius: "16px" }} />;
}

/** Shows all areas and active students of the currently selected driving school. */
export function DrivingSchoolMap() {
  const drivingSchool = useRecordContext<DrivingSchool>();
  const divRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef(null);
  const { data: areas } = useGetList<Area>(
    "areas",
    {
      filter: { drivingSchoolId: drivingSchool.id },
      pagination: { page: 1, perPage: 10000 },
    },
    { enabled: !!drivingSchool },
  );
  const { data: activeStudents } = useGetList<Student>(
    "students",
    {
      filter: { drivingSchoolId: drivingSchool.id, verboseStatus: "Aktiv" },
      pagination: { page: 1, perPage: 1000 },
    },
    { enabled: !!drivingSchool },
  );

  useEffect(() => {
    const div = divRef.current;
    if (!(drivingSchool && areas && activeStudents && div)) {
      return;
    }
    mapRef.current = null;
    void _initMap(div, mapRef, {
      areas,
      renderAreaInfoWindow: async (area) => {
        const safeHtmlSnippets: Array<SafeHtml> = [];
        if (grants.includes("viewAreas")) {
          safeHtmlSnippets.push(_linkToArea(area));
        }
        safeHtmlSnippets.push(await _linksToInstructors(area));
        return safeHtmlSnippets.join("<br /><br />") as SafeHtml;
      },
      activeStudents,
      drivingSchools: drivingSchool ? [drivingSchool] : undefined,
    });
  }, [drivingSchool, areas, activeStudents]);
  return (
    <div
      ref={divRef}
      style={{ height: "500px", borderRadius: 12, borderColor: autovioColors.borderGrey, borderStyle: "solid" }}
    />
  );
}

/** Shows all areas and active students of an instructor. */
export function InstructorMap({ instructor }: { instructor: Instructor }) {
  const instructorId = instructor.id;
  const divRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef(null);
  const { data: areas } = useGetList("areas", {
    filter: { instructorId },
    pagination: { page: 1, perPage: 1000 },
  });
  const { data: activeStudents } = useGetList("students", {
    filter: { verboseStatus: "Aktiv", instructorIds: instructorId },
    pagination: { page: 1, perPage: 1000 },
  });
  const { data: drivingSchool } = useGetOne<DrivingSchool>("drivingSchools", { id: instructor.drivingSchoolId });
  useEffect(() => {
    const div = divRef.current;
    if (!(areas && activeStudents && drivingSchool && div)) {
      return;
    }
    void _initMap(div, mapRef, {
      areas,
      renderAreaInfoWindow: (area) => {
        return grants.includes("viewAreas") ? _linkToArea(area) : ("" as SafeHtml);
      },
      activeStudents,
      drivingSchools: drivingSchool ? [drivingSchool] : undefined,
    });
  }, [drivingSchool, areas, activeStudents]);
  return <div ref={divRef} style={{ height: "100%" }} />;
}

async function _initMap(
  div: HTMLDivElement,
  mapRef: MutableRefObject<any>,
  {
    areas,
    renderAreaInfoWindow,
    activeStudents,
    drivingSchools,
  }: {
    areas: Array<Area> | undefined;
    renderAreaInfoWindow: (area: Area) => SafeHtml | Promise<SafeHtml>;
    activeStudents: Array<Student> | undefined;
    drivingSchools: Array<DrivingSchool> | undefined;
  },
) {
  try {
    let map = mapRef.current;
    if (map) {
      if (typeof map !== "string") {
        _addAreaCircles(map, areas, renderAreaInfoWindow);
        _addStudentMarkers(map, activeStudents);
        _addBranchMarkers(map, drivingSchools);
      }
      return;
    }
    mapRef.current = "Loading Google Maps JavaScript API ...";
    const loader = new Loader({
      apiKey: "AIzaSyCquUFkYm_KbAU0mHEfAWApU3tKbLszdrA",
      version: "weekly",
      language: "de",
      region: "DE",
    });
    const { Map } = await loader.importLibrary("maps");
    const marker = await google.maps.importLibrary("marker");
    // See https://developers.google.com/maps/documentation/javascript/reference/map
    mapRef.current = map = new Map(div, {
      // You can change the map style here:
      // https://console.cloud.google.com/google/maps-apis/studio/styles/5db012010de1eb09?hl=de&project=autovio-prod
      mapId: "25b75bfff2183b93", // See
      center: { lat: 51.165691, lng: 10.451526 },
      zoom: 7,
      disableDefaultUI: true,
      zoomControl: true,
      keyboardShortcuts: false,
    });
    map.__marker__ = marker;
    _addAreaCircles(map, areas, renderAreaInfoWindow);
    _addBranchMarkers(map, drivingSchools);
    _addStudentMarkers(map, activeStudents);
  } catch (error) {
    Sentry.captureMessage("Failed to initialize Google Maps", {
      extra: { level: "warning", error: safeErrorToString(error) },
    });
  }
}

function _addAreaCircles(
  map: any,
  areas: Array<Area> | undefined,
  renderAreaInfoWindow: (area: Area) => SafeHtml | Promise<SafeHtml>,
) {
  if (!areas || areas.length === 0 || map.__has_area_circles__) {
    return;
  }
  map.__has_area_circles__ = true;
  let clickCounter = 0;
  const infoWindow = new google.maps.InfoWindow();
  for (const area of areas) {
    const { drivingSchoolId } = area;
    if (!drivingSchoolId) {
      continue;
    }
    // We assume here that all driving schools have been preloaded (see main.tsx) ...
    const drivingSchool = drivingSchoolsProvider.getOneFromCache(drivingSchoolId)!;
    const color = drivingSchool.isActive ? autovioColors.green : autovioColors.red;
    for (const circle of area.circles) {
      const mapCircle = new google.maps.Circle({
        map,
        center: circle,
        radius: circle.r * 1000,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.35,
      });
      google.maps.event.addListener(mapCircle, "click", async function (event: any) {
        const clickCount = (clickCounter += 1);
        const safeHtml = await renderAreaInfoWindow(area);
        if (safeHtml && clickCounter === clickCount) {
          infoWindow.setPosition(event.latLng);
          infoWindow.setContent(safeHtml);
          infoWindow.open(map);
        }
      });
    }
  }
  // Side effect: fit map to bounding box ...
  const bb = _getBoundingBox(areas);
  map.fitBounds(bb);
  map.panToBounds(bb);
}

function _addStudentMarkers(map: any, activeStudents: Array<Student> | undefined) {
  if (!activeStudents || activeStudents.length === 0 || map.__has_student_markers__) {
    return;
  }
  map.__has_student_markers__ = true;
  for (const student of activeStudents) {
    const geoCoordinates = student.postalAddress?.geoCoordinates;
    if (!geoCoordinates) {
      continue;
    }
    const { AdvancedMarkerElement } = map.__marker__ as google.maps.MarkerLibrary;
    const marker = new AdvancedMarkerElement({
      map,
      position: geoCoordinates,
      title: student.name,
    });
    google.maps.event.addListener(marker, "click", () => browserHistory.push(`/students/${student.id}`));
  }
}

function _addBranchMarkers(map: any, drivingSchools: Array<DrivingSchool> | undefined) {
  if (!drivingSchools || drivingSchools.length === 0 || map.__has_branch_markers__) {
    return;
  }
  // sort driving schools by geo coordinates so that they overlap perspectively correct
  drivingSchools.sort((ds1, ds2) => {
    const geo1 = ds1.postalAddress.geoCoordinates;
    const geo2 = ds2.postalAddress.geoCoordinates;
    if (!geo1 || !geo2) {
      return 0;
    }
    return geo1.lng - geo2.lng;
  });
  map.__has_branch_markers__ = true;
  const { AdvancedMarkerElement, PinElement } = map.__marker__ as google.maps.MarkerLibrary;
  for (const drivingSchool of drivingSchools) {
    const branches =
      drivingSchool.branches.length === 0 ? [{ postalAddress: drivingSchool.postalAddress }] : drivingSchool.branches;
    for (const branch of branches) {
      const geoCoordinates = branch.postalAddress.geoCoordinates;
      if (!geoCoordinates) {
        continue;
      }
      const logo = document.createElement("img");
      logo.src = drivingSchool.logoUrl ?? "/AUTOVIO_logo.svg";
      logo.width = 20;
      logo.height = 20;
      const pin = new PinElement({
        scale: 1.25,
        background: autovioColors.white,
        borderColor: autovioColors.black,
        glyph: logo,
      });
      const marker = new AdvancedMarkerElement({
        map,
        position: geoCoordinates,
        content: pin.element,
        title:
          branches.length === 1
            ? drivingSchool.name
            : `${drivingSchool.name} (${_branchLabel(branch as Branch, drivingSchool)})`,
      });
      google.maps.event.addListener(marker, "click", () => browserHistory.push(`/drivingSchools/${drivingSchool.id}`));
    }
  }
}

function _branchLabel(branch: Branch, drivingSchool: DrivingSchool): string {
  // Use the city if there is only one branch in the city,
  // use the street if all branches of the driving school are in the same city,
  // otherwise use the city and the street ...
  if (drivingSchool.branches.filter((it) => it.postalAddress.city === branch.postalAddress.city).length === 1) {
    return branch.postalAddress.city;
  } else if (drivingSchool.branches.every((it) => it.postalAddress.city === branch.postalAddress.city)) {
    return branch.postalAddress.street;
  } else {
    return `${branch.postalAddress.city}, ${branch.postalAddress.street}`;
  }
}

function _getBoundingBox(areas: Array<Area>) {
  const firstCircle = areas[0].circles[0];
  const [sw, ne] = new GeoPoint(firstCircle.lat, firstCircle.lng).boundingCoordinates(
    firstCircle.r,
    GeoPoint.EARTH_RADIUS_KM,
  );
  const boundingBox = {
    north: ne.latitude(),
    east: ne.longitude(),
    south: sw.latitude(),
    west: sw.longitude(),
  };
  for (const area of areas) {
    for (const circle of area.circles) {
      const [sw, ne] = new GeoPoint(circle.lat, circle.lng).boundingCoordinates(circle.r, GeoPoint.EARTH_RADIUS_KM);
      if (boundingBox.north < ne.latitude()) {
        boundingBox.north = ne.latitude();
      }
      if (boundingBox.east < ne.longitude()) {
        boundingBox.east = ne.longitude();
      }
      if (boundingBox.south > sw.latitude()) {
        boundingBox.south = sw.latitude();
      }
      if (boundingBox.west > sw.longitude()) {
        boundingBox.west = sw.longitude();
      }
    }
  }
  return boundingBox;
}

function _linkToArea(area: Area): SafeHtml {
  const href = `javascript:browserHistory.push('/areas/${escapeHtml(area.id)}')`;
  return `${_label("Area")}<a href="${href}">${escapeHtml(area.name)}</a>` as SafeHtml;
}

async function _linkToDrivingSchool(area: Area): Promise<SafeHtml> {
  const { drivingSchoolId } = area;
  if (!drivingSchoolId) {
    return '<span class="error">Diesem Gebiet ist keiner Fahrschule zugeordnet!</span>' as SafeHtml;
  }
  const { data: drivingSchool } = await drivingSchoolsProvider.getOne("", { id: drivingSchoolId });
  const drivingSchoolName = drivingSchool.name || "???";
  const drivingSchoolHref = `javascript:browserHistory.push('/drivingSchools/${escapeHtml(drivingSchoolId)}')`;
  return `${_label("Fahrschule")}<a href="${drivingSchoolHref}">${escapeHtml(drivingSchoolName)}</a>` as SafeHtml;
}

async function _linksToInstructors(area: Area): Promise<SafeHtml> {
  const { instructorIds } = area;
  const instructors =
    instructorIds && instructorIds.length > 0
      ? (await instructorsProvider.getMany("instructors", { ids: instructorIds })).data
      : [];
  if (instructors.length === 0) {
    return '<span class="error">Diesem Gebiet sind keine Fahrlehrer zugewiesen!</span>' as SafeHtml;
  } else {
    const instructorLinks = instructors
      .sort((instructor1, instructor2) => instructor1.name.localeCompare(instructor2.name))
      .map((instructor) => {
        const href = `javascript:browserHistory.push('/instructors/${escapeHtml(instructor.id)}')`;
        return `<a href="${href}">${escapeHtml(instructor.name)}</a>`;
      });
    return `${_label("Fahrlehrer")}${instructorLinks.join(", ")}` as SafeHtml;
  }
}

function _label(labelText: string): SafeHtml {
  return `<span class="label">${escapeHtml(labelText)}:</span> ` as SafeHtml;
}
