import { GetListParams, GetListResult } from "react-admin";
import { AbstractDataProvider } from "./AbstractDataProvider";
import { DunningProcess } from "../generated/backendClient";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";

class DunningProcessesProvider extends AbstractDataProvider<DunningProcess> {
  async getOne(resource: string, { id }: { id: string }): Promise<{ data: DunningProcess }> {
    if (resource !== "dunningProcesses") {
      throw new Error(`Unexpected resource: ${resource} -- expected: "dunningProcesses"`);
    }
    const backendClient = await getAuthenticatedBackendClient();
    const dunningProcess = await backendClient.dunningProcess.dunningProcessRetrieve({ id });
    return { data: dunningProcess };
  }

  async getList(resource: string, { filter, pagination, sort }: GetListParams): Promise<GetListResult<DunningProcess>> {
    if (resource !== "dunningProcesses") {
      throw new Error(`Unexpected resource: ${resource} -- expected: "dunningProcesses"`);
    }
    const { studentId, ...clientSideFilter } = filter;
    if (!studentId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"studentId": ...}`);
    }
    const backendClient = await getAuthenticatedBackendClient();
    const { results } = await backendClient.dunningProcess.dunningProcessList({ studentId });
    return applyPagination(applySort(applyFilter(results, clientSideFilter), sort), pagination);
  }
}

export const dunningProcessesProvider = new DunningProcessesProvider();
