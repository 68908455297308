import { ReferenceInput, required, SelectInput, TextInput } from "react-admin";
import { VehicleFormToolbar } from "../../misc/VehicleFormToolbar";
import { HiddenInput } from "../../inputs/HiddenInput";
import { Form2 } from "../../misc/Form2";
import { Column } from "../../misc/Column";
import type { SxProps } from "@mui/material";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { WeeklyHoursInput } from "../../inputs/WeeklyHoursInput";

export function SimulatorForm({ mode, sx }: { mode: "create" | "edit"; sx?: SxProps }) {
  const [{ drivingSchoolId }] = useAutovioContext();

  if (!drivingSchoolId) {
    return null;
  }

  return (
    <Form2 warnWhenUnsavedChanges>
      <HiddenInput source="type" value="simulator" />
      <HiddenInput source="entitledDrivingSchools" value={[drivingSchoolId]} />
      <Column sx={sx}>
        <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
        <TextInput label="Notizen" source="description" fullWidth />
        <ReferenceInput label="Filiale" source="simulator.branchId" reference="branches" filter={{ drivingSchoolId }}>
          <SelectInput size="small" validate={required()} />
        </ReferenceInput>
        <WeeklyHoursInput label="Verfügbarkeiten" source="simulator.availabilities" />
        <VehicleFormToolbar />
      </Column>
    </Form2>
  );
}
