import { type PropsWithChildren } from "react";
import { IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { autovioColors } from "./backofficeTheme";
import { useMenu } from "../hooks/useMenu";

export function PopupMenu({ children }: PropsWithChildren) {
  const { anchorEl, openMenu, closeMenu } = useMenu();

  return (
    <div>
      <IconButton sx={{ position: "absolute", top: "17px", right: "57px" }} onClick={openMenu}>
        <MoreVertIcon sx={{ fill: autovioColors.grey }} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {children}
      </Menu>
    </div>
  );
}
